/**
 * @module SalesFlow/router
 */

import { Constants } from 'core/constants';

import Injector from 'core/injector';

import Repos from 'model/repos';

import {Repo} from 'model/repo/repo';
import {BusinessTransactionContext, SubscriptionGroupName} from 'core/ids';
import SubscriptionRepo from 'model/repo/subscription-repo';
import { VluxJsonData } from '@vfde-frontend/gso';

/**
 * @TODO Route focus id
 */
export default abstract class Router {

    protected _repos: Repos;

    private _injector: Injector;

    protected _subscriptionGroup: SubscriptionGroupName;

    protected _subscriptionId: number;

    protected _deviceId: number;

    protected _subscriptionRepo: SubscriptionRepo;

    constructor (repos: Repos, injector: Injector) {

        this._repos = repos;
        this._injector = injector;

        // subscriptionGroup [consumer, young] must be determined so early while repos depend on it
        this.setSubscriptionGroup();

        this.getInjector().getFlow().setSubscriptionGroup(this._subscriptionGroup);

    }

    public getInjector (): Injector {
        return this._injector;
    }

    protected setSubscriptionGroup (): void {

        let subscriptionGroup: SubscriptionGroupName = 'consumer';

        const subscriptionGroupFromOption: string = this.getInjector().getOptions().get('default_subscription_group');

        if (undefined !== subscriptionGroupFromOption ) {
            if ('consumer' === subscriptionGroupFromOption || 'young' === subscriptionGroupFromOption || 'easy' === subscriptionGroupFromOption) {
                subscriptionGroup = subscriptionGroupFromOption;
            }
        }

        const subscriptionGroupFromStorage: string = this.getInjector().getFlow().getSubscriptionGroup();

        if (undefined !== subscriptionGroupFromStorage) {

            if ('consumer' === subscriptionGroupFromStorage || 'young' === subscriptionGroupFromStorage || 'easy' === subscriptionGroupFromStorage) {
                subscriptionGroup = subscriptionGroupFromStorage;
            }

        }

        const subscriptionGroupFromGet: string = this.getInjector().getGetParameter().getSubscriptionGroupGetParam();

        if (undefined !== subscriptionGroupFromGet) {

            if ('consumer' === subscriptionGroupFromGet || 'young' === subscriptionGroupFromGet || 'easy' === subscriptionGroupFromGet) {
                subscriptionGroup = subscriptionGroupFromGet;
            }

        } else {
            // if subscriptionGroup not set, but subscriptionId
            const subscriptionIdFromGet: number = this.getInjector().getGetParameter().getSubscriptionIdGetParam(true);
            if (undefined !== subscriptionIdFromGet) {
                const isYoungSubscriptionId = [Constants.YoungS_Id, Constants.YoungM_Id, Constants.YoungL_Id, Constants.YoungXL_Id];
                if (-1 !== isYoungSubscriptionId.indexOf(subscriptionIdFromGet)) {
                    subscriptionGroup = 'young';
                } else {
                    subscriptionGroup = 'consumer';
                }
            }
        }

        this._subscriptionGroup = subscriptionGroup;

    }

    public getSubscriptionGroup (): SubscriptionGroupName {
        return this._subscriptionGroup;
    }

    /**
     * Return incoming unvalidated tarifIds in order
     * 1) Per get parameter
     * 2) Per storage
     * 3) option Get Parameter
     *
     * So when validate and resolve this: Get will overrule storage and storage will overule option
     *
     */
    public getSubscriptionTarifIds (): number[] {

        // [GET, Storage, Option]
        const incomingSubscriptionIds: number[] = [];

        const subscriptionIdFromOption: number = this.getInjector().getOptions().get('default_' + this.getSubscriptionGroup() + '_subscription_id');

        if (undefined !== subscriptionIdFromOption) {
            incomingSubscriptionIds.push(subscriptionIdFromOption);
        }

        const subscriptionIdFromStorage: number = this.getInjector().getFlow().getSubscriptionId();

        if (undefined !== subscriptionIdFromStorage) {
            incomingSubscriptionIds.push(subscriptionIdFromStorage);
        }

        const subscriptionIdGetParam: number = this.getInjector().getGetParameter().getSubscriptionIdGetParam();
        if (undefined !== subscriptionIdGetParam) {
            incomingSubscriptionIds.push(subscriptionIdGetParam);
        }

        return incomingSubscriptionIds.reverse();

    }

    /**
     *
     * @TODO This should be only callable when repos are loaded
     * @TODO This should resolve not only if tariff exists, it should also validate that tariff is in correct tariff group
     */
    public resolveSubscriptionId (incomingSubscriptionIds: number[]): number {

        const validatedSubscriptionIds = incomingSubscriptionIds.filter((tariffId) => {

            return undefined !== this._subscriptionRepo.getSubscription(tariffId);

        });

        if (0 === validatedSubscriptionIds.length) {
            return undefined;
        }

        return this._subscriptionRepo.getSubscriptionMainId(validatedSubscriptionIds[0]);

    }

    public getSubscriptionId (): number {

        return this._subscriptionId;
    }

    public getDeviceId (): number {
        return this._deviceId;
    }

    public getFocusSubscriptionIds (btx: BusinessTransactionContext = Constants.BTX_BNT): any {

        let subscriptionIds;

        if (Constants.BTX_VVL === btx) {
            subscriptionIds = {
                easy: this.getInjector().getOptions().get('default_easy_subscription_id') || 0,
                consumer: this.getInjector().getOptions().get('default_consumer_subscription_id') || 0,
                young: this.getInjector().getOptions().get('default_young_subscription_id') || 0
            };
        } else {
            subscriptionIds = {
                easy: this.getInjector().getOptions().get('center_tariff_tile_easy_tariff_id') || this.getInjector().getOptions().get('default_easy_subscription_id') || 0,
                consumer: this.getInjector().getOptions().get('center_tariff_tile_consumer_tariff_id') || this.getInjector().getOptions().get('default_consumer_subscription_id') || 0,
                young: this.getInjector().getOptions().get('center_tariff_tile_young_tariff_id') || this.getInjector().getOptions().get('default_young_subscription_id') || 0
            };
        }

        return subscriptionIds;
    }

    /**
     * After reading the VLUX JSON Data it has to be send to the General Offer Service
     */
    protected prepareGeneralSalesObject (vluxData: VluxJsonData, offerHardwareJSON?: any, offerRedPlusJSON?: any): void {

        const btx = this.getInjector().getBtx();
        const salesChannel = this.getInjector().getFlowStateWithSalesChannel().getSalesChannel();

        const generalSalesObjectInterface = this.getInjector().getGeneralSalesObjectInterface();

        /**
         * Fill Basic Data with primary offer data
         */
        generalSalesObjectInterface.initOfferServices(salesChannel, btx, vluxData);

    }

    /**
     * The only async methode of a page should be getting all needed getRepos
     * When all repos are fetched the dispatcher calls bind and everything else can be  synchronous
     */
    public abstract getRepos (): JQueryPromise<any>;

    public abstract validateIncoming (): void;

    public abstract createController (...repos: Repo[]): JQueryPromise<any>;

}
