/**
 * @module SalesFlow/view
 */

import ViewOverlay from './overlay';
import ViewOffer from './accessory-offer';

export default class ViewOverlayAccessoryDetails extends ViewOverlay {

    protected _image: string;
    protected _description: string[];

    constructor (accessoryOffer: ViewOffer) {
        super(accessoryOffer.name);

        this._image = accessoryOffer.nsfImage90_120;
        this._description = accessoryOffer.atomicAccessory.attributes.descriptionAccessory;
    }

    get image (): string {
        return this._image;
    }

    get description (): string[] {
        return this._description;
    }

}
