(function() {
    'use strict';
    vf['product-detail-images'] = {
        settings: {
            target: '.mod-product-detail-images',
        },

        init: function(context) {

            var s = this.settings;
            $(s.target, context).addBack(s.target).each(function() {

                $('.bxslider').bxSlider({
                    pagerCustom: '#bx-pager'
                });

            });
        },
    };
}(vf));
