/**
 * @module SalesFlow/view
 */

import {ViewEvolvedCtasGigakombiFlowCtas} from 'view-evolved/ctas/gigakombi/view-evolved--ctas--gigakombi-flow-ctas';
import Offer from 'view/view/shared/offer/offer';
import Injector from 'core/injector';
import {Constants} from 'core/constants';

/**
 * Only one button here
 * Right: Link to connect more page
 *
 * @export
 * @class DeviceDetailGigakombiCtas
 * @extends {FlowCtas}
 * @todo should probably be integrated into BNT device overview ctas because it's nearly identical
 */
export default class ViewEvolvedCtasGigakombiDeviceOverviewCtas extends ViewEvolvedCtasGigakombiFlowCtas {

    constructor (injector: Injector) {
        super(injector);

    }

    public update (offer: Offer): void {

        if (undefined === offer) {
            return;
        }

        let rightButtonUrl: string = '';

        if (offer.isDevice()) {
            rightButtonUrl = offer.atomicDevice.getDevice().getDetailLink(
                this._injector.getOptions().get('device_detail_prefix')
            );

            rightButtonUrl += '?giga=kombi';
        }
        /*
         * So we are dealing with a simonly offer
         */
        else if (true === offer.isSimOnly()) {
            /*
             * Workaround for unlimited
             * If simonly, use x-sell page instead of red plus overlay
             */
            if (Constants.RedXL_Id === offer.subscriptionId) {
                rightButtonUrl = this._injector.getOptions().get('connectmore_url') + '?giga=kombi';
            }
            /*
             * Easy can not be bought with red plus cards, so simonly for easy means direct to basket
             */
            else {

                rightButtonUrl = '#proceedToBasket';

            }

        }

        this._right.setHref(
            rightButtonUrl
        );

        this._element.html(
            this.getInjector().getTemplates().render('flow-ctas', [this._left, this._right], 'partials')
        );

    }

    protected events (): void {
        super.events();

        this._element.on('click', this._right.getSelector(), (evt: JQueryEventObject) => {

            if ('#proceedToBasket' === this._right.href) {

                if ('Unity' === this._injector.getGigakombi().getVorteil()
                    && -1 === document.cookie.indexOf('MDDKeks')) {

                    this.showUnityCaseOverlay();

                } else {

                    evt.preventDefault();

                    this.createBasketAndProceed();

                }

                return false;
            }

        });

    }

}
