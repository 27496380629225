/**
 * @module SalesFlow/evolved/view
 */

import {ViewEvolvedCtasBntBase} from 'view-evolved/ctas/bnt/view-evolved--ctas--bnt-flow-ctas';
import Offer from 'view/view/shared/offer/offer';
import { TariffGroupName } from 'core/ids';

/**
 * Two buttons in pricebox and the very same in costoverview
 * Left: Link to vvl (As always in bnt)
 * Right: Link to connect more page
 *
 * @export
 * @class DeviceDetailBntCtas
 * @extends {FlowCtas}
 */
export class ViewEvolvedCtasBntDeviceDetailCtas extends ViewEvolvedCtasBntBase {

    public update (offer: Offer): void {

        if (undefined === offer) {
            return;
        }

        const tariffGroup: TariffGroupName = this.getInjector().getFlowState().getTariffGroup();

        this._left.setHref(
            this.getContractProlongationURL(tariffGroup)
        );

        this._right.setHref(
            this.getInjector().getOptions().get('connectmore_url')
        );

        this._element.html(
            this.getInjector().getTemplates().render('flow-ctas', [this._left, this._right], 'partials')
        );

    }

}
