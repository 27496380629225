/**
 * @module SalesFlow/evolved/router
 */

import { RouterEvolvedRedPlusRouter } from './router-evolved--redplus-router';
import { ControllerEvolvedRedPlusDeviceTariff } from 'controller-evolved/redplus/controller-evolved--redplus-device-tariff';
import { Constants } from 'core/constants';
import { ModelEvolvedRepoPurchasableDevice } from 'model-evolved/repo/model-evolved--repo--purchasable-device';

/**
 * @TODO don't use injector as dependency
 */
export class RouterEvolvedRedPlusDeviceTariff extends RouterEvolvedRedPlusRouter {

    /**
     * Return incoming unvalidated tarifIds in order
     * 1) Per get parameter
     * 2) Per storage
     * 3) option Get Parameter
     *
     * So when validate and resolve this: Get will overrule storage and storage will overule option
     *
     */
    public getIncomingDeviceIds (): number[] {

        // [GET, Storage, Option]
        const incomingDeviceIds: number[] = [];

        const deviceIdFromStorage: number = this.getInjector().getFlowStateWithSalesChannel().getAtomicDeviceId();

        if (undefined !== deviceIdFromStorage) {
            incomingDeviceIds.push(deviceIdFromStorage);
        }

        const deviceIdGetParam: number = this.getInjector().getGetParameter().getDeviceIdGetParam();
        if (undefined !== deviceIdGetParam) {
            incomingDeviceIds.push(deviceIdGetParam);
        }

        return incomingDeviceIds.reverse();

    }

    /**
     * @TODO This should be only callable when repos are loaded
     * @TODO This should resolve not only if tariff exists, it should also validate that tariff is in correct tariff group
     */
    public resolveDeviceId (incomingDeviceIds: number[]): number {

        const subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(
            this.resolveSubscriptionId(this.getSubscriptionIds())
        );

        let purchasableRepo: ModelEvolvedRepoPurchasableDevice;

        if (Constants.RedData_MainId === subscription.id) {
            purchasableRepo = this.getReposSupervisor().getPurchasableTabletRepo();
        } else if (Constants.RedKids_MainId === subscription.id)  {
            purchasableRepo = this.getReposSupervisor().getPurchasableDeviceKidsRepo();
        } else {
            purchasableRepo = this.getReposSupervisor().getPurchasableDeviceRepo();
        }

        /**
         * First it looks for deviceId and then for atomicDeviceIds
         * @TODO Ask channel for a valid usecase
         */
        incomingDeviceIds = incomingDeviceIds.map((deviceId) => {

            const checkDevice = purchasableRepo.getDevice(
                deviceId,
                this.getSalesChannel(),
                subscription
            );

            // Id is not an deviceId
            if (undefined === checkDevice) {
                return deviceId;
            }

            return checkDevice.getAtomicDeviceByIndex(0).id;

        });

        const validatedDeviceIds = incomingDeviceIds.filter((deviceId) => {

            const checkAtomicDeviceId = undefined !== purchasableRepo.getDevice(
                deviceId,
                this.getSalesChannel(),
                subscription
            );

            if (true === checkAtomicDeviceId) {
                return true;
            }

            const checkDeviceId = undefined !== purchasableRepo.getAtomicDevice(
                deviceId,
                this.getSalesChannel(),
                subscription
            );

            if (true === checkDeviceId) {
                return true;
            }

            return false;

        });

        if (0 === validatedDeviceIds.length) {
            return undefined;
        }

        return validatedDeviceIds[0];

    }

    public validateIncoming (): void {

        this._subscriptionId = this.resolveSubscriptionId(
            this.getSubscriptionIds()
        );

        this._atomicDeviceId = this.resolveDeviceId(
            this.getIncomingDeviceIds()
        );

    }

    public createController (): JQueryPromise<ControllerEvolvedRedPlusDeviceTariff> {

        const deferred = $.Deferred<any>();

        this.loadReposSupervisor().then(() => {

            /**
             * Send Red+ Information to general sales object
             */
            const customer = this.getInjector().getFlowStateWithSalesChannel().customer;
            const generalSalesObjectInterface = this.getInjector().getGeneralSalesObjectInterface();
            generalSalesObjectInterface.isRedPlus = true;

            const subscriptionKIAS = customer.tariffKiasCode;
            const mainSubscriptionId = this.getReposSupervisor().getSubscriptionRepo().getAtomicSubscriptionIdByKiasId(subscriptionKIAS);

            if (undefined === mainSubscriptionId) {
                generalSalesObjectInterface.redPlusMainTariff = 0;
            } else {
                generalSalesObjectInterface.redPlusMainTariff = mainSubscriptionId;
            }

            this.validateIncoming();
            this._skeletonService.removeSkeletonLoadingClassNames();

            deferred.resolve(
                new ControllerEvolvedRedPlusDeviceTariff (
                    this.getInjector().getFlow().getCustomer(),
                    this.getSalesChannel(),
                    this._atomicDeviceId,
                    this.getSubscriptionId(),
                    this.getFocusSubscriptionIds(),
                    this.getReposSupervisor(),
                    this.getInjector()
                )
            );
        });

        return deferred.promise();

    }

}
