/**
 * @module SalesFlow/evolved/router
 */

import {RouterEvolvedBntFafRouter} from 'router-evolved/familyfriends-bnt/router-evolved--familyfriends-bnt-router';
import {SubscriptionIdPerSalesChannel} from 'core/ids';
import {ControllerEvolvedBntSubscriptionOverview} from 'controller-evolved/bnt/controller-evolved--bnt-subscription-overview';

export class RouterEvolvedFamilyfriendsBntSubscriptionOverview extends RouterEvolvedBntFafRouter {

    /**
     * Get getFocusSubscriptionId in router to be able to used it as fallback for validating device
     *
     */
    public getFocusSubscriptionId (): number {

        const focusSubscriptionIds = this.getFocusSubscriptionIds();

        return focusSubscriptionIds[this.getSalesChannel()];
    }

    public getFocusSubscriptionIds (): SubscriptionIdPerSalesChannel {

        const subscriptionIds: SubscriptionIdPerSalesChannel = {

            familyfriends: this.getInjector().getOptions().get('default_familyfriends_subscription_id') || undefined
        };

        // set recommended subscription and on focus if there is one
        if (undefined !== this.getInjector().getFlowState().getRecommendedSubscriptionId()) {

            const recommendedSubscriptionId = this.getInjector().getFlowState().getRecommendedSubscriptionId();

            // recommendedSubscriptionId could be consumer or young from current portfolio
            // so let's find out for which salesChannel we want the recommended id in focus

            const recommendedSubscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(recommendedSubscriptionId);
            subscriptionIds[recommendedSubscription.subscriptionGroupName] = recommendedSubscriptionId;

        }

        return subscriptionIds;
    }

    /**
     * Return incoming unvalidated tarifIds in order
     * 1) Per storage
     * 2) Per get parameter
     *
     * So when validate and resolve this: Get parameter will overrule storage
     *
     */
    public getSubscriptionIds (): number[] {

        // [GET, Storage, Option]
        const incomingSubscriptionIds: number[] = [];

        const subscriptionIdFromOption: number = this.getInjector().getOptions().get('default_' + this.getTariffGroup() + '_subscription_id');
        if (undefined !== subscriptionIdFromOption) {
            incomingSubscriptionIds.push(subscriptionIdFromOption);
        }

        const subscriptionIdFromStorage: number = this.getInjector().getFlowState().getSubscriptionId();
        if (undefined !== subscriptionIdFromStorage) {
            incomingSubscriptionIds.push(subscriptionIdFromStorage);
        }

        const subscriptionIdGetParam: number = this.getInjector().getGetParameter().getSubscriptionIdGetParam();
        if (undefined !== subscriptionIdGetParam) {
            incomingSubscriptionIds.push(subscriptionIdGetParam);
        }

        return incomingSubscriptionIds.reverse();

    }

    public validateIncoming (): void {

        this._subscriptionId = this.resolveSubscriptionId(
            this.getSubscriptionIds()
        );

    }

    public createController (): JQueryPromise<RouterEvolvedBntFafRouter> {

        const deferred = $.Deferred<any>();

        this.loadReposSupervisor().then(() => {

            this.validateIncoming();

            this._skeletonService.removeSkeletonLoadingClassNames();

            deferred.resolve(
                new ControllerEvolvedBntSubscriptionOverview(
                    this.getFocusSubscriptionIds(),
                    this.getReposSupervisor(),
                    this.getInjector()
                )
            );
        }, function () {
            deferred.reject();
        });

        return deferred.promise();

    }

}
