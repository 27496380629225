/**
 * @module SalesFlow/evolved/router
 */

import {RouterEvolvedInlifeTariffSelection} from 'router-evolved/inlife/router-evolved--inlife-tariff-selection';
import {Constants} from 'core/constants';

/**
 * @TODO don't use injector as dependency
 */
export class RouterEvolvedFamilyFriendsInlifeTariffSelection extends RouterEvolvedInlifeTariffSelection {

    /**
     * For soho sales channel is always soho
     */
    protected setSalesChannel (): void {

        this._salesChannel = Constants.SALESCHANNEL_FAMILY_FRIENDS;

    }

}
