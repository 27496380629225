/**
 * @module SalesFlow/evolved/view
 */

import {ViewEvolvedCtasSharedFlowCta} from 'view-evolved/ctas/shared/view-evolved--ctas--shared--flow-cta';
import Offer from 'view/view/shared/offer/offer';
import {ViewEvolvedCtasGigakombiFlowCtas} from './view-evolved--ctas--gigakombi-flow-ctas';

/**
 * Two buttons in pricebox and the very same in costoverview
 * Left: Link to vvl (As always in bnt)
 * Right: Link to basket
 *
 * @export
 * @class ConnectMoreGigakombiCtas
 * @extends {FlowCtas}
 */
export class ViewEvolvedCtasGigakombiConnectMoreCtas extends ViewEvolvedCtasGigakombiFlowCtas {

    /**
     * Overwriting right button while different text
     */
    protected getRightButton (): ViewEvolvedCtasSharedFlowCta {

        return new ViewEvolvedCtasSharedFlowCta(
            ['nfs-pricebox-confirm'],
            this.getInjector().getStrings().get('pricebox.confirm.basket')
        );

    }

    public update (offer: Offer): void {

        if (undefined === offer) {
            return;
        }

        this._right.setHref(
            '#proceedToBasket'
        );

        this._element.html(
            this.getInjector().getTemplates().render('flow-ctas', [this._left, this._right], 'partials')
        );

    }

    protected events (): void {

        super.events();

        /**
         * From connect more the next step "forward" is the basket
         */
        this._element.on('click', this._right.getSelector(), (evt: JQueryEventObject) => {

            if ('Unity' === this._injector.getGigakombi().getVorteil()
                && -1 === document.cookie.indexOf('MDDKeks')) {

                this.showUnityCaseOverlay();

            } else {

                evt.preventDefault();

                this.createBasketAndProceed();

            }

        });
    }

}
