/**
 * @module SalesFlow/tracking
 */

 import Order from './Order';
import Customer from 'shopbackend/customer';

import {IProduct} from './interfaces/IProduct';
import {IProposition} from './interfaces/IProposition';

/**
 * @internal
 */
export default class VvlOrder extends Order {
    public hasPrivatePricing: boolean = false;
    public hasPersonalOffer: boolean = true;
    public personalOfferCode: any = {};

    constructor (products: IProduct[], propositions: IProposition[], customer: Customer, hasPersonalOffer: boolean, hasPrivatePricing: boolean) {
        super(products, propositions);

        this.hasPrivatePricing = hasPrivatePricing;
        this.hasPersonalOffer = hasPersonalOffer;

        if ('' !== customer.vvlCellcode) {
            this.hasPersonalOffer = true;
            this.personalOfferCode = {};
            this.personalOfferCode.cell = customer.vvlCellcode;
        }

        if ('' !== customer.vvlCamptype) {
            if ('object' !== typeof this.personalOfferCode) {
                this.personalOfferCode = {};
            }
            this.personalOfferCode.camp = customer.vvlCamptype;
        }

        /** Adversitment send error report that hasPersonalOffer should not be true when cell or camp are not set */
        if ('' === customer.vvlCellcode || '' === customer.vvlCamptype) {
            this.hasPersonalOffer = false;
        }
    }
}
