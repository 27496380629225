/**
 * @module SalesFlow/evolved/model/repo
 */

import NsfError from 'core/error';

import Service from 'model/type/service';
import {ModelEvolvedRepoBase} from 'model-evolved/repo/model-evolved--repo--base';

export class ModelEvolvedRepoService extends ModelEvolvedRepoBase {

    private _service: Service[] = [];

    constructor (json: any) {
        super();
        this.rawData = json;
        this.parseData(json);
    }

    /**
     * @TODO create an interface for products json
     */
    protected parseData (json: any): NsfError {

        if (undefined === json.subscriptionGroups) {
            return new NsfError('subscriptionGroups is mandatory when create a ServiceRepo.');
        }

        if (undefined === json.subscriptionGroups.subscriptionGroup) {
            return new NsfError('subscriptionGroups.subscriptionGroup is mandatory when create a ServiceRepo.');
        }

        if (0 === json.subscriptionGroups.subscriptionGroup.length) {
            return new NsfError('subscriptionGroups.subscriptionGroup is empty when create a ServiceRepo.');
        }

        const subscriptionGroup = json.subscriptionGroups.subscriptionGroup[0];

        if ('serviceMobile' !== subscriptionGroup.type && 'discountMobile' !== subscriptionGroup.type) {
            return new NsfError('only types "serviceMobile" and "discountMobile" are supported in ServiceRepo.');
        }

        if (undefined === subscriptionGroup.subscriptions) {
            return new NsfError('subscriptionGroup.subscriptions is mandatory when create a ServiceRepo.');
        }

        if (undefined === subscriptionGroup.subscriptions.subscription) {
            return new NsfError('subscriptionGroup.subscriptions.subscription is mandatory when create a ServiceRepo.');
        }

        const services: Service[] = [];

        const recursiveWalker = function (service: any, serviceType: string) {

            if (undefined !== service.subscriptions && undefined !== service.subscriptions.subscription) {

                for (const subService of service.subscriptions.subscription) {
                    recursiveWalker(subService, serviceType);
                }
            }

            if (undefined === service.backendId || undefined === service.backendId.length) {
                return;
            }
            if (0 === service.backendId.length) {
                return;
            }

            if (undefined === service.backendId[0].value) {
                return;
            }

            /**
             * Every services with attribute [id=183] should be hidden
             * description of this attribute
             * Flag f&#252;r die tempor&#228;re Unterdr&#252;ckung der Anzeige von subscriptions (Workaround bis zur sauberen L&#246;sung mit OLS 2)
             *
             * While this is the only attrubute, that is used right now for services, i parse it right here without attribute repo
             *
             */

            if (undefined !== service.attributes && undefined !== service.attributes.attribute) {

                for (const attribute of service.attributes.attribute) {
                    if ('183' === attribute.id && '1' === attribute.value) {
                        service.hidden = true;
                    }
                }

            }

            service.serviceType = serviceType;

            services.push(new Service(service));

        };

        recursiveWalker(subscriptionGroup, subscriptionGroup.type);

        this._service = services;

    }

    public getOneById (id: number): Service {

        const services: Service[] = this._service.filter((service) => {
            return (id === service.id);
        });

        if (0 === services.length) {
            return undefined;
        }

        return services[0];
    }

    public getOneByKIAS (kias: string): Service {

        const services: Service[] = this._service.filter((service) => {
            return (kias === service.backendId);
        });

        if (0 === services.length) {
            return undefined;
        }

        return services[0];
    }

}
