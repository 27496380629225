/**
 * @module SalesFlow/evolved/view
 */

declare var $: JQueryStatic;

import Injector from 'core/injector';
import AtomicDevice from 'model/type/atomic-device';
import Subscription from 'model/type/subscription';
import DeviceOffer from 'view/view/shared/offer/device-offer';

export default class ViewEvolvedElementVvlDeviceTile {

    private _injector: Injector;
    private _element: JQuery;
    private _deviceOffer: DeviceOffer;

    // Text of the selected bundle, not the value, because the value(AtomicId) is diffeerent in every device
    private _selectedBundleText: string;

    constructor (
        element: JQuery,
        deviceOffer: DeviceOffer,
        injector: Injector
    ) {

        this._element = element;

        this._deviceOffer = deviceOffer;
        this._injector = injector;

        // while objects of this class are created when html ist already rendered we need just events
        this.events();
    }

    public getDeviceOffer (): DeviceOffer {
        return this._deviceOffer;
    }

    public setDeviceOffer (deviceOffer: DeviceOffer): void {
        this._deviceOffer = deviceOffer;
    }

    protected getInjector (): Injector {
        return this._injector;
    }

    public update (deviceOffer: DeviceOffer): void {

        // let's make sure that isLowestOffer is always true when there is no subscription selected
        if (undefined === this.getInjector().getFlow().getSubscriptionId()) {
            deviceOffer.offer.markAsLowestOffer();
        }

        this._element.attr('data-atomic-device-id', deviceOffer.atomicDeviceId);

        this._element.find('img').attr('src', deviceOffer.nsfImage90_120);

        this._element.find('.subscriptionDepended').html(
            this.getInjector().getTemplates().render('device_tile_price', deviceOffer, 'partials')
        );

        this._element.find('.nsf-color-picker').html(
            this.getInjector().getTemplates().render('colors', deviceOffer.colors, 'partials')
        );

        this._element.find('.nsf-size-picker').html(
            this.getInjector().getTemplates().render('sizes', deviceOffer.sizes, 'partials')
        );

        this._element.find('.nsf-bundle-picker').html(
            this.getInjector().getTemplates().render('bundles', deviceOffer.bundles, 'partials')
        );

        this._element.find('.deliveryText').html(deviceOffer.atomicDevice.attributes.deliveryTime);

        if (true === deviceOffer.offer.hasHandyInsuranceIncluded()) {
            this._element.find('.insuranceBox .checkBox').addClass('fixed');
            this._element.find('.insuranceBox .insurance-label-price').html(
                this.getInjector().getTemplates().render('insurance-inklusive-price', deviceOffer, 'partials')
            );
        }
        else {
            this._element.find('.insuranceBox .checkBox').removeClass('fixed');
            this._element.find('.insuranceBox .insurance-label-price').html(
                this.getInjector().getTemplates().render('insurance-inklusive-price', deviceOffer, 'partials')
            );
        }

        const optional = this.getInjector().getFlow().optionalServiceIds.elements;

        // VGOF-5833: Fix issue that insurance could not be unselected when a pass was selected
        if (optional.length === 0 || (-1 === optional.indexOf(158) && -1 === optional.indexOf(159))) {
            this._element.find('.insuranceBox .checkBox').removeClass('selected');
        }
        else {
            this._element.find('.insuranceBox .checkBox').addClass('selected');
        }

    }

    public events (): void {

        this._element.on('click', '.vll-device-accordion-toggle', (evt: JQueryEventObject) => {
            const currentElement: JQuery<Element> = $(evt.currentTarget);
            currentElement
                .find('.more')
                .toggleClass('is-hidden');

            currentElement
                .find('.less')
                .toggleClass('is-hidden');

            currentElement
                .parent()
                .find('.device-list-container')
                .toggleClass('device-accordion-is-open');
        });

        this._element.on('click', '.picker .valueBox', (evt) => {

            /**
             * If the picker is disabled or already selected we don't need to update the deviceOffer
             */
            if (true === $(evt.currentTarget).hasClass('disabled') || true === $(evt.currentTarget).hasClass('selected')) {
                return;
            }

            let atomicDeviceId = parseInt($(evt.currentTarget).data('atomicdeviceid'), 10);

            const selectedColor = $(evt.currentTarget).data('colorname');

            // CO-18293: If the selected color does not have a match for the selected size
            if (!atomicDeviceId) {
                const currentDevice = this._deviceOffer.atomicDevice;
                const currentDeviceColor = currentDevice.attr.color;
                const currentDeviceCapacity = currentDevice.attr.internalMemory;

                const atomicDevices = currentDevice.getDevice().getAtomicDevices();

                // Get all devices with the current color
                const previousColorDevices = atomicDevices.filter((device) => {
                    if (device.attr.color === currentDeviceColor && device.attr.bundledWith !== 'mit Starter-Kit') {
                        return device;
                    }
                });

                // Find lowest capacity of current color
                const currentLowestCapacity = previousColorDevices.map(device => {
                    return device.attr.internalMemory;
                }).reduce((prev, curr) => Math.min( prev, curr ));

                // Find biggest capacity of current color
                const currentBiggestCapacity = previousColorDevices.map(device => {
                    return device.attr.internalMemory;
                }).reduce((prev, curr) => Math.max( prev, curr ));

                // Get all devices with the requested color and no starter kit
                const requestedColorDevices = atomicDevices.filter((device) => {
                    if (device.attr.color === selectedColor && device.attr.bundledWith !== 'mit Starter-Kit') {
                        return device;
                    }
                });

                // Now get only the device in the requested color with the best matching available capacity
                const bestAvailableDevice = requestedColorDevices.reduce((prev, curr) => {
                    switch (true) {
                        case currentDeviceCapacity === currentBiggestCapacity:
                            // Get next lower capacity
                            if (prev.attr.internalMemory < curr.attr.internalMemory) {
                                return curr;
                            }
                            break;
                        case currentDeviceCapacity === currentLowestCapacity:
                        default:
                            // Get next bigger capacity
                            if (prev.attr.internalMemory > curr.attr.internalMemory) {
                                return curr;
                            }
                            break;
                    }

                    return prev;
                });

                atomicDeviceId = bestAvailableDevice.id;
            }

            if (true === isNaN(atomicDeviceId)) {

                return;

            }

            let atomicDevice: AtomicDevice = this._deviceOffer.atomicDevice.device.getAtomicDeviceById(atomicDeviceId);

            const subscription: Subscription = this._deviceOffer.subscription;

            // If an bundles has been selected, there must be only one simmilar device. use this instead of device without chosen bundle
            if (this._selectedBundleText !== undefined && this._selectedBundleText !== '') {

                const attributeQuery: any = {
                    color: atomicDevice.attr.color,
                    internalMemory: atomicDevice.attr.internalMemory,
                    bundledWith: this._selectedBundleText
                };

                // Get the device with the same attributes, for example "mit Starter kit"
                const atomicDevicesWithSameAttr = this._deviceOffer.atomicDevice.device.getAtomicDevicesByAttrs(attributeQuery);

                // If there is more than one simmilar device, something is wrong so do nothing
                if (atomicDevicesWithSameAttr.length === 1) {
                    atomicDevice = atomicDevicesWithSameAttr[0];
                }

            }

            this.getInjector().getEvent().trigger('device-tile@atomicIdChanged', {
                atomicDevice: atomicDevice,
                subscription: subscription
            });

        });

        this._element.on('change', 'select', (evt) => {

            // Set the selected text
            this._selectedBundleText = $(evt.currentTarget).find(':selected').text();

            const atomicDeviceId = parseInt($(evt.currentTarget).val().toString(), 10);

            if (true === isNaN(atomicDeviceId)) {

                return;

            }

            const atomicDevice: AtomicDevice = this._deviceOffer.atomicDevice.device.getAtomicDeviceById(atomicDeviceId);

            const subscription: Subscription = this._deviceOffer.subscription;

            this.getInjector().getEvent().trigger('device-tile@atomicIdChanged', {
                atomicDevice: atomicDevice,
                subscription: subscription
            });

        });

        // @TODO Tracking
        this._element.on('click', '.insuranceBox .checkBox .i-check, .insuranceBox .checkBox .pseudo-checkbox-label', (evt) => {

            const target = $(evt.currentTarget).parents('.checkBox');

            if (target.hasClass('fixed')) {
                return;
            }

            const deviceInsuranceId = this._deviceOffer.offer.getHandyInsuranceServiceId();
            let insuranceBoxSelected = true;

            if (target.hasClass('selected')) {

                this.getInjector().getFlow().optionalServiceIds.removeElement(deviceInsuranceId);
                insuranceBoxSelected = false;

            } else {

                /**
                 * save information in flow
                 */
                this.getInjector().getFlow().optionalServiceIds.addElement(deviceInsuranceId);

            }

            $('.insuranceBox .checkBox').toggleClass('selected');

            const atomicDevice: AtomicDevice = this._deviceOffer.atomicDevice;

            const subscription: Subscription = this._deviceOffer.subscription;

            this.getInjector().getEvent().trigger('device-tile@insuranceChanged', {
                atomicDevice: atomicDevice,
                subscription: subscription,
                insuranceBoxSelected: insuranceBoxSelected
            });

        });

        this._element.on('click', '.device-details', (evt) => {

            this.getInjector().getEvent().trigger('device-tile@openDetailOverlay', {
                deviceOffer: this._deviceOffer
            });

        });

        this.getInjector().getEvent().listen('device-tile@offerChanged:' + this._deviceOffer.atomicDevice.device.id, (eventObject: JQueryEventObject, data: any) => {

            this._deviceOffer = data.deviceOffer;
            this.update(this._deviceOffer);

        });

    }

}
