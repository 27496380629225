/**
 * @module SalesFlow/model/type
 */
import ServiceAttributes from './attributes-service';

export default class Service {

    private _id: number;
    private _label: string;
    private _backendId: string;
    private _serviceType: string;
    private _hidden: boolean;

    private _attributes: ServiceAttributes;

    constructor (data: any) {

        this._id = parseFloat(data.id);

        this._label = data.label;
        this._serviceType = data.serviceType;
        this._backendId = data.backendId[0].value;
        this._hidden = (data.hidden === true);

        if (undefined !== data.attributes && undefined !== data.attributes.attribute) {

            this._attributes = new ServiceAttributes(data.attributes.attribute);

        }

    }

    get dataVolume (): number {
        return this._attributes ? this._attributes.dataVolume : 0;
    }

    get id (): number {
        return this._id;
    }

    get backendId (): string {
        return this._backendId;
    }

    get label (): string {
        return this._label;
    }

    get serviceType (): string {
        return this._serviceType;
    }

    get hidden (): boolean {
        return this._hidden;
    }

    get attr (): ServiceAttributes {
        return this._attributes;
    }

}
