/**
 * @module SalesFlow/view
 */

import SimOnlyOffer from 'view/view/shared/offer/sim-only-offer';
import DeviceOffer from 'view/view/shared/offer/device-offer';
import Subscription from 'model/type/subscription';
import VluxOffer from 'model/type/offer';

export default class InlifeOffer extends SimOnlyOffer {

    public readonly type: string = 'inlife';

    protected _subLevel: number;

    constructor (
        subscription: Subscription,
        offer: VluxOffer,
        redPlusOffers: DeviceOffer[] = [],
        optionalServices: number[] = [],
        subLevel: number
    ) {

        super (subscription, offer, redPlusOffers, optionalServices);
        this._subLevel = subLevel;

    }

    get offer (): VluxOffer {

        return this._offer;

    }

    get subLevel (): number {

        return this._subLevel;
    }

    get monthlyPriceWithoutSub (): number {

        let monthlyPriceWithoutSub: number = this.monthlyDiscountPrice;

        if (undefined !== this._subLevel) {
            monthlyPriceWithoutSub = this.monthlyDiscountPrice - this.subLevel;
        }

        /**
         * Red+ Costs
         */
        for (const redPlusOffer of this._redPlusOffers) {
            monthlyPriceWithoutSub = monthlyPriceWithoutSub - redPlusOffer.offerTariffCosts.discountSimOnlyPrice;
        }

        return monthlyPriceWithoutSub;
    }

}
