/**
 * [CO-13330] Logic for Gigakombi customers selecting a Young 2019 Xs or S tariff,
 * Showing the notifications that they will loose their Gigakombi discount.
 */

import {
    NotificationBox,
    NotificationType,
    NotificationDataForHBS
} from '../notification-box';

import Injector from 'core/injector';
import {Constants} from 'core/constants';

export class NotificationSwitchToYoung extends NotificationBox {

    protected _notificationType: NotificationType;
    protected _dataForNotificationHBS: NotificationDataForHBS;
    protected _notificationContent: string;
    protected _CSSSelectorAsAnchorInDOM: string;

    protected _additionalCSSClassForStyling: string;
    protected _notificationHeadline: string;
    protected _selector: string;
    protected _selectedSubscriptionId: number;

    constructor (
        injector: Injector,
        selector: string,
        selectedSubscriptionId: number
        ) {
        super(
            injector
        );

        this._selector = selector;
        this._selectedSubscriptionId = selectedSubscriptionId;
        this._toggleViewIdentifier = 'switch-to-youngXSorS-notification';

        this.setNotificationOptions();
        this.setNotificationText();
        this.setNotificationDataForHBS();
    }

    public set selectedSubscriptionId (selectedSubscriptionId: number) {
        this._selectedSubscriptionId = selectedSubscriptionId;
    }

    protected setNotificationOptions () {
        this._CSSSelectorAsAnchorInDOM = this._selector;
        this._notificationType = 'warning';
        this._additionalCSSClassForStyling = '';
    }

    protected setNotificationText () {
        const hasBR5 = this.getInjector().getFlowStateWithSalesChannel().getCustomer().hasGigakombiBR5;
        const brCase = hasBR5 ? '.br5' : '';

        this._notificationHeadline = this.getInjector().getStrings().get(`vvl.switch-youngXSorS-notification.headline${brCase}`);
        this._notificationContent = this.getInjector().getStrings().get(`vvl.switch-to-youngXSorS-notification.content${brCase}`);
    }

    protected shouldRender (): boolean {

        const shouldRender = super.shouldRender();
        if (shouldRender) {
            return (this._selectedSubscriptionId === Constants.YoungS_Id);
        } else {
            return shouldRender;
        }
    }

    protected events (): void {

    }
}
