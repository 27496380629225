/**
 * @module SalesFlow/service
 */

import {Constants} from 'core/constants';

/**
 *
 * @param serviceErrorCase
 */
export function displayWinbackVoucherErrorNotification (serviceErrorCase: string): string {
    const winbackServiceResponseCases = [
        Constants.WINBACK_VOUCHER_REDEEMED, Constants.WINBACK_VOUCHER_NOT_PART_OF_CAMPAIGN,
        Constants.WINBACK_VOUCHER_CAMPAIGN_OVER, Constants.WINBACK_VOUCHER_DEFAULT];

    return (-1 !== winbackServiceResponseCases.indexOf(serviceErrorCase)) ? serviceErrorCase : Constants.WINBACK_VOUCHER_DEFAULT;
}
