/**
 * @module SalesFlow/router
 */

import Routing from 'router/shared/routing';

import {RouterEvolvedFamilyfriendsBntSubscriptionOverview} from '../../router-evolved/familyfriends-bnt/router-evolved--familyfriends-bnt-subscription-overview';
import {RouterEvolvedFamilyfriendsBntDeviceOverview} from '../../router-evolved/familyfriends-bnt/router-evolved--familyfriends-bnt-device-overview';
import {RouterEvolvedBntFafRouter} from '../../router-evolved/familyfriends-bnt/router-evolved--familyfriends-bnt-router';
import {RouterEvolvedBntDeviceDetail} from '../../router-evolved/bnt/router-evolved--bnt-device-detail';
import {RouterEvolvedBntConnectMore} from '../../router-evolved/bnt/router-evolved--bnt-connect-more';

/**
 * The new sales flow root object
 */
/**
 * @internal
 */
export default class BntFafRouting extends Routing {

    protected _validPages: string[] = [
        'subscription_overview',
        'device_overview',
        'device_detail',
        'connectmore'
    ];

    protected doSomethingBeforeRouterAreCreated (): void {
        $('.tariff-module-tile[data-gigakombi="true"]').remove();
    }

    protected getRouter (): JQueryPromise<any> {
        const deferred = $.Deferred();

        $('body').append('<script type="text/javascript" src="/simplicity/assets/js/faf-check.js"></' + 'script>');
        const currentStep = this.getCurrentPage();

        if ('subscription_overview' === currentStep) {
            deferred.resolve(new RouterEvolvedFamilyfriendsBntSubscriptionOverview(this._injector));
        }
        else if ('device_overview' === currentStep) {
            deferred.resolve(new RouterEvolvedFamilyfriendsBntDeviceOverview(this._injector));
        }
        else if ('device_detail' === currentStep) {
            deferred.resolve(new RouterEvolvedBntDeviceDetail(this._injector, this._skeletonService));
        }
        else if ('connectmore' === currentStep) {
            deferred.resolve(new RouterEvolvedBntConnectMore(this._injector));
        }

        return deferred.promise();
    }
}
