/**
 * @module SalesFlow/model/repo
 */

import NsfError from 'core/error';
import {Repo} from 'model/repo/repo';

export default class DependencyRepo extends Repo {

    constructor (json: any) {
        super();
        this.rawData = json;
        this.parseData(json);
    }

    protected parseData (json: any): NsfError {

        return undefined;

    }

}
