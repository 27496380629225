/**
 * @module SalesFlow/controller-evolved
 */

declare var $: JQueryStatic;
declare var vf: any;

import { ControllerEvolvedFlowBase } from 'controller-evolved/controller-evolved--flow-base';
import { ModelEvolvedRepoSupervisor } from 'model-evolved/repo/model-evolved--repo--supervisor';
import { SalesChannelName, ContractPeriodType } from 'core/ids';
import {FlowCtas} from 'view/ctas/shared/flow-ctas';
import { Constants } from 'core/constants';
import Customer from 'shopbackend/customer';
import Injector from 'core/injector';
import {NotificationContractPeriodType} from 'view-evolved/notifications/inlife/notification-contract-period-type';
import {NotificationSwitchToYoung} from 'view-evolved/notifications/inlife/notification-switch-to-young';
import Offer from 'view/view/shared/offer/offer';

/**
 * @internal
 */
export class InlifeSharedController extends ControllerEvolvedFlowBase {

    protected _customer: Customer;
    protected _notificationContainer: HTMLDivElement;
    protected _notificationContractPeriodType: NotificationContractPeriodType;
    protected _notificationSwitchToYoung: NotificationSwitchToYoung;
    protected _offer: Offer;

    protected _salesChannel: SalesChannelName;

    constructor (
        reposSupervisor: ModelEvolvedRepoSupervisor,
        injector: Injector
    ) {

        super (
            reposSupervisor,
            injector
        );

    }

    protected getSalesChannel (): SalesChannelName {

        return this._salesChannel;

    }

    protected createCtas (): FlowCtas {
        return undefined;

    }

    protected render (): void {

    }

    public events () {

    }

    protected tracking () {
        this.getInjector().getEvent().trigger('pageviewTracking@onload',
            {
                deviceOffer: this._offer,
                pageName: 'personal offer',
                pageType: 'product detail',
                btx: this._btx,
                currentPage: this._injector.getRouting().getCurrentPage(),
                customer: this._injector.getFlowStateWithSalesChannel().customer
            }
        );
    }

    /**
     * displays BR5 SOC Notification all other Gigakombi Notifications have been skipped in [CO-27128]
     * @param notificationContent
     * @param notificationHeadline
     * @param position
     * @param HTMLId
     * @param additionalClass
     * @protected
     */
    protected showGigakombiNotification (notificationContent: string, notificationHeadline: string, HTMLId?: string, additionalClass?: string) {

        /**
         * Only show the notification if it's not present
         */
        if (0 === $(`#${HTMLId}`).length) {

            const HBSTemplate = 'information-gigakombi-br5';
            const dataForInformationHBS = {
                notificationContent: notificationContent,
                notificationHeadline: notificationHeadline,
                toggleViewSelector: HTMLId,
                additionalClass: additionalClass

            };

            const pageType = $('#nsf-dispatcher').data('page');
            // On Reco page, the BR5 notification is positioned different from tariff selection
            if ('inlife_recommendation' === pageType) {
                $(`#nsf-hardware-only-toggle`).after(
                    this.getInjector().getTemplates().render(HBSTemplate, dataForInformationHBS)
                );
            } else {
                $(`#notificationContainer`).prepend(
                    this.getInjector().getTemplates().render(HBSTemplate, dataForInformationHBS)
                );
            }

        }
    }

    /**
     * @description
     * Function to select specific gigakombi notification within red 6 2020 / 2022 portfolio
     * CO-19571: remove gigakombi notifications for all red tariffs and replace them
     *           by a notification only displayed to customers who have the BR5 SOC.
     *
     * @protected
     */
    protected handleGigakombiNotification (selectedSubscriptionId: number) {
        const htmlSelectorAndClass = 'br5-gigakombi-notification';
        if (this._customer.isGigaKombiCustomer && this._customer.hasGigakombiBR5 && selectedSubscriptionId !== Constants.YoungS_Id) {
             const soho = this._customer.isSohoCustomer ? 'soho.' : '';
             this.showGigakombiNotification(
                this.getInjector().getStrings().get(`vvl-inlife.gigakombi-notification-br5.${soho}content`),
                this.getInjector().getStrings().get('vvl-inlife.gigakombi-notification.headline'),
                 htmlSelectorAndClass,
                 htmlSelectorAndClass
            );
        } else {
            $(`#${htmlSelectorAndClass}`).remove();
        }
    }

    protected isRedXLRecommendedTariff (subscriptionId: number): boolean {
        let isRedXLRecommendedTariff = false;

        if (-1 < Constants.TariffchangeRedXLSubLevelTariffIds.lastIndexOf(subscriptionId)) {
            isRedXLRecommendedTariff = true;
        }

        return isRedXLRecommendedTariff;
    }

    protected addNotificationContainer () {
        if (this._customer.isGigaKombiCustomer) {
            this._notificationContainer = document.createElement('div');
            this._notificationContainer.classList.add('notificationContainer');
            this._notificationContainer.id = 'notificationContainer';
        }
    }

    public bind (element: JQuery): void {

        this.handleContractPeriodTypeFootnotes();

        if (undefined !== this._notificationContractPeriodType) {
            // display Contract Perion Information plus ulttraCard Information in case User has an UltraCard
            this._notificationContractPeriodType.bind();
        }
        if (undefined !== this._notificationSwitchToYoung) {
            // display warning, when user selects y young XS or young S tariff that he loses the gigakombi
            this._notificationSwitchToYoung.bind();
        }
    }

    /**
     * Arrow function to reduce anonymous function in the event listener
     */
    protected handleContractPeriodTypeFootnotes = () => {

        if ('R' === this._contractPeriodType) {
            const tileAsterisks = document.querySelectorAll('.tariff-module-tile-inlife .asterisk-text');

            Array.from(tileAsterisks).forEach((tileAsterisk: HTMLElement) => {
                tileAsterisk.innerHTML = tileAsterisk.innerHTML
                    .replace(/Mindestlaufzeit 24 Monate(\.?)|initialized/g, '')
                    .trim();

                if (tileAsterisk.innerHTML.length === 0) {
                    const asterisk: HTMLElement = tileAsterisk.parentElement.querySelector('.asterisk');

                    tileAsterisk.style.display = 'none';
                    asterisk.style.display = 'none';
                } else {
                    vf.footnotes.init();
                }
            });

            $('.footnote-min-term, .asterisk-min-term').hide();
        }
    }
}
