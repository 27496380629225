/**
 * @module SalesFlow/view
 */

export default class Step {

    protected _name: string;

    protected _text: string;

    protected _href: string;

    protected _status: string;

    protected _step: number;

    constructor (name: string, text: string, href: string, status: string, step?: number) {

        this._name = name;
        this._text = text;
        this._href = href;
        this._status = status;
        this._step = step;

    }

    get name (): string {
        return this._name;
    }

    get text (): string {
        return this._text;
    }

    get href (): string {
        return this._href;
    }

    get status (): string {
        return this._status;
    }

    get step (): number {
        return this._step;
    }

    /**
     * @deprecated
     */
    get active (): string {
        return this._status;
    }
}
