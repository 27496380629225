/**
 * @module SalesFlow/view
 */
export default class ViewOverlay {

    private _headlineClass: string = '';
    private _headline: string = '';
    private _subHeadlineClass: string = '';
    private _subHeadline: string = '';
    private _text: string = '';

    constructor (headline: string, subHeadline?: string, text?: string, headlineClass = 'h1', subHeadlineClass = 'h2') {

        this._headlineClass = headlineClass;
        this._headline = headline;
        this._subHeadlineClass = subHeadlineClass;
        this._subHeadline = subHeadline;
        this._text = text;

    }

    get headlineClass (): string {
        return this._headlineClass;
    }

    get headline (): string {
        return this._headline;
    }

    get subHeadlineClass (): string {
        return this._subHeadlineClass;
    }

    get subHeadline (): string {
        return this._subHeadline;
    }

    get content (): string {
        return this._text;
    }

}
