/**
 * @module SalesFlow/view
 */

declare var $: JQueryStatic;

declare var window: any;
declare var mtls: any;

import { Constants } from 'core/constants';
import Injector from 'core/injector';

import {Renderable} from '../../renderable';
import Offer from '../../view/shared/offer/offer';

import CostOverview from '../shared/cost-overview';

export default class Pricebox extends Renderable<Offer> {

    private _offer: Offer;

    private _element: JQuery;

    private _costOverview: CostOverview;

    private _lastY: number;

    private _ticking: boolean = false;

    private _isVisible: boolean = true;

    private _calledMatelso: boolean = false;

    private _matelsoHotlineNumber: string = '';

    constructor (injector: Injector) {

        super(injector);

        this._costOverview = new CostOverview(injector);

        this._element = $('#nsf-pricebox');

    }

    private scrollPayload () {

        if (0 === this._costOverview.getElement().find('.cost-price-pos').length) {
            this._ticking = false;

            return;
        }

        const top: number = this._costOverview.getElement().find('.cost-price-pos').offset().top;

        const shouldBeVisible = (this._lastY + $(window).height() < top);

        if (true === shouldBeVisible) {
            if (false === this._isVisible) {
                if (undefined !== this._offer) {
                    // only show it when there's an offer
                    this._element.addClass('active');
                    this._isVisible = true;
                }
            }
        }
        else {
            if (true === this._isVisible) {
                this._element.removeClass('active');
                this._isVisible = false;
            }
        }

        this._ticking = false;
    }

    private requestTick () {

        if (false === this._ticking) {

            requestAnimationFrame(() => {

                this.scrollPayload();

            });

            this._ticking = true;
        }
    }

    private handleScroll (): void {

        if (undefined !== window.pageYOffset) {

            this._lastY = window.pageYOffset;

        } else {

            this._lastY = document.documentElement.scrollTop;

        }

        this.requestTick();
    }

    private setHotlineNumber () {

        if (undefined === this._offer) {

            return;
        }

        if (false === this._calledMatelso) {
            const hotlineNumber = this.getStandardHotlineNumber();

            $('.nsf-tel').attr('href', 'tel:' + hotlineNumber.replace(/ /g, ''));
            $('.nsf-tel').find('.part-two').text(hotlineNumber);

            this.getHotlineNumberFromMatelso();
        }

    }
    // For Promo Pack Q2 Annotate has to be hidden for consumer and unhidden for young
    private hideAnnotateOnSalesChannel () {

        const annocentElement = this._element.find('.annotate');

        if (this._offer.offer.hasNoConnectionFee()) {
            annocentElement.addClass('hide');
        } else {
            annocentElement.removeClass('hide');
        }

    }

    private getStandardHotlineNumber () {

        let hotlineNumber: string = this._injector.getStrings().get('hotline.number');

        if (undefined === this._offer) {
            return hotlineNumber;
        }

        if (this._offer.isDevice()) {

            if ('iPhone X' === this._offer.atomicDevice.device.name || 'iPhone 8' === this._offer.atomicDevice.device.name || 'iPhone 8 Plus' === this._offer.atomicDevice.device.name) {
                hotlineNumber = '0800 664 63 88';
            }

        }

        if (undefined !== window.toolbar && undefined !== window.toolbar.content && undefined !== window.toolbar.content.number) {

            hotlineNumber = window.toolbar.content.number;

        }

        if (Constants.BTX_VVL === this._offer.offer.btx) {
            hotlineNumber = '0800 724 26 10';
        }

        return hotlineNumber;
    }

    private getHotlineNumberFromMatelso () {

        // call matelso to set hotline after rendering
        if ('undefined' !== typeof mtls) {

            this._calledMatelso = true;
            mtls.scan();

        }
    }

    public update (offer: Offer) {

        this._offer = offer;
        const currentPage = this.getInjector().getRouting().getCurrentPage();

        /**
         * If Black tariff is selected pricebox may not displayed in case of sim only
         */
        if (undefined === this._offer
            || (Constants.Black_Id === this._offer.subscriptionId && this._offer.isSimOnly())
            || this._costOverview.hideForSubGroupIn ()) {

            this.hide();

            return;
        }

        if (false === this._isVisible) {

            this.show();

        }

        // use standard hotline, if matelso calls failed
        let hotlineNumber = this.getStandardHotlineNumber();

        if (true === this._calledMatelso) {

            if ('' !== this._matelsoHotlineNumber) {

                hotlineNumber = this._matelsoHotlineNumber;

            } else {

                const matelsoHotline = this._element.find('.nsf-tel').find('.part-two span').html();

                if (undefined !== matelsoHotline) {

                    hotlineNumber = matelsoHotline;
                    this._matelsoHotlineNumber = matelsoHotline;

                }
            }
        }

        if (undefined !== this._offer) {
            this.hideAnnotateOnSalesChannel();
        }

        // call matelso again, if failed
        if (false === this._calledMatelso) {

            this.getHotlineNumberFromMatelso();

        }

        if (Constants.BTX_MULTISIM !== this._offer.offer.btx && true === this._offer.isHardwareOnly()) {
            this._element.find('#nsf-pricebox-monthly .price span').html(
                this.getInjector().getTemplates().render('price-element', 0, 'partials')
            );

            this._element.find('#nsf-pricebox-once .price span').html(
                this.getInjector().getTemplates().render('price-element', offer.onetimePriceWithoutConnectionFee, 'partials')
            );
            this._element.find('.annotate').hide();

        }
        else {

            this._element.find('#nsf-pricebox-monthly .price span').html(
                this.getInjector().getTemplates().render('price-element', offer.monthlyDiscountPrice, 'partials')
            );

            let discountHint: string = '';
            if (Constants.BTX_MULTISIM !== this._offer.offer.btx && offer.isDevice() || offer.isSimOnly()) {
                discountHint = offer.monthyDiscountHint;

            }

            this._element.find('#nsf-pricebox-monthly .annotate').html(discountHint);

            this._element.find('#nsf-pricebox-once .price span').html(
                this.getInjector().getTemplates().render('price-element', offer.onetimePriceWithoutConnectionFee, 'partials')
            );
            this._element.find('.annotate').show();

            this._element.find('#nsf-pricebox-once .annotate span.price-sum').html(
                this.getInjector().getTemplates().render('price-element', offer.onetimePrice, 'partials')
            );

        }

        this._element.find('.price span').addClass('pulse');

        // animation in css is 0.3s -> So remove the class after 0.5 and it
        window.setTimeout(() => {

            this._element.find('.price span').removeClass('pulse');

        }, 500);

    }

    public render (offer?: Offer): void {

        this._offer = offer;

        if (undefined === this._offer
            || (Constants.Black_Id === this._offer.subscriptionId && this._offer.isSimOnly())
            || this._costOverview.hideForSubGroupIn ()) {

            this.hide();

        }
        else {

            this.show();

        }

        const hotlineNumber = this.getStandardHotlineNumber();
        const currentPage = this.getInjector().getRouting().getCurrentPage();

        const priceBoxData = {
            offer: this._offer,
            hotline: hotlineNumber,
            btx: this._injector.getBtx(),
            salesChannel: this._injector.getFlowStateWithSalesChannel().getSalesChannel()
        };

        this._element.html(
            this.getInjector().getTemplates().render('pricebox', priceBoxData)
        );

        this.setHotlineNumber();

        if (undefined !== this._offer) {
            this.hideAnnotateOnSalesChannel();
        }
    }

    public events (): void {

        this._element.on('click', (eventObject: JQueryEventObject) => {

            const target = $(eventObject.target);

            // click on a button should not be handled here
            if (0 !== target.parents('.nsf-pricebox-ctas').length) {

                return;
            }

            eventObject.preventDefault();

            $('html, body').animate({

                scrollTop: $('#nsf-cost-overview-wrap').offset().top

            }, 1000);

            this.getInjector().getEvent().trigger('priceboxInteraction@click');

        });

        this.getInjector().getEvent().listen('loadingIndicator@hide', (eventObject: JQueryEventObject, data: any) => {

            if (true === this._isVisible) {

                this._element.addClass('active');

            }
        });

        this.getInjector().getEvent().listen('offer@changed', (eventObject: JQueryEventObject, data: any) => {

            const offer: Offer = data.offer;
            this.update(offer);

        });

        this.getInjector().getEvent().listen('offer@none', (eventObject: JQueryEventObject) => {
            // when there's no offer - set this._offer to undefined
            this._offer = undefined;

        });

        $(window).on('load scroll', () => {
            this.handleScroll();
        });

        this.handleScroll();

    }

    public bind (offer?: Offer): void {

        this.render(offer);

        this._costOverview.bind(offer);

        this.events();

    }

    public show (): void {

        this._element.addClass('active');

        this._isVisible = true;

    }

    public hide (): void {

        this._element.removeClass('active');

        this._isVisible = false;

    }

    public setAnnotationPriceOnce (state: string): void {

        if ('hide' === state) {

            this._element.find('#nsf-pricebox-once .annotate').addClass('hide');

        } else {

            this._element.find('#nsf-pricebox-once .annotate').removeClass('hide');

        }
    }

}
