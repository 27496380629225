/**
 * @module SalesFlow/controller-evolved
 */
declare var vf: any;

import {ViewEvolvedCtasSharedFlowCtas} from 'view-evolved/ctas/shared/view-evolved--ctas--shared--flow-ctas';
import {ViewEvolvedCtasBntDeviceDetailCtas} from 'view-evolved/ctas/bnt/view-evolved--ctas--bnt-device-detail-ctas';
import DeviceOffer from 'view/view/shared/offer/device-offer';
import AtomicDevice from 'model/type/atomic-device';
import Subscription from 'model/type/subscription';
import {AbstractSubscriptionGroupSwitcher} from 'view/element/shared/subscription-group-switcher';
import { SalesChannelName, SubscriptionGroupName } from 'core/ids';
import {ControllerEvolvedBaseClassDeviceDetail} from 'controller-evolved/base-class/controller-evolved--base-class--device-detail';
import {EvolvedBntSubscriptionSelection} from 'view-evolved/element/bnt/view-evolved--element-bnt--subscription-selection';
import {TariffGroupInterface} from 'view-evolved/element/shared/view-evolved--element-shared--tariffgroup-switcher';
import ViewOverlayDeviceDetails from 'view/view/shared/overlay-device-details';
import GigakombiDeviceDetailService from 'service/gigakombi/gigakombi-device-detail-service';
import ViewEvolvedCtasGigakombiDeviceDetailCtas from 'view-evolved/ctas/gigakombi/view-evolved--ctas--gigakombi-device-detail-ctas';
import {Constants} from 'core/constants';

/**
 * I come from previous step:
 *      => Tariff is locked, device is locked
 */

export class ControllerEvolvedBntDeviceDetail extends ControllerEvolvedBaseClassDeviceDetail {

    protected _atomicDeviceId: number;

    protected _subscriptionSelection: EvolvedBntSubscriptionSelection;

    protected createSubscriptionList (): EvolvedBntSubscriptionSelection {

        const subscriptionId = this.getInjector().getFlowState().getSubscriptionId();
        const gigakombiDeviceDetailService = new GigakombiDeviceDetailService(this.getInjector());

        return new EvolvedBntSubscriptionSelection(
            subscriptionId,
            this.getInjector(),
            this._focusSubscriptionIdArray,
            gigakombiDeviceDetailService
        );

    }

    /**
     * Create the CTAs
     *
     * This method creates the CTAs for the cost overview.
     * It returns Gigakombi CTA (left button: Noch kein Feztnetz Kunde?)
     * or the default BNT CTAs.
     *
     * @returns FlowCtas
     */
    protected createCtas (): ViewEvolvedCtasSharedFlowCtas {

        const orderType = this.getInjector().getFlowState().getOrderType();

        if (Constants.BTX_GIGAKOMBI === this._btx) {
            return new ViewEvolvedCtasGigakombiDeviceDetailCtas(this._injector);
        } else {
            return new ViewEvolvedCtasBntDeviceDetailCtas(this._injector);
        }

    }

    protected getOneDeviceOffer (atomicDevice: AtomicDevice, subscription: Subscription): DeviceOffer {

        const vluxOffer = this._generalSalesObjectInterface.getSimHardwareOfferByAtomicDeviceIdAndSubscriptionId (
            atomicDevice.id,
            subscription.id,
            this._btx,
            this.getInjector().getFlowState().getSalesChannel()
        );

        /**
         * Get selected optional services
         */
        const optionalServiceIds = this.getInjector().getFlowStateWithSalesChannel().optionalServiceIds.elements;

        // @TODO Why is redplus unhandle
        return new DeviceOffer(
            atomicDevice.getDevice().getAtomicDeviceById(vluxOffer.deviceId),
            subscription,
            vluxOffer,
            [],
            optionalServiceIds
        );

    }

    private handleInsuranceChanged (atomicDevice: AtomicDevice, subscription: Subscription): void {

        this._deviceOffer = this.getDeviceOfferWithSubscription();

        let insuranceSelected: boolean = false;

        if (false === this._deviceOffer.offer.hasHandyInsuranceIncluded()) {

            for (const serviceId of this.getInjector().getFlowStateWithSalesChannel().optionalServiceIds.elements) {

                if ((158 === serviceId || 159 === serviceId)) {
                    insuranceSelected = true;
                }

            }

        }

        this.getInjector().getFlowStateWithSalesChannel().setRecommendedInsurance(insuranceSelected);

        this.getInjector().getEvent().trigger('device-detail@offerChanged:' + atomicDevice.device.id, {
            deviceOffer: this._deviceOffer
        });

        this.getInjector().getEvent().trigger('offer@changed', {
            offer: this._deviceOffer
        });

    }

    private handleDeviceTileAtomicIdChanged (atomicDevice: AtomicDevice, subscription: Subscription): void {

        let deviceOffer: DeviceOffer;
        if (false === this.getInjector().getFlowState().getHardwareOnly()) {
            deviceOffer = this.getOneDeviceOffer(atomicDevice, subscription);
        } else {
            deviceOffer = this.getHardwareOnlyOffer();
        }

        this.getInjector().getFlowStateWithSalesChannel().setAtomicDeviceId(deviceOffer.atomicDevice.id);
        this.getInjector().getFlowStateWithSalesChannel().lockDevice();

        this.getInjector().getEvent().trigger('offer@changed', {
            offer: deviceOffer
        });

        this.getInjector().getEvent().trigger('device-detail@offerChanged:' + deviceOffer.atomicDevice.device.id, {
            deviceOffer: deviceOffer
        });

    }

    protected events (): void {

        super.events();

        this.getInjector().getEvent().listen('device-detail@atomicIdChanged', (eventObject: JQueryEventObject, data: any) => {

            const atomicDevice: AtomicDevice = data.atomicDevice;
            const subscription: Subscription = data.subscription;

            this.handleDeviceTileAtomicIdChanged(atomicDevice, subscription);

        });

        this.getInjector().getEvent().listen('device-detail@insuranceChanged', (eventObject: JQueryEventObject, data: any) => {

            const atomicDevice: AtomicDevice = data.atomicDevice;
            const subscription: Subscription = data.subscription;

            this.handleInsuranceChanged(atomicDevice, subscription);

        });

        this.getInjector().getEvent().listen('device-detail@openDetailOverlay', (eventObject: JQueryEventObject, data: any) => {

            const deviceOffer: DeviceOffer = data.deviceOffer;

            const viewOverlay = new ViewOverlayDeviceDetails(this._injector, deviceOffer, this.getReposSupervisor().getAttributeRepo());
            this.getInjector().getOverlay().open(viewOverlay, 'overlay_device_details');
            vf.tabs.init();

            vf['responsive-table'].init();

            this.getInjector().getEvent().trigger('tracking@Layover', {
                name: 'product details ' + deviceOffer.atomicDevice.name
            });

        });

        this.getInjector().getEvent().listen('check@InsuranceSelection', () => {

            const subscriptionId = this.getInjector().getFlowState().getSubscriptionId();

            const vluxOffer = this._generalSalesObjectInterface.getSimHardwareOfferByAtomicDeviceIdAndSubscriptionId (
                this._atomicDevice.id,
                subscriptionId,
                this._btx,
                this.getInjector().getFlowState().getSalesChannel()
            );

            const optionalServices = vluxOffer.getOptionalServices()[0].services;
            const optionalServiceSelected = this.getInjector().getFlowStateWithSalesChannel().optionalServiceIds.elements;
            for (const serviceId of optionalServiceSelected) {
                for (const index in optionalServices) {
                    if (serviceId === optionalServices[index].id) {
                        /**
                         * Special case handy insurance: Service is already included in Red XL and Red XXL. Do not show option if one of these tariffs are selected
                         */
                        if (optionalServices[index].id === vluxOffer.getHandyInsuranceServiceId() && vluxOffer.hasHandyInsuranceIncluded()) {
                            continue;
                        }

                        this.getInjector().getFlowStateWithSalesChannel().optionalServiceIds.removeElement(serviceId);
                    }
                }
            }
        });

        this.getInjector().getEvent().listen('TariffGroupName@changed', (eventObject: JQueryEventObject, tariffGroupParam: TariffGroupInterface) => {

            const salesChannel: SalesChannelName = tariffGroupParam.salesChannel;
            const subscriptionGroup: SubscriptionGroupName = tariffGroupParam.subscriptionGroup;

            if (false === this.getInjector().getFlowState().isValidSalesChannel(salesChannel) ||
                false === this.getInjector().getFlowState().isValidSubscriptionGroup(subscriptionGroup)) {

                return undefined;

            }

            this.handleEventTariffGroupNameChanged(tariffGroupParam);

        });

        this.getInjector().getEvent().listen('TariffGroup@changed', (eventObject: JQueryEventObject, tariffGroupParam: TariffGroupInterface) => {

            const salesChannel: SalesChannelName = tariffGroupParam.salesChannel;
            const subscriptionGroup: SubscriptionGroupName = tariffGroupParam.subscriptionGroup;

            if (false === this.getInjector().getFlowState().isValidSalesChannel(salesChannel) ||
                false === this.getInjector().getFlowState().isValidSubscriptionGroup(subscriptionGroup)) {

                return undefined;

            }

            this.handleEventTariffGroupChanged(tariffGroupParam);

        });

        this.getInjector().getEvent().listen('tradein@changed', (eventObject: JQueryEventObject, data: any) => {

            /**
             * CO TRADE IN
             */
            const tradeInChkbx = $('.tradeinBox .checkBox');

            if (0 < tradeInChkbx.length) {
                if (!tradeInChkbx.hasClass('selected')) {
                    this.getInjector().getFlow().optionalServiceIds.removeElement(Constants.TradeInDiscount_Id);
                } else {
                    this.getInjector().getFlow().optionalServiceIds.addElement(Constants.TradeInDiscount_Id);
                }
            }

            this._deviceOffer = this.getDeviceOfferWithSubscription();

            this.getInjector().getEvent().trigger('offer@changed', {
                offer: this._deviceOffer
            });

            // TODO: tracking implementation

        });

    }

}
