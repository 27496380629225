/**
 * @module SalesFlow/tracking
 */
export default class Product {
    public sku: string;
    public name: string;
    public type: string;
    public priceOnce?: string;
    public priceMonthly?: string;
    public duration?: number;
    public proposition: number;
    public units?: number;

    constructor (
        sku: string,
        name: string,
        type: string,
        proposition: number,
        priceOnce?: string,
        priceMonthly?: string,
        duration?: number,
        units?: number
    ) {
        this.sku = sku;
        this.name = name;
        this.type = type;
        this.priceOnce = priceOnce;
        this.priceMonthly = priceMonthly;
        this.duration = duration;
        this.proposition = proposition;
        this.units = units;
    }
}
