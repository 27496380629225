import { CoreFlowState } from 'core/core-flow-state';

/**
 * @module SalesFlow/evolved/view
 */

declare var $: JQueryStatic;

import Injector from 'core/injector';
import { Constants } from 'core/constants';
import DeviceList from 'view/element/shared/device-list';
import AttributeRepo from 'model/repo/attribute-repo';
import DeviceTile from 'view-evolved/element/vvl/view-evolved--element-vvl--device-tile';
import DeviceOffer from 'view/view/shared/offer/device-offer';
import {ActiveFilterOptionsByGroup} from 'view/element/shared/filter/filters';
import {ViewEvolvedElementVvlInsuranceDetailOverlay} from 'view-evolved/element/vvl/view-evolved--element-vvl--insurance-detail-overlay';

export class ViewEvolvedElementVvlDeviceList extends DeviceList {

    protected _tileHbs = 'vvl_device_tile';

    protected _attributeRepo: AttributeRepo;

    protected _deviceTile: DeviceTile[];

    private _insuranceDetailOverlay: ViewEvolvedElementVvlInsuranceDetailOverlay;

    protected _focusAtomicDeviceId: number;

    constructor (injector: Injector, attributeRepo: AttributeRepo, focusAtomicDeviceId: number) {
        super(injector);

        this._attributeRepo = attributeRepo;
        this._focusAtomicDeviceId = focusAtomicDeviceId;
        this._insuranceDetailOverlay = new ViewEvolvedElementVvlInsuranceDetailOverlay(this._injector);

    }

    public events (): void {

        this._element.on('click', '.vll-device-accordion-toggle', (evt) => {
            setTimeout(() => {
                this._slider.updateHeight();
            }, 800);
        });

        this._insuranceDetailOverlay.bind();

        // @TODO deviceTiles are only a workaround

        this._deviceTile = this._viewOffers.map((deviceOffer: DeviceOffer): DeviceTile => {

            return new DeviceTile(
                this.getElement().find('.device-module-tile[data-device-id="' + deviceOffer.atomicDevice.device.id + '"]'),
                deviceOffer,
                this.getInjector()
            );

        });

        this.getElement().on('click', '.device-module-tile .selectionRadio', (evt) => {

            const deviceTile: JQuery = $(evt.currentTarget).parents('.device-module-tile');

            if (true === deviceTile.hasClass('selected')) {
                return true;
            }

            const deviceId = parseInt(deviceTile.attr('data-atomic-device-id'), 10);
            this._coreFlowState.setAtomicDeviceId(deviceId);
            this._coreFlowState.lockDevice();

        });

        this.getElement().on('click', '.device-module-tile .selectionCheck', (evt) => {
            const deviceTile = evt.currentTarget.parentElement.parentElement;
            const deviceId = parseInt(deviceTile.dataset.atomicDeviceId, 10);

            if (deviceId === this._selectedAtomicDeviceId) {
                this._selectedAtomicDeviceId = undefined;
                this._coreFlowState.setAtomicDeviceId(undefined);
                this._coreFlowState.unlockDevice();
            } else {
                this._selectedAtomicDeviceId = deviceId;
                this._coreFlowState.setAtomicDeviceId(deviceId);
                this._coreFlowState.lockDevice();
            }
        });

        // IPhone Trade in event handling

        this.getElement().on('click', '.tradeinBox .i-check', (evt) => {
            const tradeinCheckbox = evt.currentTarget.parentElement;
            const atomicDeviceId = parseInt(tradeinCheckbox.dataset.deviceid, 10);
            const tradeinCheckboxIsChecked = !tradeinCheckbox.classList.contains('selected');

            if (tradeinCheckboxIsChecked) {

                const currentDeviceModuleTile = tradeinCheckbox.closest('.device-module-tile');
                // For simOnly allowed costumer, the device selection element class is selectionCheck
                // For not simOnly allowed costumer, the device selection element class is selectionRadio
                const selectionRadio = currentDeviceModuleTile.getElementsByClassName('selectionRadio');
                const selectionCheck  = currentDeviceModuleTile.getElementsByClassName('selectionCheck');

                if (selectionRadio.length) {
                    selectionRadio[0].click();
                }

                if (selectionCheck.length) {
                    if (!currentDeviceModuleTile.classList.contains('selected')) {
                        selectionCheck[0].click();
                    }
                }

            } else {
            }
            tradeinCheckbox.classList.toggle('selected');

            this.getInjector().getEvent().trigger('tradein@changed', {
                atomicDeviceId: atomicDeviceId
            });

            this.getInjector().getEvent().trigger('tradeinCheckboxTracking@changed', {
                tradeinCheckboxIsChecked: tradeinCheckboxIsChecked,
                isVvlFlow: true
            });

        });

        this.getElement().on('click', '.tradeInDetailLink', (evt) => {
            this.getInjector().getEvent().trigger('tradeinOverlay@openned', {
                linkName: evt.currentTarget.innerHTML.trim()
            });
        });

        this.getInjector().getEvent().listen('atomicDeviceId@changed', (eventObject: JQueryEventObject, data: any) => {

            const atomicDeviceId = data.atomicDeviceId;

            this.getElement().find('.device-module-tile.selected').removeClass('selected');
            $('.tradeinBox .checkBox').removeClass('selected');

            if (undefined === atomicDeviceId) {
                return;
            }

            this.getElement().find('.device-module-tile[data-atomic-device-id="' + atomicDeviceId + '"]').addClass('selected');

            this._slider.showSelectedElement();

        });

        this.getInjector().getEvent().listen('atomicDeviceId@changedByInsurance', (eventObject: JQueryEventObject, data: any) => {

            const atomicDeviceId = data.atomicDeviceId;

            this.getElement().find('.device-module-tile.selected').removeClass('selected');

            if (undefined === atomicDeviceId) {
                return;
            }

            this.getElement().find('.device-module-tile[data-atomic-device-id="' + atomicDeviceId + '"]').addClass('selected');

        });

        this.getInjector().getEvent().listen('filters@changed', (eventObject: JQueryEventObject, activeFilterOptionsByGroup: ActiveFilterOptionsByGroup) => {

            this.filterList(activeFilterOptionsByGroup);

        });

    }

    public update (devices: DeviceOffer[]): void {

        for (const device of devices) {

            for (const tile of this._deviceTile) {

                if (device.atomicDeviceId === tile.getDeviceOffer().atomicDeviceId) {

                    tile.setDeviceOffer(device);

                }

            }

        }

        super.update(devices);

    }

    public render (devices: DeviceOffer[]): string {
        super.render(devices);

        const customer = this.getInjector().getFlow().customer;
        if (customer.isSimOnlyVvlAllowed) {
            this.getElement().find('.selectionRadio').replaceWith(this.getInjector().getTemplates().render('selection-check', '', 'partials'));
        }

        return '';
    }

    private bindSlider (): void {

        let focusAtomicDeviceId: number;

        if (undefined !== this.getInjector().getFlow().getAtomicDeviceId()) {
            focusAtomicDeviceId = this.getInjector().getFlow().getAtomicDeviceId();
        }
        else if (undefined !== this._focusAtomicDeviceId) {
            focusAtomicDeviceId = this._focusAtomicDeviceId;
        }

        this._slider.bind(
            $('.device-module-tile[data-atomic-device-id="' + focusAtomicDeviceId + '"]')
        );

    }

    public bind (devices: DeviceOffer[]): void {

        super.bind(devices);

        this.bindSlider();
    }

}
