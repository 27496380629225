/**
 * @module SalesFlow/evolved/model/repo
 */

import NsfError from 'core/error';
import {BusinessTransactionContext, SalesChannelName} from 'core/ids';

/**
 * This class is needed as root type for evolved repos
 */
export abstract class ModelEvolvedRepoBase {

    /**
     * here all non-offer vkux data is saved,
     * the offer repo has it's own rawData
     */
    private _rawData: any;

    protected abstract parseData (
        salesChannel: SalesChannelName,
        businessTransactionContext: BusinessTransactionContext,
        json: any
    ): NsfError;

    public get rawData (): any {
        return this._rawData;
    }

    public set rawData (json: any) {

        if ('' === json) {
            return;
        }

        this._rawData = json;

    }

}
