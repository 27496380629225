/**
 * @module SalesFlow/view
 */
declare var $: JQueryStatic;
declare var vf: any;

import Templates from 'core/templates';
import ViewOverlay from 'view/view/shared/overlay';

export default class Overlay {

    private _element: JQuery;

    private _templates: Templates;

    constructor (templates: Templates) {
        this._element = $('#nsf-overlay');

        this._templates = templates;
    }

    private render (viewOverlay: ViewOverlay, template: string): void {

        this._element.html(
            this._templates.render(template, viewOverlay)
        );

        this._element.find('.overlay-content').on('scroll', () => {
            vf.util.updateLazyImageStandalone(this._element);
        });

        this._element.on('click', '.close', (evt: JQueryEventObject) => {
            if (true === $(evt.currentTarget).hasClass('custom-handler')) {
                return;
            }

            this.hide();
        });

        window.addEventListener('resize', () => {
            this.resizeOverlay(this._element);
        });

        $(window).on('nsf-overlay-sdd-resize', () => {
            this.resizeOverlay(this._element);
        });

        this._element.find('iframe').on('load', () => {
            this.resizeOverlay(this._element);
        });

        // this._element.find('.mod-tabs li a').on('click', () => {
        //      const that = this;
        //     const element = this._element;
        //     $(window).on('vf::tabsSwitch', function () {
        //         that.resizeOverlay(element);
        //     });
        // });
    }

    private resizeOverlay (element: JQuery): void {
        element.find('.overlay-content').css('height', 'auto');
        const contentHeight = $(window).height() - 106;
        if (element.find('.overlay-content').innerHeight() >= contentHeight) {
            element.find('.overlay-content').css('height', contentHeight);
        }
    }

    private show (): void {

        $('body').css('overflow', 'hidden');
        this._element.find('.nsf-overlay').css('overflow', 'visible');
        this._element.find('.nsf-overlay').fadeIn();
        $('body').css('overflow', 'hidden');

        this.resizeOverlay(this._element);
        vf.toggle.init('');
        vf.footnotes.init();
        vf['eq-height'].init();
    }

    public hide () {

        $('body').css('overflow', 'visible');
        this._element.find('.nsf-overlay').fadeOut();
        $('body').css('overflow', 'visible');

    }

    public open (viewOverlay: ViewOverlay, template = 'overlay'): void {
        this.render(viewOverlay, template);

        this.show();

        // Not a solution but somewhat working for now ;)
        window.setTimeout(() => {
            this.resizeOverlay(this._element);
            vf.footnotes.init();
            vf['eq-height'].init();
        }, 100);

    }

}
