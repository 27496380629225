(function() {
    'use strict';
    vf['sticky-basket-cta'] = {
        settings: {
            target: '.mod-sticky-basket-cta',
        },

        init: function(context) {

            var s = this.settings;
            $(s.target, context).addBack(s.target).each(function() {
                var $this = $(this);

                var addWrapperHeight = function(){
                    var stickyBasketCtaHeight = $this.css('height');
                    $this.parent().css({
                        'height': stickyBasketCtaHeight
                    });
                };

                $(window).on('vf::resize', addWrapperHeight);
                addWrapperHeight();


                var scrolling = function(){

                    $('.mod-overlay .overlay-content, .nsf-overlay .overlay-content').scroll(function(){

                        var stickyBasketCtaTop = $this.parent().position().top;
                        var stickyBasketCtaWidth = $this.css('width');

                        if ( 0 > stickyBasketCtaTop ) {
                            $this.css({position: 'fixed', top: '0px', width: stickyBasketCtaWidth});
                            $this.addClass('isSticky');
                        } else {
                            $this.css({position: 'relative', top: '0px'});
                            $this.removeClass('isSticky');
                        };
                    });
                };

                $(window).on('vf::resize', scrolling);
                setTimeout(function() {
                    scrolling();
                }, 500);



            });
        },
    };
}(vf));
