/**
 * @module SalesFlow/view
 */
import {ViewEvolvedCtasBase} from 'view-evolved/ctas/vvl/view-evolved--ctas--base';
import {FlowCta} from 'view/ctas/shared/flow-cta';
import Offer from 'view/view/shared/offer/offer';

declare var $: JQueryStatic;

/**
 * Two buttons here
 * Right: Link to x-sellpage
 * Left: If user came from recommendation page, button to go back
 *
 * @export
 * @class ViewEvolvedCtasDeviceOverview
 * @extends {ViewEvolvedCtasBase}
 */
export class ViewEvolvedCtasDeviceOverview extends ViewEvolvedCtasBase {
    private window = (window as any);

    protected getLeftButton (): FlowCta {

            return undefined;

    }

    protected getRightButton (): FlowCta {

        let rightCtaText = this.getInjector().getStrings().get('vvl.pricebox.confirm.recommendation.text1');

        // skip x-sell page for winback customers
        if (this.getInjector().getFlow().customer.isWinbackCustomer) {
            rightCtaText = this.getInjector().getStrings().get('pricebox.confirm.basket');
        }

        return new FlowCta(
            ['nfs-device-overview-confirm'],
            rightCtaText
        );

    }

    /*
    * Functions to return the urls for the CTAs based on a string coming from nsf-options
    * We created these functions in order to override them inside the different sales-chnanel i.e. soho-vvl
    * and deliver different left and right buttons with custom urls, without implementing again the update method
    * when extending the class i.e. for SOHO VVL
    */
    protected getRightCtaUrl (): string {
        return this.getInjector().getOptions().get('vvl_x_sell_url');

    }

    public update (offer: Offer): void {

        if (undefined === offer) {
            return;
        }

        let rightCtaUrl: string = this.getRightCtaUrl();

        /**
         * Skip connect more page with a paramater from adobe target, if the custumor is not winback
         */
        if (this.window.vf['device-overview'].skipCMP) {
            rightCtaUrl = '#proceedToBasket';
        }

        /**
         * Customer already has used all red plus slots, so go to basket
         * skip x-sell page for winback customers
         */
        if (this.getInjector().getFlow().customer.vacantRedPlusSlots < 1
            || this.getInjector().getFlow().customer.isWinbackCustomer) {
            rightCtaUrl = '#proceedToBasket';
        }

        this._right.setHref(
            rightCtaUrl
        );

        if (true === offer.isSimOnly()) {

            const simonlyWaring = this.getInjector().getTemplates().render('simonly-notification', {}, 'partials');

            this._right.setNotification(simonlyWaring);

        }

        // Only set the back button if user is coming from recommendation page

            this._element.html(
                this.getInjector().getTemplates().render('flow-ctas', [this._right], 'partials')
            );

        // This event is only used in simonly-handling
        this.getInjector().getEvent().trigger('confirmButton@set', {});

    }

    protected events (): void {
        super.events();

        this._element.on('click', this._right.getSelector(), (evt: JQueryEventObject) => {

            this.getInjector().getLoadingIndicator().show();

            if ('#proceedToBasket' === this._right.href) {

                evt.preventDefault();

                this.createBasketAndProceed();

                return false;
            }

        });

    }

}
