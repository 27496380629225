/**
 * @module SalesFlow/evolved/model/vlux
 */

declare var $: JQueryStatic;
import {VluxDataLoaderInterface} from 'model-evolved/vlux/model-evolved--vlux--loader-interface';

/**
 * Load vlux json data
 */
export class VluxDataLoaderXhr implements VluxDataLoaderInterface {

    public get (url: string): JQueryPromise<any> {

        const deferred = $.Deferred<any>();

        $.ajax({
            url: url,
            dataType: 'json',
            data: {}
        }).done(function (data) {
            deferred.resolve(data);
        }).fail(function (error) {
            deferred.reject();
        });

        return deferred.promise();

    }

}
