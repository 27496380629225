/**
 * @module SalesFlow/model/type
 */
export default class AccessoryAttributes {

    [key: string]: any;

    private _descriptionAccessory: string[] = [];

    private _weight: number;

    private _usageTime: number;

    private _bluetooth: boolean;

    private _image170_230: string;

    private _image228_309: string;

    private _ean: string;

    private _mpn: string;

    /**
     * @TODO create an attributes interface including an index signature
     */
    constructor (attributes: any, parentAttributes?: any) {

        if (undefined !== parentAttributes) {

            for (const attribute in parentAttributes) {

                if ('weight' === attribute) {
                    this._weight = parseFloat(parentAttributes[attribute]);
                }

                if ('usageTime' === attribute) {
                    this._usageTime = parseFloat(parentAttributes[attribute]);
                }

                if ('bluetooth' === attribute) {
                    this._bluetooth = ('1' === parentAttributes[attribute]) ? true : false;
                }

                if ('image170_230' === attribute) {
                    this._image170_230 = parentAttributes[attribute];
                }

                if ('image228_309' === attribute) {
                    this._image228_309 = parentAttributes[attribute];
                }

                if ('descriptionAccessory' === attribute) {
                    for (const description of parentAttributes[attribute]) {
                        this._descriptionAccessory.push(description);
                    }
                }

                if ('ean' === attribute) {
                    this._ean = parentAttributes[attribute];
                }

                if ('mpn156' === attribute) {
                    this._mpn = parentAttributes[attribute];
                }

            }

        }

        // Sort only, if attributes are defined [VGOF-5525][INC-23524]
        if ('object' === typeof attributes && 0 < attributes.length) {

            attributes.sort(function (a: any, b: any) {

                if (a.sortOrder > b.sortOrder) {

                    return 1;
                }

                if (a.sortOrder < b.sortOrder) {

                    return -1;
                }

                // a muss gleich b sein
                return 0;
            });

            for (const attribute of attributes) {

                const attributeId = attribute.id;

                switch (attributeId) {

                    case '15':
                        this._weight = parseFloat(attribute.value);
                        break;
                    case '18':
                        this._usageTime = parseFloat(attribute.value);
                        break;
                    case '82':
                        this._bluetooth = ('1' === attribute.value) ? true : false;
                        break;
                    case '122':
                        this._image170_230 = attribute.value;
                        break;
                    case '123':
                        this._image228_309 = attribute.value;
                        break;
                    case '137':
                        this._descriptionAccessory.push(attribute.value);
                        break;
                    case '155':
                        this._ean = attribute.value;
                        break;
                    case '156':
                        this._mpn = attribute.value;
                        break;

                }
            }
        }
    }

    get descriptionAccessory (): string[] {
        return this._descriptionAccessory;
    }

    get weight (): number {
        return this._weight;
    }

    get usageTime (): number {
        return this._usageTime;
    }

    get bluetooth (): boolean {
        return this._bluetooth;
    }

    get image170_230 (): string {
        return this._image170_230;
    }

    get image228_309 (): string {
        return this._image228_309;
    }

    get ean (): string {
        return this._ean;
    }

    get mpn (): string {
        return this._mpn;
    }

}
