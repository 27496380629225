/**
 * @module SalesFlow/view
 */

declare var $: JQueryStatic;

import DeviceOffer from '../../../view/shared/offer/device-offer';

import FilterOption from '../../../view/shared/filter-option';
import FilterGeneric from './filter-generic';

export default class DeviceFilterVendor extends FilterGeneric {

    private addMissingVendors (viewOffers: DeviceOffer[]): void {

        let vendors: string[] = [];
        for (const viewOffer of viewOffers) {
            vendors.push(viewOffer.atomicDevice.attr.vendor);
        }

        const onlyUnique = function (value: any, index: any, self: any) {
            return self.indexOf(value) === index;
        };

        vendors = vendors.filter(onlyUnique);

        const missingVendors: string[] = vendors.filter((vendor) => {

            for (const filterOption of this._filterOptions) {

                if (vendor === filterOption.value) {

                    return false;

                }

            }

            return true;

        });

        if (0 !== missingVendors.length) {

            const vendorStub = $('<div>');

            vendorStub.attr('data-type', 'string');
            vendorStub.attr('data-attribute', 'vendor');
            vendorStub.attr('data-condition', '=');

            for (const missingVendor of missingVendors) {

                const missingVendorStub = vendorStub.clone();

                missingVendorStub.attr('data-title', missingVendor);
                missingVendorStub.attr('data-value', missingVendor);

                this._filterOptions.push(
                    new FilterOption(missingVendorStub)
                );

            }

            // While a missing vendor could be in storage, i have to load again
            this.load();

        }

    }

    public render (viewOffers: DeviceOffer[]): string {

        super.render(viewOffers);

        // @this point all filteroptions from dom are already parsed and now we have also all devices in their ViewOffer
        // So i can add now all vendors that are not already set in dom e.g editorial have not added them
        this.addMissingVendors(viewOffers);

        return this.getInjector().getTemplates().render('device_filter', this);
    }

}
