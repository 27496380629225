/**
 * @module SalesFlow/shopbackend
 */

import Cookie from 'router/cookie';

declare var $: JQueryStatic;
export default class CommerceApi {

    /*
    * DO NOT CHANGE APIURL !!!
    *
    * Use Environment Jabof Variable instead:
    * https://confluence.wf-de.vodafone.com/display/VFDEOPM/How+To%3A+Change+Shop+Backend+in+Jabof
    *
    */
    private _apiUrl: string;
    private _apiUrlMint: string;

    public constructor (apiUrl = '/api/commerce', mintUrl = '/mint/vfssdublin/selsec/prx/apicommerce') {
        this._apiUrl = apiUrl;
        this._apiUrlMint = mintUrl;
    }

    public apiCall (callback: () => JQueryXHR): JQueryPromise<any> {
        const deferred = $.Deferred();
        callback().then((text, status, xhr) => {
            // @ts-ignore
            // TODO: jQuery Update Problem!!!
            this.setBshopToken(xhr).then(() => {
                try {
                    const result = JSON.parse(xhr.responseText);
                    deferred.resolve(result);
                 } catch (e) {
                    deferred.reject(xhr);
                 }
            });
        }, (xhr) => {
            deferred.reject(xhr);
        });

        return deferred.promise();
    }

    private setBshopToken (xhr: XMLHttpRequest): JQueryPromise<any> {
        const deferred = $.Deferred();
        if (!(xhr.getResponseHeader('x-vf-bshoptoken-set') && '' !== xhr.getResponseHeader('x-vf-bshoptoken-set'))) {
            deferred.resolve();
        } else {
            $.ajax({
                method: 'POST',
                url: '/api/commerce/setcookie/bshop',

                headers: {
                    'x-vf-bshoptoken': xhr.getResponseHeader('x-vf-bshoptoken-set')
                },
                cache: false,
                dataType: 'text'
            }).done(function (result) {
                // throw response away since cookie was successfully set - go ahead with normal ajax call
                deferred.resolve();
            }).fail(function (error) {
                deferred.reject({error: 'something went wrong'});
            });
        }

        return deferred.promise();
    }

    get apiUrl (): string {
        return this._apiUrl;
    }

    get apiMintUrl (): string {
        return this._apiUrlMint;
    }

}
