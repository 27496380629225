/**
 * @module SalesFlow/router
 */

import Routing from 'router/shared/routing';

import {RouterEvolvedBntDeviceOverview} from 'router-evolved/bnt/router-evolved--bnt-device-overview';
import {RouterEvolvedBntDeviceDetail} from 'router-evolved/bnt/router-evolved--bnt-device-detail';
import {RouterEvolvedBntConnectMore} from 'router-evolved/bnt/router-evolved--bnt-connect-more';
import {RouterEvolvedBntSubscriptionOverview} from 'router-evolved/bnt/router-evolved--bnt-subscription-overview';

/**
 * The new sales flow root object
 */
export default class GigakombiRouting extends Routing {

    protected _validPages: string[] = [
         'subscription_overview',
        'device_overview',
        'device_detail',
        'connectmore'
    ];

    protected doSomethingBeforeRouterAreCreated (): void {
        $('.tariff-module-tile[data-gigakombi="false"]').remove();
    }

    protected getRouter (): JQueryPromise<any> {
        const deferred = $.Deferred();

        const currentStep = this.getCurrentPage();

        this._injector.getGigakombi().checkGigakombi().then(() => {

            const generalSalesObjectInterface = this._injector.getGeneralSalesObjectInterface();
            generalSalesObjectInterface.isGigakombi = true;
            generalSalesObjectInterface.gigakombiMarker = this._injector.getGigakombi().getVorteil();

            if ('subscription_overview' === currentStep) {
                deferred.resolve(new RouterEvolvedBntSubscriptionOverview(this._injector));
            } else if ('device_overview' === currentStep) {
                deferred.resolve(new RouterEvolvedBntDeviceOverview(this._injector));
            } else if ('device_detail' === currentStep) {
                deferred.resolve(new RouterEvolvedBntDeviceDetail(this._injector, this._skeletonService));
            } else if ('connectmore' === currentStep) {
                deferred.resolve(new RouterEvolvedBntConnectMore(this._injector));
            }
        });

        return deferred.promise();
    }
}
