/**
 * @module SalesFlow/router
 */

import Routing from 'router/shared/routing';

import {RouterEvolvedBntSubscriptionOverview} from '../../router-evolved/bnt/router-evolved--bnt-subscription-overview';
import {RouterEvolvedBntDeviceOverview} from '../../router-evolved/bnt/router-evolved--bnt-device-overview';
import {RouterEvolvedBntDeviceDetail} from '../../router-evolved/bnt/router-evolved--bnt-device-detail';
import {RouterEvolvedBntConnectMore} from '../../router-evolved/bnt/router-evolved--bnt-connect-more';

import SubscriptionDetailSeoBntRouter from './subscription-detail-seo-router';

/**
 * The new sales flow root object
 */
/**
 * @internal
 */
export default class BntRouting extends Routing {

    protected _validPages: string[] = [
        'subscription_overview',
        'device_overview',
        'device_detail',
        'connectmore',
        'subscription_detail_seo'
    ];

    protected doSomethingBeforeRouterAreCreated (): void {
        $('.tariff-module-tile[data-gigakombi="true"]').remove();
    }

    protected getRouter (): JQueryPromise<any> {
        const deferred = $.Deferred();

        const currentStep = this.getCurrentPage();

        if ('subscription_overview' === currentStep) {
            deferred.resolve(new RouterEvolvedBntSubscriptionOverview(this._injector));
        }
        else if ('device_overview' === currentStep) {
            deferred.resolve(new RouterEvolvedBntDeviceOverview(this._injector));
        }
        else if ('device_detail' === currentStep) {
            deferred.resolve(new RouterEvolvedBntDeviceDetail(this._injector, this._skeletonService));
        }
        else if ('connectmore' === currentStep) {
            deferred.resolve(new RouterEvolvedBntConnectMore(this._injector));
        }
        else if ('subscription_detail_seo' === currentStep) {
            deferred.resolve(new SubscriptionDetailSeoBntRouter(this._injector.getRepos(), this._injector)); // Todo: THIS IS NOT EVOLVED
        }

        return deferred.promise();
    }
}
