/**
 * @module SalesFlow/view
 */

import {FlowCta} from 'view/ctas/shared/flow-cta';
import Offer from 'view/view/shared/offer/offer';
import {ViewEvolvedCtasBase} from 'view-evolved/ctas/vvl/view-evolved--ctas--base';

/**
 * Only one button here
 * Right: Link to x-sellpage
 *
 * @export
 * @class ViewEvolvedCtasXSell
 * @extends {ViewEvolvedCtasBase}
 */
export class ViewEvolvedCtasXSell extends ViewEvolvedCtasBase {

    protected getLeftButton (): FlowCta {

        return new FlowCta(
            ['btn-alt', 'nfs-x-sell-back', 'nsf-small-button-width'],
            this.getInjector().getStrings().get('pricebox.x-sell.back')
        );

    }

    protected getRightButton (): FlowCta {

        return new FlowCta(
            ['nfs-x-sell-confirm'],
            this.getInjector().getStrings().get('pricebox.confirm.basket')
        );

    }

    protected cameFromRecommendationPage () {
        if (true === this.getInjector().getStorage().hasItem('cameFromRecommendationPage')) {
            return true;
        } else {
            return false;
        }
    }

    /*
    * Functions to return the urls for the CTAs based on a string coming from nsf-options
    * We created these functions in order to override them inside the different sales-chnanel i.e. soho-vvl
    * and deliver different left and right buttons with custom urls, without implementing again the update method
    * when extending the class i.e. for SOHO VVL
    * In this case we have only a Left Cta button url function because right always takes you to the basket
    */
    protected getLeftCtaUrl (): string {

        if (true === this.cameFromRecommendationPage()) {
            return this.getInjector().getOptions().get('vvl_recommendation_url');
        } else {
            return this.getInjector().getOptions().get('vvl_tariff_device_url');
        }

    }

    public update (offer: Offer): void {

        if (undefined === offer) {
            return;
        }

        this._right.setHref(
            '#proceedToBasket'
        );

        let leftCtaUrl: string = '';

        leftCtaUrl = this.getLeftCtaUrl();
        this._left.setHref(
            leftCtaUrl
        );

        this._element.html(
            this.getInjector().getTemplates().render('flow-ctas', [this._left, this._right], 'partials')
        );

    }

    protected events (): void {
        super.events();

        /**
         * From connect more the next step "forward" is the basket
         */
        this._element.on('click', this._right.getSelector(), (evt: JQueryEventObject) => {

            evt.preventDefault();

            this.createBasketAndProceed();

        });

    }

}
