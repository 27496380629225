/**
 * @module SalesFlow/model/type
 */

import AllowedTariffDefinition from './allowed-tariff-definition';

export default class AllowedTariffs  {

    private _allowedTariffDefinition: AllowedTariffDefinition[] = [];

    constructor (allowedTariffDefinition: AllowedTariffDefinition[]) {

        for (const allowedTariff of allowedTariffDefinition) {
            this._allowedTariffDefinition.push(
                new AllowedTariffDefinition(allowedTariff)
            );
        }

    }

    get allowedTariffDefinition (): AllowedTariffDefinition[] {
        return this._allowedTariffDefinition;
    }

}
