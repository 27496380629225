/**
 * @module SalesFlow/router
 */

import { RouterEvolvedInlifeRouter } from './router-evolved--inlife-router';
import { InlifeTariffSelectionController } from 'controller-evolved/inlife/controller-evolved--inlife-tariff-selection';
import Subscription from 'model/type/subscription';
import {Constants} from 'core/constants';
import {SubscriptionIdPerSalesChannel} from 'core/ids';

export class RouterEvolvedInlifeTariffSelection extends RouterEvolvedInlifeRouter {

    protected loadVluxDataIntoReposSupervisor (): void {
        this.getReposSupervisor().loadDependencyRepo();
    }

    protected loadReposSupervisor (): JQueryPromise<any> {

        const deferred = $.Deferred<any>();

        const salesChannel = this.getSalesChannel();

        if (Constants.SALESCHANNEL_CONSUMER === salesChannel) {
            this.getInjector().getFlowStateWithSalesChannel().setSubscriptionGroup(salesChannel);
        }

        this.getReposSupervisor().initialLoadingDone().done( () => {

            this.loadVluxDataIntoReposSupervisor();

            this.getReposSupervisor().getChainedPromise().done(() => {

                /**
                 * Send raw VLUX Data to General Offer Service node module so it doesn't has to be loaded twice
                 */
                this.prepareGeneralSalesObject();

                deferred.resolve();

            });

        });

        return deferred.promise();

    }

    public validateIncoming (): void {

        this._subscriptionId = this.resolveSubscriptionId(
            this.getSubscriptionIds()
        );

    }

    public getFocusSubscriptionIds (): SubscriptionIdPerSalesChannel {

        const subscriptionIds: SubscriptionIdPerSalesChannel = {
            consumer: this.getInjector().getOptions().get('default_consumer_subscription_id') || undefined,
            young: this.getInjector().getOptions().get('default_young_subscription_id') || undefined,
            soho: this.getInjector().getOptions().get('default_consumer_subscription_id') || undefined
        };

        // set recommended subscription and on focus if there is one
        if (undefined !== this.getInjector().getFlowStateWithSalesChannel().getRecommendedSubscriptionId()) {

            const recommendedSubscriptionId = this.getInjector().getFlowStateWithSalesChannel().getRecommendedSubscriptionId();

            // recommendedSubscriptionId could be consumer or young from current portfolio
            // so let's find out for which salesChannel we want the recommended id in focus

            const recommendedSubscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(recommendedSubscriptionId);
            subscriptionIds[recommendedSubscription.subscriptionGroupName] = recommendedSubscriptionId;

        }

        return subscriptionIds;
    }

    private getInlifeMainSubscriptionGroups (inlifeOffers: any): any {

        const mainSubscriptionGroups = [];

        for (const inlifeOffer of inlifeOffers) {
            const mainSubscriptionGroup = this.getMainSubscriptionGroup(inlifeOffer.subscriptionId);
            mainSubscriptionGroups.push(mainSubscriptionGroup);

        }

        return mainSubscriptionGroups;

    }

    private getAllowedSubscriptionIds (allowedSubscriptions: Subscription[]): number[] {

        const mainSubscriptionGroups = [];

        for (const subscription of allowedSubscriptions) {
            mainSubscriptionGroups.push(subscription.id);
        }

        return mainSubscriptionGroups;

    }

    public createController (): JQueryPromise<InlifeTariffSelectionController> {

        const deferred = $.Deferred<any>();

        this.loadReposSupervisor().then(() => {

            this.validateIncoming();
            this._skeletonService.removeSkeletonLoadingClassNames();

            const cheapestSubLevelSubscription = this.getCheapestSubLevelSubscription();

            const subLevelAmount = this.getReposSupervisor().getSubscriptionRepo().getSubLevelBySubsidizationId(cheapestSubLevelSubscription.subscriptionId);

            const allowedTariffIds = this.setCustomerAllowedTariffIds();
            const inlifeOffers = this.getInlifeOffersByAllowedTariffIds(allowedTariffIds);
            const mainSubscriptionGroups = this.getInlifeMainSubscriptionGroups(inlifeOffers);
            const allowedSubscriptionIds = this.getAllowedSubscriptionIds(mainSubscriptionGroups);

            deferred.resolve(
                new InlifeTariffSelectionController(
                    this.getSalesChannel(),
                    mainSubscriptionGroups,
                    inlifeOffers,
                    allowedSubscriptionIds,
                    subLevelAmount,
                    this.getReposSupervisor(),
                    this.getInjector()
                )
            );
        });

        return deferred.promise();

    }

}
