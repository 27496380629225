/**
 * @module SalesFlow/view
 */

import DeviceList from '../shared/device-list';

export default class DeviceListBnt extends DeviceList {

    protected _tileHbs = 'device_tile';

}
