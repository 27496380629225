/**
 * @module SalesFlow/view
 */

import Injector from 'core/injector';

interface RenderableInterface {
    bind (): void;

    unbind (): void;
}

export abstract class Renderable<T> implements RenderableInterface {

    protected _injector: Injector;

    constructor (injector?: Injector) {
        this._injector = injector;
    }

    protected abstract render (data?: T): string | void;

    protected abstract events (data?: T): void;

    public update (data?: T): void {

    }

    public bind (data?: T): void {
        this.render(data);
        this.events();
    }

    public unbind (): void {

    }

    protected getInjector (): Injector {
        return this._injector;
    }

}
