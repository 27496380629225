/**
 * @module SalesFlow/model/type
 */
import {VluxJsonOfferPrice} from '../vlux-interface';

export default class OfferPrice {

    private _value: number;
    private _recurrenceUnit: string;
    private _recurrenceStart: number;
    private _recurrenceEnd: number;
    private _label: string;
    private _strikePrice: number;

    constructor (data: VluxJsonOfferPrice) {

        if ('number' !== typeof data.value) {
            this._value = parseFloat(data.value.replace(',', '.'));
        } else {
            this._value = data.value;
        }
        this._recurrenceUnit = data.recurrenceUnit;
        this._recurrenceStart = parseInt(data.recurrenceStart, 10);
        this._recurrenceEnd = 24;
        if (undefined !== data.recurrenceEnd) {
            this._recurrenceEnd = parseInt(data.recurrenceEnd, 10);
        }
        this._label = data.label;
        if ('number' !== typeof data.strikePrice) {
            this._strikePrice = parseFloat(data.strikePrice.replace(',', '.'));
        } else {
            this._strikePrice = data.strikePrice;
        }
    }

    public addToValue (digit: number): void {
        this._value += digit;
    }

    set value (value: number) {
        this._value = value;
    }

    get value (): number {
        return this._value;
    }

    get recurrenceUnit (): string {
        return this._recurrenceUnit;
    }

    get recurrenceStart (): number {
        return this._recurrenceStart;
    }

    get recurrenceEnd (): number {
        return this._recurrenceEnd;
    }

    get label (): string {
        return this._label;
    }

    get strikePrice (): number {
        return this._strikePrice;
    }

}
