/**
 * @module SalesFlow/model/type
 */

import NsfError from 'core/error';

export default class AttributeDefinition {

    private _id: number;
    private _groupId: number;
    private _sortOrder: number;
    private _hidden: boolean;
    private _name: string;
    private _unit: string = '';
    private _description: string = '';
    private _type: string;

    /**
     * Some units are not set in attributes.json, they are set un hardware.json. E.g. internalStorage to distinguish MB and GB based on device
     */
    private _unitsFromHardware: boolean = false;

    private _value: any = undefined;

    constructor (data: any) {
        this.parseData(data);
    }

    private parseData (data: any) {

        if (undefined === data.id) {
            new NsfError('id is mandatory when create a AttributeDefinition.');
        }

        if (undefined === data.groupId) {
            new NsfError('groupId is mandatory when create a AttributeDefinition.');
        }

        if (undefined === data.sortOrder) {
            new NsfError('sortOrder is mandatory when create a AttributeDefinition.');
        }
        if (undefined === data.label) {
            new NsfError('label is mandatory when create a AttributeDefinition.');
        }
        if (undefined === data.mask) {
            new NsfError('mask is mandatory when create a AttributeDefinition.');
        }

        this._id = parseInt(data.id, 10);

        this._groupId = parseInt(data.groupId, 10);

        this._sortOrder = parseInt(data.sortOrder, 10);

        this._hidden = ('True' === data.hidden) ? true : false;

        this._name = data.label;

        if (undefined !== data.unit) {
            this._unit = data.unit;
        }

        if (-1 !== [14, 24].indexOf(this._id)) {
            this._unitsFromHardware = true;
        }

        if (undefined !== data.description) {
            this._description = data.description;
        }

        // Eco-Rating/Nachhaltigkeit
        if ('257' === data.id) {
            this._description = '<a href="/unternehmen/soziale-verantwortung/eco-rating.html" target="_blank" rel="nofollow noopener">(Mehr Infos)</a>';
        }

        this._type = ('' + data.mask).toLocaleLowerCase();
    }

    public setValue (value: any) {
        this._value = value;
    }

    get id (): number {
        return this._id;
    }

    get groupId (): number {
        return this._groupId;
    }

    get sortOrder (): number {
        return this._sortOrder;
    }

    get hidden (): boolean {
        return this._hidden;
    }

    get name (): string {
        return this._name;
    }

    get unit (): string {
        return this._unit;
    }

    get unitsFromHardware (): boolean {
        return this._unitsFromHardware;
    }

    get description (): string {
        return this._description;
    }

    get type (): string {
        return this._type;
    }

    get value (): string {
        return this._value;
    }

}
