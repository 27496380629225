/**
 * @module SalesFlow/view
 */

import Injector from 'core/injector';

import ViewOverlay from './overlay';
import ViewOffer from './offer/device-offer';

import TechnicalDetails from '../../element/bnt/technical-details';

import AttributeRepo from 'model/repo/attribute-repo';

import AttributeGroup from 'model/type/attribute-group';

export default class ViewOverlayDeviceDetails extends ViewOverlay {

    protected _injector: Injector;
    protected _deviceOffer: ViewOffer;
    protected _image: string;
    protected _topFeatures: string[];
    protected _technicalDetails: TechnicalDetails;

    constructor (injector: Injector, deviceOffer: ViewOffer, attributeRepo: AttributeRepo) {
        super(deviceOffer.name);

        this._injector = injector;
        this._deviceOffer = deviceOffer;

        /**
         * Get front device image
         */
        this._image = deviceOffer.nsfImage90_120;
        for (const image of deviceOffer.nfsLargeImages) {
            if (0 <= image.indexOf('01.png')) {
                this._image = image;
            }
        }

        this._topFeatures = deviceOffer.topFeatures;

        this._technicalDetails = new TechnicalDetails(injector);
        this._technicalDetails.setAttributeRepo(attributeRepo);
    }

    get image (): string {
        return this._image;
    }

    get topFeatures (): string[] {
        return this._topFeatures;
    }

    get atomicDeviceName (): string {
        return this._deviceOffer.name;
    }

    get packageParts (): string[] {
        return this._deviceOffer.atomicDevice.attr.packageParts;
    }

    get topAttributeDefinition (): AttributeGroup[] {
        return [this._technicalDetails.getViewTechnicalDetails(this._deviceOffer).attributeGroups[0]];
    }

    get technicalData (): string {
        return this._injector.getTemplates().render('technical_details_table', this._technicalDetails.getViewTechnicalDetails(this._deviceOffer).attributeGroups, 'partials');
    }

}
