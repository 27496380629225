/**
 * @module SalesFlow/view
 */
import {
    SwitcherDataInterface,
    ViewEvolvedElementSharedTariffgroupSwitcher
} from 'view-evolved/element/shared/view-evolved--element-shared--tariffgroup-switcher';
import {Constants} from 'core/constants';

export default class ViewEvolvedElementGigakombiTariffgroupSwitcher extends ViewEvolvedElementSharedTariffgroupSwitcher {

    protected getSwitcherData (): SwitcherDataInterface[] {

        /**
         * @TODO For sprint-28 : in stories 3377 and 4146 we must exract the allowed subscription groups form the group switcher into constants
         * and return them in order to check if the radio button is in a allowed tarif group and then hide the green badge
         * maybe the hide can be a method called for each one of these two cases i) radio button and ii) hardware only click .
         * Simply in the radio button check the tarif group and then apply  the method
         */

        return [
            {
                salesChannel: Constants.SALESCHANNEL_CONSUMER,
                subscriptionGroup: Constants.SUBSCRIPTION_GROUP_RED,
                name: 'Gigamobil-Tarife',
                active: false
            },
            {
                salesChannel: Constants.SALESCHANNEL_YOUNG,
                subscriptionGroup: Constants.SUBSCRIPTION_GROUP_YOUNG,
                name: 'Ich bin unter 28',
                active: false
            }
        ];

    }

}
