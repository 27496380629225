/**
 * @module SalesFlow/evolved/view
 */

declare var $: JQueryStatic;

import Offer from 'view/view/shared/offer/offer';
import { FlowCtas } from 'view/ctas/shared/flow-ctas';
import { FlowCta } from 'view/ctas/shared/flow-cta';
import Injector from 'core/injector';

/**
 * Only one button in the pricebox module and in costoverview
 * Right: Link to basket with the recommanded subscription
 *
 * @export
 * @class ViewEvolvedInlifeCtasRecommendation
 * @extends {FlowCtas}
 */
export class ViewEvolvedInlifeCtasRecommendation extends FlowCtas {
    private _showRedXLNotification: boolean;
    private _countOfAllowedTariffs: number;
    private _checkboxStateStorageKey: string = 'checkedRedXLWarningHint';

    constructor (injector: Injector, countOfAllowedTariffs: number, showRedXLNotification: boolean) {
        super(injector);
        this._countOfAllowedTariffs = countOfAllowedTariffs;
        this._showRedXLNotification = showRedXLNotification;
    }

    protected getLeftButton (): FlowCta {

        return new FlowCta(
            ['btn-alt', 'nfs-recommendation-skip'],
            '<span class="recommendation-cta-text-desktop">' + this.getInjector().getStrings().get('inlife.pricebox.reject.recommendation.text1') + '</span><span class="recommendation-cta-text-mobile">' + this.getInjector().getStrings().get('inlife.pricebox.reject.recommendation.text2') + '</span>'
        );
    }

    protected getRightButton (): FlowCta {

        const buttonText = this.getInjector().getStrings().get('pricebox.confirm.basket');

        return new FlowCta(
            ['nfs-recommendation-confirm'],
            buttonText
        );

    }

    protected getLeftCtaUrl (): string {

        return this.getInjector().getOptions().get('inlife_tariff_selection_url');

    }

    protected getRightCtaUrl (): string {

        const rightCtaUrl = '#proceedToBasket';

        return rightCtaUrl;

    }

    protected events (): void {

        super.events();

        this._element.on('click', this._right.getSelector(), (evt: JQueryEventObject) => {

            evt.preventDefault();

            this.createBasketAndProceed();

        });

        $(document).on('change', '#redXlWarningCheckbox', (e) => {
            const checkbox = <HTMLInputElement> e.currentTarget;

            this.getInjector().getStorage().setItem('checkedRedXLWarningHint', checkbox.checked + '');
            this.toggleButtonDisablement(checkbox.checked);
            this.getInjector().getStorage().setItem(this._checkboxStateStorageKey, checkbox.checked + '');
        });

        $(document).on('click', '.redxl-warning .close', () => {
            $('.redxl-warning').hide();
        });

    }

    private ShowRedXLTooltip (): void {
        $('.redxl-warning').show();
    }

    protected toggleButtonDisablement (enable: boolean) {
        if (enable) {
            $('.redxl-warning').hide();
            $('.redXL-disabled').parent().off('click', this.ShowRedXLTooltip);
            $('.nfs-recommendation-confirm').removeAttr('disabled').removeClass('redXL-disabled');
        } else {
            $('.nfs-recommendation-confirm').attr('disabled', 'disabled').addClass('redXL-disabled');
            $('.redXL-disabled').parent().on('click', this.ShowRedXLTooltip);
        }
    }

    public update (offer: Offer): void {

        if (undefined === offer) {
            return;
        }

        if (this._countOfAllowedTariffs > 1) {
            this._left.setHref(
                this.getLeftCtaUrl()
            );
        }

        this._right.setHref(
            this.getRightCtaUrl()
        );

        this._right.setNotification(
            this.getInjector().getTemplates().render('inlife-redxl-tooltip', {}, 'partials')
        );

        let buttons = [this._right];
        if (this._countOfAllowedTariffs > 1) {
            buttons = [this._left, this._right];
        }

        this._element.html(
            this.getInjector().getTemplates().render('flow-ctas', buttons, 'partials')
        );

        if (this._showRedXLNotification) {
            $('.redXlAnnotation-belt').show();
            const checkboxState = this.getInjector().getStorage().getItem(this._checkboxStateStorageKey);
            this.toggleButtonDisablement('true' === checkboxState);
        }

    }

}
