/**
 * @module SalesFlow/evolved/view
 */

declare var $: JQueryStatic;

import Injector from 'core/injector';
import {AbstractSubscriptionGroupSwitcher} from 'view/element/shared/subscription-group-switcher';

/**
 * @TODO CO-3119 No ChannelSwitcher for e.g. private pricing
 */
export class ViewEvolvedElementVvlNoneChannelSwitcher extends AbstractSubscriptionGroupSwitcher {

    private _element: JQuery;

    constructor (injector: Injector) {

        super(injector);

        this._element = $('#nsf-subscription-group-switcher').remove();

    }

    public render (): void {

    }

    public events (): void {

    }

}
