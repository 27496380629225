
/**
 * @module SalesFlow/evolved/view
 */

declare var $: any;

import {Renderable} from 'view/renderable';
import Customer from 'shopbackend/customer';
import Injector from 'core/injector';
import FoldUpAndDown from 'view/element/shared/fold-up-and-down';

export class ViewEvolvedElementRedPlusContractDataAccordion extends Renderable<any> {

    protected _customer: Customer;

    protected _element: JQuery;

    constructor (
        customer: Customer,
        injector: Injector
    ) {

        super(injector);

        this._customer = customer;

        this._element = $('#nsf-contract-data');
    }

    public render (): void {
        const foldUpAndDown = new FoldUpAndDown($('#nsf-fold-up-and-down-contract-data'), this._injector);
        foldUpAndDown.bind();

        const contractData = {
            customer: this._customer,
            introtext: '',
            headline: ''
        };

        let anrede = 'du';
        if (this._customer.isSohoCustomer) {
            anrede = 'sie';
        }
        contractData.introtext = this.getInjector().getStrings().get('redplus.contractdata.introtext');
        contractData.headline = this.getInjector().getStrings().get('redplus.contractdata.headline');

        this._element.html(this.getInjector().getTemplates().render('contract-data-accordion', contractData));
    }

    public events (): void {

    }

    public bind (): void {
        this.render();
        this.events();
    }
}
