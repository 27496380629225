/**
 * @module SalesFlow/tracking
 */

 import Injector from 'core/injector';

import {ActiveFilterOptionsByGroup} from 'view/element/shared/filter/filters';

interface FastMap {
    [index: string]: FilterForTracking;
}

export interface FilterForTracking {
    facet: string;
    value: string;
}

/**
 * Track filterchanges
 */
/**
 * @internal
 */
export default class TrackFilters {

    private _injector: Injector;

    private _activeFilters: FastMap = {};
    private counter: number;

    constructor (injector: Injector) {

        this._injector = injector;

        this.counter = 0;

        this._injector.getEvent().listen('filters@changed', (evt: JQueryEventObject, activeFilterOptionsByGroup: ActiveFilterOptionsByGroup) => {
            this.counter = this.counter + 1;
            this.handleFiltersChanged(activeFilterOptionsByGroup);
        });

    }

    private getMapIndex (groupName: string, value: string): string {
        return groupName + '--' + value;
    }

    private handleFiltersChanged (activeFilterOptionsByGroup: ActiveFilterOptionsByGroup): void {
        const removed: FilterForTracking[] = [];
        const added: FilterForTracking[] = [];

        // added filters
        for (const groupName in activeFilterOptionsByGroup) {

            const group = activeFilterOptionsByGroup[groupName];

            for (const option of group) {
                const index = this.getMapIndex(groupName, option.value);

                if (undefined === this._activeFilters[index]) {
                    added.push({
                        facet: groupName,
                        value: option.title
                    });
                }
                else {
                    delete this._activeFilters[index];
                }

            }

        }

        // looped over all currently filters and deleted every hit from this._activeFilters
        // so every filter that is still in this._activeFilters has been removed
        for (const filter in this._activeFilters) {
            removed.push(this._activeFilters[filter]);
        }

        // store actual state
        this._activeFilters = {};
        for (const groupName in activeFilterOptionsByGroup) {

            const group = activeFilterOptionsByGroup[groupName];

            for (const option of group) {
                const index = this.getMapIndex(groupName, option.value);

                this._activeFilters[index] = {
                    facet: groupName,
                    value: option.title
                };

            }

        }

        const active: FilterForTracking[] = [];

        for (const index in this._activeFilters) {
            active.push(this._activeFilters[index]);
        }

        // not on page load
        if (1 < this.counter) {
            this._injector.getEvent().trigger('tracking@filterChanged', {
                active: active,
                added: added,
                removed: removed
            });
        }

    }

}
