/**
 * @module SalesFlow/view
 */

export default class ViewAlertButton {

    private _additionalClass: string;
    private _label: string;
    private _href: string;

    constructor (additionalClass: string, label: string, href: string) {

        this._additionalClass = additionalClass;
        this._label = label;
        this._href = href;

    }

    public get additionalClass (): string {
        return this._additionalClass;
    }

    public get label (): string {
        return this._label;
    }

    public get href (): string {
        return this._href;
    }

}
