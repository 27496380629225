/**
 * @module SalesFlow/evolved/view
 */

import Injector from 'core/injector';
import SimOnlyOffer from 'view/view/shared/offer/sim-only-offer';
import {Renderable} from 'view/renderable';
import SubscriptionDetailOverlay from 'view/element/bnt/subscription-detail-overlay';

declare var $: JQueryStatic;
declare var vf: any;

export class ViewEvolvedElementRedPlusSubscriptionDetail extends Renderable<SimOnlyOffer[]> {

    private _element: JQuery;

    private _accordionToggle: JQuery;

    private _subscriptionDetailOverlay: SubscriptionDetailOverlay;

    constructor (element: JQuery, injector: Injector) {

        super(injector);

        this._element = element;

        this._accordionToggle = this._element.find('.redplus-subscription-tile-accordion .foot');

        this._subscriptionDetailOverlay = new SubscriptionDetailOverlay(injector);

    }

    public render (offers: SimOnlyOffer[]): void {
        const html = this.getInjector().getTemplates().render('nsf-pass-area-readonly', {});

        // Searches the tiles for list element with the class "content-show-pass" wich can be added by the content team
        // if found, it appends the pass area after that li
        for (const offer of offers) {
            const tile: JQuery = this._element.find('[data-subscription-id="' + offer.subscriptionId + '"]');
            const tariffDetailListItem: JQuery = tile.find('li.content-show-pass').append(html);

        }
    }

    public events (offers: SimOnlyOffer[]): void {

        this._element.on('click', '.redplus-accordion-toggle', (evt: JQueryEventObject) => {
            const currentElement: JQuery<Element> = $(evt.currentTarget);

            currentElement
                .find('.more')
                .toggleClass('is-hidden');

            currentElement
                .find('.less')
                .toggleClass('is-hidden');

            currentElement
                .parent()
                .find('.redplus-list-container')
                .toggleClass('redplus-accordion-is-open');
        });

        this._element.on('click', '.open-overlay', (evt: JQueryEventObject) => {
            vf.overlay.openOverlayDirect('nsf-vf-pass-overlay');
        });

    }

    public bind (offers: SimOnlyOffer[]): void {
        this.render(offers);
        this.events(offers);
        this._subscriptionDetailOverlay.bind();
    }

}
