/**
 * @module SalesFlow/view
 */

import {Renderable} from 'view/renderable';
import {SalesChannelName, SubscriptionGroupName} from 'core/ids';
import Injector from 'core/injector';
import BntDeviceFlowService from 'service/bnt/bnt-device-flow-service';

export interface SwitcherDataInterface {
    salesChannel: SalesChannelName;
    subscriptionGroup: SubscriptionGroupName;
    name: string;
    active: boolean;
}

export interface TariffGroupInterface {
    salesChannel: SalesChannelName;
    subscriptionGroup: SubscriptionGroupName;
}

export abstract class ViewEvolvedElementSharedTariffgroupSwitcher extends Renderable<any[]> {

    protected _element: JQuery;

    protected _currentSalesChannel: SalesChannelName;
    protected _currentsubscriptionGroup: SubscriptionGroupName;

    constructor (injector: Injector) {

        super(injector);

        this._element = $('#nsf-subscription-group-switcher');
        this._currentSalesChannel = this.getInjector().getFlowState().getSalesChannel();
        this._currentsubscriptionGroup = this.getInjector().getFlowState().getSubscriptionGroup();

    }

    protected abstract getSwitcherData (): SwitcherDataInterface[];

    public render (): void {

        let switcherData: SwitcherDataInterface[] = this.getSwitcherData();

        switcherData = switcherData.map((switcherDataItem: SwitcherDataInterface) => {

            if (this._currentSalesChannel === switcherDataItem.salesChannel && this._currentsubscriptionGroup === switcherDataItem.subscriptionGroup) {
                switcherDataItem.active = true;
            }

            return switcherDataItem;

        });

        this._element.html(
            this.getInjector().getTemplates().render('sales_channel_switcher', {
                switcherData: switcherData

            })
        );
    }

    protected handleTariffGroupSelectionChanging (tariffGroupParam: TariffGroupInterface): void {

        this._currentSalesChannel = tariffGroupParam.salesChannel;
        this._currentsubscriptionGroup = tariffGroupParam.subscriptionGroup;

        this._element.find('.checked').removeClass('checked');

        const activeGroupRadioButton = this._element.find('input[subscriptiongroup="' + tariffGroupParam.subscriptionGroup + '"][salesChannel="' + tariffGroupParam.salesChannel + '"]');
        activeGroupRadioButton.addClass('checked');

        const label = this._element.find('label[for="' + activeGroupRadioButton.attr('id') + '"]');
        label.addClass('checked');

        this.getInjector().getFlowState().setTariffGroup(tariffGroupParam.salesChannel, tariffGroupParam.subscriptionGroup, false, false);

    }

    protected handleClick (evt: JQueryEventObject): void {

        const $radioButton = $(evt.currentTarget);

        // radio button style: once one channel is selected in can not be deselected
        if (true === $radioButton.hasClass('checked')) {
            return;
        }

        const salesChannel = $radioButton.attr('salesChannel') as SalesChannelName;
        const subscriptionGroup = $radioButton.attr('subscriptionGroup') as SubscriptionGroupName;

        // filter invalid channels and invalid subscriptionGroup
        if (false === this.getInjector().getFlowState().isValidSalesChannel(salesChannel) ||
            false === this.getInjector().getFlowState().isValidSubscriptionGroup(subscriptionGroup)) {

            return undefined;

        }

        const tariffGroupParam: TariffGroupInterface = {
            salesChannel: salesChannel,
            subscriptionGroup: subscriptionGroup
        };

        this._injector.getEvent().trigger('TariffGroupSelection@changing', tariffGroupParam);

        BntDeviceFlowService.toggleHeadlineWithContract(this.getInjector().getFlow().getHardwareOnly());

    }

    public events (): void {

        this._injector.getEvent().listen('TariffGroupSelection@changing', (eventObject: JQueryEventObject, tariffGroupParam: TariffGroupInterface) => {

            if (false === this.getInjector().getFlowState().isValidSalesChannel(tariffGroupParam.salesChannel) ||
                false === this.getInjector().getFlowState().isValidSubscriptionGroup(tariffGroupParam.subscriptionGroup)) {

                return undefined;

            }

            this.handleTariffGroupSelectionChanging(tariffGroupParam);

        });

        this._element.on('click', '.tariff-group-switcher', (evt: JQueryEventObject) => {

            this.handleClick(evt);

        });

    }
}
