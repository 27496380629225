/**
 * @module SalesFlow/evolved/model/repo
 */

import {Repo} from 'model/repo/repo';
import SubscriptionRepo from 'model/repo/subscription-repo';
import {BusinessTransactionContext, SalesChannelName} from 'core/ids';
import AttributeRepo from 'model/repo/attribute-repo';
import {VluxDataSupervisor} from 'model-evolved/vlux/model-evolved--vlux--supervisor';
import {ModelEvolvedRepoService} from 'model-evolved/repo/model-evolved--repo--service';
import {ModelEvolvedRepoDiscount} from 'model-evolved/repo/model-evolved--repo--discount';
import {ModelEvolvedRepoDevice} from 'model-evolved/repo/model-evolved--repo--device';
import {ModelEvolvedRepoPurchasableDevice} from 'model-evolved/repo/model-evolved--repo--purchasable-device';
import {ModelEvolvedRepoAccessory} from 'model-evolved/repo/model-evolved--repo--accessory';
import {ModelEvolvedRepoPurchasableAccessory} from 'model-evolved/repo/model-evolved--repo--purchasable-accessory';
import { Constants } from 'core/constants';
import GeneralSalesObjectInterface from 'service/general-sales-object/general-sales-object-interface';
import DependencyRepo from 'model/repo/dependency-repo';

export class ModelEvolvedRepoSupervisor {

    private _loadingPromise: JQueryPromise<any>;

    private _chainedPromise: JQueryPromise<any>;

    private _vluxDataSupervisor: VluxDataSupervisor;

    private _subscriptionRepo: SubscriptionRepo;

    private _serviceRepo: ModelEvolvedRepoService;

    private _discountRepo: ModelEvolvedRepoDiscount;

    private _deviceRepo: ModelEvolvedRepoDevice;

    private _purchasableDeviceRepo: ModelEvolvedRepoPurchasableDevice;

    private _purchasableTabletRepo: ModelEvolvedRepoPurchasableDevice;

    private _purchasableDeviceKidsRepo: ModelEvolvedRepoPurchasableDevice;

    private _purchasableDeviceBasicRepo: ModelEvolvedRepoPurchasableDevice;

    /**
     * on connect-more, we have 3 device repos:
     * - the one for the device that was selected on hub page
     * - one for Red+ Allnet Devices
     * - one for Red+ Data Devices
     * @private
     */
    private _purchasableDeviceRepoConnectMore: ModelEvolvedRepoPurchasableDevice;

    private _accessoryRepo: ModelEvolvedRepoAccessory;

    private _purchasableAccessoryRepo: ModelEvolvedRepoPurchasableAccessory;

    private _attributesRepo: AttributeRepo;

    private _dependencyRepo: DependencyRepo;

    constructor (vluxRawData: VluxDataSupervisor) {

        this._vluxDataSupervisor = vluxRawData;

        // a resolved promise to be used later for chaining
        // @ts-ignore
        this._chainedPromise = $.Deferred<any>().resolve();

        /**
         * Create an empty offer repo. While every offer depend on subscription, service and discount
         * theses are loaded first
         */
        this._loadingPromise = $.when(
            this.loadSubscriptionRepo(),
            this.loadServiceRepo(),
            this.loadDiscountRepo()
        ).done(() => {
            // intentionally left blank
        });

    }

    /**
     * This promise is resolved, when subscription, service and discounts are loaded
     */
    public initialLoadingDone (): JQueryPromise<any> {

        return this._loadingPromise;

    }

    /**
     * Every public load method is chained to this promise
     */
    public getChainedPromise (): JQueryPromise<any> {

        return this._chainedPromise;

    }

    /**
     * Loaded by default in construct
     */
    private loadSubscriptionRepo (): JQueryPromise<any> {

        const deferred = $.Deferred<Repo>();

        this._vluxDataSupervisor.load(
            this._vluxDataSupervisor.getVluxPath() + '/subscriptions/tariffmobile.json'
        ).done((data) => {

            this._subscriptionRepo = new SubscriptionRepo(data);
            deferred.resolve();

        });

        return deferred.promise();

    }

    /**
     *
     * Is undefined until initialLoadingDone is resolved
     */
    public getSubscriptionRepo (): SubscriptionRepo {

        return this._subscriptionRepo;

    }

    /**
     * Loaded by default in construct
     */
    private loadServiceRepo (): JQueryPromise<Repo> {

        const deferred = $.Deferred<Repo>();

        this._vluxDataSupervisor.load(
            this._vluxDataSupervisor.getVluxPath() + '/subscriptions/servicemobile.json'
        ).done((data) => {

            this._serviceRepo = new ModelEvolvedRepoService(data);

            deferred.resolve();

        });

        return deferred.promise();

    }

    /**
     * @TODO Decide is not needed to be public?
     *
     * Is undefined until initialLoadingDone is resolved
     */
    public getServiceRepo (): ModelEvolvedRepoService {

        return this._serviceRepo;

    }

    /**
     * Loaded by default in construct
     */
    private loadDiscountRepo (): JQueryPromise<Repo> {

        const deferred = $.Deferred<Repo>();

        this._vluxDataSupervisor.load(
            this._vluxDataSupervisor.getVluxPath() + '/subscriptions/discountmobile.json'
        ).done((data) => {

            this._discountRepo = new ModelEvolvedRepoDiscount(data);

            deferred.resolve();

        });

        return deferred.promise();

    }

    /**
     * @TODO Decide is not needed to be public?
     *
     * Is undefined until initialLoadingDone is resolved
     */
    public getDiscountRepo (): ModelEvolvedRepoDiscount {

        return this._discountRepo;

    }

    /**
     * @TODO Decide is not needed to be public?
     * @TODO Add a type for type e.g. global or data
     *
     * @param type
     */
    public loadDeviceRepo (type?: string): JQueryPromise<Repo> {

        type = type || 'global';

        const deferred = $.Deferred<Repo>();

        this._vluxDataSupervisor.load(
            this._vluxDataSupervisor.getVluxPath() + '/products/hardwaremobile.json'
        ).done((data: any) => {

            this._deviceRepo = new ModelEvolvedRepoDevice(data);
            deferred.resolve();

        });

        const promise = deferred.promise();

        this._chainedPromise = $.when(
            this._chainedPromise,
            promise
        );

        return promise;

    }

    /**
     * @TODO Decide is not needed to be public?
     */
    public getDeviceRepo (): ModelEvolvedRepoDevice {

        return this._deviceRepo;

    }

    /**
     * @TODO Add a type for type e.g. global or data
     *
     * @param type
     */
    public loadPurchasableDeviceRepo (btx: BusinessTransactionContext, salesChannel: SalesChannelName, generalSalesObjectInterface: GeneralSalesObjectInterface, type?: string): JQueryPromise<Repo> {

        type = type || 'global';

        const deferred = $.Deferred<Repo>();

        this.loadDeviceRepo(type).done(() => {

            /**
             * load different device sortorders (defined in Mobile Editor in CMS) for different purposes:
             * - Tablets for Red+ Data
             * - Kids for Red+ Kids
             * - Basic for Red+ Basic
             * - general for all others
             */
            if (Constants.RedPlus_Data_Vlux_File_Extension === type) {
                this._purchasableTabletRepo = new ModelEvolvedRepoPurchasableDevice(
                    this.getDeviceRepo(),
                    generalSalesObjectInterface,
                    btx,
                    salesChannel
                );
            } else if (Constants.RedPlus_Kids_Vlux_File_Extension === type) {
                this._purchasableDeviceKidsRepo = new ModelEvolvedRepoPurchasableDevice(
                    this.getDeviceRepo(),
                    generalSalesObjectInterface,
                    btx,
                    salesChannel
                );
            } else if (Constants.RedPlus_Basic_Vlux_File_Extension === type) {
                this._purchasableDeviceBasicRepo = new ModelEvolvedRepoPurchasableDevice(
                    this.getDeviceRepo(),
                    generalSalesObjectInterface,
                    btx,
                    salesChannel
                );
            } else {
                this._purchasableDeviceRepo = new ModelEvolvedRepoPurchasableDevice(
                    this.getDeviceRepo(),
                    generalSalesObjectInterface,
                    btx,
                    salesChannel
                );
            }

            deferred.resolve();

        });

        const promise = deferred.promise();

        this._chainedPromise = $.when(
            this._chainedPromise,
            promise
        );

        return promise;

    }

    public loadPurchasableDeviceRepoConnectMore (btx: BusinessTransactionContext, salesChannel: SalesChannelName, generalSalesObjectInterface: GeneralSalesObjectInterface, type?: string): JQueryPromise<Repo> {

        type = type || 'global';

        if (Constants.OrderType_Voice === type) {
            type = 'global';
        }

        const deferred = $.Deferred<Repo>();

        this.loadDeviceRepo(type).done(() => {

            /**
             * load different device sortorders (defined in Mobile Editor in CMS) for different purposes:
             * - Tablets for Red+ Data
             * - Kids for Red+ Kids
             * - general for all others
             */

            this._purchasableDeviceRepoConnectMore = new ModelEvolvedRepoPurchasableDevice(
                this.getDeviceRepo(),
                generalSalesObjectInterface,
                btx,
                salesChannel
            );

            deferred.resolve();

        });

        const promise = deferred.promise();

        this._chainedPromise = $.when(
            this._chainedPromise,
            promise
        );

        return promise;

    }

    /**
     *
     */
    public getPurchasableDeviceRepo (): ModelEvolvedRepoPurchasableDevice {

        return this._purchasableDeviceRepo;

    }

    /**
     *
     */
    public getPurchasableTabletRepo (): ModelEvolvedRepoPurchasableDevice {

        return this._purchasableTabletRepo;

    }

    /**
     *
     */
    public getPurchasableDeviceKidsRepo (): ModelEvolvedRepoPurchasableDevice {

        return this._purchasableDeviceKidsRepo;

    }

    /**
     *
     */
    public getPurchasableDeviceBasicRepo (): ModelEvolvedRepoPurchasableDevice {

        return this._purchasableDeviceBasicRepo;

    }

    /**
     *
     */
    public getConnectMoreDeviceRepo (): ModelEvolvedRepoPurchasableDevice {

        return this._purchasableDeviceRepoConnectMore;

    }

    /**
     *
     */
    public loadAccessoryRepo (): JQueryPromise<Repo> {

        const deferred = $.Deferred<Repo>();

        this._vluxDataSupervisor.load(
            this._vluxDataSupervisor.getVluxPath() + '/products/accessorymobile-global.json'
        ).done((data: any) => {

            this._accessoryRepo = new ModelEvolvedRepoAccessory(data);
            deferred.resolve();

        });

        const promise = deferred.promise();

        this._chainedPromise = $.when(
            this._chainedPromise,
            promise
        );

        return promise;

    }

    /**
     *
     */
    public getAccessoryRepo (): ModelEvolvedRepoAccessory {

        return this._accessoryRepo;

    }

    /**
     * @TODO Add a type for type e.g. global or data
     *
     * @param type
     */
    public loadPurchasableAccessoryRepo (salesChannel: SalesChannelName, generalSalesObjectInterface: GeneralSalesObjectInterface, type?: string): JQueryPromise<Repo> {

        type = type || 'global';

        const deferred = $.Deferred<Repo>();

        this.loadDeviceRepo(type).done(() => {

            this.loadAccessoryRepo().done(() => {
                this._purchasableAccessoryRepo = new ModelEvolvedRepoPurchasableAccessory(
                    this.getAccessoryRepo(),
                    generalSalesObjectInterface,
                    salesChannel
                );

                deferred.resolve();
            });
        });

        const promise = deferred.promise();

        this._chainedPromise = $.when(
            this._chainedPromise,
            promise
        );

        return promise;

    }

    /**
     *
     */
    public getPurchasableAccessoryRepo (): ModelEvolvedRepoPurchasableAccessory {

        return this._purchasableAccessoryRepo;

    }

    /**
     *
     */
    public loadAttributeRepo (): JQueryPromise<Repo> {

        const deferred = $.Deferred<Repo>();

        this._vluxDataSupervisor.load(
            this._vluxDataSupervisor.getVluxPath() + '/attributes/attributes.json'
        ).done((data: any) => {

            this._attributesRepo = new AttributeRepo(data);
            deferred.resolve();

        });

        const promise = deferred.promise();

        this._chainedPromise = $.when(
            this._chainedPromise,
            promise
        );

        return promise;

    }

    /**
     *
     */
    public getAttributeRepo (): AttributeRepo {

        return this._attributesRepo;

    }

    /**
     *
     */
    public loadDependencyRepo (): JQueryPromise<Repo> {

        const deferred = $.Deferred<Repo>();

        this._vluxDataSupervisor.load(

            this._vluxDataSupervisor.getVluxPath() + '/dependencies/dependencies.json'

        ).done((data: any) => {

            this._dependencyRepo = new DependencyRepo(data);
            deferred.resolve();

        });

        const promise = deferred.promise();

        this._chainedPromise = $.when(
            this._chainedPromise,
            promise
        );

        return promise;

    }

    /**
     *
     */
    public getDependencyRepo (): DependencyRepo {

        return this._dependencyRepo;

    }

}
