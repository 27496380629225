/**
 * @module SalesFlow/evolved/router
 */

import {RouterEvolvedVvlDeviceOverview} from 'router-evolved/vvl/router-evolved--vvl--device-overview';

import {RouterEvolvedVvlRouter} from 'router-evolved/vvl/router-evolved--vvl--router';
import {ControllerEvolvedVvlDeviceOverview} from 'controller-evolved/vvl/controller-evolved--vvl-device-overview';
import {Constants} from 'core/constants';
import GeneralSalesObjectInterface from 'service/general-sales-object/general-sales-object-interface';
import {SalesChannelName, BusinessTransactionContext} from 'core/ids';

/**
 * @TODO don't use injector as dependency
 */
export class RouterEvolvedSohoVvlDeviceOverview extends RouterEvolvedVvlDeviceOverview {

    /**
     * For soho sales channel is always soho
     */
    protected setSalesChannel (): void {

        this._salesChannel = Constants.SALESCHANNEL_SOHO;

    }

    public loadReposSupervisor (): JQueryPromise<any> {

        const deferred = $.Deferred<any>();

        this.getReposSupervisor().initialLoadingDone().done( () => {

            const salesChannel: SalesChannelName = this.getSalesChannel();
            const btx: BusinessTransactionContext = this.getInjector().getBtx();
            const generalSalesObjectInterface: GeneralSalesObjectInterface = this.getInjector().getGeneralSalesObjectInterface();

            this.getReposSupervisor().loadPurchasableDeviceRepo(btx, salesChannel, generalSalesObjectInterface);
            this.getReposSupervisor().loadAttributeRepo();
            this.getReposSupervisor().loadDependencyRepo();

            this.getReposSupervisor().getChainedPromise().done(() => {

                /**
                 * Send raw VLUX Data to General Offer Service node module so it doesn't has to be loaded twice
                 */
                this.prepareGeneralSalesObject();

                deferred.resolve();

            });

        });

        return deferred.promise();

    }

     public createController (): JQueryPromise<RouterEvolvedVvlRouter> {
         const deferred = $.Deferred<any>();

         this.loadReposSupervisor().then(() => {

             this.validateIncoming();
             this._skeletonService.removeSkeletonLoadingClassNames();

             deferred.resolve(
                 new ControllerEvolvedVvlDeviceOverview(
                     this.getInjector().getFlowStateWithSalesChannel().customer,
                     this.getSalesChannel(),
                     this.getAtomicDeviceId(),
                     this.getSubscriptionId(),
                     this.getFocusSubscriptionIds(),
                     this.getReposSupervisor(),
                     this.getInjector()
                 )
             );

         }, function () {
             deferred.reject();
         });

         return deferred.promise();

     }

}
