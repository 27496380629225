/**
 * @module SalesFlow/model/type
 */
export default class ServiceAttributes {

    [key: string]: any;

    private _hidden: boolean = false;

    private _elws: string = '';

    private _dataVolume: number = 0;

    constructor (attributes: any) {

        for (const attribute of attributes) {

            const attributeId = attribute.id;

            if ('183' === attributeId) {
                this._hidden = ('1' === attribute.value) ? true : false;
            }

            if ('198' === attributeId) {
                this._elws = attribute.value;
            }

            if ('167' === attributeId) {
                this._dataVolume = <number> attribute.value;
            }

        }

    }

    get hidden (): boolean {
        return this._hidden;
    }

    get elws (): string {
        return this._elws;
    }

    /**
     * size of additional datavolume given by the service
     */
    get dataVolume (): number {
        return this._dataVolume;
    }

}
