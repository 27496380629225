/**
 * @module SalesFlow/core
 */

/**
 * A wrapper for throw new Error
 * Can be used in production to switch from throw to log
 */
export default class NsfError {

    constructor (msg: string) {
        throw new Error(msg);
    }

}
