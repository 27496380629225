/**
 * @module SalesFlow/core
 */

declare var $: JQueryStatic;

export default class PromoBadgeApi {

    public static getPromoBadgeData (): JQueryPromise<any> {
        const deferred = $.Deferred<any>();

        const apiUrl = '/privat/json/promo-badge.json';

        $.ajax({
            url: apiUrl,
            method: 'get',
            dataType: 'json'
        }).done(function (response) {
            deferred.resolve(response);
        }).fail(function (error) {
            deferred.reject(error);
        });

        return deferred.promise();
    }
}
