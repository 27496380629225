import {CoreFlowState} from 'core/core-flow-state';

/**
 * @module SalesFlow/view
 */

declare var $: JQueryStatic;

declare var vf: any;

import Injector from 'core/injector';

import {Renderable} from '../../renderable';

import DeviceOffer from '../../view/shared/offer/device-offer';

import FilterMatch from '../shared/filter/filter-match';

import {ActiveFilterOptionsByGroup} from '../shared/filter/filters';

import SlideMeIfYouCan from '../shared/slide-if-you-can';

export default class DeviceList extends Renderable<DeviceOffer[]> {

    protected _viewOffers: DeviceOffer[];

    protected _element: JQuery;

    protected _slider: SlideMeIfYouCan;

    protected _tileHbs: string;

    protected _coreFlowState: CoreFlowState;
    protected _selectedAtomicDeviceId: number;

    constructor (injector: Injector) {

        super(injector);

        this._coreFlowState = injector.getFlow();
        this._selectedAtomicDeviceId = this._coreFlowState.getAtomicDeviceId();

        this._element = $('#nsf-product-list');

        this._slider = new SlideMeIfYouCan(
            $('#nsf-product-list-slide'),
            'device',
            injector
        );

    }

    public getElement (): JQuery {
        return this._element;
    }

    public updateHeight (): void {
        this._slider.updateHeight();
    }

    public update (devices: DeviceOffer[]): void {

        const currentOffers = this._element.find('.device-module-tile');
        const listDeviceIds = [];
        for (let x = 0; x < currentOffers.length; x++) {
            if (undefined !== parseInt(currentOffers[x].getAttribute('data-atomic-device-id'), 10)) {
                listDeviceIds.push(parseInt(currentOffers[x].getAttribute('data-atomic-device-id'), 10));
            }
        }

        for (const device of devices) {
            if (undefined === device) {
                this._element.find('[data-atomic-device-id="' + device.atomicDeviceId + '"]').addClass('not-combinable');
                continue;
            } else {
                if (this._element.find('[data-atomic-device-id="' + device.atomicDeviceId + '"]').hasClass('not-combinable')) {
                    this._element.find('[data-atomic-device-id="' + device.atomicDeviceId + '"]').removeClass('not-combinable');
                    this._slider.update();
                }
            }

            for (let x = 0; x < listDeviceIds.length; x++) {
                if (listDeviceIds[x] === device.atomicDeviceId) {
                    listDeviceIds.splice(x, 1);
                }
            }

            if (undefined !== this.getInjector().getFlow().getSubscriptionId()) {
                device.offer.markAsLowestOffer(false);
            }

            const subscriptionDepended: JQuery = this._element.find('[data-atomic-device-id="' + device.atomicDeviceId + '"] .subscriptionDepended');

            subscriptionDepended.html(
                this.getInjector().getTemplates().render('device_tile_price', device, 'partials')
            );

        }

        if (0 < listDeviceIds.length) {
            for (let x = 0; x < listDeviceIds.length; x++) {
                this._element.find('[data-atomic-device-id="' + listDeviceIds[x] + '"]').addClass('not-combinable');
            }

            this._slider.update();
            this._slider.scrollToFocus();
        }
    }

    public render (devices: DeviceOffer[]): string {

        const html: string[] = [];

        for (const device of devices) {

            if (undefined === device) {
                continue;
            }

            html.push(
                this.getInjector().getTemplates().render(this._tileHbs, device)
            );
        }

        const joined: string = html.join('');

        this._element.html(joined);

        if (undefined !== this.getInjector().getFlow().getAtomicDeviceId()) {
            this._element.find('[data-atomic-device-id="' + this.getInjector().getFlow().getAtomicDeviceId() + '"]').addClass('selected');
        }

        /**
         * for tradein checkbox
         */
        if (vf && vf.footnotes) {
            vf.footnotes.init();
        }

        return joined;

    }

    public filterList (activeFilterOptionsByGroup: ActiveFilterOptionsByGroup): void {

        let hasNoFilters = true;

        for (const filterName in activeFilterOptionsByGroup) {
            hasNoFilters = false;
        }

        const $devices: JQuery = this._element.find('.device-module-tile');

        // hide all devices
        $devices.addClass('hide');

        for (const viewOffer of this._viewOffers) {

            viewOffer.atomicDevice.getDevice().setIsMatchingFilter(true);

            for (const filterName in activeFilterOptionsByGroup) {

                // Is already not matched in a previous filterGroup
                if (false === viewOffer.atomicDevice.getDevice().getIsMatchingFilter()) {
                    continue;
                }

                let matchGroup: boolean = false;

                for (const activeFilter of activeFilterOptionsByGroup[filterName]) {

                    const match = FilterMatch.exec(activeFilter, viewOffer);
                    if (true === match) {
                        matchGroup = true;
                        break;
                    }

                }

                viewOffer.atomicDevice.getDevice().setIsMatchingFilter(matchGroup);

            }

            if (true === viewOffer.atomicDevice.getDevice().getIsMatchingFilter()) {

                $devices.filter('[data-device-id="' + viewOffer.atomicDevice.getDevice().id + '"]').removeClass('hide');

            }

        }

        const matchingViewOffers = this._viewOffers.filter((viewOffer) => {
            return viewOffer.atomicDevice.getDevice().getIsMatchingFilter();
        });

        // if filter result = 0 do not slider update
        if (0 < matchingViewOffers.length) {
            this._slider.update();
        }

        this.getInjector().getEvent().trigger('device-list@filtered', {matchingViewOffers: matchingViewOffers});

    }

    public events (): void {

        this._element.off('click').on('click', '.device-module-tile .selectionCheck', (evt) => {

            const deviceTile = evt.currentTarget.parentElement.parentElement;
            const atomicDeviceId = parseInt(deviceTile.dataset.atomicDeviceId, 10);

            if (atomicDeviceId === this._selectedAtomicDeviceId) {
                this._selectedAtomicDeviceId = undefined;
                this._coreFlowState.setAtomicDeviceId(undefined);
                this._coreFlowState.unlockDevice();
            } else {
                this._selectedAtomicDeviceId = atomicDeviceId;
                this._coreFlowState.setAtomicDeviceId(atomicDeviceId);
                this._coreFlowState.lockDevice();

                for (const viewOffer of this._viewOffers) {
                    if (atomicDeviceId === viewOffer.atomicDeviceId) {

                        const url = viewOffer.atomicDevice.getDevice().getDetailLink(this.getInjector().getOptions().get('device_detail_prefix'));
                        this.getInjector().getEvent().trigger('stepper@device_detail_url', url);

                    }
                }
            }

        });

        this.getInjector().getEvent().listen('atomicDeviceId@changed', (eventObject: JQueryEventObject, data: any) => {

            const atomicDeviceId = data.atomicDeviceId;

            this._element.find('.device-module-tile.selected').removeClass('selected');

            if (undefined === atomicDeviceId) {
                return;
            }

            this._element.find('.device-module-tile[data-atomic-device-id="' + atomicDeviceId + '"]').addClass('selected');

        });

        this.getInjector().getEvent().listen('filters@changed', (eventObject: JQueryEventObject, activeFilterOptionsByGroup: ActiveFilterOptionsByGroup) => {
            this.filterList(activeFilterOptionsByGroup);

        });
    }

    public bind (devices: DeviceOffer[]): void {
        this._viewOffers = devices;

        this.render(devices);
        this.events();

        this._slider.bind();

    }

}
