/**
 * @module SalesFlow/view
 */

import Injector from 'core/injector';

import {Renderable} from '../../renderable';

import ViewAlert from '../../view/shared/alert';

export default class Alert extends Renderable<ViewAlert> {

    private _element: JQuery;

    /**
     * @PARAM DIV where the errormessage should be parsed into
     */
    constructor (injector: Injector, element: JQuery) {

        super(injector);

        this._element = element;

    }

    public getElement (): JQuery {

        return this._element;

    }

    public render (alert: ViewAlert): void {

        this._element.html(this.getInjector().getTemplates().render('alert', alert));

    }

    public events (): void {
    }

    public bind (alert: ViewAlert): void {

        this.render(alert);

    }

}
