/**
 * @module SalesFlow/router
 */

import { RouterEvolvedInlifeRouter } from './router-evolved--inlife-router';
import { InlifeRecommendationController } from 'controller-evolved/inlife/controller-evolved--inlife-recommendation';
import VluxOffer from 'model/type/offer';
import {Constants} from 'core/constants';

export class RouterEvolvedInlifeRecommendation extends RouterEvolvedInlifeRouter {

    protected loadVluxDataIntoReposSupervisor (): void {
        this.getReposSupervisor().loadDependencyRepo();
    }

    protected loadReposSupervisor (): JQueryPromise<any> {

        const deferred = $.Deferred<any>();

        this.getReposSupervisor().initialLoadingDone().done( () => {

            this.loadVluxDataIntoReposSupervisor();

            this.getReposSupervisor().getChainedPromise().done(() => {

                /**
                 * Send raw VLUX Data to General Offer Service node module so it doesn't has to be loaded twice
                 */
                this.prepareGeneralSalesObject();

                deferred.resolve();

            });

        });

        return deferred.promise();

    }

    public validateIncoming (): void {

        this._subscriptionId = this.resolveSubscriptionId(
            this.getSubscriptionIds()
        );

    }

    /**
     *
     * @param tariffId
     * Get the tariff change offer based on the cheapest allowed tariff IDs we got from the NBA call
     */
    private getInlifeOffersByTariffId (tariffId: number): any {

        const generalSalesObjectInterface = this.getInjector().getGeneralSalesObjectInterface();

        return generalSalesObjectInterface.getSimOnlyOfferByAtomicSubscriptionId(
            tariffId,
            Constants.BTX_INLIFE,
            this.getSalesChannel()
        );
    }

    public createController (): JQueryPromise<InlifeRecommendationController> {

        const deferred = $.Deferred<any>();

        this.loadReposSupervisor().then(() => {

            this.validateIncoming();
            this._skeletonService.removeSkeletonLoadingClassNames();

            // Get an offer from the allowed tariffIds coming from the URL parameter tariffId
            const offerFromUrlParamTariffId = this.getOfferFromUrlParamTariffId();

            // Get the cheapest offer from the allowed tariffIds
            const cheapestSubLevelSubscription = this.getCheapestSubLevelSubscription();

            const countOfAllowedTariffs = this.setCustomerAllowedTariffIds().length;

            if (countOfAllowedTariffs < 1) {
                window.location.href = this.getInjector().getOptions().get('vlux_url_404');
            }

            // @TODO: If selectOfferFromUrlOrCheapest returns undefined either because allowedTariffs is empty or there isn't an offer found
            // for the one of the allowed offers then a fallback solution should be implemented either with an error message or a redirection or a default subscription
            const selectOfferFromUrlOrCheapest = (offerFromUrlParamTariffId) ? offerFromUrlParamTariffId : cheapestSubLevelSubscription;

            const mainSubscriptionGroup = this.getMainSubscriptionGroup(selectOfferFromUrlOrCheapest.subscriptionId);

            const inlifeOffer = this.getInlifeOffersByTariffId(selectOfferFromUrlOrCheapest.subscriptionId);

            const subLevelAmount = this.getReposSupervisor().getSubscriptionRepo().getSubLevelBySubsidizationId(selectOfferFromUrlOrCheapest.subscriptionId);

            deferred.resolve(
                new InlifeRecommendationController(
                    this.getSalesChannel(),
                    mainSubscriptionGroup,
                    inlifeOffer,
                    countOfAllowedTariffs,
                    subLevelAmount,
                    this.getReposSupervisor(),
                    this.getInjector()
                )
            );
        });

        return deferred.promise();

    }

}
