/**
 * @module SalesFlow/controller-evolved
 */

import Injector from 'core/injector';

import {BusinessTransactionContext, SalesChannelName, SubscriptionGroupName, SubscriptionIdPerSalesChannel} from 'core/ids';

import {ControllerEvolvedBaseClassSubscriptionOverview} from 'controller-evolved/base-class/controller-evolved--base-class--subscription-overview';

import {ModelEvolvedRepoSupervisor} from 'model-evolved/repo/model-evolved--repo--supervisor';

import {EvolvedBntSubscriptionSelection} from 'view-evolved/element/bnt/view-evolved--element-bnt--subscription-selection';
import {AbstractSubscriptionGroupSwitcher} from 'view/element/shared/subscription-group-switcher';
import {ViewEvolvedElementBntTariffGroupSwitcher} from 'view-evolved/element/bnt/view-evolved--element-bnt--tariffgroup-switcher';

import {TariffGroupInterface} from 'view-evolved/element/shared/view-evolved--element-shared--tariffgroup-switcher';
import GigakombiDeviceDetailService from 'service/gigakombi/gigakombi-device-detail-service';

/**
 * @TODO When Get parameter, tha focus that
 * @TODO Resolve by Router
 */
export class ControllerEvolvedBntSubscriptionOverview extends ControllerEvolvedBaseClassSubscriptionOverview {

    protected _subscriptionSelection: EvolvedBntSubscriptionSelection;

    constructor (
        focusSubscriptionIds: SubscriptionIdPerSalesChannel,
        reposSupervisor: ModelEvolvedRepoSupervisor,
        injector: Injector
    ) {

        super(focusSubscriptionIds, reposSupervisor, injector);

        if (true === this.getInjector().getOptions().get('debug')) {
            const cnsl = console;
            cnsl.log('TariffOverviewPage....');
        }

    }

    protected createSubscriptionList (): EvolvedBntSubscriptionSelection {

        const subscriptionId = this.getInjector().getFlowState().getSubscriptionId();
        const gigakombiDeviceDetailService = new GigakombiDeviceDetailService(this.getInjector());

        return new EvolvedBntSubscriptionSelection(
            subscriptionId,
            this.getInjector(),
            this._focusSubscriptionIdArray,
            gigakombiDeviceDetailService
        );

    }

    protected createTariffGroupSwitcher (): AbstractSubscriptionGroupSwitcher {

        if (this._showSubscriptionGroupSwitcher) {
            return new ViewEvolvedElementBntTariffGroupSwitcher(
                this.getInjector()
            );
        }

    }

    public events () {

        this.getInjector().getEvent().listen('offerByVFPass@changed', (eventObject: JQueryEventObject, data: any) => {

            this.getInjector().getEvent().trigger('offer@changed', {
                offer: this.getInjector().getOfferCollection().getActiveOffer()
            });

        });

        this.getInjector().getEvent().listen('TariffGroupName@changed', (eventObject: JQueryEventObject, tariffGroupParam: TariffGroupInterface) => {

            const salesChannel: SalesChannelName = tariffGroupParam.salesChannel;
            const subscriptionGroup: SubscriptionGroupName = tariffGroupParam.subscriptionGroup;

            if (false === this.getInjector().getFlowState().isValidSalesChannel(salesChannel) ||
                false === this.getInjector().getFlowState().isValidSubscriptionGroup(subscriptionGroup)) {

                return undefined;

            }

            this.handleEventTariffGroupNameChanged(tariffGroupParam);

        });

        this.getInjector().getEvent().listen('TariffGroup@changed', (eventObject: JQueryEventObject, tariffGroupParam: TariffGroupInterface) => {

            const salesChannel: SalesChannelName = tariffGroupParam.salesChannel;
            const subscriptionGroup: SubscriptionGroupName = tariffGroupParam.subscriptionGroup;

            if (false === this.getInjector().getFlowState().isValidSalesChannel(salesChannel) ||
                false === this.getInjector().getFlowState().isValidSubscriptionGroup(subscriptionGroup)) {

                return undefined;

            }

            this.handleEventTariffGroupChanged(tariffGroupParam);

        });

    }

}
