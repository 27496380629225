/**
 * @module SalesFlow/view
 */

import AttributeGroup from 'model/type/attribute-group';

import AtomicDevice from 'model/type/atomic-device';
import AttributesParser from 'model/type/attributes-parser';

export default class ViewTechnicalDetails {

    private _attributeGroups: AttributeGroup[];
    private _atomicDevice: AtomicDevice;

    constructor (attributeGroups: AttributeGroup[], atomicDevice: AtomicDevice) {

        this._attributeGroups = attributeGroups.filter((attributeGroup) => {
            if (0 === attributeGroup.attributeDefinition.length) {
                return false;
            }

            return true;
        });

        this._atomicDevice = atomicDevice;

        for (const attributeGroup of this._attributeGroups) {

            for (const attributeDefinition of attributeGroup.attributeDefinition) {

                const mapId = attributeDefinition.id;

                if (undefined === AttributesParser.attributesMapping[mapId]) {

                    continue;

                }

                let name = AttributesParser.attributesMapping[mapId].name;

                if (undefined === atomicDevice.attr[name]) {
                    continue;
                }

                if (true === attributeDefinition.unitsFromHardware) {
                    name += 'WithUnit';
                }

                attributeDefinition.setValue(atomicDevice.attr[name]);

            }
        }

        for (const attributeGroup of this._attributeGroups) {
            attributeGroup.removeEmptyAttributes();
        }

        this._attributeGroups = this._attributeGroups.filter((attributeGroup) => {

            let hasAtLeastOneValue = false;

            for (const attributeDefinition of attributeGroup.attributeDefinition) {
                if (false === hasAtLeastOneValue && undefined !== attributeDefinition.value) {
                    hasAtLeastOneValue = true;
                    break;
                }
            }

            return hasAtLeastOneValue;
        });

    }

    get attributeGroups (): AttributeGroup[] {
        return this._attributeGroups;
    }

    get atomicDevice (): AtomicDevice {
        return this._atomicDevice;
    }

}
