/**
 * @module SalesFlow/shopbackend
 */

declare var $: JQueryStatic;
declare var vf: any;
declare var _ddq: any;
import Injector from 'core/injector';

import ViewOverlay from 'view/view/shared/overlay';
import { Constants } from 'core/constants';

export default class BasketHandler {

    private _injector: Injector;

    constructor (injector: Injector) {
        this._injector = injector;
    }

    public defaultSuccessHandling (success: any): void {
        this._injector.getLoadingIndicator().hide();

        if ('object' === typeof success && ('B.P.200.0' === success.responseCode || 'P.Po.200.0' === success.responseCode || 'P.Po.200.1' === success.responseCode)) {
            window.location.href = this._injector.getOptions().get('basket_url');
        }
    }

    public defaultErrorHandling (error: any): void {
        if (401 === error.status) {
            window.location.href = '/shop/authentifizierung.html?btype=force&goto=' + window.location.pathname;
        } else {
            this._injector.getLoadingIndicator().hide();

            const data = JSON.parse(error.responseText);
            let overlayContent: JQuery;

            switch (data.responseCode) {
                /**
                 * Check for basket rules
                 */
                case 'B.P.403.3': // the number of propositions in the basket exceeds the allowed maximum
                case 'P.Po.403.3':
                    /**
                     * INCM-873:
                     * no need to count the items already in the basket, as we want to put > 7 in here
                     * and the request from Demand Management, that we can put more stuff into basket in
                     * one ore two steps as never been implemented
                     */
                    overlayContent = $('.basket-error.max-propositions-1');
                    const viewOverlay = new ViewOverlay('', '', overlayContent.html());
                    this._injector.getOverlay().open(viewOverlay);
                    break;
                case 'B.P.403.4': // the value of the basket exceeds the allowed maximum
                case 'P.Po.403.4':
                    overlayContent = $('.basket-error.max-value');
                    const viewOverlayMaxValue = new ViewOverlay('', '', overlayContent.html());
                    this._injector.getOverlay().open(viewOverlayMaxValue);
                    _ddq.push(['error', {
                        type: 'validation',
                        message: 'total order too expensive'
                    }]);
                    break;
                case 'B.P.403.5': // the number of tariffs in the basket exceeds the allowed maximum
                case 'P.Po.403.5':
                    overlayContent = $('.basket-error.max-tariffs');
                    const viewOverlayMaxTariffs = new ViewOverlay('', '', overlayContent.html());
                    this._injector.getOverlay().open(viewOverlayMaxTariffs);
                    break;
                case 'B.P.403.7': // the value of the accessoires in basket exceeds the allowed maximum
                case 'P.Po.403.7':
                    overlayContent = $('.basket-error.max-accessories-value');
                    const viewOverlayMaxAccessoiresValue = new ViewOverlay('', '', overlayContent.html());
                    this._injector.getOverlay().open(viewOverlayMaxAccessoiresValue);
                    _ddq.push(['error', {
                        type: 'validation',
                        message: 'accessory order too expensive'
                    }]);
                    break;
                default:
                    $('.basket-error.technical-error').show();
                    vf.util.scrollto($('.basket-error.technical-error'));
            }
        }
    }
}
