/**
 * @module SalesFlow/router
 */

import Routing from 'router/shared/routing';

import Customer from 'shopbackend/customer';
import Nba from 'shopbackend/nba';
import {Constants} from 'core/constants';

import {RouterEvolvedInlifeRecommendation} from 'router-evolved/inlife/router-evolved--inlife-recommendation';
import {RouterEvolvedInlifeTariffSelection} from 'router-evolved/inlife/router-evolved--inlife-tariff-selection';

/**
 * The new sales flow root object
 */
/**
 * @internal
 */
export default class InlifeRouting extends Routing {

    protected salesChannel = this.getInjector().getOptions().get('default_sales_channel');

    protected _validPages: string[] = [
        'inlife_tariff_selection'
    ];

    /**
     * CO-28942 Removing Young XS and offer Young XL & Promo Refactoring
     * @param consumer
     * @returns redirection url for the soho customer
     */
     protected sohoCustomerRedirect (customer: Customer) {
        // Soho customer with right soho channel
        if (customer.isSohoCustomer && (Constants.SALESCHANNEL_SOHO ===  this.salesChannel || Constants.SALESCHANNEL_CONSUMER === this.salesChannel)) {
            const sohoInlifeSelfSelect = this._injector.getOptions().get('inlife_slotmachine_soho');

            return sohoInlifeSelfSelect;
        }
    }

    /**
     * CO-3209, This function gets the URL that we will redirect the user to if he is not allowed to enter inlife curiosity flow
     * We redirect Soho, Young and FF users to inlife legacy flow.
     * We redirect black tariff users and users with no allowed tariffs to hotline notification.
     * @param _customer
     */
    private getRedirectURL (_customer: Customer): string {
        let redirectUrl: string = '';
        const customer = _customer;
        const actualLocation = window.location.pathname;

        /**
         * check if Customer is eligible for Inlife
         * If not, redirect to VVL when eligable
         */
        if (!customer.isInlifeEligible) {
            if (customer.isVVLEligible) {
                redirectUrl = this._injector.getOptions().get('contract_prolongation_url');
            } else {
                redirectUrl = '/shop/authentifizierung.html?btype=tariffChange&goto=' + window.location.pathname;
            }
        } else {
            // really impossible case, but ca happen. so better catch it up
            if (undefined === typeof customer.allowedTariffs || 0 === customer.allowedTariffs.allowedTariffDefinition.length) {
                redirectUrl = this._injector.getOptions().get('vlux_url_404');
            }

            // Customer is not young anymore :'(
            if (!customer.isYoungCustomer && Constants.SALESCHANNEL_YOUNG === this.salesChannel) {
                redirectUrl = this._injector.getOptions().get('inlife_tariff_selection_url');
            }

            // Family & Friends If not consumer - redirect to not allowed page
            if ((customer.isSohoCustomer || customer.hasFF) && Constants.SALESCHANNEL_FAMILY_FRIENDS === this.salesChannel) {
                redirectUrl = this._injector.getOptions().get('inlife_slotmachine_faf');
            }

            /**
             * for Story CO-28942  a bit of quick refactoring was needed because of extremely big complexity of the function
             * at some point this needs to be evaluated because there are many obsolete business logic and a huge simplification needs to be done
             */
            this.sohoCustomerRedirect(customer);

            /**
             * When a customer who is not soho goes into the soho flow redirect him to default consumer inlife page - recommendation (nsf-options)
             */
            if (!customer.isSohoCustomer && Constants.SALESCHANNEL_SOHO === this.salesChannel) {
                redirectUrl = this._injector.getOptions().get('inlife_tariff_selection_url');
            }

            // has an old black tariff
            if (customer.hasBlackTariff) {
                redirectUrl = this._injector.getOptions().get('inlife_black_tariff');
            }
        }

        // for testing with playground pages on int108 the second check is needed in order to stay on the same page
        // because redirect url comes from the nsf options and actual location is the playground page
        // the whole file needs cleanup and refactoring
        if (actualLocation === redirectUrl || actualLocation.match(/(\w|\W)*playground(\w|\W)*/g)) {
            redirectUrl = '';
        }

        return redirectUrl;
    }

    protected resolveRouter (deferred: JQueryDeferred<any>, currentStep: string) {

        if ('inlife_tariff_selection' === currentStep) {
            deferred.resolve(new RouterEvolvedInlifeTariffSelection(this._injector));
        } else {
            deferred.resolve(new RouterEvolvedInlifeRecommendation(this._injector));
        }
    }

    protected getRouter (): JQueryPromise<any> {
        const deferred = $.Deferred();
        const currentStep = this.getCurrentPage();
        const forApp: boolean = this._injector.getGetParameter().getForAppGetParam();
        const nba: Nba = new Nba();

        nba.getData(forApp, 'tariffchange', Constants.SalesChannelGeneralOfferObjectMapping[this.salesChannel].toLocaleLowerCase()).then((customer: Customer) => {
            this._injector.getFlow().setCustomer(customer);
            this._injector.getFlowStateWithSalesChannel().setSalesChannel(this.salesChannel);
            const redirectUrl = this.getRedirectURL(customer);

            if ('' === redirectUrl) {

                if (customer.hasGigakombiCase) {
                    $('.tariff-module-tile[data-gigakombi="false"]').remove();
                } else {
                    $('.tariff-module-tile[data-gigakombi="true"]').remove();
                }

                this.resolveRouter(deferred, currentStep);

            } else {
                window.location.href = redirectUrl;
            }
        }, (error) => {
            if (401 === error.status) {
                const redirectUrl = document.location.pathname + decodeURIComponent(document.location.search);
                window.location.href = '/shop/authentifizierung.html?btype=tariffChange&goto=' + this.getRedirectUrlWithAffiliateParameters(redirectUrl);
            } else {
                deferred.reject();
            }
        });

        return deferred.promise();

    }

}
