/**
 * @module SalesFlow/evolved/view
 */
import {Constants} from 'core/constants';

declare var $: any;

import {Renderable} from 'view/renderable';
import Customer from 'shopbackend/customer';
import Injector from 'core/injector';
import FoldUpAndDown from 'view/element/shared/fold-up-and-down';

export class ViewEvolvedElementVvlContractDataAccordion extends Renderable<any> {

    protected _customer: Customer;

    protected _element: JQuery;

    constructor (
        customer: Customer,
        injector: Injector
    ) {

        super(injector);

        this._customer = customer;

        this._element = $('#nsf-contract-data');
    }

    public render (): void {
        const foldUpAndDown = new FoldUpAndDown($('#nsf-fold-up-and-down-contract-data'), this._injector);
        foldUpAndDown.bind();

        const contractData = {
            customer: this._customer,
            introtext: 'Hier k&ouml;nnen Sie Ihren Vertrag anpassen und verl&auml;ngern.'
        };

        // @TODO Move that to nsf-strings
        if (Constants.SALESCHANNEL_FAMILY_FRIENDS === this.getInjector().getFlow().getSalesChannel()) {
            contractData.introtext = 'Hier kannst Du Deinen Vertrag mit Deinem bisherigen Tarif verl&auml;ngern oder Dir einen neuen aussuchen. Dein Vertrag verl&auml;ngert sich dann um 24 Monate. Und Dein Friends & Family-Rabatt gilt nat&uuml;rlich weiter.';
        }

        this._element.html(this.getInjector().getTemplates().render('contract-data-accordion-faf', contractData));
    }

    public events (): void {

    }

    public bind (): void {
        this.render();
        this.events();
    }
}
