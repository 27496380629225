/**
 * @module SalesFlow/evolved/view
 */

import {TariffGroupName} from 'core/ids';
import {ViewEvolvedCtasSharedFlowCta} from 'view-evolved/ctas/shared/view-evolved--ctas--shared--flow-cta';
import {ViewEvolvedCtasSharedFlowCtas} from 'view-evolved/ctas/shared/view-evolved--ctas--shared--flow-ctas';

export class ViewEvolvedCtasBntBase extends ViewEvolvedCtasSharedFlowCtas {

    /**
     * In Bnt the left button is always cross link into prolongation
     */
    protected getLeftButton (): ViewEvolvedCtasSharedFlowCta {

        return new ViewEvolvedCtasSharedFlowCta(
            ['btn-alt', 'nfs-pricebox-contract'],
            this.getInjector().getStrings().get('pricebox.contract')
        );

    }

    /**
     * On device overview this is the right button. Overwrite when diff
     */
    protected getRightButton (): ViewEvolvedCtasSharedFlowCta {

        return new ViewEvolvedCtasSharedFlowCta(
            ['nfs-pricebox-confirm', 'nsf-small-button-width'],
            this.getInjector().getStrings().get('pricebox.confirm.text1') + '<span class="part-one">' + this.getInjector().getStrings().get('pricebox.confirm.text2') + '</span>'

        );

    }

    /**
     * Using subscription and device from flow. ignoring offer here
     */
    protected getContractProlongationURL (tariffGroup: TariffGroupName): string {

        let tariffGroupSel: string;
        const salesChannel = this.getInjector().getFlowStateWithSalesChannel().getSalesChannel();

        /**
         * We have two different VVL Pages: Consumer and Soho
         */

        if ('soho' === tariffGroup) {
            tariffGroupSel = 'soho';
        }
        else if ('familyfriends' === salesChannel) {
            tariffGroupSel = 'familyfriends';
        } else {
            tariffGroupSel = 'consumer';
        }

        let deviceOverviewUrl = this.getInjector().getOptions().get('vvl_tariff_device_url_' + tariffGroupSel);

        /**
         * Fallback
         */
        if (undefined === deviceOverviewUrl) {
            deviceOverviewUrl = this.getInjector().getOptions().get('vvl_tariff_device_url');
        }

        const params: string[] = [];

        if (undefined !== this.getInjector().getFlow().getSubscriptionId()) {
            params.push(
                'tariffId=' + this.getInjector().getFlow().getSubscriptionId()
            );
        }

        if (undefined !== this.getInjector().getFlow().getAtomicDeviceId()) {
            params.push('deviceId=' + this.getInjector().getFlow().getAtomicDeviceId());
        }

        return deviceOverviewUrl + '?' + params.join('&');
    }

}
