/**
 * @module SalesFlow/router
 */

 export default class Cookie {

    public get (name: string): string {

        const result = new RegExp('(?:^|; )' + encodeURIComponent(name) + '=([^;]*)').exec(document.cookie);

        return result ? result[1] : null;

    }

    public write (name: string, value: string, days?: number) {
        if (!days) {
            days = 30;
        }

        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));

        const expires = '; expires=' + date.toUTCString();

        document.cookie = name + '=' + encodeURIComponent(value) + expires + '; path=/';
    }

    public remove (name: string) {
        this.write(name, '', -1);
    }

}
