/**
 * @module SalesFlow/model/type
 */

import { Constants } from 'core/constants';

import {SubscriptionGroupName, OrderType} from 'core/ids';
import Subsidization from './subsidization';
import SubscriptionAttributes from './attributes-subscription';
import { type } from 'os';

/**
 * @internal
 */
export default class Subscription {

    private _id: number;
    private _name: string;
    private _backendSystem: string;
    private _dataVolume: number;
    private _subscriptionGroupId: number;
    private _pib: string;
    private _orderType: OrderType;

    private _attibutes: SubscriptionAttributes;

    private _subsidizations: Subsidization[] = [];

    constructor (data: any) {

        this._id = parseFloat(data.id);
        this._name = data.label;
        this._subscriptionGroupId = data.subscriptionGroupId;

        this._backendSystem = data.backendId[0].system;

        if (undefined !== data.attributes) {
            this._attibutes = new SubscriptionAttributes(data.attributes.attribute);
            this._dataVolume = this._attibutes.dataVolume;
            this._pib = this._attibutes.pib;
            this._orderType = Constants.OrderType_Voice;   // default

            if ('string' === typeof this._attibutes.orderType) {
                if ('fixed' === this._attibutes.orderType) {
                    this._orderType = Constants.OrderType_Fixed;
                }
            }
        }

        /**
         * Black has no subscriptions
         */

        if ('object' === typeof data.subscriptions) {
            for (const subsidization of data.subscriptions.subscription) {
                this._subsidizations.push(new Subsidization(subsidization));
            }
        } else {
            this._subsidizations.push(new Subsidization(data));
        }

    }

    set dataVolume (val: number) {
        this._dataVolume = val;
    }

    public isRedPlusAllowed (): boolean {

        const notRedPlus = [Constants.Black_Id, Constants.EasyS_Id, Constants.EasyM_Id];

        return 0 > notRedPlus.indexOf(this.id);

    }

    public getSimOnlyId (): number {

        const simOnly: Subsidization[] = this._subsidizations.filter((subsidization) => {
            return subsidization.isSimOnly;
        });

        if (0 === simOnly.length) {
            return undefined;
        }

        return simOnly[0].id;

    }

    public getSubsidizations (): Subsidization[] {
        return this._subsidizations;
    }

    get id (): number {
        return this._id;
    }

    get name (): string {
        return this._name;
    }

    get dataVolume (): number {
        return this._dataVolume;
    }

    get orderType (): OrderType {
        return this._orderType;
    }

    /**
     * Using hardcoded text here while no access to injector ...
     * So better to used text here then passing injector to every subscription
     */
    get dataVolumeText (): string {

        if (this.isRedXLUnlimited()) {

            return ' mit unbegrenztem Datenvolumen';
        }

        if (undefined === this.dataVolume || 0 === this.dataVolume) {

            return '';

        }

        return this.dataVolume + ' GB Datenvolumen';

    }

    public isRedXLUnlimited (): boolean {
        return this._id === Constants.RedXL_Id;
    }

    public isXLUnlimitedTariff (): boolean {
        return this._id === Constants.RedXL_Id || this._id === Constants.YoungXL_Id;
    }

    get pib (): string {
        return this._pib;
    }

    get subscriptionGroupId (): number {
        return this._subscriptionGroupId;
    }

    get subscriptionGroupName (): SubscriptionGroupName {

        if (Constants.Black_Group_Id === this._subscriptionGroupId) {
            return 'consumer';
        }

        if (Constants.Red_Group_Id === this._subscriptionGroupId) {
            return 'consumer';
        }

        if (Constants.Red_New_Subs_Group_Id === this._subscriptionGroupId) {
            return 'consumer';
        }
        if (Constants.Unlimited_Group_Id === this._subscriptionGroupId) {
            return 'consumer';
        }

        if (Constants.Easy_Group_Id === this._subscriptionGroupId) {
            return 'easy';
        }

        if (Constants.Young_Group_Id === this._subscriptionGroupId) {
            return 'young';
        }

    }

    public getSubsidizationIdByKiasCode (kiasCode: string): number {

        for (const subsidization of this.getSubsidizations()) {

            if (kiasCode === subsidization.backendId) {
                return subsidization.subLevel;
            }

        }

        return undefined;
    }

    public getSubsidizationIdBySubLevel (sublevel: number): number {

        for (const subsidization of this.getSubsidizations()) {

            if (0 === sublevel && subsidization.isSimOnly) {
                return subsidization.id;
            }

            if (sublevel === subsidization.subLevel) {
                return subsidization.id;
            }

        }

        return undefined;
    }

    public isCurrentPortfolio (): boolean {

        if (-1 !== Constants.CurrentSubscriptionGroupIdsOfCurrentPortfolio.lastIndexOf(this.subscriptionGroupId)) {
            return true;
        }

        return false;
    }

    public isLatestPortfolio (): boolean {
        return -1 !== Constants.SubscriptionGroupIdsOfLatestPortfolio.lastIndexOf(this.subscriptionGroupId);
    }

    public isRedOrYoung (): boolean {
        if (-1 !== Constants.AllRedAndAllYoungSubscriptionGroupIds.lastIndexOf(this.subscriptionGroupId)) {
            return true;
        }

        return false;
    }

}
