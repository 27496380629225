declare var _ddq: any;

import {displayWinbackVoucherErrorNotification} from 'service/winback/winback-error-handling-service';

export class  ViewEvolvedVvlWinback {

  /**
   * Update voucher code to the view
   */
   public updateVoucher (serviceResponse: any ) {

    const getVoucherCodeClass = document.getElementsByClassName('winback-vouchercode')[0];
    getVoucherCodeClass.getElementsByTagName('h2')[0].innerHTML = serviceResponse.voucherCode;
  }

   /**
    * Update the tariff tiles with the discounts
    */
   public updateDiscountsToTariff (priceMap: any) {

        const priceMapKeys = Object.keys(priceMap);

        for (let i = 0; i < priceMapKeys.length; i += 1) {

            const winbackTariffClass = document.getElementsByClassName(`winback-${priceMapKeys[i]}-discount`)[0];
            const priceVlux = winbackTariffClass.getElementsByClassName('priceVlux')[0].innerHTML;
            winbackTariffClass.getElementsByClassName('priceVlux')[0].innerHTML = `${priceMap[priceMapKeys[i]]} ${priceVlux}`;

        }
}
    /**
     * Update view with the correct notification when there is no voucher code
     */
  public updateNotifications (handledServiceResponse: any) {
      const serviceResponse = displayWinbackVoucherErrorNotification(handledServiceResponse);
      const winBackNotifications = $('.ws2-notification');

      $.each(winBackNotifications, function () {
          const thisNotification = $(this);
          if (thisNotification.hasClass(serviceResponse)) {
              thisNotification.css('display', 'block');
          } else {
              thisNotification.remove();
          }
      });
      this.trackWinbackErrorNotifications(handledServiceResponse);
  }

    /**
     * Winback landing page view events
     */
    protected events (): void {

        // event for click on proceed button and redirect to self selection page
        document.getElementsByClassName('winback-copy-and-proceed')[0].addEventListener(
            'click', event => {
                const getVoucherCodeClass = document.getElementsByClassName('winback-vouchercode')[0];
                const copyVoucher = getVoucherCodeClass.getElementsByTagName('h2')[0].innerHTML;

                const tempField = document.createElement('input');
                tempField.type = 'text';
                tempField.value = copyVoucher;

                document.body.appendChild(tempField);
                tempField.select();
                document.execCommand('copy');
                document.body.removeChild(tempField);

            }
        );
    }

    /**
     * Track the error notifications displayed on the landing page when customer have a notification not a voucher code
     */
    private trackWinbackErrorNotifications (errorMessage: string) {
        _ddq.push(['pageview', {
            siteArea: 'service',
            platformType: 'responsive',
            loginStatus: 'logged in',
            siteStructure: [
                'privatkunden',
                'authentication',
                'vvl',
                'error page'
            ],
            pageType: 'error page',
            error: {
                type: 'notification', // static
                message: errorMessage, // dynamic errormMssagefrom winback voucher api response or from Constants
                code: '',
                location: ''
            }
        }]);

    }

    /**
     * Binds the view with the correct elements according to voucher service response
     */
  public bind (handeledServiceResponse: any) {

    const winbackWithErrorElement = document.getElementById('winback-with-error');
    const winbackWithVoucherElement =  document.getElementById('winback-with-voucher');

     if (handeledServiceResponse.voucherCode) {

           winbackWithErrorElement.remove();
           winbackWithVoucherElement.style.visibility = 'visible';

         this.updateVoucher(handeledServiceResponse);
         this.updateDiscountsToTariff(handeledServiceResponse.groupDiscounts);
         this.events();

     } else if (handeledServiceResponse.error) {

         winbackWithErrorElement.style.display = 'block';
         winbackWithVoucherElement.remove();

         this.updateNotifications(handeledServiceResponse.error);
     }
 }

}
