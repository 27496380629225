/**
 * @module SalesFlow/model/type
 */

import NsfError from 'core/error';

import AccessoryAttributes from 'model/type/attributes-accessory';

import Accessory from 'model/type/accessory';

/**
 * @TODO If this can be (de-)serialized, on productDetailPage less repo calls are needed
 */
export default class AtomicAccessory {

    private _id: number;

    private _name: string;

    private _backendId: string;

    private _accessory: Accessory;

    private _attributes: AccessoryAttributes;

    /**
     * @TODO Better false insted of undefined
     */
    private _purchasable: boolean;

    constructor (data: any, parentAccessory: Accessory) {

        this._accessory = parentAccessory;

        this.parseData(data);
    }

    private parseData (data: any) {

        if (undefined === data.id) {
            new NsfError('id is mandatory when create a AtomicAccessory.');
        }

        if (undefined === data.label) {
            new NsfError('label is mandatory when create a AtomicAccessory.');
        }
        if (undefined === data.attributes) {
            new NsfError('attributes is mandatory when create a AtomicAccessory.');
        }

        if (undefined === data.attributes.attribute) {
            new NsfError('attributes.attribute is mandatory when create a AtomicAccessory.');
        }

        if (undefined === data.attributes.attribute.length) {
            new NsfError('attributes.attribute must be an array when create a AtomicAccessory.');
        }

        this._id = parseInt(data.id, 10);

        this._name = data.label;

        this._attributes = new AccessoryAttributes(data.attributes.attribute, this._accessory.getAttributes());

    }

    public getAccessory (): Accessory {
        return this._accessory;
    }

    public setPurchasable (purchasable: boolean) {
        this._purchasable = purchasable;
    }

    get accessory (): Accessory {
        return this._accessory;
    }

    get id (): number {
        return this._id;
    }

    get name (): string {
        return this._name;
    }

    get attributes (): AccessoryAttributes {
        return this._attributes;
    }

    get attr (): AccessoryAttributes {
        return this._attributes;
    }

    get purchasable (): boolean {
        return this._purchasable;
    }

}
