
/**
 * @module SalesFlow/evolved/view
 */

declare var $: any;

import {Renderable} from 'view/renderable';
import Customer from 'shopbackend/customer';
import Injector from 'core/injector';
import FoldUpAndDown from 'view/element/shared/fold-up-and-down';

export class ViewEvolvedElementInlifeContractDataAccordion extends Renderable<any> {

    protected _customer: Customer;

    protected _element: JQuery;

    constructor (
        customer: Customer,
        injector: Injector
    ) {

        super(injector);

        this._customer = customer;

        this._element = $('#nsf-contract-data');
    }

    public render (): void {
        const foldUpAndDown = new FoldUpAndDown($('#nsf-fold-up-and-down-contract-data'), this._injector);
        foldUpAndDown.bind();

        const contractData = {
            customer: this._customer,
            introtext: '',
            headline: ''
        };

        // inlife.contractdata.introtext
        // inlife.gigakombi.contractdata.introtext
        // inlife.gigakombi.contractdata.introtext.br5
        // inlife.soho.contractdata.introtext
        // inlife.soho.gigakombi.contractdata.introtext
        // inlife.soho.gigakombi.contractdata.introtext.br5

        const gigakombiCase = this._customer.isGigaKombiCustomer ? 'gigakombi.' : '';
        const brCase = this._customer.hasGigakombiBR5 ? '.br5' : '';
        const soho = this._customer.isSohoCustomer ? 'soho.' : '';

        console.log (`inlife.${soho}${gigakombiCase}contractdata.introtext${brCase}`);

        contractData.headline = this.getInjector().getStrings().get(`vvl-inlife.${soho}contractdata.headline`);
        contractData.introtext = this.getInjector().getStrings().get(`inlife.${soho}${gigakombiCase}contractdata.introtext${brCase}`);

        this._element.html(this.getInjector().getTemplates().render('contract-data-accordion', contractData));
    }

    public events (): void {

    }

    public bind (): void {
        this.render();
        this.events();
    }
}
