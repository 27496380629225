/**
 * @module SalesFlow/model/type
 */
import Subscription from './subscription';

import SubscriptionAttributes from './attributes-subscription';

export default class SubscriptionGroup {

    private _id: number;
    private _label: string;
    private _backendSystem: string;
    private _dataVolume: number;

    private _attributes: SubscriptionAttributes;

    private _subscriptions: Subscription[] = [];

    constructor (data: any) {

        this._id = parseFloat(data.id);
        this._label = data.label;

        this._backendSystem = data.backendId[0].system;

        if (undefined !== data.attributes) {
            this._attributes = new SubscriptionAttributes(data.attributes.attribute);
            this._dataVolume = this._attributes.dataVolume;
        }

        const i = 0;
        for (const subscription of data.subscriptions.subscription) {
            subscription.subscriptionGroupId = this._id;
            this._subscriptions.push(new Subscription(subscription));
            if (undefined !== this._dataVolume) {
                this._subscriptions[i].dataVolume = this._dataVolume;
            }
        }

    }

    public getSubscriptions (): Subscription[] {
        return this._subscriptions;
    }

    public getSubscription (id: number): Subscription {

        for (const subscription of this._subscriptions) {
            if (id === subscription.id) {
                return subscription;
            }
        }

        return;
    }

    get id (): number {
        return this._id;
    }

    get label (): string {
        return this._label;
    }

    get attributes (): SubscriptionAttributes {
        return this._attributes;
    }

}
