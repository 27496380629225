/**
 * @module SalesFlow/view
 */

import { Renderable } from '../../renderable';

import {SubscriptionGroupName} from 'core/ids';

export interface SubscriptionGroupInterface {
    value: SubscriptionGroupName;
    name: string;
    active: boolean;

}

export abstract class AbstractSubscriptionGroupSwitcher extends Renderable<any[]> {

}
