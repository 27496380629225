/**
 * @module SalesFlow/evolved/router
 */

import VvlRouting from 'router/vvl/routing';
import Injector from 'core/injector';
import { RouterEvolvedFamilyfriendsVvlDeviceOverview } from 'router-evolved/familyfriends-vvl/router-evolved--familyfriends-vvl--device-overview';
import { RouterEvolvedFamilyFriendsVvlXSell } from 'router-evolved/familyfriends-vvl/router-evolved--familyfriends-vvl--x-sell';

export class FamilyFriendsVvlRouting extends VvlRouting {
    constructor (injector: Injector) {
        super(injector);
    }

    protected _validPages: string[] = ['vvl_tariff_device', 'vvl_x_sell'];

    /**
     * @TODO getRouter for every page as overwritable methode instead of this one
     * and the deferred.resolve(this.getRecommendationRouter());
     */

    protected resolveRouter (deferred: JQueryDeferred<any>, currentStep: string) {
        /**
         * If not consumer - redirect to not allowed page
         */
        const customer = this.getInjector().getFlowStateWithSalesChannel().customer;

        if (true === customer.isSohoCustomer) {
            window.location.href = this.getInjector().getOptions().get('faf_vvl_tariff_not_consumer_url');
        }

        if (!customer.hasFF) {
            $('body').append('<script type="text/javascript" src="/simplicity/assets/js/faf-check.js"></' + 'script>');
        }

        if ('vvl_tariff_device' === currentStep) {
            deferred.resolve(new RouterEvolvedFamilyfriendsVvlDeviceOverview(this._injector));
        }

        if ('vvl_x_sell' === currentStep) {
            deferred.resolve(new RouterEvolvedFamilyFriendsVvlXSell(this._injector));
        }
    }
}
