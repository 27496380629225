import {Constants} from 'core/constants';

/**
 * @module SalesFlow/tracking
 */

declare var vf: any;

import Injector from 'core/injector';
import DeviceOffer from 'view/view/shared/offer/device-offer';
import { OrderType } from 'core/ids';

export default class GetOrderData {

    private _injector: Injector;

    constructor (injector: Injector) {
        this._injector = injector;
    }

    /* Product */

    public getProductSku (name: string, id: string) {
        if ('' !== name) {
            return name + id;
        }

        return '';
    }

    public getProductType (type: string) {
        if ('tariff' === type) {
            return 'tariff';
        }

        return 'device';
    }

    public getPriceOnce (offer: DeviceOffer) {
        if (undefined !== offer && 0.00 < offer.onetimePrice) {
            return String(offer.onetimePrice);
        } else if (undefined !== offer && 0.00 < offer.onetimePriceWithoutConnectionFee) {
            return String(offer.onetimePriceWithoutConnectionFee);
        }

        return '';
    }

    public getPriceMonthly (offer: DeviceOffer) {
        if (undefined !== offer && 0.00 < offer.monthlyDiscountPrice) {
            return String(offer.monthlyDiscountPrice);
        } else if (undefined !== offer && 0.00 < offer.monthlyRegularPrice) {
            return String(offer.monthlyRegularPrice);
        }

        return '';
    }

    public getPropositionId (): number {
        return 1;
    }

    /* Proposition */

    public getPropositionName (deviceName: string, subscriptionName: string) {
        if ('' === deviceName) {
            return subscriptionName;
        }
        if ('' === subscriptionName) {
            return deviceName;
        }

        return deviceName + ' | ' + subscriptionName;
    }

    public getPropositionType () {
        const btx = this._injector.getBtx();

        if (Constants.BTX_VVL === btx || Constants.BTX_INLIFE === btx) {

            return 'prolongation';

        }

        return 'newcontract';
    }

    public getPropositionUnits (): number {
        return 1;
    }

    public getProductLine (subscriptionOrderType: string) {
        if ('' === subscriptionOrderType) {
            // Fallback for "as it was before" (e.g. hardwareOnly returned 'voice' as well)
            return 'voice';
        }

        return subscriptionOrderType;
    }

    public getProductCategory (subscriptionOnly: boolean, atomicDeviceOnly: boolean) {
        if (true === atomicDeviceOnly) {
            return 'device';
        }

        if (true === subscriptionOnly) {
            return 'tariff';
        }

        return 'bundle';
    }

    public getProductUnits (): number {
        return 1;
    }

    /**
     * If the login cookie exists, the customer ist logged in.
     * There is no check via the NBA call, so we do not know if the backend session is expired.
     */
    public getLoggedInStatus () {
        if (vf && vf.glnav && vf.glnav.getLoginCookieData()) {
            return 'logged in';
        }

        return 'not logged in';
    }

    public getSiteStructure (pageName: string, btx: string) {
        const siteStructures: string[] = [
            'privatkunden',
            'mobilfunk'
        ];

        switch (btx) {
            case Constants.BTX_BNT:
                siteStructures.push('angebote mit vertrag');
                break;
            case Constants.BTX_VVL:
                siteStructures.push('vvl');
                break;
            case Constants.BTX_INLIFE:
                siteStructures.push('inlife');
                break;
        }

        return siteStructures.concat(pageName.split(':'));
    }

    public getSimpleSiteStructure (pageName: string, btx: string) {
        let structure = [
            'privatkunden',
            'mobilfunk'
        ];
        if ('' !== pageName) {
            structure = structure.concat(pageName.split(':'));
        }

        return structure;

    }

    public getPageType (isPageListing: boolean) {

        if (isPageListing) {
            return 'product listing';
        }

        return 'product detail';
    }

    public getFlowType (injector: Injector) {
        let flowType;

        // Device first HO
        if (injector.getFlow().getHardwareOnly()) {
            flowType = 'OLS Handys:Voice:BNT';
        }

        // Device Details first
        if (injector.getFlow().isDeviceFirstFlow()) {
            flowType = 'OLS Product Details:Voice:BNT';
        }

        // Device first
        if (injector.getFlow().isDevicesFirstFlow()) {
            flowType = 'OLS Handys:Voice:BNT';
        }

        // tarif first with faf
        if ( injector.getFlow().isSubscriptionFirstFlow() && Constants.SALESCHANNEL_FAMILY_FRIENDS === injector.getFlow().getSalesChannel()) {
            flowType = 'ols tariffs:voice:bnt fuf';

        }

        // Tariff first
        if (injector.getFlow().isSubscriptionFirstFlow()) {
            flowType = 'OLS Tarife:Voice:BNT';
        }

        // Add soho prefix
        if (flowType && Constants.SALESCHANNEL_SOHO === injector.getFlow().getSalesChannel()) {
            flowType += ' soho';
        }

        return flowType || 'OLS Tarif Details:Voice:BNT';

    }

    public getVvlFlowType (currentPage: string): string {
        if ('vvl_tariff_device' === currentPage) {
            return 'product overview:voice:vvl';
        }

        if ('vvl_x_sell' === currentPage) {
            return undefined;
        }

        return 'nba:voice:vvl';
    }

    public getRedPlusFlowType (injector: Injector, subscriptionOrderType: string) {

        const productLine = this.getProductLine(subscriptionOrderType);

        // Red+ Device first
        if (injector.getFlow().isRedPlusTariffFirstFlow()) {
            return 'ols tariff details:' + productLine + ':bnt redplus';
        }

        // Red+ Tariff first
        if (injector.getFlow().isRedplusDeviceFirstFlow()) {
            return 'ols product overview:' + productLine + ':bnt redplus';
        }

    }

    public getTariffchangeFlowType (currentPage: string): string {

        return 'nba:voice:tariffchange';
    }

    public getLineOfCustomer () {

        const subscriptionGroup = this._injector.getFlow().getSubscriptionGroup();
        const salesChannel = this._injector.getFlow().getSalesChannel();

        if ('young' === subscriptionGroup) {
            return 'yolo';
        }

        // in order to track SOHO VVL and get targetAudience
        if (Constants.SALESCHANNEL_SOHO === salesChannel) {
            return 'soho';
        }

        // friends and family vvl flow
        if (Constants.SALESCHANNEL_FAMILY_FRIENDS === salesChannel) {
            return 'fuf';
        }

        return 'consumer';
    }

    public getTargetAudience () {
        return this.getLineOfCustomer();
    }

    public getAppliedFilters () {
        const appliedFilters = [];

        const vendorFromOption = this._injector.getOptions().get('filter_vendor');
        if (undefined !== vendorFromOption && '' !== vendorFromOption) {
            const filter = {
                facet: 'vendor',
                value: vendorFromOption
            };
            appliedFilters.push(filter);
        } else {
            const vendor = JSON.parse(this._injector.getStorage().getItem('DeviceFilter:vendor'));
            for (const i in vendor) {
                const filter = {
                    facet: 'vendor',
                    value: vendor[i]
                };
                appliedFilters.push(filter);
            }
        }

        const promotion = JSON.parse(this._injector.getStorage().getItem('DeviceFilter:promotion'));
        for (const i in promotion) {
            const filter = {
                facet: 'promotion',
                value: promotion[i]
            };
            appliedFilters.push(filter);
        }

        const characteristic = JSON.parse(this._injector.getStorage().getItem('DeviceFilter:characteristic'));
        for (const i in characteristic) {
            const filter = {
                facet: 'characteristic',
                value: characteristic[i]
            };
            appliedFilters.push(filter);
        }

        const tariffGroup = this._injector.getFlow().getSubscriptionGroup();
        const salesChannel = this._injector.getFlow().getSalesChannel();
        let value;

        switch (tariffGroup) {
            case 'consumer':
                value = 'red';
                break;
            case 'young':
                value = 'yolo';
                break;
            case 'easy':
                value = 'easy';
                break;
            default:
                value = 'red';
        }

        value = Constants.SALESCHANNEL_SOHO === salesChannel ? 'soho' : value;

        const tariffGroupFilter = {
            facet: 'tariff',
            value: value
        };
        appliedFilters.push(tariffGroupFilter);

        return appliedFilters;
    }
}
