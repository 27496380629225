/**
 * @module SalesFlow/view
 */
declare var $: JQueryStatic;

import Injector from 'core/injector';

import {Renderable} from '../../../renderable';

import FilterOption from '../../../view/shared/filter-option';

import FilterBase from './filter-base';

/**
 * These Filters has both: Logic and view attributes
 */
export default class ActiveFilterBox extends Renderable<FilterBase[]> {

    private _element: JQuery;

    private _results: JQuery;

    private _filters: FilterBase[];

    constructor (injector: Injector) {

        super(injector);

    }

    private getActiveFilterOptions (): FilterOption[] {

        const activeFilters: FilterOption[] = [];

        for (const filter of this._filters) {

            for (const option of filter.options) {
                if (true === option.getActive()) {
                    activeFilters.push(option);
                }
            }

        }

        return activeFilters;
    }

    private removeFilter (filter: FilterOption): void {

        this.getInjector().getEvent().trigger('filter@unselect', filter);

    }

    private removeAllFilters (): void {

        this._element.find('.selection').remove();

        for (const activeFilterOption of this.getActiveFilterOptions()) {
            this.removeFilter(activeFilterOption);
        }

    }

    public render (filters: FilterBase[]): string {

        this._filters = filters;

        return this.getInjector().getTemplates().render('device_filters_active', {activeFilters: this.getActiveFilterOptions()});
    }

    public events (): void {

        this._element = $('#nsf-active-device-filters');
        this._results = $('.nsf-device-filters-results-count');

        this.getInjector().getEvent().listen('filters@changed', (eventObject: JQueryEventObject, data: any) => {

            const partialsHtml: string[] = [];

            const activeFilterOptions = this.getActiveFilterOptions();

            for (const activeFilterOption of activeFilterOptions) {
                partialsHtml.push(
                    this.getInjector().getTemplates().render('filter-active', activeFilterOption, 'partials')
                );
            }

            this._element.html(partialsHtml.join(''));

            if (0 === activeFilterOptions.length) {
                $('#nsf-device-filters').removeClass('has-active-filters');
            }
            else {
                $('#nsf-device-filters').addClass('has-active-filters');
            }

        });

        this.getInjector().getEvent().listen('device-list@filtered', (eventObject: JQueryEventObject, data: any) => {

            const matches: number = data.matchingViewOffers.length;

            let text: string = this.getInjector().getStrings().get('filters.count_plural');

            if (1 === matches) {
                text = this.getInjector().getStrings().get('filters.count_singular');
            }

            this._results.html(
                matches + ' ' + text
            );

        });

        this._element.on('click', '.selection', (evt) => {

            const name = $(evt.currentTarget).data('name');
            const value = $(evt.currentTarget).data('value');

            for (const activeFilterOption of this.getActiveFilterOptions()) {
                if (name === activeFilterOption.attribute && value === activeFilterOption.value) {
                    this.removeFilter(activeFilterOption);
                }
            }

        });

        this._element.parent('.filters').on('click', '.clearAllSelection', (evt) => {
            this.removeAllFilters();
        });

    }

}
