/**
 * @module SalesFlow/controller
 */

import {Renderable} from 'view/renderable';
import {BusinessTransactionContext} from 'core/ids';
import Injector from 'core/injector';

export abstract class Controller extends Renderable<any> {
    protected _btx: BusinessTransactionContext;

    constructor (injector: Injector) {
        super(injector);

        this._btx = injector.getBtx();

    }
}
