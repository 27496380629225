/**
 * @module SalesFlow/core
 */

declare var $: JQueryStatic;

/**
 * Read options that are stored in data-attributes
 */
export default class Options {

    private _element: JQuery;

    private _debug: boolean = false;

    private _options: any = {};

    constructor () {
        this._element = $('#nsf .options');

        this._element.find('div[data-name]:not([data-type="array"])').each((index, item) => {
            // just ignore the type and let JQuery to what it can to return the correct types. use type if needed
            const type = $(item).data('type');
            const name = $(item).data('name');
            const value = $(item).data('value');

            this._options[name] = value;

        });

        this._element.find('div[data-type="array"]').each((index, item) => {
            const name = $(item).data('name');
            const value = $.trim($(item).data('value'));

            if (undefined === this._options[name]) {
                this._options[name] = [];
            }

            this._options[name].push(value);

        });

    }

    public get (name: string): any {

        // there are already options published in OpenText with dash instead of underscore
        if (undefined === this._options[name] && -1 !== name.lastIndexOf('_')) {

            const legacyName = name.replace(/\_/g, '-');

            if (undefined !== this._options[legacyName]) {
                name = legacyName;
            }

        }

        if (undefined === this._options[name] && -1 !== name.lastIndexOf('-')) {

            const legacyName = name.replace(/\-/g, '_');

            if (undefined !== this._options[legacyName]) {
                name = legacyName;
            }

        }

        return this._options[name];

    }

}
