/**
 * @module SalesFlow/shopbackend
 */

import CommerceApi from 'shopbackend/commerce-api';
import Cookie from 'router/cookie';
import Customer from 'shopbackend/customer';
import { AUTH_NBA_API, MTAN_PAGE_URL } from '../constants/api-config';

declare var vf: any;
export default class Nba {

    public constructor () {
    }

    /**
     * [DIPO-952] [CO-23606] Implement Auth in NSF.
     * Resolve the authentication.
     */
    private handleNBA (forApp: boolean, flow: string, salesChannel: string): JQueryPromise<any> {
        const deferred = $.Deferred();
        const myCookie = new Cookie();
        const commerceApi: CommerceApi = new CommerceApi();

        let lokURL: string = commerceApi.apiUrl;

         /* use another URL when comin from App */
        if ('true' === myCookie.get('forApp') ||  forApp) {

            /* Use general API if MINT login already failed */
            if (null === myCookie.get('forAppConnection')) {
                lokURL = commerceApi.apiMintUrl;
            }
        }

        /**
         * add additional Parameters
         */
        lokURL += '/nba/' + flow + '/' + salesChannel;

         /**
          * if the sales-flow is called in CMS, we work with stubbs
          */
        if ('object' === typeof vf && 'object' === typeof vf.devhelper && 'function' == typeof vf.devhelper.serviceUrl) {
            lokURL = vf.devhelper.serviceUrl() + lokURL;
        }

        commerceApi.apiCall(() => {
            return $.ajax({
                url: lokURL,
                method: 'get',
                dataType: 'json'
            });
        }).done((response: JQueryPromiseCallback<any>) => {
            const customer = new Customer(response);
            deferred.resolve(customer);
        }).fail((error: JQueryPromiseCallback<any>) => { console.log('here error');

            /* If MINT service by forApp failed try it one more time with general API */
            if (('true' === myCookie.get('forApp') ||  forApp) && null === myCookie.get('forAppConnection')) {

                myCookie.write('forAppConnection', 'true', 1);

                /* On that point location.reload() would be a simple solution  */

                lokURL = commerceApi.apiUrl + '/nba/' + flow + '/' + salesChannel;
                if ('object' === typeof vf && 'object' === typeof vf.devhelper && 'function' == typeof vf.devhelper.serviceUrl) {
                    lokURL = vf.devhelper.serviceUrl() + lokURL;
                }
                commerceApi.apiCall(() => {
                    return $.ajax({
                        url: lokURL,
                        method: 'get',
                        dataType: 'json'
                    });
                }).done((response: JQueryPromiseCallback<any>) => {
                    const customer = new Customer(response);
                    deferred.resolve(customer);
                }).fail((error: JQueryPromiseCallback<any>) => {
                    deferred.reject(error);
                });

            } else {
                deferred.reject(error);
            }

        });

        return deferred.promise();

    }

    /**
     * [DIPO-952] [CO-23606] Implement Auth in NSF.
     * Calls the new authentication service to check customer's session.
     */
    private authentication (): Promise<any> {

        return new Promise((resolve, reject) => {
            const request = new XMLHttpRequest();
            request.onload = () => {
                return resolve(request);
            };
            request.onerror = () => {
                return reject(request);
            };
            request.open('GET', AUTH_NBA_API, true);
            request.send();
        });
    }

    /**
     * Changed with [DIPO-952] [CO-23606] Implement Auth in NSF.
     * Resolves the authentication and gets nba data. Used in VVL,Inlife, RedPlus & Multisim routing.
     */
    public getData (forApp: boolean, flow: string, salesChannel: string): JQueryPromise<any> {
        const deferredService = $.Deferred();

        this.authentication().then((response) => {

            if (200 !== response.status) {
                deferredService.reject(response);
            } else {
                this.handleNBA(forApp, flow, salesChannel).done((response) => {
                    deferredService.resolve(response);
                }).fail((error) => {
                    deferredService.reject(error);
                });
            }
        }, (error: any) => {
            deferredService.reject(error);
        });

        return deferredService.promise();

    }

}
