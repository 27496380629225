/**
 * @module SalesFlow/core
 */

import {CoreFlowState} from 'core/core-flow-state';

export default class FlowArrayHelper {

    private flowState: CoreFlowState;
    private _elements: any[] = [];

    constructor (flowState: CoreFlowState, param?: string, valueType?: string) {

        this.flowState = flowState;
        if ('string' === typeof param) {
            if ('number' === valueType) {
                /**
                 * The values of the array will parse to a number
                 */
                this._elements = param.split(',').map(Number);
            } else {
                this._elements = param.split(',');
            }
        }

    }

    public addElement (element: any) {
        if (undefined !== element) {
            this._elements.push(element);
            this.flowState.saveToStorage();
        }
    }

    public removeElement (element: any) {
        if (0 <= this._elements.indexOf(element)) {
            this._elements.splice(this._elements.indexOf(element), 1);
            this.flowState.saveToStorage();
        }
    }

    public removeRedPlusAllnetOrderId (element: any) {
        const pos = this._elements.indexOf(element);
        if (0 <= pos) {
            this._elements.splice(pos);
            this.flowState.saveToStorage();
        }
    }

    public resetAllElements () {
        this._elements = [];
        this.flowState.saveToStorage();
    }

    public toString (): string {
        return this._elements.join();
    }

    get elements (): any[] {
        return this._elements;
    }

}
