/**
 * @module SalesFlow/evolved/router
 */

import {RouterEvolvedBntRouter} from 'router-evolved/bnt/router-evolved--bnt-router';
import {SubscriptionIdPerSalesChannel} from 'core/ids';
import {ControllerEvolvedBntDeviceOverview} from 'controller-evolved/bnt/controller-evolved--bnt-device-overview';
import {RouterEvolvedBntFafRouter} from 'router-evolved/familyfriends-bnt/router-evolved--familyfriends-bnt-router';

export class RouterEvolvedFamilyfriendsBntDeviceOverview extends RouterEvolvedBntFafRouter {

    /**
     * Get getFocusSubscriptionId in router to be able to used it as fallback for validating device
     *
     */
    public getFocusSubscriptionId (): number {

        const focusSubscriptionIds = this.getFocusSubscriptionIds();

        return focusSubscriptionIds[this.getSalesChannel()];
    }

    public getFocusSubscriptionIds (): SubscriptionIdPerSalesChannel {

        const subscriptionIds: SubscriptionIdPerSalesChannel = {
            consumer: this.getInjector().getOptions().get('default_consumer_subscription_id') || undefined,
            young: this.getInjector().getOptions().get('default_young_subscription_id') || undefined,
            soho: this.getInjector().getOptions().get('default_soho_subscription_id') || undefined,
            easy: this.getInjector().getOptions().get('default_easy_subscription_id') || undefined
        };

        // set recommended subscription and on focus if there is one
        if (undefined !== this.getInjector().getFlowState().getRecommendedSubscriptionId()) {

            const recommendedSubscriptionId = this.getInjector().getFlowState().getRecommendedSubscriptionId();

            // recommendedSubscriptionId could be consumer or young from current portfolio
            // so let's find out for which salesChannel we want the recommended id in focus

            const recommendedSubscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(recommendedSubscriptionId);
            subscriptionIds[recommendedSubscription.subscriptionGroupName] = recommendedSubscriptionId;

        }

        return subscriptionIds;
    }

    /**
     * Return incoming unvalidated tarifIds in order
     * 1) Per storage
     * 2) Per get parameter
     *
     * So when validate and resolve this: Get parameter will overrule storage
     *
     */
    public getSubscriptionIds (): number[] {

        // [GET, Storage, Option]
        const incomingSubscriptionIds: number[] = [];

        /**
         * no subscription shall be preseleced when device overview is first flow (devicesFirst)
         */
        /*
        const subscriptionIdFromOption: number = this.getInjector().getOptions().get('default_' + this.getTariffGroup() + '_subscription_id');
        if (undefined !== subscriptionIdFromOption) {
            incomingSubscriptionIds.push(subscriptionIdFromOption);
        }
        */

        const subscriptionIdFromStorage: number = this.getInjector().getFlowState().getSubscriptionId();
        if (undefined !== subscriptionIdFromStorage) {
            incomingSubscriptionIds.push(subscriptionIdFromStorage);
        }

        const subscriptionIdGetParam: number = this.getInjector().getGetParameter().getSubscriptionIdGetParam();
        if (undefined !== subscriptionIdGetParam) {
            incomingSubscriptionIds.push(subscriptionIdGetParam);
        }

        return incomingSubscriptionIds.reverse();

    }

    /**
     * Return incoming unvalidated tarifIds in order
     * 1) Per get parameter
     * 2) Per storage
     * 3) option Get Parameter
     *
     * So when validate and resolve this: Get will overrule storage and storage will overule option
     *
     */
    public getIncomingDeviceIds (): number[] {

        // [GET, Storage, Option]
        const incomingDeviceIds: number[] = [];

        const deviceIdFromStorage: number = this.getInjector().getFlow().getAtomicDeviceId();

        if (undefined !== deviceIdFromStorage) {
            incomingDeviceIds.push(deviceIdFromStorage);
        }

        const deviceIdGetParam: number = this.getInjector().getGetParameter().getDeviceIdGetParam();
        if (undefined !== deviceIdGetParam) {
            incomingDeviceIds.push(deviceIdGetParam);
        }

        return incomingDeviceIds.reverse();

    }

    /**
     * @TODO perhaps move into base class, as it's the same in vvl and bnt
     * @TODO This should be only callable when repos are loaded
     * @TODO This should resolve not only if tariff exists, it should also validate that tariff is in correct tariff group
     */
    public resolveDeviceId (incomingDeviceIds: number[]): number {

        let subscriptionId = this.resolveSubscriptionId(this.getSubscriptionIds());

        if (undefined === subscriptionId) {
            subscriptionId = this.getFocusSubscriptionId();
        }

        const subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(
            subscriptionId
        );
        /**
         * First it looks for deviceId and then for atomicDeviceIds
         * @TODO Ask channel for a valid usecase
         */
        incomingDeviceIds = incomingDeviceIds.map((deviceId) => {

            const checkDevice = this.getPurchasableDeviceRepo().getDevice(
                deviceId,
                this.getSalesChannel(),
                subscription
            );

            // Id is not an deviceId
            if (undefined === checkDevice) {
                return deviceId;
            }

            return checkDevice.getAtomicDeviceByIndex(0).id;

        });

        const validatedDeviceIds = incomingDeviceIds.filter((deviceId) => {

            const checkAtomicDeviceId = undefined !== this.getPurchasableDeviceRepo().getAtomicDevice(
                deviceId,
                this.getSalesChannel(),
                subscription
            );

            if (true === checkAtomicDeviceId) {
                return true;
            }

            const checkDeviceId = undefined !== this.getPurchasableDeviceRepo().getDevice(
                deviceId,
                this.getSalesChannel(),
                subscription
            );

            if (true === checkDeviceId) {
                return true;
            }

            return false;

        });

        if (0 === validatedDeviceIds.length) {
            return undefined;
        }

        return validatedDeviceIds[0];

    }

    public validateIncoming (): void {

        this._subscriptionId = this.resolveSubscriptionId(
            this.getSubscriptionIds()
        );

        this._atomicDeviceId = this.resolveDeviceId(
            this.getIncomingDeviceIds()
        );

    }

    public createController (): JQueryPromise<RouterEvolvedBntRouter> {

        const deferred = $.Deferred<any>();

        this.loadReposSupervisor().then(() => {

            this.validateIncoming();

            this.getInjector().getFlowState().setSubscriptionId(this._subscriptionId);

            this.getInjector().getFlowState().setAtomicDeviceId(this._atomicDeviceId);

            deferred.resolve(
                new ControllerEvolvedBntDeviceOverview(
                    this.getFocusSubscriptionIds(),
                    this.getReposSupervisor(),
                    this.getInjector()
                )
            );
        }, function () {
            deferred.reject();
        });

        return deferred.promise();

    }

}
