/**
 * @module SalesFlow/core
 */

import Storage from 'core/storage';
import Templates from 'core/templates';
import Repos from 'model/repos';
import Event from 'core/event';
import Tracker from 'tracking/tracker';
import Options from 'core/options';
import Strings from 'core/strings';
import LoadingIndicator from 'view/loading-indicator';
import Overlay from 'view/overlay';
import GetParameter from 'router/get-parameter';
import Routing from 'router/shared/routing';
import { createRouting } from 'router/create-routing';

import Gigakombi from 'core/gigakombi';
import {BusinessTransactionContext, SalesChannelName} from 'core/ids';
import {CoreEvolvedOfferCollection} from 'core-evolved/core--evolved--offer-collection';
import {CoreFlowStateWithoutSalesChannel} from 'core/core--flow-state-without-sales-channel';
import {ModelEvolvedRepoSupervisor} from 'model-evolved/repo/model-evolved--repo--supervisor';
import {VluxDataLoaderXhr} from 'model-evolved/vlux/model-evolved--vlux--loader-xhr';
import {CoreEvolvedFlowState} from 'core-evolved/core-evolved--flow-state';
import {CoreEvolvedFlowStateSalesChannelSubscriptionGroup} from 'core-evolved/core-evolved--flow-state-sales-channel-subscription-group';
import {VluxDataSupervisor} from 'model-evolved/vlux/model-evolved--vlux--supervisor';
import {CoreFlowState} from 'core/core-flow-state';
import {Constants} from 'core/constants';
import GeneralSalesObjectInterface from '../service/general-sales-object/general-sales-object-interface';
import GeneralBasketServiceInterface from '../shopbackend/general-basket-service/general-basket-service-interface';
import Cookie from 'router/cookie';
import GeneralBasketServiceInterfaceBNT from 'shopbackend/general-basket-service/general-basket-service-interface-bnt';
import GeneralBasketServiceInterfaceVVL from 'shopbackend/general-basket-service/general-basket-service-interface-vvl';
import GeneralBasketServiceInterfaceInlife from 'shopbackend/general-basket-service/general-basket-service-interface-inlife';
import GeneralBasketServiceInterfaceRedPlus from 'shopbackend/general-basket-service/general-basket-service-interface-redplus';

/**
 * Poor man's dependency managment
 */
export default class Injector {

    private _offerCollection: CoreEvolvedOfferCollection;

    private _flowState: CoreFlowState = undefined;

    private _storage: Storage = undefined;

    private _templates: Templates = undefined;

    private _repo: Repos = undefined;

    private _reposSupervisor: ModelEvolvedRepoSupervisor = undefined;

    private _event: Event = undefined;

    private _tracker: Tracker = undefined;

    private _options: Options = undefined;

    private _strings: Strings = undefined;

    private _loadingIndicator: LoadingIndicator = undefined;

    private _overlay: Overlay = undefined;

    private _getParameter: GetParameter = undefined;

    private _routing: Routing = undefined;

    private _gigakombi: Gigakombi = undefined;

    private _btx: BusinessTransactionContext = undefined;

    private _generalSalesObjectInterface: GeneralSalesObjectInterface = undefined;

    private _generalBasketServiceInterface: GeneralBasketServiceInterface = undefined;

    constructor () {
        this.getOptions();
        this.getStrings();
        this.getTracker();
    }

    /**
     * Get storage; create if not already exists
     */
    public getStorage (): Storage {

        if (undefined === this._storage) {
            this._storage = new Storage();
        }

        return this._storage;

    }

    /**
     * Get templates; create if not already exists
     */
    public getTemplates (): Templates {

        if (undefined === this._templates) {
            this._templates = new Templates(this.getStrings());
        }

        return this._templates;

    }

    /**
     * Get templates; create if not already exists
     */
    public getOverlay (): Overlay {

        if (undefined === this._overlay) {
            this._overlay = new Overlay(this.getTemplates());
        }

        return this._overlay;

    }

    public getBtx (): BusinessTransactionContext {

        if (undefined === this._btx) {
            const nsfDispatcher = document.getElementById('nsf-dispatcher');
            const availableBtxs: BusinessTransactionContext[] = Constants.AvailableBusinessTransactionContexts;
            const defaultBtx: BusinessTransactionContext = Constants.BTX_BNT;

            if (null !== nsfDispatcher) {
                this._btx = nsfDispatcher.dataset.btx as BusinessTransactionContext;
            }
            else {
                this._btx = defaultBtx;
                console.warn('Business Transaction Context is not set');
            }

            /**
             * reset to default btx when dispatcher-btx is not allowed
             */
            if (-1 === availableBtxs.indexOf(this._btx)) {
                console.warn(this._btx + ' Business Transaction Context is set to default: ' + defaultBtx);
                this._btx = defaultBtx;
            }
        }

        return this._btx;

    }

    public setBtx (newBtx: BusinessTransactionContext) {
        const allowedOverwrites: any = {
            bnt: [Constants.BTX_GIGAKOMBI]
        };

        if (undefined !== allowedOverwrites[this._btx] && 1 !== allowedOverwrites[this._btx].indexOf(newBtx)) {
            this._btx = newBtx;
        } else {
            throw 'New Business Transaction Context is not allowed';
        }

    }

    public updateBtx (newBtx: BusinessTransactionContext): BusinessTransactionContext {

        this.setBtx(newBtx);

        return this._btx;

    }

    /**
     * Get Repos; create if not already exists
     */
    public getRepos (): Repos {

        if (undefined === this._repo) {

            const vluxPath: string = this._options.get('vlux_path');
            const btx = this.getBtx();

            this._repo = new Repos(vluxPath, btx);

        }

        return this._repo;

    }

    public getReposSupervisor (): ModelEvolvedRepoSupervisor {

        if (undefined === this._reposSupervisor) {

            this._reposSupervisor = new ModelEvolvedRepoSupervisor(
                new VluxDataSupervisor(new VluxDataLoaderXhr())
            );

        }

        return this._reposSupervisor;

    }

    /**
     * Get Events; create if not already exists
     */
    public getEvent (): Event {

        if (undefined === this._event) {
            this._event = new Event();
        }

        return this._event;

    }

    public getTracker (): Tracker {

        if (undefined === this._tracker) {
            this._tracker = new Tracker(
                this
            );
        }

        return this._tracker;

    }

    public getOptions (): Options {

        if (undefined === this._options) {
            this._options = new Options();
        }

        return this._options;

    }

    public getStrings (): Strings {

        if (undefined === this._strings) {
            this._strings = new Strings();
        }

        return this._strings;

    }

    public getLoadingIndicator (): LoadingIndicator {

        if (undefined === this._loadingIndicator) {
            this._loadingIndicator = new LoadingIndicator(this.getEvent());
        }

        return this._loadingIndicator;

    }

    public getGetParameter (): GetParameter {

        if (undefined === this._getParameter) {
            this._getParameter = new GetParameter();
        }

        return this._getParameter;

    }

    public getRouting (): Routing {

        if (undefined === this._routing) {
            /**
             * While this creates the pages, it needs the Injector
             */
            this._routing = createRouting(this);
        }

        return this._routing;

    }

    public getGigakombi (): Gigakombi {

        if (undefined === this._gigakombi) {
            this._gigakombi = new Gigakombi(
                this.getStorage(),
                this.getGetParameter(),
                this.getOptions()
            );
        }

        return this._gigakombi;

    }

    /**
     * Remove this after refacoting is down
     * And do not sniff into page. snigg into #nsf-dispatcher.btx
     */
    private createFlowState (): CoreFlowState {

        const currentPage = this.getRouting().getCurrentPage();

        const curiosityPages = [
            'vvl_recommendation',
            'vvl_tariff_device',
            'vvl_x_sell',
            'inlife_recommendation',
            'inlife_tariff_selection',
            'redplus_device_tariff',
            'redplus_tariff_device'
        ];

        const pagesWithSalesChannelAndSubscriptionGroup = [
            'device_overview',
            'device_detail',
            'connectmore',
            'subscription_overview',
            'vvl_winback_landing_page'
        ];

        if (0 <= curiosityPages.lastIndexOf(currentPage)) {
            this._flowState = new CoreEvolvedFlowState(this);
        } else if (-1 !== pagesWithSalesChannelAndSubscriptionGroup.indexOf(currentPage)) {

            this._flowState = new CoreEvolvedFlowStateSalesChannelSubscriptionGroup(this);

        } else {
            this._flowState = new CoreFlowStateWithoutSalesChannel(this);
        }

        return this._flowState;

    }

    public getFlowStateWithSalesChannel (): CoreEvolvedFlowState {

        if (undefined === this._flowState) {
            this.createFlowState();
        }

        return this._flowState as CoreEvolvedFlowState;

    }

    /**
     * get flowstate with salesChannel and Subscriptiongroup
     */
    public getFlowState (): CoreEvolvedFlowStateSalesChannelSubscriptionGroup {

        if (undefined === this._flowState) {
            this.createFlowState();
        }

        return this._flowState as CoreEvolvedFlowStateSalesChannelSubscriptionGroup;

    }

    /**
     *
     */
    public getFlow (): CoreFlowState {

        if (undefined === this._flowState) {
            this.createFlowState();
        }

        return this._flowState;

    }

    public getOfferCollection (): CoreEvolvedOfferCollection {

        if (undefined === this._offerCollection) {
            this._offerCollection = new CoreEvolvedOfferCollection();
        }

        return this._offerCollection;

    }

    public getGeneralSalesObjectInterface (): GeneralSalesObjectInterface {

        if (undefined === this._generalSalesObjectInterface) {
            this._generalSalesObjectInterface = new GeneralSalesObjectInterface ();
        }

        return this._generalSalesObjectInterface;

    }

    public getGeneralBasketServiceInterface (btx: BusinessTransactionContext): GeneralBasketServiceInterface {

        if (undefined === this._generalBasketServiceInterface) {

            /** these variables are needed for seamless login stuff in shop backend */
            const myCookie = new Cookie();
            const forAppConnection: boolean = null === myCookie.get('forAppConnection');

            const forApp: boolean = this.getGetParameter().getForAppGetParam();

            // the sales flow (naming convention as it's used in NSF)
            const salesChannel: SalesChannelName = this.getFlowStateWithSalesChannel().getSalesChannel();

            switch (this._btx) {

                case 'bnt':
                case 'familyfriends':
                case 'gigakombi':
                    this._generalBasketServiceInterface = new GeneralBasketServiceInterfaceBNT (salesChannel, forApp, forAppConnection);
                    break;

                case 'vvl':
                    this._generalBasketServiceInterface = new GeneralBasketServiceInterfaceVVL (salesChannel, forApp, forAppConnection);
                    break;

                case 'inlife':
                    this._generalBasketServiceInterface = new GeneralBasketServiceInterfaceInlife (salesChannel, forApp, forAppConnection);
                    break;

                case 'redplus':
                    this._generalBasketServiceInterface = new GeneralBasketServiceInterfaceRedPlus (salesChannel, forApp, forAppConnection);
                    break;

                case 'hardware':
                    this._generalBasketServiceInterface = new GeneralBasketServiceInterfaceBNT (salesChannel, forApp, forAppConnection);
                    break;

            }

        }

        return this._generalBasketServiceInterface;

    }

}
