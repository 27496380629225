/**
 * @module SalesFlow/evolved/router
 */
import Injector from 'core/injector';
import Subscription from 'model/type/subscription';
import VluxOffer from 'model/type/offer';
import Customer from 'shopbackend/customer';
import {Constants} from 'core/constants';
import {SalesChannelName, BusinessTransactionContext} from 'core/ids';
import {SkeletonService} from '../../service/render/skeleton-service';

import {RouterEvolvedSharedBase} from 'router-evolved/shared/router-evolved--shared--base';

export abstract class RouterEvolvedInlifeRouter extends RouterEvolvedSharedBase {

    protected _skeletonService: SkeletonService;

    constructor (injector: Injector, skeletonService?: SkeletonService) {

        super (injector);

        if (undefined === skeletonService) {
            skeletonService = new SkeletonService(injector);
        }

        this._skeletonService = skeletonService;

    }

    /**
     * @param subLevelSubscriptionId
     * Small little function to help us get the main subscription group
     */
    protected getMainSubscriptionGroup (subLevelSubscriptionId: number): Subscription {

        const mainSubscriptionGroup = this.getReposSupervisor().getSubscriptionRepo().getSubscription(subLevelSubscriptionId);

        if (undefined !== mainSubscriptionGroup) {
            return mainSubscriptionGroup;
        } else {
            /**
             * As per CO-3209, in case no matching between allowed tariffs and VLUX offers we redirect the user to notification page with hotline
             * Check https://confluence.wf-de.vodafone.com/display/VFDEOPM/Recommendation+Inlife
             */
            window.location.href = this.getInjector().getOptions().get('consumer_black_and_platin_path');
        }
    }

    protected getCheapestSubLevelSubscription (): VluxOffer {

        const allowedTariffIds = this.setCustomerAllowedTariffIds();
        const inlifeOffers = this.getInlifeOffersByAllowedTariffIds(allowedTariffIds);
        const orderedInlifeOffers = this.sortInlifeOffersByCheapestPrice(inlifeOffers);
        const cheapestSubLevelSubscription = orderedInlifeOffers[0];

        if (undefined !== cheapestSubLevelSubscription) {
            return cheapestSubLevelSubscription;
        }

    }

    /**
     * Let's fetch the allowed tariff IDs from the NBA call and save the ids in an array
     */
    protected setCustomerAllowedTariffIds (): number[] {

        const customer: Customer = this.getInjector().getFlowStateWithSalesChannel().getCustomer();
        const customerAllowedTariffs = customer.allowedTariffs.allowedTariffDefinition;
        const customerAllowedTariffIds: number[] = [];

        /**
         * As per CO-3209
         * In case no allowed tariffs, main routing will block the user from entering the flow and redirect him to notification page with hotline
         * Check https://confluence.wf-de.vodafone.com/display/VFDEOPM/Recommendation+Inlife
         */
        if (0 < customerAllowedTariffs.length) {

            for (const allowedTariff of customerAllowedTariffs) {

                const mainSubscriptionGroup = this.getMainSubscriptionGroup(allowedTariff.subscriptionId);
                /**
                 * getMainSubscriptionGroup function handles the case where mainSubscriptionGroup is undefined
                 */
                if (undefined !== mainSubscriptionGroup) {
                    customerAllowedTariffIds.push(allowedTariff.subscriptionId);
                }

            }
        }

        return customerAllowedTariffIds;
    }

    /**
     *
     * @param customerAllowedTariffIds
     * Get the tariff change offers based on the allowed tariff IDs we got from the NBA call
     */
    protected getInlifeOffersByAllowedTariffIds (customerAllowedTariffIds: number[]): any {

        const inlifeOffers = [];
        const generalSalesObjectInterface = this.getInjector().getGeneralSalesObjectInterface();

        for (const customerAllowedTariffId of customerAllowedTariffIds) {

            const inlifeOffer = generalSalesObjectInterface.getSimOnlyOfferByAtomicSubscriptionId(
                customerAllowedTariffId,
                Constants.BTX_INLIFE,
                this.getSalesChannel());
            inlifeOffers.push(inlifeOffer);

        }

        return inlifeOffers;
    }

    /**
     *
     * @param inlifeOffers
     * Let's order the offers from cheapest to most expensive
     */
    protected sortInlifeOffersByCheapestPrice (inlifeOffers: any): any {

        inlifeOffers.sort(function (a: any, b: any) {
            return a.subcriptionPriceMonthly.value - b.subcriptionPriceMonthly.value;
        });

        return inlifeOffers;

    }

    protected setSalesChannel (): void {

        let salesChannel: SalesChannelName;

        const defaultSalesChannel = this.getInjector().getOptions().get('default_sales_channel');

        if (Constants.SALESCHANNEL_FAMILY_FRIENDS === defaultSalesChannel) {
            /**
             * [VGOF-5736]
             * In case, the default sales channel is "familyfriends",
             * the sales channel is always "familyfriends".
             * No need to get it from customer.
             */

            salesChannel = defaultSalesChannel;

        } else {

            const customer = this.getInjector().getFlowStateWithSalesChannel().getCustomer();

            const salesChannelFromCustomer: SalesChannelName = customer.getSubscriptionGroupName() as SalesChannelName;

            if (undefined !== salesChannelFromCustomer) {
                salesChannel = salesChannelFromCustomer;
            }

            // Refactor and fix frontend business logic regarding [INC-25488]
            const salesChannelFromStorage: SalesChannelName = this.getInjector().getFlowStateWithSalesChannel().getSalesChannel();
            const isYoungCustomer: boolean = customer.isYoungCustomer;

            if (Constants.SALESCHANNEL_CONSUMER === salesChannelFromStorage
                || Constants.SALESCHANNEL_YOUNG === salesChannelFromStorage
            ) {
                if (Constants.SALESCHANNEL_YOUNG === salesChannelFromStorage && !isYoungCustomer) {
                    // Storage salesChannel is young but customer is not young eligible so consumer is taken
                    salesChannel = Constants.SALESCHANNEL_CONSUMER;
                } else {
                    salesChannel = salesChannelFromStorage;
                }
            }

        }

        this._salesChannel = salesChannel;

    }

    /**
     * getOfferFromUrlParamTariffId ()
     * @description: For Story CO-16767 we need to pick first the tariffId coming from the URL parameters
     * and if this is within the allowed tariffs then recommend the offer based on
     * that tariffId to the customer : https://jira.wf-de.vodafone.com/browse/CO-16767
     * @returns: VluxOffer
     */
    protected getOfferFromUrlParamTariffId (): VluxOffer {
        // Get the allowed tariffs from Customer
        const allowedTariffIds = this.setCustomerAllowedTariffIds();
        // Get tariffId value from URL parameter
        const subscriptionIdGetParam: number = this.getInjector().getGetParameter().getSubscriptionIdGetParam();

        const subscriptionIds: number[] = [];
        let inlifeOfferFromUrl: VluxOffer [] = [];

        // Add the tariffId from URL parameter to the array
        subscriptionIds.push(subscriptionIdGetParam);

        // if subscriptionIdGetParam exists inside the allowedTariffIds array then get the offer
        if ( allowedTariffIds.indexOf(subscriptionIdGetParam) > -1 ) {
            inlifeOfferFromUrl = this.getInlifeOffersByAllowedTariffIds(subscriptionIds);

            return inlifeOfferFromUrl ? inlifeOfferFromUrl[0] : undefined;
        }

        return undefined;
    }

}
