/**
 * @module SalesFlow/view
 */

import {ViewEvolvedCtasRedPlusBase} from 'view-evolved/ctas/redplus/view-evolved--ctas--redplus-base';
import {FlowCta} from 'view/ctas/shared/flow-cta';
import Offer from 'view/view/shared/offer/offer';

declare var $: JQueryStatic;

/**
 * One button here
 * Right: Goto Basket
 */
export class ViewEvolvedCtasRedPlusDeviceOverview extends ViewEvolvedCtasRedPlusBase {

    protected getLeftButton (): FlowCta {
        return undefined;
    }

    protected getRightButton (): FlowCta {

        return new FlowCta(
            ['nfs-device-overview-confirm'],
            this.getInjector().getStrings().get('pricebox.confirm.basket')
        );

    }

    public update (offer: Offer): void {

        if (undefined === offer) {
            return;
        }

        const rightCtaUrl: string = '#proceedToBasket';

        this._right.setHref(
            rightCtaUrl
        );

        this._element.html(
            this.getInjector().getTemplates().render('flow-ctas', [this._right], 'partials')
        );

        // This event is only used in simonly-handling
        this.getInjector().getEvent().trigger('confirmButton@set', {});

    }

    protected events (): void {
        super.events();

        this._element.on('click', this._right.getSelector(), (evt: JQueryEventObject) => {

            this.getInjector().getLoadingIndicator().show();

            if ('#proceedToBasket' === this._right.href) {

                evt.preventDefault();

                this.createBasketAndProceed();

                return false;
            }

        });

    }

}
