/**
 * @module SalesFlow/view
 */

import Subscription from 'model/type/subscription';
import VluxOffer from 'model/type/offer';
import VluxOfferService from 'model/type/offer-service';
import ViewOfferTariffCosts from '../offer-tariff-costs';

import DeviceOffer from './device-offer';
import SimOnlyOffer from './sim-only-offer';
import SimCardOffer from './simcard-offer';
import InlifeOffer from './inlife-offer';
import {Constants} from 'core/constants';

/**
 * @TODO To me clear in class naming: This should be named and imported as FrontendDeviceOffer
 */
export default abstract class Offer {

    public abstract readonly type: string;

    protected _subscription: Subscription;
    protected _offer: VluxOffer;
    public _offerTariffCost: ViewOfferTariffCosts;

    /**
     * @TODO: überlegen, wie man das mit RedPlus besser machen kann
     * kann vom Typ ViewDeviceOffer oder ViewSimOnlyOffer sein
     */
    protected _redPlusOffers: DeviceOffer[];
    /* in case, user selects Red+ */

    protected _optionalServices: number[] = [];
    protected _optionalDiscounts: number[] = [];

    protected _monthlyDiscountSum: number;
    protected _monthlyDiscountPrice: number;
    protected _monthlyRegularPrice: number;

    protected _onetimePrice: number;
    protected _connectionFee: number = 0;

    constructor (
        subscription: Subscription,
        offer: VluxOffer,
        redPlusOffers: DeviceOffer[] = [],
        optionalServices: number[] = []
    ) {

        this._subscription = subscription;
        this._offer = offer;
        this._redPlusOffers = redPlusOffers;

        // @TODO if insurance is included, than skip it here
        this._optionalServices = optionalServices;

        if (undefined !== subscription) {

            this._offerTariffCost = new ViewOfferTariffCosts(this._offer, this._subscription);
            // Add costs for any Red+ Card to Price Array
            const redPlusOffersCount = this._redPlusOffers.length;
            for ( let i = 0; i < redPlusOffersCount; i++) {
                // add SubscriptionPrice to priceArray
                const subscriptionPrice = this._redPlusOffers[i]._offerTariffCost.regularSimOnlyPrice + this._redPlusOffers[i]._offerTariffCost.subsidization;
                this._offerTariffCost.updateRuntimeArray(subscriptionPrice, 24);
                // remove possible discounts
                this._offerTariffCost.adjustRuntimeArray(this._redPlusOffers[i].offer);
            }

        }

        this.setMonthlyPrices();

    }

    protected setMonthlyPrices (): void {

        if (undefined === this._offerTariffCost) {
            return;
        }

        this._monthlyDiscountPrice = this._offerTariffCost.discountPrice;
        this._monthlyRegularPrice = this._offerTariffCost.regularPrice;

        /**
         * Red+ Costs
         */
        for (const redPlusOffer of this._redPlusOffers) {

            this._monthlyDiscountPrice += redPlusOffer.offerTariffCosts.discountSimOnlyPrice;
            this._monthlyRegularPrice += redPlusOffer.offerTariffCosts.regularSimOnlyPrice;

            /**
             * subsidization for red+ device
             */

            if ('HmK' === redPlusOffer.offer.offerType) {

                this._monthlyDiscountPrice += redPlusOffer.offerTariffCosts.subsidization;
                this._monthlyRegularPrice += redPlusOffer.offerTariffCosts.subsidization;

            }

        }

        /**
         * optionalServices
         */
        for (const optionalService of this.optionalServices) {

            try {
                this._monthlyDiscountPrice += optionalService.monthlyPrice.value;
                this._monthlyRegularPrice += optionalService.monthlyPrice.value;

                /**
                 * update "Ab dem x. Monat...."
                 */
                this._offerTariffCost.updateRuntimeArray(optionalService.monthlyPrice.value, 24);
            } catch (e) {}

        }

        /**
         * mandatoryServices
         */
        for (const optionalService of this.mandatoryServices) {

            this._monthlyDiscountPrice += optionalService.monthlyPrice.value;
            this._monthlyRegularPrice += optionalService.monthlyPrice.value;

        }

        /**
         * co-27531 optionalDiscounts
         */
        for (const optionalDiscounts of this.selectedOptionalDiscounts) {
            try {

                this._monthlyDiscountPrice += optionalDiscounts.monthlyPrice.value;
                this._monthlyRegularPrice += optionalDiscounts.monthlyPrice.value;

                /**
                 * update "Ab dem x. Monat...."
                 */
                this._offerTariffCost.updateRuntimeArray(optionalDiscounts.monthlyPrice.value, 24);
            } catch (e) {}

        }

    }

    public addUnlimitedCharingSoc (): void {
        this._offer.addUnlimitedCharingSoc();
    }

    public removeUnlimitedCharingSoc (): void {
        this._offer.removeUnlimitedCharingSoc();
    }

    protected setOnetimePrice (): void {

        this._onetimePrice = 0;

        if ('HoK' !== this._offer.offerType) {
            this._connectionFee += this._offerTariffCost.connectionFee;
        }

        /**
         * Red+ Costs
         */
        for (const redPlusOffer of this._redPlusOffers) {

            if ('HoK' !== this._offer.offerType) {
                this._connectionFee += redPlusOffer._offerTariffCost.connectionFee;
            }

        }

        this._onetimePrice += this._offer.devicePrice[0].value;

    }

    abstract get subscriptionId (): number;

    /**
     * Cumulated monthly price inluding optional services
     * excluding mandatory services and discounts
     */
    get monthlyDiscountPrice (): number {
        return 0;
    }

    /**
     * cumulated regular price without any discounts/mandatory services
     */
    get monthlyRegularPrice (): number {
        return 0;
    }

    /**
     * get all services where the customer has the choice to selec
     */
    abstract get optionalServices (): VluxOfferService[];

    /**
     * get all services where the customer has no choice
     */
    abstract get mandatoryServices (): VluxOfferService[];

    /**
     * get all discounts where the customer has the choice to select
     */
    abstract get optionalDiscounts (): VluxOfferService[];

    /**
     * get all discounts where the customer has the choice to select
     */
    abstract get selectedOptionalDiscounts (): VluxOfferService[];

    /**
     * get all discounts where the customer has no choicet
     */
    abstract get mandatoryDiscounts (): VluxOfferService[];

    /**
     * get all discounts where the customer has the choice to select
     */
    abstract get privatePricingDiscounts (): VluxOfferService[];

    /**
     * While all subscriptions durate 2years, just a constant value
     * @return duration of a contract in month
     */
    get duration (): number {
        return 24;
    }

    abstract get subscription (): Subscription;

    abstract get subscriptionName (): string;

    abstract get offer (): VluxOffer

    abstract get offerTariffCosts (): ViewOfferTariffCosts;

    get redPlusOffers (): DeviceOffer[] {
        return [];
    }

    /**
     * get list of all included services and discounts, that has a benefit for the customer
     */
    abstract get includedServicesAndDiscounts (): any[];

    abstract get includedServiceAndDiscountIds (): number[];

    /**
     * headline in  Vorteile Overlay
     */
    abstract get labelMainProposition (): string;

    /**
     * Headline in pricebox. In this case logic out of template, because of further expansions (Voice / Data)
     */
    get labelHeaderOnetime (): string {
        return '';
    }

    public isDevice (): this is DeviceOffer {
        return 'device' === this.type;
    }

    public isHardwareOnly (): this is DeviceOffer {
        return 'hardwareonly' === this.type;
    }

    public isSimOnly (): this is SimOnlyOffer {
        return 'simonly' === this.type;
    }

    public isSimCard (): this is SimCardOffer {
        return 'simcard' === this.type;
    }

    public isInlife (): this is InlifeOffer {
        return 'inlife' === this.type;
    }

    /**
     * Get active gigakombi discounts in offer
     */
    public getGigakombiDiscountIDs (): number[] {

        let gigakombiDiscountIDs: number[] = [];
        gigakombiDiscountIDs = this.offer.getGigakombiDiscountIDs();
        if (0 < gigakombiDiscountIDs.length) {

            if (1 < gigakombiDiscountIDs.length
                || (1 === gigakombiDiscountIDs.length && !gigakombiDiscountIDs.includes(Constants.Gigakombi_TV))) {
                const promoId = this.addGigakombiUnlimitedSOC();
                if (undefined !== promoId) {
                    gigakombiDiscountIDs.push(promoId);
                }
            }
        }

        return gigakombiDiscountIDs;
    }

    /**
     * Add Unlimited Promo SOC
     */
    private addGigakombiUnlimitedSOC (): number {

        if (this.offer.salesChannel === Constants.SALESCHANNEL_YOUNG) {
            return Constants.GigakombiYoungSoc;
        }

        switch (this.subscriptionId) {
            case Constants.RedXS_Id:
                return Constants.GigakombiUnlimitedPromoIdRedXS;
            case Constants.RedS_Id:
                return Constants.GigakombiUnlimitedPromoIdRedS;
            case Constants.RedM_Id:
                return Constants.GigakombiUnlimitedPromoIdRedM;
            case Constants.RedL_Id:
                return Constants.GigakombiUnlimitedPromoIdRedL;
        }

        return undefined;
    }

    get isGigakombi (): boolean {

        return (0 !== this.getGigakombiDiscountIDs().length);

    }

    /**
     * @TODO This returns always 15, at least in unit tests
     * Get the sum of all monthly gigakombi discounts
     */
    get monthlyDiscountGigakombiSum (): number {

        if (false === this.isGigakombi) {
            return 0;
        }

        const gigakombiDiscountIDs = this.getGigakombiDiscountIDs();

        if (0 === gigakombiDiscountIDs.length) {
            return 0;
        }

        let discountSum = 0;

        const includedDiscounts = this.offer.getIncludedDiscounts();
        const includedDiscountsLength = includedDiscounts.length;

        for (let i = 0; i < includedDiscountsLength; i++) {
            for (const service of includedDiscounts[i].services) {

                if (-1 === gigakombiDiscountIDs.lastIndexOf(service.id)) {
                    continue;
                }

                if (undefined !== service.monthlyPrice) {
                    discountSum += -1 * service.monthlyPrice.value;
                }
            }
        }

        return discountSum;
    }

    private getAddidtionalDataVolume (all: boolean): number {

        let additionalDataVolume: number = Number(0);
        const offerServiceGroups = this.offer.getIncludedDiscounts();
        for (const servicegroup of offerServiceGroups) {
            for (const service of servicegroup.services) {
                if (undefined !== service.dataVolume) {
                    if (false === all) {
                        if (service.id !== Constants.GigakombiYoungSoc) {
                            additionalDataVolume += Number(service.dataVolume);
                        }
                    } else {
                        additionalDataVolume += Number(service.dataVolume);
                    }
                }
            }
        }

        return additionalDataVolume;
    }

    /**
     * @TODO use from GSO
     * Get Unlimited Datavolume
     */
    get isGigakombiUnlimitedDatavolume (): boolean {
        if (false === this.isGigakombi) {
            return false;
        }

        const gigakombiDiscountIDs = this.getGigakombiDiscountIDs();

        if (0 === gigakombiDiscountIDs.length) {
            return false;
        }

        for (const service of this.offer.getIncludedDiscounts()[0].services) {

            if (-1 === gigakombiDiscountIDs.lastIndexOf(service.id)) {
                continue;
            }
            if (service.id === Constants.GigakombiUnlimitedPromoIdRedM
                || service.id === Constants.GigakombiUnlimitedPromoIdRedL) {
                    this.offer.applyUnlimited();

                    return true;
            }
        }

        return false;
    }

    /**
     * Show if Black Week unlimited is available for costumer
     * part of Black Week promo 2023
     */
    get isBlackWeekUnlimited (): boolean {
        if (0 === this.offer.getGigakombiDiscountIDs().length) {

            return false;
        }

        for (const service of this.offer.getIncludedDiscounts()[0].services) {

            if (service.id === Constants.BlackWeekUnlimitedForGM
                || service.id === Constants.BlackWeekUnlimitedForGMY) {

                return true;
            }
        }

        return false;
    }

    /**
     * //dj
     * Sumarize the device datavolume and a possible additional datavolume from services
     */
    get fullDataVolume (): number {

        const additionalDataVolume: number = this.getAddidtionalDataVolume(true);

        return Number(this.subscription.dataVolume) + Number(additionalDataVolume);
    }

    /**
     * returns only the additional datavolume from services
     */
    get extraDataVolume (): number {

        if (true === this.isGigakombi) {
            if (undefined !== this.fullDataVolume
                && undefined !== this.subscription.dataVolume) {

                // Young only has a fake fullDatavolume
                if (this.offer.salesChannel === Constants.SALESCHANNEL_YOUNG) {

                    const additionalDataVolume: number = this.getAddidtionalDataVolume(false);

                    return Number(this.fullDataVolume) - Number(this.subscription.dataVolume) - Number(additionalDataVolume);
                }

                return Number(this.fullDataVolume) - Number(this.subscription.dataVolume);
            }
        }

        return 0;
    }

    /**
     * create Datavolume Text with included discount datavolume
     */
    get fullDataVolumeText (): string {
        if (this.subscription.isRedXLUnlimited()
            || undefined === this.subscription.dataVolume
            || 0 === this.subscription.dataVolume) {

            return this.subscription.dataVolumeText;
        }
        if  (this.isGigakombiUnlimitedDatavolume) {
                return ' mit unbegrenztem Datenvolumen';
            }

        return this.fullDataVolume + ' GB Datenvolumen';
    }

    get onetimeDevicePrice (): number {
        return this._offer.devicePrice[0].value;
    }

    /**
     *  Device regular onetime price
     */
    get onetimeDeviceStrikePrice (): number {
        return this._offer.devicePrice[0].strikePrice;
    }

    /**
     * Cumulated Onetime Price inluding connection Fee
     */
    get onetimePrice (): number {

        return 0;
    }

    /**
     * Cumulated Onetime Price excluding connection Fee
     */
    get onetimePriceWithoutConnectionFee (): number {

        return 0;

    }

    get monthyDiscountHint (): string {
        return '';
    }

    /**
     * For now it's a hardcoded solution for double data in
     * All simonly offers in young and all red
     */
    get hasPromo (): boolean {

        return false;

    }

    get promoLabel (): string {

        return '';

    }

    get promoText (): string {

        return '';

    }

    get hasPrivatePricingDiscount (): boolean {
        return false;
    }

    get hasTradeIn (): boolean {
        for (const offerservice of this.optionalDiscounts) {
            if (Constants.TradeInDiscount_Id === offerservice.id) {
                return true;
            }
        }

        return false;
    }

    get hasVvlTradeIn (): boolean {
        for (const offerservice of this.optionalDiscounts) {
            if (Constants.VVL_TradeInDiscount_Id === offerservice.id) {
                return true;
            }
        }

        return false;
    }

    /**
     * returns boolean if customer is not soho nor fandf and had trade in optional discount
     */
    get isTradeInEligible (): boolean {

        return (this.hasVvlTradeIn && !(Constants.SALESCHANNEL_FAMILY_FRIENDS === this.offer.salesChannel) && !(Constants.SALESCHANNEL_SOHO === this.offer.salesChannel));

    }
}
