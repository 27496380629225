/**
 * @module SalesFlow/view
 */

import AtomicDevice from 'model/type/atomic-device';

class Size {

    private _value: number;
    private _internalMemoryWithUnit: string;
    private _unit: string;

    constructor (atomicDevice: AtomicDevice) {
        this._value = atomicDevice.attr.internalMemory || 0;
        this._internalMemoryWithUnit = atomicDevice.attr.internalMemoryWithUnit;
    }

    get value (): number {
        return this._value;
    }

    public splitInternalMemoryWithUnit () {

        const tmpUnit = this._internalMemoryWithUnit.replace(this._value + '', '').trim();

        if (-1 !== ['MB', 'GB', 'TB'].indexOf(tmpUnit)) {
            this._unit = tmpUnit;
        } else {
            this._unit = 'GB';
        }
    }

    get unit (): string {
        this.splitInternalMemoryWithUnit();

        return this._unit;
    }

}

class ViewSize {

    private _size: Size;
    private _atomicDeviceId: number = undefined;
    private _isSelected: boolean = false;

    constructor (size: Size) {
        this._size = size;
    }

    public setSelected (selected: boolean): void {
        this._isSelected = selected;
    }

    public setAtomicDeviceId (id: number): void {
        this._atomicDeviceId = id;
    }

    public isSelected (): boolean {
        return this._isSelected;
    }

    public isAvailable (): boolean {
        if (undefined !== this._atomicDeviceId) {
            return true;
        }

        return false;

    }

    get value (): number {
        return this._size.value;
    }

    get unit (): string {
        return this._size.unit;
    }

    get atomicDeviceId (): number {
        return this._atomicDeviceId;
    }

    get cssClasses (): string {

        const cssClasses: string[] = [];

        if (false === this.isAvailable()) {
            cssClasses.push('disabled');
        }

        if (true === this.isSelected()) {
            cssClasses.push('selected');
        }

        // add en emmpty element to array to create leading space after reverse and join
        if (0 !== cssClasses.length) {
            cssClasses.push('');
        }

        return cssClasses.reverse().join(' ');

    }

}

/**
 * @internal
 */
export default class ViewSizes {

    private _sizes: ViewSize[] = [];

    constructor (atomicDevice: AtomicDevice) {

        const sizes: Size[] = this.setSizes(atomicDevice);
        this._sizes = this.setViewSizes(atomicDevice, sizes);

    }

    private uniqueSizes (sizes: Size[]): Size[] {
        const uniqueSizes: Size[] = [];

        const unique: any = {};

        for (const size of sizes) {
            if (true === unique[size.value]) {
                continue;
            }

            uniqueSizes.push(size);
            unique[size.value] = true;

        }

        return uniqueSizes;
    }

    private sortSizes (size: Size[]): void {
        size.sort((a: Size, b: Size) => {
            const nameA = this.convertSizeToGB(a);
            const nameB = this.convertSizeToGB(b);
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            return 0;
        });

    }

    private convertSizeToGB (size: Size) {
        const convertUnit = 1000;
        if (size.unit === 'MB') {
            return size.value / convertUnit;
        } else if (size.unit === 'TB') {
            return size.value * convertUnit;
        } else {
            return size.value;
        }
    }

    /**
     * Map sizes with atomicDevices with the very same size
     */
    private setViewSizes (atomicDevice: AtomicDevice, sizes: Size[]): ViewSize[] {

        const viewSizes: ViewSize[] = sizes.map((color) => {
            return new ViewSize(color);
        });

        const atomicDevicesWithSameSize = atomicDevice.device.getAtomicDevicesByAttr('color', atomicDevice.attr.color);

        for (const atomicDeviceWithSameSize of atomicDevicesWithSameSize) {
            for (const viewSize of viewSizes) {

                if (viewSize.value !== atomicDeviceWithSameSize.attr.internalMemory) {
                    continue;
                }

                if (undefined !== viewSize.atomicDeviceId) {
                    continue;
                }

                viewSize.setAtomicDeviceId(atomicDeviceWithSameSize.id);

            }
        }

        for (const viewSize of viewSizes) {
            if (viewSize.value !== atomicDevice.attr.internalMemory) {
                continue;
            }

            viewSize.setSelected(true);
            break;

        }

        return viewSizes;

    }

    /**
     * Get sizes from all atomicDevices
     */
    private setSizes (atomicDevice: AtomicDevice): Size[] {
        let sizes: Size[] = [];

        sizes = atomicDevice.getDevice().getAtomicDevices().map((atomicDevice) => {
            return new Size(
                atomicDevice
            );
        });

        sizes = sizes.filter((size) => {
            if (0 >= size.value) {
                return false;
            }

            return true;
        });

        sizes = this.uniqueSizes(sizes);

        this.sortSizes(sizes);

        return sizes;

    }

    get all (): ViewSize[] {
        return this._sizes;
    }

    get selected (): ViewSize {

        for (const size of this._sizes) {
            if (size.isSelected()) {
                return size;
            }
        }

        return undefined;
    }

}
