/**
 * @module SalesFlow/evolved/controller
 */

import { Constants } from 'core/constants';
import Stepper from 'view/element/shared/stepper';
import StepperBnt from 'view/element/bnt/stepper';
import StepperGigakombi from 'view/element/gigakombi/stepper';
import {ViewEvolvedElementVvlStepper} from 'view-evolved/element/vvl/view-evolved--element-vvl--stepper';
import {ViewEvolvedCtasSharedFlowCtas} from 'view-evolved/ctas/shared/view-evolved--ctas--shared--flow-ctas';
import Injector from 'core/injector';

import {ControllerEvolvedBase} from 'controller-evolved/controller-evolved--base';
import {ModelEvolvedRepoSupervisor} from 'model-evolved/repo/model-evolved--repo--supervisor';

export abstract class ControllerEvolvedFlowBaseWithoutSalesChannel extends ControllerEvolvedBase {

    protected _stepper: Stepper;
    protected _ctas: ViewEvolvedCtasSharedFlowCtas;

    protected _reposSupervisor: ModelEvolvedRepoSupervisor;

    constructor (reposSupervisor: ModelEvolvedRepoSupervisor, injector: Injector) {

        super(injector);

        this._reposSupervisor = reposSupervisor;

        const orderType = this.getInjector().getFlowState().getOrderType();

        if (Constants.OrderType_Voice === orderType) {
            /**
             * Use same stepper for BNT and Gigakombi as they have slimier text
             */
            if (Constants.BTX_BNT === this._btx ) {
                this._stepper = new StepperBnt(injector);
            } else if ( Constants.BTX_GIGAKOMBI === this._btx) {
                this._stepper = new StepperGigakombi(injector);
            } else {
                this._stepper = new ViewEvolvedElementVvlStepper(injector);
            }
        }

        this._ctas = this.createCtas();

    }

    protected abstract tracking (eventType: string, overwriteRule?: boolean): void;

    protected abstract createCtas (): ViewEvolvedCtasSharedFlowCtas;

    protected getReposSupervisor (): ModelEvolvedRepoSupervisor {
        return this._reposSupervisor;
    }

    protected resetRedPlusCards () {
        this.getInjector().getFlowStateWithSalesChannel().resetRedPlusFlowData();
    }

}
