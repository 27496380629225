/**
 * @module SalesFlow/model/type
 */
import SubscriptionAttributes from './attributes-subscription';

export default class Subsidization {

    private _id: number;
    private _label: string;
    private _backendSystem: string;
    private _backendId: string;

    private _simonly: boolean = false;

    private _attr: SubscriptionAttributes;

    constructor (data: any) {

        this._id = parseFloat(data.id);
        this._label = data.label;

        this._backendSystem = data.backendId[0].system;
        this._backendId = data.backendId[0].value;

        let attributes = {};

        if (undefined !== data.attributes && undefined !== data.attributes.attribute) {
            attributes = data.attributes.attribute;
        }

        this._attr = new SubscriptionAttributes(attributes);

        // if attribute for sublevel it not set: this is sim only
        if (undefined === this._attr.subLevel) {
            this._simonly = true;
        }

    }

    get id (): number {
        return this._id;
    }

    get label (): string {
        return this._label;
    }

    get backendId (): string {
        return this._backendId;
    }

    get isSimOnly (): boolean {
        return this._simonly;
    }

    get subLevel (): number {
        if (undefined === this._attr) {
            return undefined;
        }

        return this._attr.subLevel;
    }

}
