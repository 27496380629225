/**
 * @module SalesFlow/evolved/router
 */

import {RouterEvolvedVvlRouter} from 'router-evolved/vvl/router-evolved--vvl--router';
import {SalesChannelName, SubscriptionIdPerSalesChannel} from 'core/ids';
import {ControllerEvolvedVvlDeviceOverview} from 'controller-evolved/vvl/controller-evolved--vvl-device-overview';
import {Constants} from 'core/constants';

/**
 * @TODO don't use injector as dependency
 */
export class RouterEvolvedVvlDeviceOverview extends RouterEvolvedVvlRouter {

    /**
     * Get getFocusSubscriptionId in router to be able to used it as fallback for validating device
     *
     */
    public getFocusSubscriptionId (): number {

        const focusSubscriptionIds = this.getFocusSubscriptionIds();

        return focusSubscriptionIds[this.getSalesChannel()];
    }

    public getFocusSubscriptionIds (): SubscriptionIdPerSalesChannel {

        const subscriptionIds: SubscriptionIdPerSalesChannel = {
            consumer: this.getInjector().getOptions().get('default_consumer_subscription_id') || undefined,
            young: this.getInjector().getOptions().get('default_young_subscription_id') || undefined,
            soho: this.getInjector().getOptions().get('default_consumer_subscription_id') || undefined
        };

        // set recommended subscription and on focus if there is one
        if (undefined !== this.getInjector().getFlowStateWithSalesChannel().getRecommendedSubscriptionId()) {

            const recommendedSubscriptionId = this.getInjector().getFlowStateWithSalesChannel().getRecommendedSubscriptionId();

            // recommendedSubscriptionId could be consumer or young from current portfolio
            // so let's find out for which salesChannel we want the recommended id in focus

            const recommendedSubscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(recommendedSubscriptionId);
            subscriptionIds[recommendedSubscription.subscriptionGroupName] = recommendedSubscriptionId;

        }

        return subscriptionIds;
    }

    /**
     * Return incoming unvalidated tarifIds in order
     * 1) Per storage
     * 2) Per get parameter
     *
     * So when validate and resolve this: Get parameter will overrule storage
     *
     */
    public getSubscriptionIds (): number[] {

        // [GET, Storage, Option]
        const incomingSubscriptionIds: number[] = [];

        const subscriptionIdFromStorage: number = this.getInjector().getFlowStateWithSalesChannel().getSubscriptionId();
        if (undefined !== subscriptionIdFromStorage) {
            incomingSubscriptionIds.push(subscriptionIdFromStorage);
        }

        const subscriptionIdGetParam: number = this.getInjector().getGetParameter().getSubscriptionIdGetParam();
        if (undefined !== subscriptionIdGetParam) {

            // Skip pushing a tariff id from url parameter if customer sales channel is consumer but tariff id belongs to young tariffs [VGOF-5714]
            const salesChannelFromCustomer: SalesChannelName = this.getInjector().getFlowStateWithSalesChannel().getCustomer().getSubscriptionGroupName() as SalesChannelName;
            if (!(-1 !== Constants.AllYoungSubscriptionMainIds.lastIndexOf(subscriptionIdGetParam) && Constants.SALESCHANNEL_CONSUMER === salesChannelFromCustomer)) {
                incomingSubscriptionIds.push(subscriptionIdGetParam);
            }
        }

        return incomingSubscriptionIds.reverse();

    }

    /**
     * Return incoming unvalidated tarifIds in order
     * 1) Per storage
     * 2) Get Parameter
     *
     * So when validate and resolve this: Get will overrule storage and storage will overule option
     *
     */
    public getIncomingDeviceIds (): number[] {

        // [GET, Storage, Option]
        const incomingDeviceIds: number[] = [];

        const deviceIdFromStorage: number = this.getInjector().getFlowStateWithSalesChannel().getAtomicDeviceId();

        if (undefined !== deviceIdFromStorage) {
            incomingDeviceIds.push(deviceIdFromStorage);
        }

        const deviceIdGetParam: number = this.getInjector().getGetParameter().getDeviceIdGetParam();
        if (undefined !== deviceIdGetParam) {
            incomingDeviceIds.push(deviceIdGetParam);
        }

        return incomingDeviceIds.reverse();

    }

    /**
     *
     * @TODO This should be only callable when repos are loaded
     * @TODO This should resolve not only if tariff exists, it should also validate that tariff is in correct tariff group
     */
    public resolveDeviceId (incomingDeviceIds: number[]): number {

        let subscriptionId = this.resolveSubscriptionId(this.getSubscriptionIds());

        if (undefined === subscriptionId) {
            subscriptionId = this.getFocusSubscriptionId();
        }

        const subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(
            subscriptionId
        );

        // maybe there are deviceIds in incomingDeviceIds: Map them to atomicDeviceIds
        const incomingAtomicDeviceIds = incomingDeviceIds.map((deviceId) => {

            const checkDevice = this.getReposSupervisor().getPurchasableDeviceRepo().getDevice(
                deviceId,
                this.getSalesChannel(),
                subscription
            );

            // Id is not an deviceId
            if (undefined === checkDevice) {
                return deviceId;
            }

            return checkDevice.getAtomicDeviceByIndex(0).id;

        });

        // are incomingAtomicDeviceIds purchasable with subscription
        const validatedDAtomicDeviceIds = incomingAtomicDeviceIds.filter((deviceId) => {

            const checkAtomicDeviceId = undefined !== this.getReposSupervisor().getPurchasableDeviceRepo().getAtomicDevice(
                deviceId,
                this.getSalesChannel(),
                subscription
            );

            if (true === checkAtomicDeviceId) {
                return true;
            }

            return false;

        });

        if (0 === validatedDAtomicDeviceIds.length) {
            return undefined;
        }

        return validatedDAtomicDeviceIds[0];

    }

    public validateIncoming (): void {

        this._subscriptionId = this.resolveSubscriptionId(
            this.getSubscriptionIds()
        );

        this._atomicDeviceId = this.resolveDeviceId(
            this.getIncomingDeviceIds()
        );

    }

    public createController (): JQueryPromise<RouterEvolvedVvlRouter> {
        const deferred = $.Deferred<any>();

        this.loadReposSupervisor().then(() => {

            this.validateIncoming();
            this._skeletonService.removeSkeletonLoadingClassNames();

            deferred.resolve(
                new ControllerEvolvedVvlDeviceOverview(
                    this.getInjector().getFlowStateWithSalesChannel().customer,
                    this.getSalesChannel(),
                    this.getAtomicDeviceId(),
                    this.getSubscriptionId(),
                    this.getFocusSubscriptionIds(),
                    this.getReposSupervisor(),
                    this.getInjector()
                )
            );
        }, function () {
            deferred.reject();
        });

        return deferred.promise();

    }

}
