import { SalesChannelName } from 'core/ids';

import GeneralBasketServiceInterface from './general-basket-service-interface';

import * as ŃsfOffer from 'view/view/shared/offer/offer';

import GeneralSalesObjectInterface from 'service/general-sales-object/general-sales-object-interface';

/**
 * @module SalesFlow/shopbackend
 */

export default class GeneralBasketServiceInterfaceRedPlus extends GeneralBasketServiceInterface {

    constructor (salesChannel: SalesChannelName, forApp: boolean, forAppConnection: boolean) {

        super (salesChannel, forApp, forAppConnection);

    }

    /**
     * Main offer is the NSF View Offer Type (SimOnlyOffer, DeviceOffer, HardwareOnlyOffer) that
     * includes every information we need. Red+ Items, Accessory Items, optional services ....
     * @param mainOffer
     * @param salesChannel
     * @param generalSalesObjectInterface
     */
    public executeOffer (mainOffer: ŃsfOffer.default, salesChannel: SalesChannelName, generalSalesObjectInterface: GeneralSalesObjectInterface): JQueryPromise<any> {

        this.init();

        this._salesChannel = salesChannel;
        this._generalSalesObjectInterface = generalSalesObjectInterface;

        // Offer Data in NSF Style
        this._NSF_Data.mainOffer = mainOffer;

        // First step: empty Basket
        this.clear();

        // generates Baskert Object out of NSF Offer (Including Red+)
        this.generateMainOfferFromNSFData();

        // Add Main Offer to Basket
        this._basketFactory.putSecondCardOfferIntoBasket (
            this._GSO_Data.mainOffer
        );

        return this.sendBasketToShopBackend();

    }

}
