/**
 * @module SalesFlow/view
 */

declare var $: JQueryStatic;

import DeviceOffer from '../../../view/shared/offer/device-offer';

import FilterBase from './filter-base';

export default class FilterGeneric extends FilterBase {

    public render (viewOffers: DeviceOffer[]): string {

        super.render(viewOffers);

        return this.getInjector().getTemplates().render('device_filter', this);
    }

    public events (): void {

        super.events();

        this._element = $('.mod-dropdowncheckbox[data-name="' + this._name + '"]');

        const pane: any = this._element.find('.scroll-pane');
        pane.jScrollPane({
            autoReinitialise: true
        });

        pane.css('display', 'none');

        const api = pane.data('jsp');

        this._element.find('.baseBox').on(
            'click',
            () => {
                // Close other open filters
                $('.mod-dropdowncheckbox[data-name!="' + this._name + '"]').find('.scrollContainer').removeClass('active');

                // Toggle state of clicked dropdown
                this._element.find('.scrollContainer').toggleClass('active');
            }
        );

        this._element.on('click', '.checkBox', (evt) => {
            this.check(evt);

        });

        this._element.on('click', '.scrollContainer.active .baseBox', (evt) => {
            this.reset(evt);
        });

        this._element.on('click', '.buttonBox .btn', (evt) => {
            this.select();
        });

    }

    private check (evt: any): void {

        const $check = $(evt.currentTarget);

        if (true === $check.hasClass('disabled')) {
            return;
        }

        if (true === $check.hasClass('selected') || true === $check.hasClass('checked')) {
            $check.removeClass('selected');
            $check.removeClass('checked');
        }
        else {
            $check.addClass('checked');
        }

    }

    private select (): void {

        this._element.find('.checked').addClass('selected').removeClass('checked');

        this.updateActive();

        this._element.find('.baseBox').trigger('click');

        this._element.addClass('lastChanged');

        this.getInjector().getEvent().trigger('filter@changed', {});

    }

}
