/**
 * @module SalesFlow/core
 */

import { Constants } from 'core/constants';
import WatchOffer from 'view/view/shared/offer/watch-offer';

import Routing from 'router/shared/routing';
import Storage from 'core/storage';
import Options from 'core/options';
import Event from 'core/event';

import FlowArrayHelper from 'core/flow-array';
import Customer from 'shopbackend/customer';

import {SalesChannelName, SubscriptionGroupName, OrderType} from 'core/ids';
import Injector from 'core/injector';

export interface SubscriptionIds {
    [index: string]: number;

    consumer: number;
    young: number;
    easy: number;
    soho: number;
    familyfriends: number;

}

/**
 * Get and set flow wide things (e.g. selected subscription and device ids) and persit flow state to sessionstorage
 *
 * @TODO Split vvl and bnt flow
 */
export abstract class CoreFlowState {

    private _version: string = 'CI_PIPELINE_ID';

    protected _flowStateType = 'Abstract';

    protected _storageName: string = 'flow';

    protected _orderType: OrderType;

    private _routing: Routing;
    private _storage: Storage;
    protected _event: Event;
    private _options: Options;

    /**
     * Has the user selected a subscription or was a default preselected
     */
    private _subscriptionLocked: boolean;

    /**
     * Has the user selected a device or was a default preselected
     */
    private _deviceLocked: boolean;

    /**
     * Has the user selected hardwareOnly
     */
    protected _hardwareOnly: boolean;

    private _subscriptionsFirst: boolean = false;
    private _devicesFirst: boolean = false;
    private _deviceFirst: boolean = false;
    private _watchFirst: boolean = false;
    private _redPlusTariffFrirst = false;
    private _redplusDeviceFirst = false;

    protected _subscriptionGroup: SubscriptionGroupName;

    private _atomicDeviceId: number;
    private _simcardId: number;
    private _simcardAtomicId: number;
    private _offerId: string;
    private _watchOffers: WatchOffer[] = [];

    private _vvlFirstVisit: boolean = true;
    private _vvlRecommendedAtomicId: number;
    private _vvlRecommendedSubscriptionId: number;
    private _vvlRecommendedHasInsurance: boolean;

    // @TODO subscriptiongroup consumer should be renamed to red to be clearer
    protected _subscriptionIds: SubscriptionIds = {
        consumer: undefined,
        young: undefined,
        easy: undefined,
        soho: undefined,
        familyfriends: undefined
    };

    /**
     * did the user opt in for special tariff services on BntConnectMoreController
     */
    private _optionalServiceIds: FlowArrayHelper;

    /**
     * did the user select Accessory on BntConnectMoreController
     */
    private _accessoryIds: FlowArrayHelper;

    /**
     * does the user select any red+ offer. this could be a sim-only
     * or a device offer.
     */
    private _redPlusAllnetOfferIds: FlowArrayHelper;
    private _redPlusDataOfferIds: FlowArrayHelper;
    private _redPlusKidsOfferIds: FlowArrayHelper;
    private _redPlusBasicOfferIds: FlowArrayHelper;

    /**
     * Logged in customer
     */
    private _customer: Customer;
    private _injector: Injector;

    constructor (injector: Injector) {

        this._injector = injector;
        this._routing = injector.getRouting();
        this._storage = injector.getStorage();
        this._event = injector.getEvent();
        this._options = injector.getOptions();

        this._optionalServiceIds = new FlowArrayHelper(this);
        this._accessoryIds = new FlowArrayHelper(this);
        this._redPlusAllnetOfferIds = new FlowArrayHelper(this);
        this._redPlusBasicOfferIds = new FlowArrayHelper(this);
        this._redPlusDataOfferIds = new FlowArrayHelper(this);
        this._redPlusKidsOfferIds = new FlowArrayHelper(this);
        /**
         * This empty customer is needed in deeper scope because the customer needs to be present for other function calls. If any questions need to be answered regarding this, talk to Bastian ;)
         */
        this._customer = new Customer({});

        /**
         * find out, if flow is data or voice
         */
        this.detectOrderType();

    }

    protected log (): void {

        if (true !== this.getOptions().get('debug')) {
            return;
        }

        const cnsl = console;

        cnsl.group('New Sales FlowState!');

        cnsl.log('%c Version: ' + this._version, 'font-weight:bold;');

        cnsl.log('%c FlowState: ' + this._flowStateType, 'font-weight:bold;');

        cnsl.log('');

        cnsl.log('BTX:', this._injector.getBtx());
        cnsl.log('');

        cnsl.log('%c isSubscriptionFirstFlow', (true === this.isSubscriptionFirstFlow()) ? 'color:green;' : 'color:red;');
        cnsl.log('%c isSubscriptionFirstFlow', (true === this.isSubscriptionFirstFlow()) ? 'color:green;' : 'color:red;');
        cnsl.log('%c isDevicesFirstFlow', (true === this.isDevicesFirstFlow()) ? 'color:green;' : 'color:red;');
        cnsl.log('%c isDeviceFirstFlow', (true === this.isDeviceFirstFlow()) ? 'color:green;' : 'color:red;');

        cnsl.log('');

        cnsl.log('%c isSubscriptionLocked', (true === this.isSubscriptionLocked()) ? 'color:green;' : 'color:red;');
        cnsl.log('%c isDeviceLocked', (true === this.isDeviceLocked()) ? 'color:green;' : 'color:red;');
        cnsl.log('%c isHardwareOnly', (true === this._hardwareOnly) ? 'color:green;' : 'color:red;');

        cnsl.log('');

        cnsl.log('subscriptionGroup', this.getSubscriptionGroup());
        cnsl.log('getSubscriptionId', this.getSubscriptionId());
        cnsl.log('getAtomicDeviceId', this.getAtomicDeviceId());
        cnsl.log('getOfferId', this.getOfferId());

        cnsl.log('');

        cnsl.log('%c hasDeviceInsurance', (true === this.hasDeviceInsurance()) ? 'color:green;' : 'color:red;');
        cnsl.log('%c RedPlusAllnet', (0 < this._redPlusAllnetOfferIds.elements.length) ? 'color:green;' : 'color:red;');
        cnsl.log('%c RedPlusBasic', (0 < this._redPlusBasicOfferIds.elements.length) ? 'color:green;' : 'color:red;');
        cnsl.log('%c RedPlusData', (0 < this._redPlusDataOfferIds.elements.length) ? 'color:green;' : 'color:red;');
        cnsl.log('%c RedPlusKids', (0 < this._redPlusKidsOfferIds.elements.length) ? 'color:green;' : 'color:red;');
        cnsl.log('%c Optional Services', (0 < this._optionalServiceIds.elements.length) ? 'color:green;' : 'color:red;');
        cnsl.log('%c Accessory', (0 < this._accessoryIds.elements.length) ? 'color:green;' : 'color:red;');

        cnsl.groupEnd();

    }

    // TODO: this is absurd?
    public getSalesChannel (): SalesChannelName {

        if ('easy' === this.getSubscriptionGroup()) {
            return Constants.SALESCHANNEL_CONSUMER;
        }

        return this.getSubscriptionGroup() as SalesChannelName;

    }

    private getRouting (): Routing {
        return this._routing;
    }

    private getStorage (): Storage {
        return this._storage;
    }

    protected getOptions (): Options {
        return this._options;
    }

    protected getEvent (): Event {
        return this._event;
    }

    protected setFirstFlow (): void {

        const subscriptionFirstPages = [
            'subscription_overview',
            'gigakombi_subscription_overview'
        ];

        const currentPage = this.getRouting().getCurrentPage();

        if ('vvl_tariff_device' === currentPage) {
            this._subscriptionsFirst = true;
            this._devicesFirst = true;
            this._deviceFirst = false;

        }
        else if (-1 !== subscriptionFirstPages.indexOf(currentPage)) {
            // we are on tariff page and if there was an other flow, it should be discarded
            this._subscriptionsFirst = true;
            this._devicesFirst = false;
            this._deviceFirst = false;
        }
        else if ('device_overview' === currentPage) {
            // we are on tariff page and if there was an other flow, it should be discarded
            // with the exception of the subscription_overview
            if (false === this._subscriptionsFirst) {
                this._devicesFirst = true;
            }
            this._deviceFirst = false;

        }
        else if ('device_detail' === currentPage) {
            // we are on product page. if no other flow is active: Marke it as productFirst
            if (false === this._subscriptionsFirst && false === this._devicesFirst) {
                this._deviceFirst = true;
            }
        }
        else if ('multisim_watch_first' === currentPage) {
            this._watchFirst = true;
        }
        else if ('redplus_tariff_device' === currentPage) {
            this._redPlusTariffFrirst = true;
        }
        else if ('redplus_device_tariff' === currentPage) {
            this._redplusDeviceFirst = true;
        }
    }

    protected mapFlowToStringableObject (): any {

        // @TODO Should not be any
        const data: any = {};

        data.subscriptionsFirst = (true === this._subscriptionsFirst);
        data.devicesFirst = (true === this._devicesFirst);
        data.deviceFirst = (true === this._deviceFirst);

        data.subscriptionLocked = (true === this._subscriptionLocked);
        data.deviceLocked = (true === this._deviceLocked);
        data.hardwareOnly = (true === this._hardwareOnly);

        data.subscriptionGroup = this._subscriptionGroup;
        data.consumerId = this._subscriptionIds.consumer;
        data.youngId = this._subscriptionIds.young;
        data.easyId = this._subscriptionIds.easy;
        data.sohoId = this._subscriptionIds.soho;
        data.familyfriendsId = this._subscriptionIds.familyfriends;
        data.atomicDeviceId = this._atomicDeviceId;
        data.simcardId = this._simcardId;
        data.simcardAtomicId = this._simcardAtomicId;
        data.offerId = this._offerId;
        data.vvlFirstVisit = (true === this._vvlFirstVisit);
        data.vvlRecommendedAtomicId = this._vvlRecommendedAtomicId;
        data.vvlRecommendedSubscriptionId = this._vvlRecommendedSubscriptionId;
        data.vvlRecommendedHasInsurance = (true === this._vvlRecommendedHasInsurance);

        data.redPlusAllnet = this._redPlusAllnetOfferIds.toString();
        data.redPlusBasic = this._redPlusBasicOfferIds.toString();
        data.redPlusData = this._redPlusDataOfferIds.toString();
        data.redPlusKids = this._redPlusKidsOfferIds.toString();
        data.optionalServices = this._optionalServiceIds.toString();
        data.accessory = this._accessoryIds.toString();

        data.customer = this._customer.toString();

        return data;

    }

    public toString (): string {

        return JSON.stringify(
            this.mapFlowToStringableObject()
        );

    }

    public saveToStorage (): void {
        this.getStorage().setItem(
            this._storageName,
            this.toString()
        );

    }

    private parseToInt (value: any): number {

        value = parseInt(value, 10);

        if (true === isNaN(value)) {
            value = undefined;
        }

        return value;

    }

    protected mapJsonFromStringToFlowState (stored: any): void {

        this._subscriptionsFirst = (true === stored.subscriptionsFirst);
        this._devicesFirst = (true === stored.devicesFirst);
        this._deviceFirst = (true === stored.deviceFirst);

        this._subscriptionLocked = (true === stored.subscriptionLocked);
        this._deviceLocked = (true === stored.deviceLocked);
        this._hardwareOnly = (true === stored.hardwareOnly);

        this._subscriptionGroup = stored.subscriptionGroup;
        this._subscriptionIds = {
            consumer: this.parseToInt(stored.consumerId),
            young: this.parseToInt(stored.youngId),
            easy: this.parseToInt(stored.easyId),
            soho: this.parseToInt(stored.sohoId),
            familyfriends: this.parseToInt(stored.familyfriendsId)
        };

        this._atomicDeviceId = this.parseToInt(stored.atomicDeviceId);
        this._simcardId = this.parseToInt(stored.simcardId);
        this._simcardAtomicId = stored.simcardAtomicId;

        this._offerId = stored.offerId;

        this._vvlFirstVisit = stored.vvlFirstVisit;
        this._vvlRecommendedAtomicId = stored.vvlRecommendedAtomicId;
        this._vvlRecommendedSubscriptionId = stored.vvlRecommendedSubscriptionId;
        this._vvlRecommendedHasInsurance = stored.vvlRecommendedHasInsurance;

        if ('' !== stored.redPlusAllnet) {
            this._redPlusAllnetOfferIds = new FlowArrayHelper(this, stored.redPlusAllnet);
        }
        if ('' !== stored.redPlusBasic) {
            this._redPlusBasicOfferIds = new FlowArrayHelper(this, stored.redPlusBasic);
        }
        if ('' !== stored.redPlusData) {
            this._redPlusDataOfferIds = new FlowArrayHelper(this, stored.redPlusData);
        }
        if ('' !== stored.redPlusKids) {
            this._redPlusKidsOfferIds = new FlowArrayHelper(this, stored.redPlusKids);
        }
        if ('' !== stored.optionalServices) {
            this._optionalServiceIds = new FlowArrayHelper(this, stored.optionalServices, 'number');
        }
        if ('' !== stored.accessory) {
            this._accessoryIds = new FlowArrayHelper(this, stored.accessory, 'number');
        }

        if ('' !== stored.customer) {
            const customerJSON = JSON.parse(stored.customer);
            this._customer.fromFlow(customerJSON);
        }

    }

    public loadFromStorage (): void {
        let stored: any = this.getStorage().getItem(this._storageName);

        if (null === stored) {
            return;
        }

        stored = JSON.parse(stored);

        this.mapJsonFromStringToFlowState(stored);

    }

    public getHardwareOnly (): boolean {

        if (undefined === this._hardwareOnly) {
            return false;
        }

        return this._hardwareOnly;
    }

    /**
     * if param hardwareOnly == false, the new SubscriptionId has to be filled, otherwise it should be 'undefined'
     */
    public setHardwareOnly (hardwareOnly: boolean, newSubscriptionId?: number): void {

        this._hardwareOnly = hardwareOnly;

        // if hardwareonly -> it should be applied over all subscription groups
        if (true === hardwareOnly) {

            this._subscriptionIds.consumer = undefined;
            this._subscriptionIds.young = undefined;
            this._subscriptionIds.easy = undefined;
            this._subscriptionIds.soho = undefined;

        }
        else {

            // @TODO Not sure for what newSubscriptionId is needed ;)
            // but kept it here by 1) set all to undefined and then set newSupscriptionId int current group
            this._subscriptionIds.consumer = undefined;
            this._subscriptionIds.young = undefined;
            this._subscriptionIds.easy = undefined;
            this._subscriptionIds.soho = undefined;

            this.setSubscriptionId(newSubscriptionId, true);
        }

        this.saveToStorage();

        this._event.trigger('subscription@changed', {
            subscriptionId: newSubscriptionId,
            hardwareOnly: hardwareOnly
        });

    }

    public getSubscriptionGroup (): SubscriptionGroupName {

        return this._subscriptionGroup;

    }

    public setSubscriptionGroup (subscriptionGroup: SubscriptionGroupName, suppressEvent = false): void {

        // As a rule of thumb - if nothing changed: Change nothing
        if (subscriptionGroup === this._subscriptionGroup) {
            return;
        }

        this._subscriptionGroup = subscriptionGroup;
        this.resetDedicatedFlowData(true); // INC-14843
        this.saveToStorage();

        if (true === suppressEvent) {
            return;
        }

        // @TODO Trigger events here is very implicit
        this._event.trigger('subscriptionGroupName@changed', subscriptionGroup);

    }

    public setRecommendedAtomicId (atomicId: number): void {
        this._vvlRecommendedAtomicId = atomicId;
        this.saveToStorage();
    }

    public getRecommendedAtomicId (): number {

        if (undefined === this._vvlRecommendedAtomicId) {
            return undefined;
        }

        return this._vvlRecommendedAtomicId;
    }

    public setRecommendedSubscriptionId (subscriptionId: number): void {
        this._vvlRecommendedSubscriptionId = subscriptionId;
        this.saveToStorage();
    }

    public getRecommendedSubscriptionId (): number {

        if (undefined === this._vvlRecommendedSubscriptionId) {
            return undefined;
        }

        return this._vvlRecommendedSubscriptionId;
    }

    public setRecommendedInsurance (hasInsurance: boolean): void {
        this._vvlRecommendedHasInsurance = hasInsurance;
        this.saveToStorage();
    }

    public getRecommendedInsurance (): boolean {

        if (undefined === this._vvlRecommendedHasInsurance) {
            return false;
        }

        return this._vvlRecommendedHasInsurance;
    }

    public setVvlFirstVisit (firstVisit: boolean): void {
        this._vvlFirstVisit = firstVisit;
        this.saveToStorage();
    }

    public getVvlFirstVisit (): boolean {

        if (undefined === this._vvlFirstVisit) {
            return true;
        }

        return this._vvlFirstVisit;
    }

    public getSubscriptionId (): number {

        if (undefined === this.getSubscriptionGroup()) {
            return undefined;
        }

        return this._subscriptionIds[this.getSubscriptionGroup()];

    }

    public setSubscriptionId (subscriptionId: number, suppressEvent = false): void {

        // As a rule of thumb - if nothing changed: Change nothing
        if (subscriptionId === this.getSubscriptionId()) {
            return;
        }

        if (undefined !== subscriptionId) {
            this._hardwareOnly = false;
        }

        if (Constants.Black_Id === subscriptionId) {
            this.resetRedPlusFlowData();
        }

        this._subscriptionIds[this.getSubscriptionGroup()] = subscriptionId;

        this.saveToStorage();

        if (true === suppressEvent) {
            return;
        }

        this._event.trigger('subscription@changed', {
            subscriptionId: subscriptionId,
            hardwareOnly: this._hardwareOnly
        });

    }

    public clearSubscriptionId (suppressEvent = false): void {

        for (const groupName in this._subscriptionIds) {
            this._subscriptionIds[groupName] = undefined;
        }

        this.saveToStorage();

        if (true === suppressEvent) {
            return;
        }

        this._event.trigger('subscription@changed', {
            subscriptionId: undefined,
            hardwareOnly: this._hardwareOnly
        });

    }

    public getAtomicDeviceId (): number {
        return this._atomicDeviceId;
    }

    public setAtomicDeviceId (atomicDeviceId: number, suppressEvent = false): void {

        // As a rule of thumb - if nothing changed: Change nothing
        if (atomicDeviceId === this._atomicDeviceId) {
            return;
        }

        this._atomicDeviceId = atomicDeviceId;

        this.saveToStorage();

        if (true === suppressEvent) {
            return;
        }

        this._event.trigger('atomicDeviceId@changed', {
            atomicDeviceId: atomicDeviceId
        });

    }

    public setWatchOffers (watchOffers: WatchOffer[]): void {
        this._watchOffers = watchOffers;
    }

    public getWatchOffers (): WatchOffer[] {
        return this._watchOffers;
    }

    public setSimcardAtomicId (atomicId: number): void {
        this._simcardAtomicId = atomicId;
        this.saveToStorage();
    }

    public getSimcardAtomicId (): number {

        if (undefined === this._simcardAtomicId) {
            return undefined;
        }

        return this._simcardAtomicId;
    }

    public getOfferId (): string {
        return this._offerId;
    }

    public setOfferId (offerId: string): void {
        this._offerId = offerId;
        this.saveToStorage();
    }

    public isSubscriptionLocked (): boolean {
        return this._subscriptionLocked;
    }

    public lockSubscription (): void {
        this._subscriptionLocked = true;
        this.saveToStorage();
    }

    public isDeviceLocked (): boolean {
        return this._deviceLocked;
    }

    public lockDevice (): void {

        this._deviceLocked = true;
        this.saveToStorage();
    }

    /**
     * @TODO Does this lock device/subscription is needed anymore with NSF 2.0???
     */
    public unlockDevice (): void {
        this._deviceLocked = false;
        this.saveToStorage();
    }

    /**
     * Decice Insurance: is OPtional Service id 158  selected
     */
    public hasDeviceInsurance (): boolean {

        let hasInsurance = false;

        for (const id of this._optionalServiceIds.elements) {
            if (158 === id || 159 === id) {
                hasInsurance = true;
            }
        }

        return hasInsurance;
    }

    /**
     * Has this flow started with subscription overview
     */
    public isSubscriptionFirstFlow (): boolean {
        return this._subscriptionsFirst;
    }

    /**
     * Has this flow started with devices overview
     */
    public isDevicesFirstFlow (): boolean {
        return this._devicesFirst;
    }

    /**
     * Has this flow started with device detail
     */
    public isDeviceFirstFlow (): boolean {
        return this._deviceFirst;
    }

    /**
     * Has this flow started with watch first
     */
    public isWatchFirstFlow (): boolean {
        return this._watchFirst;
    }

    /**
     * Has this flow started with Red+ Device first
     */
    public isRedplusDeviceFirstFlow (): boolean {
        return this._redplusDeviceFirst;
    }

    /**
     * Has this flow started with Red+ Tariff first
     */
    public isRedPlusTariffFirstFlow (): boolean {
        return this._redPlusTariffFrirst;
    }

    public setCustomer (customer: Customer) {
        this._customer = customer;
        this.saveToStorage();
    }

    public getCustomer (): Customer {

        return this._customer;

    }

    /**
     * removes special data that bases on tariff-device combination
     */
    public resetDedicatedFlowData (withRedPlus: boolean): void {

        this._optionalServiceIds.resetAllElements();

        this._accessoryIds.resetAllElements();

        if (withRedPlus) {

            this.resetRedPlusFlowData();

        }

        this.saveToStorage();

    }

    /**
     * removes special data that bases on tariff-device combination
     */
    public resetRedPlusFlowData (): void {

        this._redPlusAllnetOfferIds.resetAllElements();

        this._redPlusBasicOfferIds.resetAllElements();

        this._redPlusDataOfferIds.resetAllElements();

        this._redPlusKidsOfferIds.resetAllElements();

    }

    get redPlusAllnet (): FlowArrayHelper {
        return this._redPlusAllnetOfferIds;
    }

    get redPlusData (): FlowArrayHelper {
        return this._redPlusDataOfferIds;
    }

    get redPlusKids (): FlowArrayHelper {
        return this._redPlusKidsOfferIds;
    }
    get redPlusBasic (): FlowArrayHelper {
        return this._redPlusBasicOfferIds;
    }
    get optionalServiceIds (): FlowArrayHelper {
        return this._optionalServiceIds;
    }

    get accessoryIds (): FlowArrayHelper {
        return this._accessoryIds;
    }

    get customer (): Customer {
        return this._customer;
    }

    /**
     * OrderType can be "voice" or "data"
     * @param order_type
     */
    private isValidOrderType (order_type: string): boolean {
        return (Constants.OrderType_Voice === order_type || Constants.OrderType_Fixed === order_type);
    }

    /**
     * Find out, if orderType is set in CMS __NSF__Init
     */
    private detectOrderType (): void {

        /**
         * set initial ordertype (voice or data)
         * default is voice (if not set via page option)
         */

        if (undefined === this._orderType) {

            this._orderType = Constants.OrderType_Voice;

            const orderTypeFromPage: string = this.getOptions().get('order_type');

            if (true === this.isValidOrderType(orderTypeFromPage)) {
                this._orderType = orderTypeFromPage as OrderType;
            }

        }

    }

    public getOrderType (): OrderType {
        return this._orderType;
    }

}
