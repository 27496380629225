/**
 * @module SalesFlow/view
 */

import AtomicDevice from 'model/type/atomic-device';

interface AttributeQuery {
    [index: string]: any;

    color: string;
    internalMemory: number;
    bundledWith?: string;
}

class Bundle {

    private _value: string;

    constructor (atomicDevice: AtomicDevice) {
        this._value = atomicDevice.attr.bundledWith;
    }

    get value (): string {
        return this._value;
    }

}

class ViewBundle {

    private _bundle: Bundle;
    private _isSelected: boolean = false;
    private _atomicDeviceId: number = undefined;
    private _atomicDeviceIdNoBundle: number = undefined;

    constructor (bundle: Bundle) {
        this._bundle = bundle;
    }

    public setSelected (selected: boolean): void {
        this._isSelected = selected;
    }

    public setAtomicDeviceId (id: number): void {
        this._atomicDeviceId = id;
    }

    public setAtomicDeviceIdNoBundle (id: number): void {
        this._atomicDeviceIdNoBundle = id;
    }

    public isSelected (): boolean {
        return this._isSelected;
    }

    get name (): string {
        return this._bundle.value;
    }

    get selected (): string {
        if (false === this._isSelected) {
            return '';
        }

        return 'selected';

    }

    get atomicDeviceId (): number {
        return this._atomicDeviceId;
    }

    get atomicDeviceIdNoBundle (): number {
        return this._atomicDeviceIdNoBundle;
    }

}

/**
 * @internal
 */
export default class ViewBundles {

    private _bundles: ViewBundle[] = [];

    constructor (atomicDevice: AtomicDevice) {

        const bundles: Bundle[] = this.setBundles(atomicDevice);
        this._bundles = this.setViewBundles(atomicDevice, bundles);

    }

    private uniqueBundles (bundles: Bundle[]): Bundle[] {

        const uniqueBundles: Bundle[] = [];

        const unique: any = {};

        for (const bundle of bundles) {
            if (true === unique[bundle.value]) {
                continue;
            }

            uniqueBundles.push(bundle);
            unique[bundle.value] = true;

        }

        return uniqueBundles;
    }

    private sortBundles (bundle: Bundle[]): void {

        bundle.sort((a: Bundle, b: Bundle) => {
            const nameA = a.value;
            const nameB = b.value;
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            return 0;
        });

    }

    /**
     * Map bundles with atomicDevices with the very same size and viewColor
     */
    private setViewBundles (atomicDevice: AtomicDevice, bundles: Bundle[]): ViewBundle[] {

        const viewBundles: ViewBundle[] = bundles.map((bundle) => {
            return new ViewBundle(bundle);
        });

        const attributeQuery: AttributeQuery = {
            color: atomicDevice.attr.color,
            internalMemory: atomicDevice.attr.internalMemory
        };

        const atomicDevicesWithSameAttr = atomicDevice.device.getAtomicDevicesByAttrs(attributeQuery);

        for (const atomicDeviceWithSameAttr of atomicDevicesWithSameAttr) {

            for (const viewBundle of viewBundles) {

                if (viewBundle.name !== atomicDeviceWithSameAttr.attr.bundledWith) {
                    continue;
                }

                if (undefined !== viewBundle.atomicDeviceId) {
                    continue;
                }
                if (undefined === viewBundle.name) {
                    viewBundle.setAtomicDeviceIdNoBundle(atomicDeviceWithSameAttr.id);
                } else {
                    viewBundle.setAtomicDeviceId(atomicDeviceWithSameAttr.id);
                }

            }
        }

        for (const viewBundle of viewBundles) {

            if (viewBundle.name !== atomicDevice.attr.bundledWith) {
                continue;
            }

            viewBundle.setSelected(true);
            break;

        }

        return viewBundles;

    }

    /**
     * Get bundles from all atomicDevices
     */
    private setBundles (atomicDevice: AtomicDevice): Bundle[] {

        let bundles: Bundle[] = [];

        bundles = atomicDevice.getDevice().getAtomicDevices().map((atomicDevice) => {
            return new Bundle(
                atomicDevice
            );

        });

        bundles = this.uniqueBundles(bundles);

        /**
         * in this case, we have no bundles
         */
        if (1 === bundles.length && undefined === bundles[0].value) {
            return [];
        }

        this.sortBundles(bundles);

        return bundles;

    }

    get all (): ViewBundle[] {
        return this._bundles;
    }

    get selected (): ViewBundle {

        for (const bundle of this._bundles) {
            if (bundle.isSelected()) {
                return bundle;
            }
        }

        return undefined;
    }

}
