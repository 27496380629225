/**
 * @module SalesFlow/evolved/router
 */
import Injector from 'core/injector';

import {RouterEvolvedBntRouter} from 'router-evolved/bnt/router-evolved--bnt-router';
import {SkeletonService} from '../../service/render/skeleton-service';

import GeneralSalesObjectInterface from 'service/general-sales-object/general-sales-object-interface';
import {SalesChannelName, BusinessTransactionContext} from 'core/ids';
import { Constants } from 'core/constants';

export abstract class RouterEvolvedBntFafRouter extends RouterEvolvedBntRouter {

    protected _skeletonService: SkeletonService;

    constructor (injector: Injector, skeletonService?: SkeletonService) {

        super (injector);

    }

    /**
     * All three different vvl controller are needing the some data
     * Expect that X-Sell page does not need attributes.
     */
    protected loadVluxDataIntoReposSupervisor (): void {

        const salesChannel: SalesChannelName = this.getSalesChannel();
        const btx: BusinessTransactionContext = this.getInjector().getBtx();
        const generalSalesObjectInterface: GeneralSalesObjectInterface = this.getInjector().getGeneralSalesObjectInterface();

        /**
         * get the general configuration from Mobile Editor
         */
        this.getReposSupervisor().loadPurchasableDeviceRepo(btx, salesChannel, generalSalesObjectInterface, Constants.FamilyFriendsType);
        this.getReposSupervisor().loadAttributeRepo();
        this.getReposSupervisor().loadDependencyRepo();
    }

}
