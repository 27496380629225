import Customer from 'shopbackend/customer';
import { SalesChannelName } from 'core/ids';
import { Constants } from 'core/constants';

/**
 * [CO-9456] Helper class for the nba call.
 * Checks the setted flags for gigakombi customer and winback customers and makes sure to show and hide the right text
 */
export default class NbaFlagCheck {

    public constructor (protected customer: Customer, protected salesChannel: SalesChannelName) {
        this.customer = customer;
        this.salesChannel = salesChannel;
    }

    /**
     * Checks if the sales channel or customer type is family and friends.
     */
    public isFamilyAndFriends (): boolean {
        return (this.salesChannel === Constants.SALESCHANNEL_FAMILY_FRIENDS || this.customer.hasFF);
    }

    /**
     * Checks the flag for winback and SOHO winback customers and removes the default elements for headline and promo badge.
     * Winback customers have higher priority, when both gigakombi and winback are true.
     * The 3 classes live in the CMS.
     */
    public updateViewForIdentifiedUser (): void {

        if (this.customer.isWinbackCustomer && !this.isFamilyAndFriends()) {

            /* Special case for winback SOHO */
            if (this.customer.isSohoCustomer) {
                $('.personalized-flow[data-flag-winback="true"]').remove();
            }
            else if (!this.customer.isSohoCustomer) {
                $('.personalized-flow[data-flag-SOHO-winback="true"]').remove();
            }
            $('.default-flow').remove();

        }

        /**
         * Default vvl customers, with false winback, with or without a gigakombi flag set to true
         * because no changes are required for gigakombi in headline and promo badge.
         */
        else {
            /* Family and friends is excluded from winback or gigakombi personalized flow.*/
            if (!this.isFamilyAndFriends()) {
                $('.personalized-flow[data-flag-SOHO-winback="true"],.personalized-flow[data-flag-winback="true"]').remove();
            }

            /**
             * If customer is not winback removes the winback voucher reminder green notification
             */
            this.handleWinbackVoucherNotification();

        }
    }

    /**
     * Removes Winback notification
     */
    private handleWinbackVoucherNotification (): void {
        $('.winback-voucher-reminder').remove();
    }
}
