/**
 * @module SalesFlow/evolved/router
 */

import Injector from 'core/injector';

import {RouterEvolvedVvlRouter} from 'router-evolved/vvl/router-evolved--vvl--router';
import {ControllerEvolvedFlowBase} from 'controller-evolved/controller-evolved--flow-base';
import {ControllerEvolvedVvlXSell} from 'controller-evolved/vvl/controller-evolved--vvl--x-sell';
import {SkeletonService} from '../../service/render/skeleton-service';
import {Constants} from 'core/constants';
import GeneralSalesObjectInterface from 'service/general-sales-object/general-sales-object-interface';
import {SalesChannelName, BusinessTransactionContext} from 'core/ids';

export class RouterEvolvedVvlXSell extends RouterEvolvedVvlRouter {

    protected _skeletonService: SkeletonService;

    constructor (injector: Injector, skeletonService?: SkeletonService) {

        super (injector);

        if (undefined === skeletonService) {
            skeletonService = new SkeletonService(injector);
        }

        this._skeletonService = skeletonService;
    }

    /**
     * Overwrite method here because
     * Cross sales page should always get it's sales channel from storage
     * @TODO What should happen, when not sales channel is in storage
     */
    protected setSalesChannel (): void {

        let salesChannel: SalesChannelName;

        const salesChannelFromStorage: string = this.getInjector().getFlowStateWithSalesChannel().getSalesChannel();

        if (undefined !== salesChannelFromStorage) {

            if (Constants.SALESCHANNEL_CONSUMER === salesChannelFromStorage || Constants.SALESCHANNEL_YOUNG === salesChannelFromStorage) {
                salesChannel = salesChannelFromStorage;
            }

        }

        this._salesChannel = salesChannel;

    }

    /**
     * All three different vvl controller are needing the some data
     * Expect that X-Sell page does not need attributes.
     */
    protected loadVluxDataIntoReposSupervisor (): void {

        const salesChannel: SalesChannelName = this.getSalesChannel();
        const btx: BusinessTransactionContext = this.getInjector().getBtx();
        const generalSalesObjectInterface: GeneralSalesObjectInterface = this.getInjector().getGeneralSalesObjectInterface();

        this.getReposSupervisor().loadPurchasableDeviceRepo(btx, salesChannel, generalSalesObjectInterface);
        this.getReposSupervisor().loadDependencyRepo();
    }

    /**
     * On x sell page the only incoming subscription is from storgae
     */
    public getIncomingSubscriptionIds (): number[] {

        // [Storage]
        const incomingSubscriptionIds: number[] = [];

        const subscriptionIdFromStorage: number = this.getInjector().getFlowStateWithSalesChannel().getSubscriptionId();

        if (undefined !== subscriptionIdFromStorage) {
            incomingSubscriptionIds.push(subscriptionIdFromStorage);
        }

        return incomingSubscriptionIds.reverse();

    }

    public getIncomingDeviceIds (): number[] {

        // [Storage]
        const incomingDeviceIds: number[] = [];

        const deviceIdFromStorage: number = this.getInjector().getFlowStateWithSalesChannel().getAtomicDeviceId();

        if (undefined !== deviceIdFromStorage) {
            incomingDeviceIds.push(deviceIdFromStorage);
        }

        return incomingDeviceIds.reverse();

    }

    public resolveDeviceId (incomingDeviceIds: number[]): number {

        const subscriptionId = this.resolveSubscriptionId(this.getSubscriptionIds());

        const subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(
            subscriptionId
        );

        // are incomingAtomicDeviceIds purchasable with subscription
        const validatedDAtomicDeviceIds = incomingDeviceIds.filter((deviceId) => {

            const atomicDevice = this.getReposSupervisor().getPurchasableDeviceRepo().getAtomicDevice(
                deviceId,
                this.getSalesChannel(),
                subscription
            );

            const salesChannelFromStorage: string = this.getInjector().getFlowStateWithSalesChannel().getSalesChannel();

            const checkAtomicDeviceId = undefined !== this.getReposSupervisor().getPurchasableDeviceRepo().getAtomicDevice(
                deviceId,
                this.getSalesChannel(),
                subscription
            );

            if (true === checkAtomicDeviceId) {
                return true;
            }

            return false;

        });

        if (0 === validatedDAtomicDeviceIds.length) {
            return undefined;
        }

        return validatedDAtomicDeviceIds[0];

    }

    public validateIncoming (): void {

        this._subscriptionId = this.resolveSubscriptionId(
            this.getIncomingSubscriptionIds()
        );

        this._atomicDeviceId = this.resolveDeviceId(
            this.getIncomingDeviceIds()
        );

    }

    public createController (): JQueryPromise<ControllerEvolvedFlowBase> {
        const deferred = $.Deferred<any>();

        this.loadReposSupervisor().then(() => {

            this.validateIncoming();
            this._skeletonService.removeSkeletonLoadingClassNames();

            deferred.resolve(
                new ControllerEvolvedVvlXSell(
                    this.getSalesChannel(),
                    this._atomicDeviceId,
                    this.getSubscriptionId(),
                    this.getReposSupervisor(),
                    this.getInjector()
                )
            );
        }, function () {
            deferred.reject();
        });

        return deferred.promise();
    }
}
