/**
 * @module SalesFlow/core
 */

import Injector from 'core/injector';

/**
 * The new sales flow root object
 */
export default class App {

    constructor (injector: Injector) {

        // @TODO This should be removed
        this.changeSubscriptionMockup();

        // @TODO This is an test todos
        injector.getRouting().route();
    }

    /**
     * @TODO While subscriptions are linked in OpenText everywhere:
     * Here is a little method to change the markup
     * and when contentfreese before going live:
     * Just used 2.0 Content-Class with swapped lines
     *
     */
    private changeSubscriptionMockup (): void {

        $('.tariff-module-tile .price-info.monthly .interval').remove();

        $('.tariff-module-tile[data-subscription-id]').each(function () {

            let discountText = $(this).data('discount-text');

            if (undefined !== $(this).find('.discount').get(0)) {
                discountText = $(this).find('.discount').text();
                $(this).find('.discount').remove();
            }

            if (undefined !== discountText) {
                $(this).attr('data-discount-text', discountText);
            }
        });

    }

}
