/**
 * @module SalesFlow/evolved/router
 */

import InlifeRouting from 'router/inlife/routing';

import Injector from 'core/injector';
import {RouterEvolvedSohoInlifeRecommendation} from 'router-evolved/soho-inlife/router-evolved--soho-inlife-recommendation';
import {RouterEvolvedSohoInlifeTariffSelection} from 'router-evolved/soho-inlife/router-evolved--soho-inlife-tariff-selection';

export class SohoInlifeRouting extends InlifeRouting {

    constructor (injector: Injector) {

        super(injector);

    }

    protected resolveRouter (deferred: JQueryDeferred<any>, currentStep: string) {

        if ('inlife_tariff_selection' === currentStep) {
            deferred.resolve(new RouterEvolvedSohoInlifeTariffSelection(this._injector));
        } else {
            deferred.resolve(new RouterEvolvedSohoInlifeRecommendation(this._injector));
        }

    }

}
