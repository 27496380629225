/**
 * @module SalesFlow/model/repo
 */

import { Constants } from 'core/constants';

import NsfError from 'core/error';
import {Repo} from './repo';
import Subscription from '../type/subscription';
import SubscriptionGroup from '../type/subscription-group';

interface IdMapping {
    [index: number]: number;
}

export default class SubscriptionRepo extends Repo {

    private _subscriptionGroupIdMapping: IdMapping = {};
    private _subscriptionIdMapping: IdMapping = {};
    private _subscriptionSubIdMapping: IdMapping = {};

    private _subscriptionGroups: SubscriptionGroup[] = [];

    /**
     * @TODO create an interface for products json
     */
    constructor (data: any) {
        super();
        this.rawData = data;
        this.parseData(data);
    }

    private isSupportedSubscriptionGroup (subscriptionGroupId: string): boolean {

        const id = parseInt(subscriptionGroupId, 10);

        if (Constants.Red_Group_Id === id) {
            return true;
        }

        if (Constants.Red_New_Subs_Group_Id === id) {
            return true;
        }

        if (Constants.Easy_Group_Id === id) {
            return true;
        }

        if (Constants.Black_Group_Id === id) {
            return true;
        }

        if (Constants.Young_Group_Id === id) {
            return true;
        }

        if (Constants.RedPlus_Group_Id === id) {
            return true;
        }

        if (Constants.Unlimited_Group_Id === id) {
            return true;
        }

        if (Constants.Unlimited_2018_Group_Id === id) {
            return true;
        }

        if (Constants.FamilyCard_Group_Id === id) {
            return true;
        }

        return false;

    }

    protected parseData (json: any): NsfError {

        if (undefined === json.subscriptionGroups) {
            return new NsfError('subscriptionGroups is mandatory when create a SubscriptionRepo.');
        }

        if (undefined === json.subscriptionGroups.subscriptionGroup) {
            return new NsfError('productGroups.productGroup is mandatory when create a DeviceRepo.');
        }

        if (undefined === json.subscriptionGroups.subscriptionGroup.length) {
            return new NsfError('productGroups.productGroup must be an array when create a DeviceRepo.');
        }

        if (0 === json.subscriptionGroups.subscriptionGroup.length) {
            return new NsfError('productGroups.productGroup is empty when create a DeviceRepo.');
        }

        const subscriptionGroup = json.subscriptionGroups.subscriptionGroup[0];

        if (undefined === subscriptionGroup.subscriptions) {
            return new NsfError('productGroups.productGroup[0].products is empty when create a DeviceRepo.');
        }

        if (undefined === subscriptionGroup.subscriptions.subscription) {
            return new NsfError('productGroups.productGroup[0].products.product is empty when create a DeviceRepo.');
        }

        const subscriptionsGroup = json.subscriptionGroups.subscriptionGroup[0].subscriptions.subscription;

        for (const subscriptionGroup of subscriptionsGroup) {

            if (false === this.isSupportedSubscriptionGroup(subscriptionGroup.id)) {
                continue;
            }
            this._subscriptionGroups.push(new SubscriptionGroup(subscriptionGroup));
        }

        for (let i = 0, x = this._subscriptionGroups.length; i < x; i += 1) {
            const subscriptionGroup = this._subscriptionGroups[i];

            this._subscriptionGroupIdMapping[subscriptionGroup.id] = i;

            const subscriptions = subscriptionGroup.getSubscriptions();
            for (const subscription of subscriptions) {

                this._subscriptionIdMapping[subscription.id] = subscriptionGroup.id;

                for (const subsidization of subscription.getSubsidizations()) {

                    this._subscriptionSubIdMapping[subsidization.id] = subscription.id;

                }

            }
        }

        return undefined;

    }

    public getSubscriptionGroupIds (subscriptionGroupName: string): number[] {

        if ('young' === subscriptionGroupName || 'young_redxl' === subscriptionGroupName) {
            return [Constants.Young_Group_Id];
        }

        if ('easy' === subscriptionGroupName) {
            return [Constants.Easy_Group_Id];
        }

        /**
         * This return is normally for the 'consumer' group
         * We return only Red_Group as Red XL is no longer a seprate group from Red portfolio 2019
         */
        return [Constants.Red_Group_Id];

    }

    /**
     * if subscriptionId is "Sub" Tariff Id, then return the belonging "Main" Tariff Id
     */
    public getSubscriptionMainId (id: number): number {

        if (undefined !== this._subscriptionSubIdMapping[id]) {
            id = this._subscriptionSubIdMapping[id];
        }

        return id;
    }

    /**
     * @TOOD filter is more readable the this IdMapping stuff
     */
    public getSubscription (id: number): Subscription {

        let subscriptionMapping = this._subscriptionIdMapping[id];

        if (undefined !== subscriptionMapping) {

            /**
             * Parameter is "Main" Tariff Id
             */
            const groupMapping = this._subscriptionGroupIdMapping[subscriptionMapping];

            return this._subscriptionGroups[groupMapping].getSubscription(id);

        } else {

            /**
             * Parameter is "Sub" Tariff Id
             */

            const subscriptionParentId = this._subscriptionSubIdMapping[id];

            if (undefined === subscriptionParentId) {

                /**
                 * in this case a completely unknow subscriptionId was given
                 */
                return undefined;

            }

            subscriptionMapping = this._subscriptionIdMapping[subscriptionParentId];

            const groupMapping = this._subscriptionGroupIdMapping[subscriptionMapping];

            return this._subscriptionGroups[groupMapping].getSubscription(subscriptionParentId);

        }

    }

    /**
     * returns all Subscriptions for the given group
     * e.g. "All Red+ Tariffs", "All Juno Tariffs"
     */
    public getSubscriptions (groupIds: number[], subscriptionIdsToIgnore: number[] = []): Subscription[] {

        // Get subscription group mapping indexes
        const subscriptionGroupIdMappingIndexes: number[] = [];

        for (const groupsIdFiltered of groupIds) {
            subscriptionGroupIdMappingIndexes.push(this._subscriptionGroupIdMapping[groupsIdFiltered]);
        }

        // Get all available subscriptions for all mapping indexes
        let subscriptions: Subscription[] = [];

        for (const subscriptionGroupIdMappingIndex of subscriptionGroupIdMappingIndexes) {
            const subscriptionsForGivenIndex = this._subscriptionGroups[subscriptionGroupIdMappingIndex].getSubscriptions();
            subscriptions = subscriptions.concat(subscriptionsForGivenIndex);
        }

        // Filter out subscriptions which should not be sold
        const subscriptionsFiltered = subscriptions.filter(subscription => {
            return subscriptionIdsToIgnore.indexOf(subscription.id) < 0;
        });

        return subscriptionsFiltered;

    }

    /**
     * returns the subscription Id of the tariff from corresponding kias Id
     *
     */
    public getSubscriptionIdByKiasId (kiasId: string): number {

        for (const subscriptionGroup of this._subscriptionGroups) {

            for (const subscription of subscriptionGroup.getSubscriptions()) {

                for (const subsidizations of subscription.getSubsidizations()) {

                    if (subsidizations.backendId === kiasId) {
                        return subscription.id;
                    }

                }

            }

        }

        return undefined;

    }

    /**
     * returns the subscription Id of the tariff from corresponding kias Id
     *
     */
    public getAtomicSubscriptionIdByKiasId (kiasId: string): number {

        for (const subscriptionGroup of this._subscriptionGroups) {

            for (const subscription of subscriptionGroup.getSubscriptions()) {

                for (const subsidizations of subscription.getSubsidizations()) {

                    if (subsidizations.backendId === kiasId) {
                        return subsidizations.id;
                    }

                }

            }

        }

        return undefined;

    }

    /**
     * returns the sublevel of the tariff from corresponding subsidization Id
     *
     */
    public getSubLevelBySubsidizationId (id: number): number {

        for (const subscriptionGroup of this._subscriptionGroups) {

            for (const subscription of subscriptionGroup.getSubscriptions()) {

                for (const subsidization of subscription.getSubsidizations()) {

                    if (id === subsidization.id) {
                        return subsidization.subLevel;
                    }
                }
            }

        }

        return undefined;
    }

}
