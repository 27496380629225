/**
 * @module SalesFlow/view
 */

declare var $: JQueryStatic;
import Injector from 'core/injector';

import {Renderable} from '../../renderable';

import ViewStopper from '../../view/shared/stopper';

export default class Stopper extends Renderable<ViewStopper> {

    private _element: JQuery;

    constructor (injector: Injector) {

        super(injector);

        this._element = $('#nsf-stopper');

    }

    public render (viewStopper: ViewStopper): void {

        this._element.html(this.getInjector().getTemplates().render('stopper', viewStopper, 'partials'));

        const subscriptionId = viewStopper.subscriptionId;
        const subscriptionGroupId = viewStopper.subscriptionGroupId;
        const hardwareOnly = viewStopper.hardwareOnly;

        $('.nsf-stopper-promo-module').hide();

        if (true === hardwareOnly) {

            $('.nsf-stopper-promo-module[data-valid-for="hwonly"]').show();

        } else {

            $('.nsf-stopper-promo-module[data-valid-for="tariffid-' + subscriptionId + '"]').show();

            /** kein spezieller für Tarif definiert, vielleicht für die Tarifgruppe?  */
            if (0 === $('.nsf-stopper-promo-module:visible').length) {
                $('.nsf-stopper-promo-module[data-valid-for="tariffgroupid-' + subscriptionGroupId + '"]').show();

            }

        }

        if (0 === $('.nsf-stopper-promo-module:visible').length) {
            $('.nsf-stopper-promo-module[data-valid-for="all"]').show();
        }

    }

    public events (viewStopper: ViewStopper): void {

        this._element.on('click', 'a[href*="#vfpre"]:not([href="#"])', (evt) => {

            const defaultSprungMarke = 'nsf-promo-module-link';

            const tHash = $(evt.currentTarget).data('hash');

            let hash = $(evt.currentTarget).attr('href').replace('vfpre', '');

            if ('#' === hash) {
                if (0 === tHash.length) {
                    hash += defaultSprungMarke;
                } else {
                    hash += tHash;
                }
            }

            // remove Global Navigation height from anchor
            let gnHeight = 0;
            if (null !== $('.mod-glnav').find('.main-nav-wrapper').height()) {
                gnHeight = $('.mod-glnav').find('.main-nav-wrapper').height();
            }

            const target = $(hash);
            let firstVisiblePromo;

            // If the promo teaser has display none, scroll won't work. So we scroll to the first visible promo teaser
            if (target.css('display') === 'none') {
                firstVisiblePromo = target.siblings('#nsf-stopper-promo-modules');
            } else {
                firstVisiblePromo = target;
            }

            if (firstVisiblePromo.length) {
                $('html,body').animate({
                    scrollTop: firstVisiblePromo.offset().top - gnHeight
                }, 800);

                return false;
            }

            return false;

        });

    }

    public bind (viewStopper: ViewStopper): void {

        this.render(viewStopper);
        this.events(viewStopper);

    }

}
