/**
 * @module SalesFlow/view
 */

export class FlowCta {

    private _classNames: string[] = ['btn'];
    private _text: string = '';
    private _href: string = '';
    private _notification: string = '';
    private _disabled: boolean = false;
    private _hidden: boolean = false;

    constructor (
        classNames: string[],
        text: string
    ) {

        this._classNames = this._classNames.concat(classNames);
        this._text = text;
    }

    /**
     * Get jQuery selector based on it's classes
     *
     * @returns {string}
     * @memberof FlowCta
     */
    public getSelector (): string {
        return this._classNames.map((className: string) => { return '.' + className; }).join('');
    }

    public get classNames (): string {
        return this._classNames.join(' ');
    }

    public get ctaText (): string {
        return this._text;
    }

    public setHref (href: string): void {
        this._href = href;
    }

    public get href (): string {
        return this._href;
    }

    public setNotification (notification: string): void {
        this._notification = notification;
    }

    public get notification (): string {
        return this._notification;
    }

}
