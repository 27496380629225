/**
 * @module SalesFlow/model/repo
 */

import NsfError from 'core/error';
import {Repo} from 'model/repo/repo';

import Accessory from 'model/type/accessory';

interface IdMapping {
    [index: number]: number;
}

interface AtmicIdMapping {
    [index: number]: number[];
}

export default class AccessoryRepo extends Repo {

    private _deviceIdMapping: IdMapping = {};
    private _atomicDeviceIdMapping: AtmicIdMapping = {};

    private _accessories: Accessory[] = [];

    constructor (json: any) {
        super();
        this.rawData = json;
        this.parseData(json);
    }

    protected parseData (json: any): NsfError {

        if (undefined === json.productGroups) {
            return new NsfError('productGroups is mandatory when create a AccessoryRepo.');
        }

        if (undefined === json.productGroups.productGroup) {
            return new NsfError('productGroups.productGroup is mandatory when create a AccessoryRepo.');
        }

        if (undefined === json.productGroups.productGroup.length) {
            return new NsfError('productGroups.productGroup must be an array when create a AccessoryRepo.');
        }

        if (0 === json.productGroups.productGroup.length) {
            return new NsfError('productGroups.productGroup is empty when create a AccessoryRepo.');
        }

        const productGroup = json.productGroups.productGroup[0];

        if (undefined === productGroup.products) {
            return new NsfError('productGroups.productGroup[0].products is empty when create a AccessoryRepo.');
        }

        if (undefined === productGroup.products.product) {
            return new NsfError('productGroups.productGroup[0].products.product is empty when create a AccessoryRepo.');
        }

        if (undefined === productGroup.products.product.length) {
            return new NsfError('productGroups.productGroup[0].products.product must be an array when create a AccessoryRepo.');
        }

        const accessory: any = productGroup.products.product;

        this._accessories = accessory.map((accessory: any) => {
            return new Accessory(accessory);
        });

        // fetch only devices with at leat one atomicDevice
        this._accessories = this._accessories.filter((accessory: Accessory) => {
            return 0 !== accessory.getAtomicAccessories().length;
        });

        for (let i: number = 0, x = this._accessories.length; i < x; i += 1) {
            this._deviceIdMapping[this._accessories[i].id] = i;

            const atomicDevices = this._accessories[i].getAtomicAccessories();

            for (let j: number = 0, y = atomicDevices.length; j < y; j += 1) {

                this._atomicDeviceIdMapping[atomicDevices[j].id] = [i, j];

            }

        }

        return undefined;

    }

    public getAccessories (): Accessory[] {
        return this._accessories;
    }

    public getAccessory (id: number): Accessory {

        const accessory: Accessory[] = this._accessories.filter((accessory) => {
            return (id === accessory.id);
        });

        if (0 === accessory.length) {
            return undefined;
        }

        return accessory[0];
    }

}
