/**
 * [WFKT-1405] Logic for Gigakombi customers for launch portfolio 2024
 * For Gk young, customers, Soho VVl, Soho Gk users.
 */

import { Constants } from 'core/constants';
import {
    NotificationBox,
    NotificationType,
    NotificationDataForHBS
} from '../notification-box';

import Injector from 'core/injector';
import { GigakombiVorteilName } from 'core/ids';

export class NotificationGigaKombiCase extends NotificationBox {

    protected _notificationType: NotificationType;
    protected _dataForNotificationHBS: NotificationDataForHBS;
    protected _notificationContent: string;
    protected _CSSSelectorAsAnchorInDOM: string;

    protected _additionalCSSClassForStyling: string;
    protected _notificationHeadline: string;
    protected _selector: string;
    protected _selectedSubscriptionId: number;
    protected _isGigaKombiCustomer: boolean;
    protected _gigakombiCase: GigakombiVorteilName;
    protected _isTesting: boolean;
    constructor (
        injector: Injector,
        selector: string,
        isGigaKombiCustomer: boolean,
        gigakombiCase: GigakombiVorteilName
        ) {
        super(
            injector
        );
        this._selector = selector;
        this._isGigaKombiCustomer = isGigaKombiCustomer;
        this._gigakombiCase = gigakombiCase;
        this._toggleViewIdentifier = 'notification-gk-case';
        this.setNotificationOptions();
        this.setNotificationText();
        this.setNotificationDataForHBS();
    }

    public set selectedSubscriptionId (selectedSubscriptionId: number) {
        this._selectedSubscriptionId = selectedSubscriptionId;
    }

    protected setNotificationOptions () {
        this._CSSSelectorAsAnchorInDOM = this._selector;
        this._notificationType = Constants.gkNotificationData[this._gigakombiCase] ?  Constants.gkNotificationData[this._gigakombiCase].notificationType : undefined;
        this._additionalCSSClassForStyling = '';
    }

    protected setNotificationText () {
        this._notificationHeadline = this.getInjector().getStrings().get(`vvl.switch-to-youngXSorS-notification.headline.${this._gigakombiCase}`);
        this._notificationContent = this.getInjector().getStrings().get(`vvl.switch-to-youngXSorS-notification.content.${this._gigakombiCase}`);
    }

    protected shouldRender (): boolean {
        return this._isGigaKombiCustomer === true && !!Constants.gkNotificationData[this._gigakombiCase];
    }

    protected events (): void {

    }
}
