/**
 * @module SalesFlow/view
 */

import AtomicDevice from 'model/type/atomic-device';

import DeviceOffer from './device-offer';
import ViewAccessoryOffer from '../accessory-offer';

import VluxOffer from 'model/type/offer';

/**
 * @TODO To me clear in class naming: This should be named and imported as FrontendDeviceOffer
 * @TODO Add ViewAccessoryOffer
 */
export default class HardwareOnlyOffer extends DeviceOffer {

    public readonly type: string = 'hardwareonly';

    constructor (
        atomicDevice: AtomicDevice,
        offer: VluxOffer,
        redPlusOffers: DeviceOffer[] = [],
        optionalServices: number[] = [],
        accessoryOffers: ViewAccessoryOffer[] = []
    ) {

        super(atomicDevice, undefined, offer, [], [], accessoryOffers);

    }

    get monthlyDiscountPrice (): number {
        return 0;
    }

    get monthyDiscountHint (): string {
        return '';
    }
}
