/**
 * @module SalesFlow/tracking
 */

declare var _ddq: any;

export type MessageErrorType = 'validation' | 'technical' | 'notification';

export interface ErrorMessageInterface {
    type: MessageErrorType;
    message: string; // max 255 chars
    code?: string;
    location?: string;

}
/**
 * This function can be used to send an error to adobe tracking via adversitments tag implementation
 * Every error send is collected in a dashboard with shared access for INC managment
 * So make sure that trigger this for errors that need an action by INC managment
 *
 * @example
 *  logError({
 *   type: 'technical',
 *   message: 'tariffmobile.json is not valid json. All sales flows affected',
 *   code: 'vlux'
 * });
 */
export const logError = (errorMessage: ErrorMessageInterface) => {

    _ddq.push(['error', errorMessage]);

};
