import {Constants} from 'core/constants';

/**
 * @module SalesFlow/view
 */

declare var $: any;

import { Renderable } from '../../renderable';

import Injector from 'core/injector';
import Offer from 'view/view/shared/offer/offer';

export default class PrivatePricingBadge extends Renderable<Offer> {

    private _elementId: string;
    private _parentElement: JQuery;
    private _offer: Offer;

    constructor (injector: Injector, parentElement: JQuery) {
        super(injector);

        this._parentElement = parentElement;
        this._elementId = '#private-pricing-badge';
    }

    public update (offer: Offer): void {

        if (0 < offer.privatePricingDiscounts.length) {
            if (0 === this._parentElement.find(this._elementId).length) {
                this._parentElement.prepend(
                    this.getInjector().getTemplates().render('private-pricing-badge', this.getInjector().getFlowStateWithSalesChannel().getSalesChannel(), 'partials')
                );
                this.updateCss();
            }
        } else {
            this._parentElement.find(this._elementId).remove();
        }
    }

    public render (offer: Offer): string {
        this.update(offer);

        return '';
    }

    public events (): void {
        this.getInjector().getEvent().listen('offer@changed', (eventObject: JQueryEventObject, data: any) => {
            this._offer = data.offer;
            this.update(data.offer);
        });

        $(window).on('resize', () => {
            this.updateCss();
        });
    }

    public bind (offer: Offer): void {
        this._offer = offer;

        this.events();
        this.render(offer);
    }

    private updateCss (): void {
        this._parentElement.find(this._elementId).css('margin-left', 0);

        const offset = this._parentElement.find(this._elementId).offset();
        const browserWidth = $('body').width();

        this._parentElement.find(this._elementId).css('margin-left', -1 * offset.left);
        this._parentElement.find(this._elementId).css('width', browserWidth);
    }

}
