/**
 * @module SalesFlow/view
 */

declare var $: JQueryStatic;

import Injector from 'core/injector';

import {Renderable} from '../../renderable';

import DeviceOffer from '../../view/shared/offer/device-offer';

import OfferService from 'model/type/offer-service';

/**
 * @internal
 */
export default class CmOptionalService extends Renderable<DeviceOffer> {

    private _element: JQuery;
    private _selected: boolean;
    private _service: OfferService;

    constructor (injector: Injector, service: OfferService) {

        super(injector);

        this._element = $('.cm-service[data-service-id="' + service.id + '"]');
        this._service = service;
        this._selected = false;

    }

    public render (): void {

        /**
         * Set service title
         */
        this._element.find('.title').html(this._service.label);

        /**
         * Set the service price
         */
        if (this._service.monthlyPrice) {

            this._element.find('.price').html(this._service.monthlyPrice.value.toFixed(2).replace('.', ',') + ' &euro;');

        }

        /**
         * Check if the service is already in the optionalServiceIds array
         */
        if (0 <= this.getInjector().getFlow().optionalServiceIds.elements.indexOf(this._service.id)) {

            this._selected = true;
            this._element.find('.checkBox').addClass('selected');

        }

        /**
         * Show tariffoption
         */
        this._element.show();

    }

    public events (): void {

        this._element.on('click', '.checkBox', (evt) => {

            this._selected = !this._selected;

            if (this._selected) {

                this.getInjector().getFlow().optionalServiceIds.addElement(this._service.id);

            } else {

                this.getInjector().getFlow().optionalServiceIds.removeElement(this._service.id);

            }

            $(evt.currentTarget).toggleClass('selected');

            /**
             * Trigger that the optional service has changed. Will be handled in connect-more-controller.ts
             */
            this._injector.getEvent().trigger('optionalServiceIds@changed', {
                selected: this._selected
            });

        });

    }

}
