/**
 * @module SalesFlow/tracking
 */

export default class Proposition {
    public id: number;
    public name: string;
    public type: string;
    public targetAudience: string;
    public retention: string;
    public productType: string;
    public productLine: string;
    public productCategory: string;
    public units: number;

    constructor (
        id: number,
        name: string,
        type: string,
        targetAudience: string,
        retention: string,
        productType: string,
        productLine: string,
        productCategory: string,
        units: number
    ) {
        this.id = id;
        this.name = name;
        this.type = type;
        this.targetAudience = targetAudience;
        this.retention = retention;
        this.productType = productType;
        this.productLine = productLine;
        this.productCategory = productCategory;
        this.units = units;
    }
}
