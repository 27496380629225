declare var vf: any;
declare var $: JQueryStatic;

import Injector from 'core/injector';
import App from 'core/app';

(function () {

    'use strict';
    const init = function () {

        new App(new Injector());

    };

    vf['new-sales-flow'] = {
        settings: {},
        init: init
    };

}());
