/**
 * @module SalesFlow/evolved/router
 */

import InlifeRouting from 'router/inlife/routing';

import Injector from 'core/injector';
import {RouterEvolvedFamilyFriendsInlifeRecommendation} from 'router-evolved/familyfriends-inlife/router-evolved--familyfriends-inlife-recommendation';
import {RouterEvolvedFamilyFriendsInlifeTariffSelection} from 'router-evolved/familyfriends-inlife/router-evolved--familyfriends-inlife-tariff-selection';

export class FamilyFriendsInlifeRouting extends InlifeRouting {

    constructor (injector: Injector) {

        super(injector);

    }

    protected resolveRouter (deferred: JQueryDeferred<any>, currentStep: string) {

        /**
         * If not consumer - redirect to not allowed page
         */
        const customer = this.getInjector().getFlowStateWithSalesChannel().customer;
        if ( true === customer.isSohoCustomer ) {
            window.location.href = this.getInjector().getOptions().get('faf_inlife_tariff_not_consumer_url');
        }

        if ( !customer.hasFF ) {
            $('body').append('<script type="text/javascript" src="/simplicity/assets/js/faf-check.js"></' + 'script>');
        }

        if ('inlife_tariff_selection' === currentStep) {
            deferred.resolve(new RouterEvolvedFamilyFriendsInlifeTariffSelection(this._injector));
        } else {
            deferred.resolve(new RouterEvolvedFamilyFriendsInlifeRecommendation(this._injector));
        }

    }

}
