/**
 * @module SalesFlow/evolved/controller
 */

import Stepper from 'view/element/shared/stepper';
import StepperBnt from 'view/element/bnt/stepper';
import StepperGigakombi from 'view/element/gigakombi/stepper';
import {ViewEvolvedElementVvlStepper} from 'view-evolved/element/vvl/view-evolved--element-vvl--stepper';

import {FlowCtas} from 'view/ctas/shared/flow-ctas';
import Injector from 'core/injector';
import {SalesChannelName, ContractPeriodType} from 'core/ids';
import {ControllerEvolvedBase} from 'controller-evolved/controller-evolved--base';
import {ModelEvolvedRepoSupervisor} from 'model-evolved/repo/model-evolved--repo--supervisor';
import Customer from 'shopbackend/customer';
import { Constants } from 'core/constants';

export abstract class ControllerEvolvedFlowBase extends ControllerEvolvedBase {

    protected _stepper: Stepper;
    protected _ctas: FlowCtas;
    protected _contractPeriodType: ContractPeriodType;

    protected _reposSupervisor: ModelEvolvedRepoSupervisor;

    constructor (reposSupervisor: ModelEvolvedRepoSupervisor, injector: Injector) {

        super(injector);

        this._reposSupervisor = reposSupervisor;

        /**
         * Use same stepper for BNT and Gigakombi as they have slimier text
         * In case of FAF VVL it will be overwritten in child class!
         */
        if (Constants.BTX_BNT === this._btx ) {
            this._stepper = new StepperBnt(injector);
        } else if ( Constants.BTX_GIGAKOMBI === this._btx) {
            this._stepper = new StepperGigakombi(injector);
        } else {
            this._stepper = new ViewEvolvedElementVvlStepper(injector);
        }
        this._ctas = this.createCtas();

    }

    /**
     * Get sales channel
     */
    protected abstract getSalesChannel (): SalesChannelName;

    protected abstract tracking (): void;

    protected abstract createCtas (): FlowCtas;

    protected getReposSupervisor (): ModelEvolvedRepoSupervisor {
        return this._reposSupervisor;
    }

    protected resetRedPlusCards () {
        this.getInjector().getFlowStateWithSalesChannel().resetRedPlusFlowData();
    }

    protected setContractPeriodType (customer: Customer, subLevelSubscriptionId: number) {
        customer.setContractPeriodType(subLevelSubscriptionId);
        this._contractPeriodType = customer.contractPeriodType;
    }

}
