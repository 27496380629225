/**
 * @module SalesFlow/model/repo
 */

import NsfError from 'core/error';

import {Repo} from 'model/repo/repo';
import Device from 'model/type/device';
import AtomicDevice from 'model/type/atomic-device';

import {VluxJsonDevices} from 'model/vlux-interface';

interface IdMapping {
    [index: number]: number;
}

interface AtmicIdMapping {
    [index: number]: number[];
}

export default class DeviceRepo extends Repo {

    /**
     * @TODO What is the better approach: An IdMapping or the filter on demand?
     */
    private _deviceIdMapping: IdMapping = {};
    private _atomicDeviceIdMapping: AtmicIdMapping = {};

    private _devices: Device[] = [];

    constructor (json: any) {
        super();
        this.rawData = json;
        this.parseData(json);
    }

    /**
     * @TODO create an interface for products json
     */
    protected parseData (json: VluxJsonDevices): NsfError {

        if (1 !== json.productGroups.productGroup.length) {
            return new NsfError('one productGroup is expected');
        }

        const productGroup = json.productGroups.productGroup[0];

        const devices: any = productGroup.products.product;

        this._devices = devices.map((device: any) => {
            return new Device(device);
        });

        // fetch only devices with at least one atomicDevice
        this._devices = this._devices.filter((device: Device) => {
            if (undefined === device) {

                return false;
            }

            return 0 !== device.getAtomicDevices().length;
        });

        for (let i: number = 0, x = this._devices.length; i < x; i += 1) {
            this._deviceIdMapping[this._devices[i].id] = i;

            const atomicDevices = this._devices[i].getAtomicDevices();

            for (let j: number = 0, y = atomicDevices.length; j < y; j += 1) {

                this._atomicDeviceIdMapping[atomicDevices[j].id] = [i, j];

            }

        }

        return undefined;
    }

    public getDevices (): Device[] {
        return this._devices;
    }

    public getDevice (id: number): Device {

        let device;

        if (undefined !== this._deviceIdMapping[id]) {
            const deviceMapping: number = this._deviceIdMapping[id];
            device = this._devices[deviceMapping];
        }

        return device;
    }

    public getAtomicDevice (id: number): AtomicDevice {

        if (undefined === this._atomicDeviceIdMapping[id]) {
            return undefined;
        }

        const deviceMapping: number[] = this._atomicDeviceIdMapping[id];

        const device = this._devices[deviceMapping[0]];

        const atomicDevices = device.getAtomicDevices();
        const index = deviceMapping[1];

        return device.getAtomicDeviceByIndex(index);

    }

}
