/**
 * @module SalesFlow/core
 */

export default class Storage {

    private _storage: any = {};

    private _persist: boolean = false;

    constructor () {

        this._persist = this.checkPersist();

        if (true === this._persist) {
            this._storage = window.sessionStorage;
            this._persist = true;

        }

    }

    private checkPersist (): boolean {
        try {
            const storage: any = window.sessionStorage;
            const x = '__storage_test__';
            storage.setItem(x, x);
            storage.removeItem(x);

            return true;
        }
        catch (e) {
            return false;
        }
    }

    public canPersist (): boolean {
        return this._persist;
    }

    public hasItem (name: string): boolean {

        if (true === this._persist) {
            return (null !== this._storage.getItem(name));
        }
        else {
            return (undefined === this._storage[name]);
        }

    }

    public getItem (name: string): string {
        if (true === this._persist) {
            return this._storage.getItem(name);
        }
        else {
            if (undefined === this._storage[name]) {
                return null;
            }
            else {
                return this._storage[name];
            }
        }
    }

    public setItem (name: string, value: string): void {
        if (true === this._persist) {
            this._storage.setItem(name, value);
        }
        else {
            this._storage[name] = value;
        }

    }

    public removeItem (name: string): void {
        if (true === this._persist) {
            this._storage.removeItem(name);
        }
        else {
            delete this._storage[name];
        }

    }

    public clear (): void {
        if (true === this._persist) {
            this._storage.clear();
        }
        else {
            this._storage = {};
        }

    }
}
