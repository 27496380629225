/**
 * @module SalesFlow/evolved/view
 */

declare var $: JQueryStatic;
declare var vf: any;

import {ViewEvolvedElementBaseClassVfPassOverlay} from 'view-evolved/element/base-class/view-evolved--element-base-class--vf-pass-overlay';

import Injector from 'core/injector';

export default class ViewEvolvedElementBntClassVfPassOverlay extends ViewEvolvedElementBaseClassVfPassOverlay {

    private _selectedPassId: number;

    constructor (injector: Injector) {

        super(injector);

    }

    public render () {

        super.render();

        this._element.find('.pass-module-tile.selected').removeClass('selected');

        const selectedPassId = parseInt(this._injector.getStorage().getItem('passId'), 10);
        if (selectedPassId) {
            // this._element.find('h1').text('Jetzt kostenlosen Pass ändern');
            this._element.find('.pass-module-tile[data-pass-id="' + selectedPassId + '"]').addClass('selected');
            this._element.find('#nsf-pass-slide .set-pass').removeAttr('disabled');

            this._selectedPassId = selectedPassId;
        } else {
            // this._element.find('h1').text('Jetzt kostenlosen Pass auswählen');
            this._element.find('#nsf-pass-slide .set-pass').attr('disabled', 'disabled');
        }

    }

    public events () {

        super.events();

        this._element.find('.pass-module-tile').on('click', '.selectionRadio', (event: JQueryEventObject) => {

            const passTile: JQuery<Element> = $(event.currentTarget).closest('.pass-module-tile');
            if (passTile.hasClass('selected')) {
                return;
            }

            this._element.find('.pass-module-tile.selected').removeClass('selected');
            passTile.addClass('selected');

            this._element.find('.set-pass').removeAttr('disabled');

            const passId = parseInt(passTile.data('pass-id'), 10);

            this._selectedPassId = passId;
        });

        this._element.find('.button-wrap').on('click', '.set-pass', (event: JQueryEventObject) => {
            if ('inactive' === $(event.currentTarget).attr('inactive')) {
                return;
            }

            this.setPass();
        });

        this._element.find('.pass-module-tile .content-wrap').on('click', 'a', (event: JQueryEventObject) => {
            event.preventDefault();
            const targetId = $(event.currentTarget).attr('href');
            const $target = $(targetId);

            if (0 < $target.length) {
                const $accHead = $target.find('.tg-head');
                if (!$accHead.hasClass('tg-active')) {
                    $accHead.trigger('click');
                }

                this._element.find('.overlay-content').animate({
                    scrollTop: this._element.find('.overlay-content').scrollTop() + $target.position().top
                }, 'slow');
            }
        });

        this._element.find('.partnericon-tiles-button-wrap').on('click', '.set-pass', (event: JQueryEventObject) => {
            const passId = parseInt($(event.currentTarget).data('pass-id'), 10);
            if (!isNaN(passId)) {
                this._selectedPassId = passId;
                this.setPass();
            }

        });

    }

    private setPass (): void {

        const previousPassId = parseInt(this._injector.getStorage().getItem('passId'), 10);

        if (previousPassId !== this._selectedPassId) {
            /**
             * Remove previous pass id from optional services array
             */
            this.getInjector().getFlow().optionalServiceIds.removeElement(previousPassId);

            /**
             * Add new pass id to optional services array
             */
            this.getInjector().getFlow().optionalServiceIds.addElement(this._selectedPassId);
            this._injector.getStorage().setItem('passId', this._selectedPassId.toString());

            /**
             * Trigger pass changed event
             */
            this._injector.getEvent().trigger('vfPass@changed', this._selectedPassId);
        }

        vf.overlay.closeOverlay();
        this.trackSelection(this._selectedPassId, previousPassId);
    }

    private trackSelection (newPassId: number, oldPassId: number) {

        this._injector.getEvent().trigger('vfPass@changed-trackSelection', {
            newPassId: newPassId,
            oldPassId: oldPassId
        });

    }
}
