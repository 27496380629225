/**
 * @module SalesFlow/view
 */

import Injector from 'core/injector';

import {Renderable} from '../../renderable';

import ViewOffer from '../../view/shared/accessory-offer';
import ViewOverlayAccessoryDetails from '../../view/shared/overlay-accessory-details';
import { forOfStatement } from 'babel-types';
import FlowArrayHelper from 'core/flow-array';

export default class CmAccessoryTile extends Renderable<ViewOffer> {

    private _container: JQuery;
    private _element: JQuery;
    private _selected: boolean;
    private _accessoryOffer: ViewOffer;
    private _coreFlowStateAccessoryIds: FlowArrayHelper;

    constructor (injector: Injector, container: JQuery) {
        super(injector);

        this._coreFlowStateAccessoryIds =  this.getInjector().getFlowState().accessoryIds;
        this._container = container;
        this._selected = false;
    }

    public render (accessoryOffer: ViewOffer): string {
        this._accessoryOffer = accessoryOffer;
        this._container.append(this.getInjector().getTemplates().render('cm_accessory_tile', accessoryOffer));
        this._element = this._container.find('.device-module-tile[data-atomic-accessory-id="' + accessoryOffer.atomicAccessoryId + '"]');

        /**
         * Check weather the accessory is already selected
         */
        if (0 <= this._coreFlowStateAccessoryIds.elements.indexOf(accessoryOffer.atomicAccessoryId)) {
            this._selected = true;
            this._element.addClass('selected');
        }

        return '';
    }

    public events (): void {
        this._element.on('click', '.selectionCheck', (evt) => {
            const tile = evt.currentTarget.parentElement.parentElement;

            if (!this._selected && 5 <= this._coreFlowStateAccessoryIds.elements.length) {
                return;
            }

            this._selected = !this._selected;
            const atomicAccessoryId = parseInt(this._element.data('atomic-accessory-id'), 10);

            if (this._selected) {
                tile.classList.add('selected');
                this._coreFlowStateAccessoryIds.addElement(atomicAccessoryId);
            } else {
                tile.classList.remove('selected');
                this._coreFlowStateAccessoryIds.removeElement(atomicAccessoryId);
            }

            this._injector.getEvent().trigger('accessoryIds@changed', {
                selected: this._selected,
                accessoryId: atomicAccessoryId
            });

        });

        this._element.on('click', '.accessory-details', (evt) => {
            const viewOverlay = new ViewOverlayAccessoryDetails(this._accessoryOffer);
            this.getInjector().getOverlay().open(viewOverlay, 'overlay_accessory_details');
            this.getInjector().getEvent().trigger('pageviewTracking@onload', {
                pageName: 'extras:accessory',
                pageType: 'product detail',
                deviceOffer: {},
                overwriteRule: true
            });
        });
    }

}
