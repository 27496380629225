/**
 * @module SalesFlow/router
 */

import Routing from 'router/shared/routing';

import Customer from 'shopbackend/customer';
import Nba from 'shopbackend/nba';
import { RouterEvolvedRedPlusDeviceTariff } from 'router-evolved/redplus/router-evolved--redplus--device-tariff';

/**
 * The new sales flow root object
 */
/**
 * @internal
 */
export default class RedPlusRouting extends Routing {

    protected _validPages: string[] = [
        'redplus_device_tariff',  // Device first on page (when you come from Red+ landing page)
        'redplus_tariff_device'   // Tariff first on page (when you come from app)
    ];

    /**
     * Due we have the best cms of the world, we might have the need to do somthing before the page takes over control
     * E.g. If gigakombi we have to hide all not gigakombi subscription tiles and vice versa
     */
    protected doSomethingBeforeRouterAreCreated (): void {

        /**
         * we inittially show just one subscription tile skeleton, as more
         * than one tile is never displayed.
         */
        $('.tariff-module-tile').not(':first').addClass('hide');

    }

    private getRedirectURL (_customer: Customer): string {

        let redirectUrl: string = '';
        const customer = _customer;

        /**
         * Check Red+ eligibility
         */
        if (false === customer.currentTariffSupportsRedPlus || 0 >= customer.vacantRedPlusSlots) {
            redirectUrl = this.getInjector().getOptions().get('redplus-landing-page-url');
        }

        return redirectUrl;

    }

    protected getRouter (): JQueryPromise<any> {

        const deferred = $.Deferred();

        const forApp: boolean = this._injector.getGetParameter().getForAppGetParam();
        const nba: Nba = new Nba();

        nba.getData(forApp, 'redplus', 'all').then((customer: Customer) => {

            this._injector.getFlow().setCustomer(customer);

            // Check custumor's red+ eligibility
            const redirectUrl = this.getRedirectURL(customer);

            if ('' === redirectUrl) {
                deferred.resolve(new RouterEvolvedRedPlusDeviceTariff(this._injector));
            } else {
                window.location.href = redirectUrl;
            }

        }, (error) => {

            if (401 === error.status) {
                /**
                 * not authenticated yet? go to mTAN page
                 */
                const redirectUrl = document.location.pathname + decodeURIComponent(document.location.search);
                window.location.href = '/shop/authentifizierung.html?btype=redplus&goto=' + this.getRedirectUrlWithAffiliateParameters(redirectUrl);
            } else {
                deferred.reject();
            }

        });

        return deferred.promise();

    }
}
