/**
 * @module SalesFlow/model/type
 */
export default class SimCardAttributes {

    [key: string]: any;

    private _simcardType: string[];

    constructor (attributes: any, parentAttributes?: SimCardAttributes) {
        for (const attribute of attributes) {
            const attributeId = attribute.id;

            if ('99' === attributeId) {
                if ('object' !== typeof this._simcardType) {
                    this._simcardType = [];
                }

                this._simcardType.push(attribute.value);
            }
        }

    }

}
