/**
 * @module SalesFlow/view
 */

import {ViewEvolvedCtasGigakombiFlowCtas} from 'view-evolved/ctas/gigakombi/view-evolved--ctas--gigakombi-flow-ctas';
import Offer from 'view/view/shared/offer/offer';

/**
 * Only one button here
 * Right: Link to connect more page
 *
 * @export
 * @class DeviceDetailGigakombiCtas
 * @extends {FlowCtas}
 */
export default class ViewEvolvedCtasGigakombiDeviceDetailCtas extends ViewEvolvedCtasGigakombiFlowCtas {

    public update (offer: Offer): void {

        if (undefined === offer) {
            return;
        }

        this._right.setHref(
            this.getInjector().getOptions().get('connectmore_url') + '?giga=kombi'
        );

        this._element.html(
            this.getInjector().getTemplates().render('flow-ctas', [this._left, this._right], 'partials')
        );

    }

}
