/**
 * @module SalesFlow/model/type
 */
import OfferPrice from './offer-price';

import {VluxJsonOfferService} from '../vlux-interface';

type ServiceReference = 'tariff' | 'subsidization';

/**
 * label and backend id are stored in another json file so they are not mandatory here and are set only in offer-repo
 */
export default class OfferService {

    private _id: number;

    private _backendId: string;

    private _label: string;

    private _serviceType: string;
    /* discountMobile or serviceMobile */

    private _prices: OfferPrice[];

    private _dataVolume: number;

    private _hidden: boolean;

    private _reference: ServiceReference = 'tariff';

    /**
     * @TODO this makes only sense when service.id is matched with services
     */
    constructor (data: VluxJsonOfferService) {

        this._id = parseInt(data.id, 10);

        if (undefined !== data.prices && undefined !== data.prices.price && undefined !== data.prices.price.map) {
            this._prices = data.prices.price.map((price: any) => {
                return new OfferPrice(price);
            });
        }

    }

    public setLabel (label: string): void {
        this._label = label;
    }

    public setBackendId (backendId: string): void {
        this._backendId = backendId;
    }

    public setServiceType (serviceType: string): void {
        this._serviceType = serviceType;
    }

    public setHidden (hidden: boolean): void {
        this._hidden = hidden;
    }

    public setDataVolume (dataVolume: number | string): void {

        if ('string' === typeof dataVolume) {

            dataVolume = parseFloat(dataVolume.replace(',', '.'));

        }

        this._dataVolume = dataVolume;

    }

    public setReference (reference: ServiceReference) {
        this._reference = reference;
    }

    get id (): number {
        return this._id;
    }

    get backendId (): string {
        return this._backendId;
    }

    get label (): string {
        return this._label;
    }

    get prices (): OfferPrice[] {
        return this._prices;
    }

    get dataVolume (): number {
        return this._dataVolume;
    }

    get hidden (): boolean {
        return this._hidden;
    }

    get reference (): ServiceReference {
        return this._reference;
    }

    get monthlyPrice (): OfferPrice {

        let price: OfferPrice[] = [];

        try {
            price = this._prices.filter((price: OfferPrice) => {
                return ('month' === price.recurrenceUnit);
            });
        } catch (e) {
        }

        if (0 === price.length) {
            return undefined;
        }

        return price[0];
    }

}
