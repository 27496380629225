/**
 * @module SalesFlow/model/type
 */

import PurchasableDeviceRepo from 'model/repo/purchasable-device-repo';

import DeviceAttributes from './attributes-device';

import AtomicDevice from './atomic-device';

import {VluxJsonDevice} from '../vlux-interface';
import {ModelEvolvedRepoPurchasableDevice} from 'model-evolved/repo/model-evolved--repo--purchasable-device';
import { BusinessTransactionContext, SalesChannelName } from 'core/ids';

interface AttributeQuery {
    [index: string]: any;

    color: string;
    internalMemory: number;
    bundledWith?: string;
}

export default class Device {

    [key: string]: any;

    private _id: number;

    private _name: string;

    private _attributes: DeviceAttributes;

    /**
     * From additional Attributes
     */
    private _topDevice: string;

    private _promotion: string;

    private _characteristic: string;

    private _badge_text: string;

    private _atomicDevice: AtomicDevice[] = [];

    private _json: any;

    private _isMatchingFilter: boolean = true;

    constructor (data: any) {
        this.parseData(data);
    }

    private parseData (data: VluxJsonDevice) {

        this._json = data;

        this._id = parseInt(data.id, 10);

        this._name = data.label;

        let attributes = {};

        if (undefined !== data.attributes && undefined !== data.attributes.attribute) {
            attributes = data.attributes.attribute;
        }

        this._attributes = new DeviceAttributes(attributes);

        if (undefined !== data.products && undefined !== data.products.product) {
            for (const atomicDeviceData of data.products.product) {

                // If no backendId this atomicDevice is not orderable, so skip it anyway
                if (undefined === atomicDeviceData.backendId) {
                    continue;
                }

                this._atomicDevice.push(
                    new AtomicDevice(atomicDeviceData, this)
                );
            }
        } else if (undefined !== data.attributes && undefined !== data.attributes.attribute) {
            const atomicDeviceData = data;
            this._atomicDevice.push(
                new AtomicDevice(atomicDeviceData, this)
            );
        }

        // @TODO When more additional attributes come, move their parsing and setting to DeviceAttributes
        if (undefined !== data.additional) {

            if (undefined !== data.additional.topdevice) {
                this._topDevice = data.additional.topdevice;
            }

        }
    }

    /**
     * @TODO What is the best method to clone
     */
    public clone (): Device {
        return new Device(this._json);
    }

    public getAttributes (): DeviceAttributes {
        return this._attributes;
    }

    public isVirtualDevice (): boolean {
        return (0 !== this._atomicDevice.length);
    }

    public getAtomicDevices (): AtomicDevice[] {
        return this._atomicDevice;
    }

    public removeUnPurchasableAtomicDevices (): void {

        this._atomicDevice = this._atomicDevice.filter((atomicDevice) => {
            return true === atomicDevice.purchasable;
        });

    }

    public getAtomicDeviceByIndex (index: number): AtomicDevice {

        if (undefined === this._atomicDevice[index]) {

            return undefined;

        }

        return this._atomicDevice[index];

    }

    public getAtomicDeviceById (id: number): AtomicDevice {

        const atomicDevices = this._atomicDevice.filter((atomicDevice: AtomicDevice) => {

            if (id === atomicDevice.id) {
                return true;
            }

            return false;

        });

        if (0 === atomicDevices.length) {
            return undefined;
        }

        return atomicDevices[0];

    }

    public getAtomicDevicesByAttr (attrName: string, attrValue: any): AtomicDevice[] {

        const atomicDevices = this._atomicDevice.filter((atomicDevice: AtomicDevice) => {

            if (attrValue === atomicDevice.attr[attrName]) {
                return true;
            }

            return false;

        });

        return atomicDevices;

    }

    public getAtomicDevicesByAttrs (attributes: AttributeQuery): AtomicDevice[] {

        const atomicDevices = this._atomicDevice.filter((atomicDevice: AtomicDevice) => {

            let match: Boolean = true;
            for (const attributeName in attributes) {

                const attributeValue = attributes[attributeName];

                if (attributeValue !== atomicDevice.attr[attributeName]) {
                    match = false;
                    break;
                }

            }

            return match;

        });

        return atomicDevices;

    }

    private getSeoUrl (name: string): string {

        name = name.toLocaleLowerCase();

        const umlaute: any = {
            'ä': 'ae',
            'ö': 'oe',
            'ü': 'ue',
            'ß': 'ss',
            '\\+': ' plus'
        };

        for (const umlaut in umlaute) {
            const regEx = new RegExp(umlaut, 'g');
            name = name.replace(regEx, umlaute[umlaut]);
        }

        name = name.replace(/\W+/g, '-');
        name = name.replace(/[^a-z0-9-]/g, '');
        name = name.replace(/[^0-9a-z]$/g, '');

        return name;
    }

    /**
     * Assume that prefix has no traling slash
     * @TODO In produktion no '?'
     */
    public getDetailLink (prefix: string): string {
        const overrides: any = {
            'oppo-find-x3-pro-5g-amp-nreal-ar-brille': 'oppo-find-x3-pro-5g-nreal'
        };
        const page: string = this.getSeoUrl(this._name);

        return prefix + (overrides[page] || page) + '.html';
    }

    public setIsMatchingFilter (match: boolean): void {
        this._isMatchingFilter = match;
    }

    public getIsMatchingFilter (): boolean {
        return this._isMatchingFilter;
    }

    get id (): number {
        return this._id;
    }

    get name (): string {
        return this._name;
    }

    get attr (): DeviceAttributes {
        return this._attributes;
    }

    get attributes (): DeviceAttributes {
        return this._attributes;
    }

    get topDevice (): string {
        return this._topDevice;
    }

    get promotion (): string {
        return this._promotion;
    }

    get characteristic (): string {
        return this._characteristic;
    }

    get badge_text (): string {
        return this._badge_text;
    }

    /**
     * @TODO Implement getAlternativeDevicesEvolved into purchasableDeviceRepo and remove here
     * @param purchasableDeviceRepo
     * @param atomicDevice
     */
    public getAlternativeDevices (purchasableDeviceRepo: PurchasableDeviceRepo, atomicDevice: AtomicDevice): AtomicDevice[] {

        let ret: AtomicDevice[] = [];

        if ('string' !== typeof atomicDevice.attributes.deliveryDate) {
            return ret;
        }

        try {

            const myDeliveryDate: string = atomicDevice.attributes.deliveryDate;
            const myInternalMemory: number = atomicDevice.attributes.internalMemory;
            const myColor: string = atomicDevice.attributes.color;
            const myVendor: string = atomicDevice.device.attributes.vendor;
            const myId: number = atomicDevice.id;

            /**
             * get List of all Devices
             */
            let allDevices = purchasableDeviceRepo.getDevices();
            allDevices = allDevices.filter((a: Device) => {
                return (myVendor === a.attributes.vendor);
            });

            /**
             * sortorder fpr allDevices is defined by mobile editor. as we only look for devices after current
             * device, we remove all devices in list that are before current device
             */
            let pos = 0;
            for (let i = 0; i < allDevices.length; i++) {
                if (allDevices[i].id === atomicDevice.device.id) {
                    pos = i;
                    break;
                }
            }
            allDevices = allDevices.splice(pos + 1);

            /**
             * find alternative Devices in Array
             */

            for (let i = 0; i < allDevices.length; i++) {
                ret = atomicDevice.extractAlternativeDevices(allDevices[i], myId, myDeliveryDate, myInternalMemory, myColor);
                if (3 >= ret.length) {
                    return ret;
                }
            }

        } catch (e) {
            return ret;
        }

        return ret;
    }

    /**
     * @TODO Implement this into purchasableDeviceRepo and remove here
     * @param modelRepoPurchasableDevice
     * @param atomicDevice
     */
    public getAlternativeDevicesEvolved (modelRepoPurchasableDevice: ModelEvolvedRepoPurchasableDevice, atomicDevice: AtomicDevice, salesChannel: SalesChannelName): AtomicDevice[] {

        let ret: AtomicDevice[] = [];

        if ('string' !== typeof atomicDevice.attributes.deliveryDate) {
            return ret;
        }

        try {

            const myDeliveryDate: string = atomicDevice.attributes.deliveryDate;
            const myInternalMemory: number = atomicDevice.attributes.internalMemory;
            const myColor: string = atomicDevice.attributes.color;
            const myVendor: string = atomicDevice.device.attributes.vendor;
            const myId: number = atomicDevice.id;

            /**
             * get List of all Devices
             */
            let allDevices = modelRepoPurchasableDevice.getDevices(salesChannel);
            allDevices = allDevices.filter((a: Device) => {
                return (myVendor === a.attributes.vendor);
            });

            /**
             * sortorder fpr allDevices is defined by mobile editor. as we only look for devices after current
             * device, we remove all devices in list that are before current device
             */
            let pos = 0;
            for (let i = 0; i < allDevices.length; i++) {
                if (allDevices[i].id === atomicDevice.device.id) {
                    pos = i;
                    break;
                }
            }
            allDevices = allDevices.splice(pos + 1);

            /**
             * find alternative Devices in Array
             */

            for (let i = 0; i < allDevices.length; i++) {
                ret = atomicDevice.extractAlternativeDevices(allDevices[i], myId, myDeliveryDate, myInternalMemory, myColor);
                if (3 >= ret.length) {
                    return ret;
                }
            }

        } catch (e) {
            return ret;
        }

        return ret;
    }

}
