/**
 * @module SalesFlow/evolved/model/repo
 */

import NsfError from 'core/error';

import Device from 'model/type/device';
import AtomicDevice from 'model/type/atomic-device';

import Subscription from 'model/type/subscription';

import {BusinessTransactionContext, SalesChannelName} from 'core/ids';
import {ModelEvolvedRepoBase} from 'model-evolved/repo/model-evolved--repo--base';
import {ModelEvolvedRepoDevice} from 'model-evolved/repo/model-evolved--repo--device';

import GeneralSalesObjectInterface from 'service/general-sales-object/general-sales-object-interface';
import { Constants } from 'core/constants';

/**
 * Repo for device but with an additional check if a device is purchasable
 */
export class ModelEvolvedRepoPurchasableDevice extends ModelEvolvedRepoBase {

    private _deviceRepo: ModelEvolvedRepoDevice;
    private _generalOfferServiceInterface: GeneralSalesObjectInterface;

    private _btx: BusinessTransactionContext;
    private _salesChannel: SalesChannelName;

    constructor (deviceRepo: ModelEvolvedRepoDevice, generalOfferServiceInterface: GeneralSalesObjectInterface, btx: BusinessTransactionContext, salesChannel: SalesChannelName) {
        super();

        this._btx = btx;
        this._salesChannel = salesChannel;
        this._deviceRepo = deviceRepo;
        this._generalOfferServiceInterface = generalOfferServiceInterface;

    }

    protected parseData (
        salesChannel: SalesChannelName,
        businessTransactionContext: BusinessTransactionContext,
        json: any
    ): NsfError {

        return undefined;
    }

    /**
     * @TODO Trace this, seems to be called more often
     */
    public getDevices (
        salesChannel: SalesChannelName,
        subscription?: Subscription
    ): Device[] {

        /**
         * @TODO What is the best method to clone
         */
        let devices: Device[] = this._deviceRepo.getDevices().map((device) => {
            return device.clone();
        });

        for (const device of devices) {

            for (const atomicDevice of device.getAtomicDevices()) {
                if ('undefined' === typeof subscription) {
                    atomicDevice.setPurchasable(
                        undefined !== this._generalOfferServiceInterface.getHardwareOnlyOfferByAtomicId (
                            atomicDevice.id,
                            salesChannel));
                } else {
                    atomicDevice.setPurchasable(
                        undefined !== this._generalOfferServiceInterface.getSimHardwareOfferByAtomicDeviceIdAndSubscriptionId(
                            atomicDevice.id,
                            subscription.id,
                            this._btx,
                            salesChannel
                        )
                    );
                }

            }

            device.removeUnPurchasableAtomicDevices();
        }

        devices = devices.filter((device: Device) => {
            return 0 !== device.getAtomicDevices().length;
        });

        return devices;

    }

    public getDevice (
        id: number,
        salesChannel: SalesChannelName,
        subscription?: Subscription
    ): Device {

        const devices = this.getDevices(salesChannel, subscription);

        for (const device of devices) {
            if (id === device.id) {
                return device;
            }
        }

    }

    public getAtomicDevice (
        id: number,
        salesChannel: SalesChannelName,
        subscription: Subscription
    ): AtomicDevice {

        const devices = this.getDevices(salesChannel, subscription);

        for (const device of devices) {
            for (const atomicDevice of device.getAtomicDevices()) {
                if (id === atomicDevice.id) {
                    return atomicDevice;
                }
            }
        }

        return undefined;

    }

    public getAtomicDeviceHardwareOnly (id: number, salesChannel: SalesChannelName): AtomicDevice {

        const devices = this.getDevices(salesChannel);

        for (const device of devices) {
            for (const atomicDevice of device.getAtomicDevices()) {
                if (id === atomicDevice.id) {
                    return atomicDevice;
                }
            }
        }

    }

}
