/**
 * @module SalesFlow/model/repo
 */

import NsfError from 'core/error';

import {Repo} from './repo';
import Device from '../type/device';
import AtomicDevice from '../type/atomic-device';

import Subscription from '../type/subscription';
import DeviceRepo from './device-repo';

import GeneralSalesObjectInterface from 'service/general-sales-object/general-sales-object-interface';
import { BusinessTransactionContext, SalesChannelName } from 'core/ids';
import { Constants } from 'core/constants';

/**
 * Repo for device but with an additional check if a device is purchasable
 */
export default class PurchasableDeviceRepo extends Repo {

    private _deviceRepo: DeviceRepo;
    private _generalOfferServiceInterface: GeneralSalesObjectInterface;
    private _btx: BusinessTransactionContext;
    private _salesChannel: SalesChannelName;

    constructor (deviceRepo: DeviceRepo, generalOfferServiceInterface: GeneralSalesObjectInterface, btx: BusinessTransactionContext, salesChannel: SalesChannelName) {
        super();

        this._deviceRepo = deviceRepo;
        this._generalOfferServiceInterface = generalOfferServiceInterface;
        this._btx = btx;
        this._salesChannel = salesChannel;

    }

    protected parseData (json: any): NsfError {
        return undefined;
    }

    /**
     * @TODO Trace this, seems to be called more often
     */
    public getDevices (subscription?: Subscription): Device[] {

        /**
         * @TODO What is the best method to clone
         */
        let devices: Device[] = this._deviceRepo.getDevices().map((device) => {
            return device.clone();
        });

        for (const device of devices) {

            for (const atomicDevice of device.getAtomicDevices()) {
                if ('undefined' === typeof subscription) {
                    atomicDevice.setPurchasable(undefined !== this._generalOfferServiceInterface.getHardwareOnlyOfferByAtomicId(
                        atomicDevice.id,
                        this._salesChannel));
                } else {
                    atomicDevice.setPurchasable(undefined !== this._generalOfferServiceInterface.getSimHardwareOfferByAtomicDeviceIdAndSubscriptionId(
                        atomicDevice.id,
                        subscription.id,
                        this._btx,
                        this._salesChannel));
                }

            }

            device.removeUnPurchasableAtomicDevices();
        }

        devices = devices.filter((device: Device) => {
            return 0 !== device.getAtomicDevices().length;
        });

        return devices;
    }

    public getDevice (id: number, subscription?: Subscription): Device {
        const devices = this.getDevices(subscription);

        for (const device of devices) {
            if (id === device.id) {
                return device;
            }
        }

    }

    public getAtomicDevice (id: number, subscription: Subscription): AtomicDevice {

        const devices = this.getDevices(subscription);

        for (const device of devices) {
            for (const atomicDevice of device.getAtomicDevices()) {
                if (id === atomicDevice.id) {
                    return atomicDevice;
                }
            }
        }

        return undefined;

    }

    public getAtomicDeviceHardwareOnly (id: number): AtomicDevice {

        const devices = this.getDevices();

        for (const device of devices) {
            for (const atomicDevice of device.getAtomicDevices()) {
                if (id === atomicDevice.id) {
                    return atomicDevice;
                }
            }
        }

    }

}
