/**
 * @module SalesFlow/controller-evolved
 */

import Injector from 'core/injector';

import {SalesChannelName} from 'core/ids';

import {ViewEvolvedCtasSharedFlowCtas} from 'view-evolved/ctas/shared/view-evolved--ctas--shared--flow-ctas';
import {ViewEvolvedCtasBntConnectMoreCtas} from 'view-evolved/ctas/bnt/view-evolved--ctas--bnt-connect-more-ctas';

import {ControllerEvolvedBaseClassConnectMore} from 'controller-evolved/base-class/controller-evolved--base-class--connect-more';

import {ModelEvolvedRepoSupervisor} from 'model-evolved/repo/model-evolved--repo--supervisor';
import {Constants} from 'core/constants';
import {ViewEvolvedCtasGigakombiConnectMoreCtas} from 'view-evolved/ctas/gigakombi/view-evolved--ctas--gigakombi-connect-more-ctas';

/**
 * @TODO When Get parameter, tha focus that
 * @TODO Resolve by Router
 */
export class ControllerEvolvedBntConnectMore extends ControllerEvolvedBaseClassConnectMore {

    constructor (
        salesChannel: SalesChannelName,
        atomicDeviceId: number,
        subscriptionId: number,
        reposSupervisor: ModelEvolvedRepoSupervisor,
        injector: Injector
    ) {
        super(
            salesChannel,
            atomicDeviceId,
            subscriptionId,
            reposSupervisor,
            injector
        );

        if (true === this.getInjector().getOptions().get('debug')) {
            const cnsl = console;
            cnsl.log('ControllerEvolvedBntConnectMore....');
        }

    }

    protected createCtas (): ViewEvolvedCtasSharedFlowCtas {

        const orderType = this.getInjector().getFlowState().getOrderType();

        if (Constants.BTX_GIGAKOMBI === this._btx) {
            return new ViewEvolvedCtasGigakombiConnectMoreCtas(this._injector);
        } else {
            return new ViewEvolvedCtasBntConnectMoreCtas(this._injector);
        }

    }

}
