/**
 * @module SalesFlow/view
 */

declare var $: JQueryStatic;

import Injector from 'core/injector';

import {Renderable} from 'view/renderable';

import DeviceOffer from 'view/view/shared/offer/device-offer';

import Offer from 'model/type/offer';

import {ViewEvolvedElementBntCmRedplusDeviceTile} from './view-evolved--element-bnt--cm-redplus-device-tile';

import SlideMeIfYouCan from 'view/element/shared/slide-if-you-can';

import ViewOverlay from '../../../view/view/shared/overlay';
import { Constants } from 'core/constants';

import BntDeviceFlowService from 'service/bnt/bnt-device-flow-service';

export class ViewEvolvedElementBntCmRedPlus extends Renderable<DeviceOffer[]> {

    private _element: JQuery;
    private _accordion: JQuery;
    private _selected: boolean;
    private _type: string;
    private _offer: Offer;
    private _slider: SlideMeIfYouCan;

    private _comingFromSailsPage: boolean;

    constructor (injector: Injector, offer: Offer, type: string) {
        super(injector);

        this._element = $('.cm-redplus[data-redplus="' + type + '"]');
        this._accordion = this._element.find('.mod-cm-accordion');
        this._offer = offer;
        this._type = type;
        this._selected = false;
        this._comingFromSailsPage = !!sessionStorage.getItem('SAILS_BNT');

        this._slider = new SlideMeIfYouCan(
            this._element.find('#nsf-product-list-slide'),
            'device',
            injector
        );
    }

    public render (devices: DeviceOffer[]): void {
        if (0 === this._element.length) {
            return;
        }

        if (this._comingFromSailsPage) {
            $('body').addClass('sails-flow');
        }
        /**
         * Remove the element if it is not bookable with the selected tariff
         */
        if (this._element.data('not-bookable-with')) {
            const nonBookableTariffs = ('' + this._element.data('not-bookable-with')).split(',').map(Number);

            if (0 <= nonBookableTariffs.indexOf(this.getInjector().getFlowState().getSubscriptionId())) {
                this._element.remove();

                return;
            }
        }

        if (Constants.RedPlus_Allnet === this._type) {
            /**
             * Check if the Red+ Partnerkarte is already selected
             */
            if (0 < this.getInjector().getFlowState().redPlusAllnet.elements.length) {
                this._selected = true;
            }

        } else if (Constants.RedPlus_Basic === this._type) {
            /**
             * Check if the Red+ Partnerkarte is already selected
             */
            if (0 < this.getInjector().getFlowState().redPlusBasic.elements.length) {
                this._selected = true;
            }

        } else if (Constants.RedPlus_Data === this._type) {
            /**
             * Check if Red+ Data is already selected
             */
            if (0 < this.getInjector().getFlowState().redPlusData.elements.length) {
                this._selected = true;
            }
        }

        if (2 === $('.cm-redplus .checkBox.selected').length) {
            $('.cm-redplus .checkBox:not(.selected)').addClass('disabled');
        }

        if (this._offer.subcriptionPriceMonthly) {
            this._accordion.find('.price').html(this._offer.subcriptionPriceMonthly.value.toFixed(2).replace('.', ',') + ' &euro;');
        }

        if (this._selected) {
            this._accordion.addClass('active');
            this._accordion.find('.checkBox').addClass('selected');
            this._accordion.find('.stateOne').show();
        }

        /**
         * Show element - important to do it before the device row module is rendered
         */
        this._element.show();

        /**
         * Loop over devices and create an instance of CmRedplusDeviceTile for each of them
         */
        for (const device of devices) {

            if (undefined === device) {
                continue;
            }

            const deviceTile = new ViewEvolvedElementBntCmRedplusDeviceTile(
                this._injector,
                this._accordion.find('.device-module-tiles'),
                this._type,
                device.subscription
            );

            deviceTile.bind(device);

        }

        if ('HmK' === this._offer.offerType) {
            this._accordion.find('.stateOne').addClass('active');
            this._accordion.find('.stateTwo').show();
            this._slider.bind();
            this._slider.updateHeight();
            this.setColorPickerHeight();
        }
        else {
            this._accordion.find('.selectionRedplusOnly').addClass('selected');
        }
        // For Promo Pack Q2
        BntDeviceFlowService.toggleConsumerOrYoungBanner(this._offer.salesChannel, this._injector.getFlowState().getHardwareOnly());
    }

    public events (): void {

        this._accordion.on('click', '.checkBox', (evt: JQueryEventObject) => {

            $('.cm-redplus .checkBox.disabled').removeClass('disabled');
            const myParent = $(evt.currentTarget).parent().parent();

            /**
             * not more than two options are allowed
             * or in case user wants to deselect
             */
            if (2 > $('.cm-redplus .checkBox.selected').length || this._selected) {
                this._selected = !this._selected;

                if (Constants.RedPlus_Allnet === this._type) {
                    this.getInjector().getFlowState().redPlusAllnet.resetAllElements();

                    if (this._selected) {
                        this.getInjector().getFlowState().redPlusAllnet.addElement(this._offer.offerId);
                    } else {
                        this.resetRedPlusWithSubLevel(myParent);
                    }
                } else if (Constants.RedPlus_Basic === this._type) {
                    this.getInjector().getFlowState().redPlusBasic.resetAllElements();

                    if (this._selected) {
                        this.getInjector().getFlowState().redPlusBasic.addElement(this._offer.offerId);
                    } else {
                        this.resetRedPlusWithSubLevel(myParent);
                    }
                } else if (Constants.RedPlus_Data === this._type) {
                    this.getInjector().getFlowState().redPlusData.resetAllElements();

                    if (this._selected) {
                        this.getInjector().getFlowState().redPlusData.addElement(this._offer.offerId);
                    } else {
                        this.resetRedPlusWithSubLevel(myParent);
                    }
                }

                this._injector.getEvent().trigger('redPlusOffer@changed');
                this._injector.getEvent().trigger('redPlusTracking@changed', {
                    redPlusOption: this._offer,
                    redPlusSelected: this._selected,
                    redPlusName: this._type
                });

                if (myParent.hasClass('active')) {
                    myParent.find('.stateOne').animate({
                        height: 'toggle'
                    }, 500, function () {
                        $(this).hide();
                    });
                    myParent.removeClass('active');
                    $(evt.currentTarget).removeClass('selected');

                } else {
                    myParent.find('.stateOne').animate({
                        height: 'toggle'
                    }, 500, function () {
                        $(this).show();
                    });
                    myParent.addClass('active');
                    $(evt.currentTarget).addClass('selected');

                }

                if (myParent.find('.stateOne').hasClass('active')) {
                    myParent.find('.stateTwo').animate({
                        height: 'toggle'
                    }, 500, function () {
                        $(this).hide();
                    });
                    myParent.find('.stateOne').removeClass('active');
                }

            }

            if (2 === $('.cm-redplus .checkBox.selected').length) {
                $('.cm-redplus .checkBox:not(.selected)').addClass('disabled');
            }
        });

        let isDeviceRowModuleInitialized = false;
        this._accordion.on('click', '.stateOneHead', (evt: JQueryEventObject) => {
            const myParent = $(evt.currentTarget).parent();

            if (myParent.hasClass('active')) {
                myParent.find('.stateTwo').animate({
                    height: 'toggle'
                }, 500, function () {
                    $(this).hide();
                });
                myParent.removeClass('active');
            } else {
                myParent.find('.stateTwo').animate({
                    height: 'toggle'
                }, 500, function () {
                    $(this).show();
                });
                myParent.addClass('active');
                if (!isDeviceRowModuleInitialized) {
                    this._slider.bind();
                    this._slider.updateHeight();
                    this.setColorPickerHeight();
                    isDeviceRowModuleInitialized = true;
                }
            }
        });

        this._accordion.on('click', '.selectionRedplusOnly', (evt: JQueryEventObject) => {

            if ($(evt.currentTarget).hasClass('selected')) {
                return;
            }

            this._accordion.find('.device-module-tile.selected').removeClass('selected');
            $(evt.currentTarget).addClass('selected');

            let simOnlyOffer;
            const generalOfferServiceInterface = this.getInjector().getGeneralSalesObjectInterface();
            if (Constants.RedPlus_Allnet === this._type) {
                simOnlyOffer = generalOfferServiceInterface.getRedPlusSimOnlyOfferByType(Constants.RedPlus_Allnet, this.getInjector().getFlowState().getSalesChannel());
                this.getInjector().getFlowState().redPlusAllnet.resetAllElements();
                this.getInjector().getFlowState().redPlusAllnet.addElement(simOnlyOffer.offerId);
            }

            if (Constants.RedPlus_Basic === this._type) {
                simOnlyOffer = generalOfferServiceInterface.getRedPlusSimOnlyOfferByType(Constants.RedPlus_Basic, this.getInjector().getFlowState().getSalesChannel());
                this.getInjector().getFlowState().redPlusBasic.resetAllElements();
                this.getInjector().getFlowState().redPlusBasic.addElement(simOnlyOffer.offerId);
            }

            if (Constants.RedPlus_Data === this._type) {
                simOnlyOffer = generalOfferServiceInterface.getRedPlusSimOnlyOfferByType(Constants.RedPlus_Data, this.getInjector().getFlowState().getSalesChannel());
                this.getInjector().getFlowState().redPlusData.resetAllElements();
                this.getInjector().getFlowState().redPlusData.addElement(simOnlyOffer.offerId);
            }

            this._accordion.find('.baseBox .price').html(simOnlyOffer.subcriptionPriceMonthly.value.toFixed(2).replace('.', ',') + ' &euro;');

            /**
             * Close accordion
             */
            this._accordion.find('.stateOneHead').trigger('click');

            this._injector.getEvent().trigger('redPlusOffer@changed');
            this._injector.getEvent().trigger('redPlusTracking@changed', {
                redPlusOption: this._offer,
                redPlusSelected: this._selected,
                redPlusName: this._type
            });

        });

        $('.cm-redplus').on('click', '.redplus', (evt: JQueryEventObject) => {
            evt.preventDefault();
            const viewOverlay = new ViewOverlay('', '', $('#nsf-red-plus-overlay-content').html());
            this.getInjector().getOverlay().open(viewOverlay);
            // hide checkBoxes
            $('#nsf-overlay .overlay-content').find('.addcard-box').hide();
        });

    }

    private setColorPickerHeight (): void {
        /**
         * Set the color picker divs to same max heigt because in some cases there are two lines on mobile devices
         */
        let maxHeight = 0;
        const that = this;
        setTimeout(function () {
            that._accordion.find('.device-module-tile').each((index, element) => {
                $(element).find('.color').removeAttr('style');
                const height = $(element).find('.color').outerHeight();
                if (height > maxHeight) {
                    maxHeight = height;
                }
            });
            that._accordion.find('.device-module-tile .color').height(maxHeight);
        }, 500);
    }

    private resetRedPlusWithSubLevel (redPlusCardParentElement: any): void {
        this._accordion.find('.device-module-tile.selected').removeClass('selected');
        redPlusCardParentElement.find('.selectionRedplusOnly').addClass('selected');
        this._accordion.find('.baseBox .price').html(this._offer.subcriptionPriceMonthly.value.toFixed(2).replace('.', ',') + ' &euro;');
    }

}
