/**
 * @module SalesFlow/model/type
 */

import NsfError from 'core/error';

import AttributeDefinition from './attribute-definition';

export default class Device {

    private _id: number;
    private _sortOrder: number;
    private _name: string;
    private _description: string = '';

    private _attributeDefinitions: AttributeDefinition[] = [];

    constructor (data: any, attributeDefinitions: AttributeDefinition[] = []) {
        this.parseData(data);

        for (const attributeDefinition of attributeDefinitions) {
            if (true === attributeDefinition.hidden) {
                continue;
            }
            if (this._id !== attributeDefinition.groupId) {
                continue;
            }
            this._attributeDefinitions.push(attributeDefinition);
        }

        this._attributeDefinitions.sort(function (a: AttributeDefinition, b: AttributeDefinition) {

            if (a.sortOrder > b.sortOrder) {

                return 1;
            }
            if (a.sortOrder < b.sortOrder) {

                return -1;
            }

            // a muss gleich b sein
            return 0;
        });

    }

    private parseData (data: any) {

        if (undefined === data.id) {
            new NsfError('id is mandatory when create a AttributeDefinition.');
        }

        if (undefined === data.sortOrder) {
            new NsfError('sortOrder is mandatory when create a AttributeDefinition.');
        }
        if (undefined === data.name) {
            new NsfError('name is mandatory when create a AttributeDefinition.');
        }

        this._id = parseInt(data.id, 10);

        this._sortOrder = parseInt(data.sortOrder, 10);

        this._name = data.name;

        if (undefined !== data.description) {
            this._description = data.description;
        }
    }

    public removeEmptyAttributes (): void {

        this._attributeDefinitions = this._attributeDefinitions.filter((attributeDefinition) => {
            return (undefined !== attributeDefinition.value);
        });

    }

    get id (): number {
        return this._id;
    }

    get sortOrder (): number {
        return this._sortOrder;
    }

    get name (): string {
        return this._name;
    }

    get description (): string {
        return this._description;
    }

    get attributeDefinition (): AttributeDefinition[] {
        return this._attributeDefinitions;
    }

}
