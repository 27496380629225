/**
 * @module SalesFlow/router
 */

import Injector from 'core/injector';

import { Controller } from 'controller/controller';

import Router from 'router/shared/router';
import { SkeletonService } from '../../service/render/skeleton-service';
import { BusinessTransactionContext } from 'core/ids';
import { Constants } from 'core/constants';

/**
 * The new sales flow root object
 */
export default abstract class Routing {

    protected _injector: Injector;

    protected _validPages: string[] = [];

    protected _btx: BusinessTransactionContext;

    protected _skeletonService: SkeletonService;

    constructor (injector: Injector) {

        this._injector = injector;
        this._skeletonService = new SkeletonService(this._injector);

        this.doSomethingBeforeRouterAreCreated();

    }

    protected abstract getRouter (): JQueryPromise<Router>;

    /**
     * Due we have the best cms of the world, we might have the need to do somthing before the page takes over control
     * E.g. If gigakombi we have to hide all not gigakombi subscription tiles and vice versa
     */
    protected doSomethingBeforeRouterAreCreated (): void {

    }

    protected getInjector (): Injector {
        return this._injector;
    }

    /**
     * This function returns the redirect URL with affiliate parameters to use it in case of nba call error
     * @param _redirectUrl
     */
    public getRedirectUrlWithAffiliateParameters (_redirectUrl: string): string {
        const searchObjectFromCurrentUrl = this._injector.getGetParameter().getLocationSearchParameters();
        const searchObjectFromRedirectUrl = this._injector.getGetParameter().getLocationSearchParameters(_redirectUrl);
        const urlWithoutParams = _redirectUrl.split('?')[0];
        const keysToManipulate = [
            'icmp',
            'b_id',
            'c_id',
            'j_id'
        ];

        keysToManipulate.map(key => {
            if (undefined !== searchObjectFromCurrentUrl[key]) {
                searchObjectFromRedirectUrl[key] = searchObjectFromCurrentUrl[key];
            }
        });

        return encodeURIComponent(urlWithoutParams) + this._injector.getGetParameter().getLocationSearchStringFromObject(searchObjectFromRedirectUrl);
    }

    public getCurrentPage (): string {

        const page = $('#nsf-dispatcher').data('page');

        // @TODO Maybe an error should be thrown here
        if (-1 === $.inArray(page, this._validPages)) {
            if (this._injector.getOptions().get('debug')) {
                console.log('The page name is not in the _validPages array');
            }

            return undefined;
        }

        return page;

    }

    public route (): void {

        this.getRouter().then(function (router: Router) {
            router.createController()
                .then((page: Controller) => {
                    page.bind();
                });
        });

    }

}
