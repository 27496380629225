/**
 * @module SalesFlow/model/type
 */

import {VluxJsonSimCard} from '../vlux-interface';
import SimCardAttributes from './attributes-simcard';

export default class SimCard {

    private _id: number;
    private _label: string;
    private _backendId: number;
    private _attributes: SimCardAttributes;

    constructor (data: any) {
        this.parseData(data);
    }

    private parseData (data: VluxJsonSimCard) {
        this._id = parseInt(data.id, 10);
        this._label = data.label;
        this._backendId = parseInt(data.backendId[0].value, 10);

        let attributes = {};

        if (undefined !== data.attributes && undefined !== data.attributes.attribute) {
            attributes = data.attributes.attribute;
        }

        this._attributes = new SimCardAttributes(attributes);
    }

    get id (): number {
        return this._id;
    }

    get label (): string {
        return this._label;
    }

    get backendId (): number {
        return this._backendId;
    }

    get attributes (): SimCardAttributes {
        return this._attributes;
    }
}
