(function () {
    'use strict';
    vf['tariff-row-module'] = {
        settings: {
            target: '.mod-tariff-row-module',
        },

        init: function (focusTariffId, context) {
            if (undefined === focusTariffId) {
                focusTariffId = 0;
            };
            var s = this.settings;
            $(s.target, context).addBack(s.target).each(function () {
                var $this = $(this);

                var getSliderWidth = function () {
                    return $this.find('.tariff-module-tiles-wrapper').width();
                };

                var getItemsCount = function () {
                    return $this.find('.tariff-module-tiles').children().length;
                };

                var getItemWidth = function () {

                    if ('desktop' === vf.util.layout(true)) {

                        var count = (getItemsCount());

                        if ($this.find('.tariff-module-tiles-wrapper').hasClass('scroll-pane')) {
                            var width = (getSliderWidth() / 100 * 33.33333333333);
                            $this.find('.tariff-module-tiles').css('width', width * count + 'px');
                            $this.find('.tariff-module-tile').css('width', width + 'px');
                        } else {
                            var width = (getSliderWidth() / 100 * 33.33333333333);
                            $this.find('.tariff-module-tiles').css('width', width * count + 'px');
                            $this.find('.tariff-module-tile').css('width', width + 'px');
                        }

                    }

                    if ('tablet' === vf.util.layout(true)) {

                        var width = (getSliderWidth() / 100 * 41.66666666666);
                        var count = (getItemsCount());

                        $this.find('.tariff-module-tiles').css('width', width * count + 'px');
                        $this.find('.tariff-module-tile').css('width', width + 'px');

                    }

                    if ('mobile' === vf.util.layout(true)) {

                        var width = ((getSliderWidth() - 3) / 100 * 83.33333333333);
                        var count = (getItemsCount());

                        $this.find('.tariff-module-tiles').css('width', width * count + 'px');
                        $this.find('.tariff-module-tile').css('width', width + 'px');

                    }
                };

                var getTileIndex = function (tariffId) {
                    var tariffIds = [];
                    $this.find('.tariff-module-tile').each(function (i, e) {
                        tariffIds.push(parseInt($(e).data('subscription-id')));
                    });
                    return tariffIds.indexOf(tariffId);
                };

                $(window).on('vf::resize', getItemWidth);
                getItemWidth();

                /**
                 * Init jScrollPane only on touch devices
                 */
                function isTouchDevice() {
                    return (('ontouchstart' in window) || (0 < navigator.MaxTouchPoints) || (0 < navigator.msMaxTouchPoints));
                }

                var api;
                var pane;

                var scrollToPos = getTileIndex(focusTariffId) - 1;

                if (0 > scrollToPos) { scrollToPos = 0; }

                if (!isTouchDevice()) {
                    pane = $this.find('.scroll-pane');
                    pane.jScrollPane();

                    api = pane.data('jsp');

                    if (undefined !== api && null !== api) {
                        if (-0 === api.getPercentScrolledX()) {
                            $this.find('.row-prev').hide();
                        }
                        if (1 === api.getPercentScrolledX()) {
                            $this.find('.row-next').hide();
                        }
                    }

                    pane.on(
                        'jsp-scroll-x',
                        function (event, srollPositionX, isAtLeft, isAtRight) {
                            if (isAtLeft) {
                                $this.find('.row-prev').hide();
                            } else {
                                $this.find('.row-prev').show();
                            }

                            if (isAtRight) {
                                $this.find('.row-next').hide();
                            } else {
                                $this.find('.row-next').show();
                            }
                        }
                    );

                    $this.on('click', '.row-next', function () {
                        api.scrollByX($this.find('.tariff-module-tile')[0].getBoundingClientRect().width, true);
                    });

                    $this.on('click', '.row-prev', function () {
                        api.scrollByX(-$this.find('.tariff-module-tile')[0].getBoundingClientRect().width, true);
                    });

                    $('.tg-head').on('click', function () {
                        setTimeout(function () {
                            if (api) {
                                api.destroy();

                                pane = $this.find('.scroll-pane');
                                pane.jScrollPane();

                                api = pane.data('jsp');
                            }
                        }, 1);
                    });

                    $('.mod-tariff-detail-akko .foot').on('click', function () {
                        var that = this;
                        setTimeout(function () {
                            var heighest;

                            $(s.target).find('.tariff-module-tile').each(function(index, item) {
                                if (undefined === heighest || heighest < $(item).outerHeight()) {
                                    heighest = $(item).outerHeight();
                                }
                            });

                            $(that).closest('.jspContainer').height(heighest);
                        }, 500);
                    });

                    try {
                        api.scrollByX($this.find('.tariff-module-tile')[0].getBoundingClientRect().width * scrollToPos, true);
                    }
                    catch (e) {}

                } else {
                    var scrollWidth = 0;
                    if ('desktop' === vf.util.layout(true)) {

                        if (16 === $this.find('.tariff-module-tiles').position().left) {
                            $this.find('.row-prev').hide();
                        }

                        if (-1 * ($this.find('.tariff-module-tiles').width() - $this.find('.scroll-pane').width() - ($this.find('.tariff-module-tile-wrapper:hidden').length * $this.find('.tariff-module-tile').outerWidth()) - 16) === $this.find('.tariff-module-tiles').position().left) {
                            $this.find('.row-next').hide();
                        }

                        $this.find('.scroll-pane').on('scroll', function () {
                            if (16 === $this.find('.tariff-module-tiles').position().left) {
                                $this.find('.row-prev').hide();
                            } else {
                                $this.find('.row-prev').show();
                            }

                            if (-1 * ($this.find('.tariff-module-tiles').width() - $this.find('.scroll-pane').width() - ($this.find('.tariff-module-tile-wrapper:hidden').length * $this.find('.tariff-module-tile').outerWidth()) - 16) === $this.find('.tariff-module-tiles').position().left) {
                                $this.find('.row-next').hide();
                            } else {
                                $this.find('.row-next').show();
                            }

                        });

                        $this.on('click', '.row-next', function () {
                            var scrollWidth = ($this.find('.tariff-module-tiles').position().left * -1) + ($this.find('.tariff-module-tile')[0].getBoundingClientRect().width + 16);
                            $this.find('.scroll-pane').animate({
                                scrollLeft: scrollWidth
                            }, 500);
                        });

                        $this.on('click', '.row-prev', function () {
                            var scrollWidth = ($this.find('.tariff-module-tiles').position().left * -1) - ($this.find('.tariff-module-tile')[0].getBoundingClientRect().width - 16);
                            $this.find('.scroll-pane').animate({
                                scrollLeft: scrollWidth
                            }, 500);
                        });

                    }

                    scrollWidth = ($this.find('.tariff-module-tiles').position().left * -1) + ($this.find('.tariff-module-tile')[0].getBoundingClientRect().width + 16);
                    $this.find('.scroll-pane').scrollLeft(scrollWidth * scrollToPos);

                }
            });
        },
    };
}(vf));
