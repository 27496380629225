/**
 * @module SalesFlow/controller-evolved
 */
import {ViewEvolvedElementVvlDeviceList} from 'view-evolved/element/vvl/view-evolved--element-vvl--device-list';

declare var $: JQueryStatic;
declare var vf: any;

import { Constants } from 'core/constants';
import {FlowCtas} from 'view/ctas/shared/flow-ctas';
import Injector from 'core/injector';
import {DeviceOverviewPageState} from 'controller/shared/device-overview-controller';
import Offer from 'view/view/shared/offer/offer';
import DeviceOffer from 'view/view/shared/offer/device-offer';
import Customer from 'shopbackend/customer';
import AtomicDevice from 'model/type/atomic-device';
import Subscription from 'model/type/subscription';
import ViewOverlayDeviceDetails from 'view/view/shared/overlay-device-details';
import ViewAlertWarning from 'view/view/shared/alert-warning';
import {ControllerEvolvedSharedDeviceOverview} from 'controller-evolved/shared/controller-evolved--shared--device-overview';
import {AbstractSubscriptionGroupSwitcher} from 'view/element/shared/subscription-group-switcher';
import {SalesChannelName, SubscriptionGroupName, SubscriptionIdPerSalesChannel} from 'core/ids';
import {ViewEvolvedElementVvlContractDataAccordion} from 'view-evolved/element/vvl/view-evolved--element-vvl--contract-data-accordion';
import {EvolvedProlongationInCurrentSubscription} from 'view-evolved/element/vvl/view-evolved--element-vvl--prolongation-in-current-subscription';
import {EvolvedSubscriptionSelectionDeviceOverviewVvl} from 'view-evolved/element/vvl/view-evolved--element-vvl--subscription-selection-device-overview';
import {ViewEvolvedElementVvlSimonlyHandling} from 'view-evolved/element/vvl/view-evolved--element-vvl--simonly-handling';
import {ModelEvolvedRepoSupervisor} from 'model-evolved/repo/model-evolved--repo--supervisor';
import {ViewEvolvedElementVvlSalesChannelSwitcher} from 'view-evolved/element/vvl/view-evolved--element--vvl--sales-channel-switcher';
import {ViewEvolvedCtasDeviceOverview} from 'view-evolved/ctas/vvl/view-evolved--ctas--device-overview';
import {ViewEvolvedElementVvlNoneChannelSwitcher} from 'view-evolved/element/vvl/view-evolved--element-vvl--none-channel-switcher';
import { StrikePriceService, StrikePriceInput } from 'rest-api-services/strike-price-service';
import { ViewEvolvedElementVvlStepperNoXsell } from 'view-evolved/element/vvl/view-evolved--element-vvl--stepper-no-x-sell';
import {NotificationSwitchToYoung} from 'view-evolved/notifications/inlife/notification-switch-to-young';
import { NotificationGigaKombiCase } from 'view-evolved/notifications/inlife/notification-gk-case';

export class ControllerEvolvedVvlDeviceOverview extends ControllerEvolvedSharedDeviceOverview {

    protected _deviceList: ViewEvolvedElementVvlDeviceList;

    protected _contractDataAccordion: ViewEvolvedElementVvlContractDataAccordion;
    protected _deviceOffersInitial: DeviceOffer[] = [];
    protected _simonlyHandling: ViewEvolvedElementVvlSimonlyHandling;

    protected _prolongationInCurrentSubscription: EvolvedProlongationInCurrentSubscription;

    private _focusAtomicDeviceId: number;

    protected _subscriptionSelection: EvolvedSubscriptionSelectionDeviceOverviewVvl;

    private _notificationContainer: HTMLDivElement;
    private _notificationSwitchToYoung: NotificationSwitchToYoung;
    protected _notificationForGigaKombiCases: NotificationGigaKombiCase;

    constructor (
        customer: Customer,
        salesChannel: SalesChannelName,
        atomicDeviceId: number,
        subscriptionId: number,
        focusSubscriptionIds: SubscriptionIdPerSalesChannel,
        reposSupervisor: ModelEvolvedRepoSupervisor,
        injector: Injector
    ) {

        super(
            salesChannel,
            atomicDeviceId,
            subscriptionId,
            focusSubscriptionIds,
            customer,
            reposSupervisor,
            injector
        );

        /**
         * In case of Winback Customer use other Stepper
         */
        if (customer.isWinbackCustomer || 0 === this._customer.vacantRedPlusSlots) {
            this._stepper = new ViewEvolvedElementVvlStepperNoXsell(injector);
        }

        const subscriptionGroup = this.getInjector().getFlowStateWithSalesChannel().getSubscriptionGroup();

        let focusSubscriptionId;
        // set recommended subscription and device on focus, if none is selected
        if (undefined === this._subscriptionId) {
            if (undefined !== this.getInjector().getFlowStateWithSalesChannel().getRecommendedSubscriptionId()) {
                focusSubscriptionId = this.getInjector().getFlowStateWithSalesChannel().getRecommendedSubscriptionId();
            } else {
                focusSubscriptionId = focusSubscriptionIds[subscriptionGroup];
            }
        }

        if (undefined === this._atomicDeviceId && undefined !== this.getInjector().getFlowStateWithSalesChannel().getRecommendedAtomicId()) {
            this._focusAtomicDeviceId = this.getInjector().getFlowStateWithSalesChannel().getRecommendedAtomicId();
        }

        this._deviceList = new ViewEvolvedElementVvlDeviceList(
            injector,
            this.getReposSupervisor().getAttributeRepo(),
            this._focusAtomicDeviceId
        );

        this._customer = this.getInjector().getFlowStateWithSalesChannel().customer;

        this._contractDataAccordion = new ViewEvolvedElementVvlContractDataAccordion(
            this._customer,
            injector
        );

        this._simonlyHandling = new ViewEvolvedElementVvlSimonlyHandling(injector);

        const currentSubscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(
            this.getReposSupervisor().getSubscriptionRepo().getSubscriptionIdByKiasId(this._customer.tariffKiasCode)
        );

        this._prolongationInCurrentSubscription = new EvolvedProlongationInCurrentSubscription(
            this._customer,
            currentSubscription,
            this.getInjector()
        );

    }

    protected createSubscriptionList (): EvolvedSubscriptionSelectionDeviceOverviewVvl {

        return new EvolvedSubscriptionSelectionDeviceOverviewVvl(
            this._subscriptionId,
            this.getInjector(),
            this._focusSubscriptionId
        );

    }

    protected createSubscriptionGroupSwitcher (): AbstractSubscriptionGroupSwitcher {

        if (false === this._customer.isYoungCustomer) {
            return new ViewEvolvedElementVvlNoneChannelSwitcher(
                this.getInjector()
            );
        }

        return new ViewEvolvedElementVvlSalesChannelSwitcher(
            this.getInjector()
        );
    }

    protected getSalesChannel (): SalesChannelName {
        return this._salesChannel;
    }

    protected getSubscriptionGroup (): SubscriptionGroupName {
        return this.getInjector().getFlowStateWithSalesChannel().getSubscriptionGroup();
    }

    protected createCtas (): FlowCtas {

        return new ViewEvolvedCtasDeviceOverview(this.getInjector());

    }

    protected getOneDeviceOffer (atomicDevice: AtomicDevice, subscription: Subscription): DeviceOffer {

        const vluxOffer = this._generalSalesObjectInterface.getSimHardwareOfferByAtomicDeviceIdAndSubscriptionId(
            atomicDevice.id,
            subscription.id,
            this._btx,
            this.getSalesChannel()
        );

        /**
         * Get selected optional services
         */
        const optionalServiceIds = this.getInjector().getFlowStateWithSalesChannel().optionalServiceIds.elements;

        return new DeviceOffer(
            atomicDevice.getDevice().getAtomicDeviceById(vluxOffer.deviceId),
            subscription,
            vluxOffer, [],
            optionalServiceIds, []
        );

    }

   /**
    * [CO-13330] Logic for Gigakombi customers selecting a Young 2019 Xs or S tariff,
    * Showing the notifications that they will loose their Gigakombi discount.
    * @param selectedSubscriptionId
    */
   protected handleSwitchToYoungNotification (selectedSubscriptionId: number) {
       this.createNotificationSwitchToYoung();
       this._notificationSwitchToYoung.selectedSubscriptionId = selectedSubscriptionId;
       this._notificationSwitchToYoung.bind();
   }

   /**
    * displays BR5 SOC Notification all other Gigakombi Notifications have been skipped in [CO-27128]
    * @param notificationContent
    * @param notificationHeadline
    * @param HTMLId
    * @param additionalClass
    * @protected
    */
    protected showGigakombiNotification (notificationContent: string, notificationHeadline: string, HTMLId?: string, additionalClass?: string) {

        /**
         * Only show the notification if it's not present
         */
        if (0 === $(`#${HTMLId}`).length) {

            const HBSTemplate = 'information-gigakombi-br5';
            const dataForInformationHBS = {
                notificationContent: notificationContent,
                notificationHeadline: notificationHeadline,
                toggleViewSelector: HTMLId,
                additionalClass: additionalClass

            };

            $(`#notificationContainer`).append(
                this.getInjector().getTemplates().render(HBSTemplate, dataForInformationHBS)
            );
        }
    }

    /**
     * CO-19571: remove gigakombi notifications for all red tariffs and replace them
     *           by a notification only displayed to customers who have the BR5 SOC.
     * @param selectedSubscriptionId
     */
    protected handleGigakombiNotification (selectedSubscriptionId: number) {
        const htmlSelectorAndClass = 'br5-gigakombi-notification';
        if (this._customer.isGigaKombiCustomer && this._customer.hasGigakombiBR5 && selectedSubscriptionId !== Constants.YoungS_Id) {
            const soho = this._customer.isSohoCustomer ? 'soho.' : '';
            this.showGigakombiNotification(
                this.getInjector().getStrings().get(`vvl-inlife.gigakombi-notification-br5.${soho}content`),
                this.getInjector().getStrings().get('vvl-inlife.gigakombi-notification.headline'),
                htmlSelectorAndClass,
                htmlSelectorAndClass
            );
        } else {
            $(`#${htmlSelectorAndClass}`).remove();
        }
     }
     protected handleGKNotification () {
            this._notificationForGigaKombiCases = new NotificationGigaKombiCase(
                this.getInjector(),
                '#notificationContainer',
                this._customer.isGigaKombiCustomer,
                this._customer.hasGigakombiCase
            );
    }

    /**
     * adds a div after nsf-subscription-device-detail-wrappere where all
     * different notifications are collected
     * @protected
     */
    protected addNotificationContainer () {
        if (this._customer.isGigaKombiCustomer) {
            this._notificationContainer = document.createElement('div');
            this._notificationContainer.classList.add('notificationContainer');
            this._notificationContainer.id = 'notificationContainer';
            $('#nsf-hardware-only-toggle').after(this._notificationContainer);
        }
    }

    /**
     * Logic for handling the notifications on VVL self selection page.
     */
    protected notificationBoxHandler () {

        const selectedSubscription = this.getActiveOffer();
        if (undefined !== selectedSubscription) {
            const selectedSubscriptionId = selectedSubscription.subscriptionId;
            const selectedSubscriptionGroupId = selectedSubscription.subscription.subscriptionGroupId;
            const selectedSubscriptionName = selectedSubscription.subscriptionName;

            /**
             * Handle switching to a Young 2019 XS or S notification for Gigakombi Customers
             */
            this.handleSwitchToYoungNotification(selectedSubscriptionId);

            /**
             * Handle GigaKombi Notification for BR5 Customers.
             * Customers will see the 5€ tv discount (i.e. br5) on their Festnetzrechnung
             */
            this.handleGigakombiNotification(selectedSubscriptionId);

        }

    }

    /**
     * Creates a notification that, when the user switches to a young xs or
     * young s tariff, he looses his Gigakombi discount
     * @protected
     */
    protected createNotificationSwitchToYoung () {
        this._notificationSwitchToYoung = new NotificationSwitchToYoung(
            this.getInjector(),
            '#notificationContainer',
            this._subscriptionId
        );
    }

    /**
     *
     * @param salesChannel
     */
    protected handleEventSalesChannelNameChanged (salesChannel: SalesChannelName): void  {

        this._salesChannel = salesChannel;

        const subscriptionId = this.getInjector().getFlowStateWithSalesChannel().getSubscriptionId();

        this._subscriptionId = subscriptionId;

        this._subscriptionSelection.setActiveSubscriptionId(this._subscriptionId);

        this.fillOfferOfferCollection();

        this.getInjector().getEvent().trigger('SalesChannel@changed', salesChannel);

        // @TODO why handleAtomicDeviceIdChanged was in orginal method
        // this.handleAtomicDeviceIdChanged(atomicDeviceId, avoidTracking);

    }

    protected handleEventSalesChannelChanged (salesChannel: SalesChannelName) {

        this._deviceList.update(
            this.getInjector().getOfferCollection().getDevices()
        );

        this.getInjector().getEvent().trigger('SalesChannel@loaded', salesChannel);

        $.when(this._subscriptionSelection.animateTileSwitch('fadeOut', 400, [])).done(() => {

            $.when(this._subscriptionSelection.animateTileSwitch('fadeIn', 300, [])).done( () => {

                let activeSubscriptionId: any = undefined;

                const activeOffer = this.getInjector().getOfferCollection().getActiveOffer();
                if (undefined !== activeOffer) {
                    activeSubscriptionId = activeOffer.subscriptionId;
                }
                const savedSubscriptionId = this.getInjector().getFlowState().getSubscriptionId();
                let newOffer: any = undefined;

                if (undefined !== activeSubscriptionId && savedSubscriptionId === activeSubscriptionId) {
                    newOffer = this.getInjector().getOfferCollection().getActiveOffer();
                }

                this.getInjector().getEvent().trigger('offer@changed', {
                    offer: newOffer
                });

                // @TODO this should only an update an not an render... Especially while in vvl no device specific stuff is in subscription list is maybe can be removed
                this._subscriptionSelection.render(
                    this.getInjector().getOfferCollection().getSubscriptions()
                );

                this._subscriptionSelection._slider.updateHeight();
                this._subscriptionSelection._slider.scrollToFocus();

            });

        });

    }

    /**
     * A device is selected
     *
     * @param atomicDeviceId
     * @param avoidTracking
     */
    private handleEventAtomicDeviceIdChanged (atomicDeviceId: number, avoidTracking: boolean) {

        this._atomicDeviceId = atomicDeviceId;

        this._pageState = new DeviceOverviewPageState(this._subscriptionId, this._atomicDeviceId, this.getInjector().getFlowStateWithSalesChannel().getHardwareOnly());

        const selectedDeviceId = $('.device-module-tile.selected').data('device-id');

        // A device is selected, but not yet a subscription -> Scroll to scubsriptions
        if (undefined !== this._atomicDeviceId && undefined === this._subscriptionId) {

            const offset = $('header').height() - 20;

            $('html, body').animate({
                scrollTop: $('#subscriptions').offset().top - offset
            }, 1000);

        }

        const tradeInChkbx = $(`.checkbox-tradeIn-${selectedDeviceId}`);

        if (0 < tradeInChkbx.length) {
            if (!tradeInChkbx.hasClass('selected')) {
                this.getInjector().getFlowStateWithSalesChannel().optionalServiceIds.removeElement(Constants.VVL_TradeInDiscount_Id);
            } else {
                this.getInjector().getFlowStateWithSalesChannel().optionalServiceIds.addElement(Constants.VVL_TradeInDiscount_Id);
            }
        }
        this._subscriptionSelection.setScrollToFocus(false);
        this._subscriptionSelection.update(this.getSubscriptions());

        // atomicDeviceId has changed and is now undefined or no subscription is selected
        // -> We can make no offer
        // @TODO could this be n vvl??
        if (undefined === atomicDeviceId && undefined === this._subscriptionId) {
            this.getInjector().getEvent().trigger('offer@none');

            return;
        }

        this._simonlyHandling.update(false);

        const offer: Offer = this.getActiveOffer();

        if (undefined === offer) {
            return;
        }

        this.getInjector().getOfferCollection().setActiveOffer(offer);

        this.getInjector().getEvent().trigger('offer@changed', {
            offer: offer
        });

        if (true !== avoidTracking) {
            this.getInjector().getEvent().trigger('pageviewTracking@changed', {
                pageName: 'all smartphones and tariffs',
                pageType: (undefined === offer) ? 'product listing' : 'product detail',
                deviceOffer: offer,
                btx: this._btx,
                currentPage: this._injector.getRouting().getCurrentPage(),
                customer: this.getInjector().getFlowStateWithSalesChannel().getCustomer()
            });
        }

        // load device strike price only for VVL Consumer, VVL Soho, VVL F&F
        if (undefined !== this._subscriptionId && ('consumer' === this.getSalesChannel() || 'soho' === this.getSalesChannel() || 'familyfriends' === this.getSalesChannel())) {
            const requestParamsDeviceStrikePrice = this.getInjector().getOfferCollection().getDevices().map((deviceOffer: DeviceOffer) => {
                return {productId: deviceOffer.atomicDeviceId, subscriptionId: deviceOffer.offer.subscriptionId};
            });

            this.loadDeviceStrikePrice(requestParamsDeviceStrikePrice);
        }

    }

    /**
     * A subscription hast been selected
     *
     * @TODO Handle simonly
     *
     * With the new subscription we need to update all device tiles
     *
     * @param subscriptionId
     * @param avoidTracking
     */
    private handleEventSubscriptionIdChanged (subscriptionId: number, avoidTracking: boolean) {

        this._subscriptionId = subscriptionId;

        this._pageState = new DeviceOverviewPageState(this._subscriptionId, this._atomicDeviceId);

        this._subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(this.getSubscriptionId());

        // For  INC-29403  we need to have the deviceOffersInitial always getting the latest device offers
        // based on what the user has selected either color or GB
        this._deviceOffersInitial = this.getInjector().getOfferCollection().getDevices();

        let deviceOffers = this._deviceOffersInitial.map((deviceOffer: DeviceOffer) => {

            const offer = this._generalSalesObjectInterface.getSimHardwareOfferByAtomicDeviceIdAndSubscriptionId(
                deviceOffer.atomicDevice.id,
                this._subscription.id,
                this._btx,
                this.getSalesChannel()
            );

            // add only found offers
            if (undefined !== offer) {
                // We reset red plus cards when user enters tariff device selection page, so we pass red plus cards as an empty array
                return new DeviceOffer(
                    deviceOffer.atomicDevice,
                    this._subscription,
                    offer,
                    [],
                    this.getInjector().getFlowStateWithSalesChannel().optionalServiceIds.elements
                );
            }

        });

        // remove all deviceOffers, if there are undefined
        deviceOffers = deviceOffers.filter(function (n) {
            return n !== undefined;
        });

        this.getInjector().getOfferCollection().setDevices(deviceOffers);

        for (const deviceOffer of this.getInjector().getOfferCollection().getDevices()) {

            this.getInjector().getEvent().trigger('device-tile@offerChanged:' + deviceOffer.atomicDevice.device.id, {
                deviceOffer: deviceOffer
            });

        }

        this.getInjector().getFlowStateWithSalesChannel().setSubscriptionId(this.getSubscriptionId());

        this._deviceList.update(this.getInjector().getOfferCollection().getDevices());

        this.getInjector().getOfferCollection().setActiveOffer(
            this.getActiveOffer()
        );

        this.setSubscriptionName(this._subscription.name);

        this.getInjector().getFlowStateWithSalesChannel().setSubscriptionId(this.getInjector().getOfferCollection().getActiveOffer().subscriptionId);

        if (true === this.getInjector().getOfferCollection().getActiveOffer().isSimOnly()) {
            this._simonlyHandling.update(true);
        } else {
            this._simonlyHandling.update(false);
        }

        this.getInjector().getEvent().trigger('offer@changed', {
            offer: this.getInjector().getOfferCollection().getActiveOffer()
        });

        if (true !== avoidTracking) {
            this.getInjector().getEvent().trigger('pageviewTracking@changed', {
                pageName: 'all smartphones and tariffs',
                pageType: (undefined === this.getInjector().getOfferCollection().getActiveOffer()) ? 'product listing' : 'product detail',
                deviceOffer: this.getInjector().getOfferCollection().getActiveOffer(),
                btx: this._btx,
                currentPage: this._injector.getRouting().getCurrentPage(),
                customer: this.getInjector().getFlowStateWithSalesChannel().getCustomer()
            });
        }

    }

    /**
     * Active atomicId of a deviceTile has changes. e.g. by color picker or bundle dropdown
     *
     * We need to update tiles offer and send it back to tile
     * If the tile is currently selected we need to update the current offer as well
     *
     * @param atomicDevice
     * @param subscription
     */
    private handleEventDeviceTileAtomicIdChanged (atomicDevice: AtomicDevice, subscription: Subscription): void {

        const deviceOffer = this.getOneDeviceOffer(atomicDevice, subscription);

        const selectedDeviceId = $('.device-module-tile.selected').data('device-id');

        const deviceOffers = this.getInjector().getOfferCollection().getDevices();

        // replace matching this._deviceOffer
        // @TODO instead of looping here fetch one device offer from offerCollection
        for (let i = 0, x = deviceOffers.length; i < x; i += 1) {

            if (deviceOffers[i].atomicDevice.device.id === deviceOffer.atomicDevice.device.id) {

                deviceOffers[i] = deviceOffer;

                // is the offer that we are replacing to active one?
                if (selectedDeviceId === deviceOffer.atomicDevice.device.id) {

                    this._atomicDeviceId = deviceOffer.atomicDeviceId;

                    this.getInjector().getOfferCollection().setActiveOffer(deviceOffer);
                    this.getInjector().getOfferCollection().setDevices(deviceOffers);

                    // we changed the atomic device of the active offer.
                    // most likely the onetime price per subscription has changed as well
                    this.getInjector().getOfferCollection().setSubscriptions(
                        this.getSubscriptions()
                    );

                    this._subscriptionSelection.update(
                        this.getInjector().getOfferCollection().getSubscriptions()
                    );

                    this.getInjector().getFlowStateWithSalesChannel().setAtomicDeviceId(deviceOffer.atomicDevice.id, true);
                    this.getInjector().getEvent().trigger('offer@changed', {
                        offer: deviceOffer
                    });

                }

                // no need to loop up to the end while we had  a match
                break;

            }

        }

        this.getInjector().getEvent().trigger('device-tile@offerChanged:' + deviceOffer.atomicDevice.device.id, {
            deviceOffer: deviceOffer
        });

    }

    /**
     * Insurance has been (un)checked
     *
     * While all insurance checkboxes are checked together, we need to update all deviceOffers and send them to all tiles
     * If a device is already selected, we update the current offer as well
     *
     * @param atomicDevice
     * @param subscription
     */
    private handleEventDeviceTileInsuranceChanged (atomicDevice: AtomicDevice, subscription: Subscription, isSelected: boolean): void {

        const deviceOffers = this.getInjector().getOfferCollection().getDevices().map(deviceOffer => {

            // We reset red plus cards when user enters tariff device selection page, so we pass red plus cards as an empty array
            return new DeviceOffer(
                deviceOffer.atomicDevice,
                deviceOffer.subscription,
                deviceOffer.offer,
                [],
                this.getInjector().getFlowStateWithSalesChannel().optionalServiceIds.elements
            );

        });

        this.getInjector().getOfferCollection().setDevices(deviceOffers);

        const selectedDeviceId = $('.device-module-tile.selected').data('device-id');
        const isSubscriptionSelected = this.getInjector().getFlowStateWithSalesChannel().getSubscriptionId();

        for (const deviceOffer of this.getInjector().getOfferCollection().getDevices()) {

            this.getInjector().getEvent().trigger('device-tile@offerChanged:' + deviceOffer.atomicDevice.device.id, {
                deviceOffer: deviceOffer
            });

            // is the offer that we are replacing to active one? and is there a subscription tile already selected?
            if (selectedDeviceId === deviceOffer.atomicDevice.device.id && undefined !== isSubscriptionSelected) {
                this.getInjector().getEvent().trigger('offer@changed', {
                    offer: deviceOffer
                });
            }

        }

        // automatic select device, if insuranceBox is selected
        if (true === isSelected) {

            const deviceOffer = this.getOneDeviceOffer(atomicDevice, subscription);

            this.getInjector().getFlowStateWithSalesChannel().setAtomicDeviceId(deviceOffer.atomicDevice.id, false);

            // Only trigger this event if a subscription tile is selected
            if (undefined !== isSubscriptionSelected) {
                this.getInjector().getEvent().trigger('offer@changed', {
                    offer: deviceOffer
                });
            }

            this.getInjector().getEvent().trigger('atomicDeviceId@changedByInsurance', {
                atomicDeviceId: deviceOffer.atomicDeviceId
            });
        }
    }

    /**
     *
     * @param deviceOffer
     */
    protected handleEventDeviceTileOpenDetailOverlay (deviceOffer: DeviceOffer): void  {

        const viewOverlay = new ViewOverlayDeviceDetails(this._injector, deviceOffer, this.getReposSupervisor().getAttributeRepo());
        this.getInjector().getOverlay().open(viewOverlay, 'overlay_device_details');
        vf.tabs.init();

        vf['responsive-table'].init();

    }

    /**
     * Handle the toggling of the notifications
     */
    protected handleEventNotificationDiscountsToggle (): void  {
        this.notificationBoxHandler();
    }

    public events () {

        this.getInjector().getEvent().listen('SalesChannelName@changed', (eventObject: JQueryEventObject, data: any) => {

            const salesChannel: SalesChannelName = data;

            if (Constants.SALESCHANNEL_CONSUMER !== salesChannel && Constants.SALESCHANNEL_YOUNG !== salesChannel) {
                return;
            }

            this.handleEventSalesChannelNameChanged(salesChannel);

        });

        this.getInjector().getEvent().listen('SalesChannel@changed', (eventObject: JQueryEventObject, data: any) => {

            const salesChannel = data as SalesChannelName;

            if (Constants.SALESCHANNEL_CONSUMER !== salesChannel && Constants.SALESCHANNEL_YOUNG !== salesChannel) {

                return undefined;

            }

            this.handleEventSalesChannelChanged(salesChannel);

        });

        this.getInjector().getEvent().listen('atomicDeviceId@changed', (eventObject: JQueryEventObject, data: any): void => {

            const atomicDeviceId: number = data.atomicDeviceId;

            if (this._atomicDeviceId === atomicDeviceId) {
                return;
            }

            const avoidTracking = (true === data.avoidTracking) ? true : false;

            this.handleEventAtomicDeviceIdChanged(atomicDeviceId, avoidTracking);

        });

        this.getInjector().getEvent().listen('subscriptionId@changed', (eventObject: JQueryEventObject, data: any) => {

            const subscriptionId: number = data.subscriptionId;

            if (this._subscriptionId === subscriptionId) {
                return;
            }

            const avoidTracking = (true === data.avoidTracking) ? true : false;

            this.handleEventSubscriptionIdChanged(subscriptionId, avoidTracking);

            // load device strike price only for VVL Consumer, VVL Soho, VVL F&F
            if (undefined !== this._subscriptionId && ('consumer' === this.getSalesChannel() || 'soho' === this.getSalesChannel() || 'familyfriends' === this.getSalesChannel())) {
                const requestParamsDeviceStrikePrice = this.getInjector().getOfferCollection().getDevices().map((deviceOffer: DeviceOffer) => {
                    return {productId: deviceOffer.atomicDeviceId, subscriptionId: deviceOffer.offer.subscriptionId};
                });

                this.loadDeviceStrikePrice(requestParamsDeviceStrikePrice);
            }

        });

        this.getInjector().getEvent().listen('device-tile@atomicIdChanged', (eventObject: JQueryEventObject, data: any) => {

            const atomicDevice: AtomicDevice = data.atomicDevice;
            const subscription: Subscription = data.subscription;

            this.handleEventDeviceTileAtomicIdChanged(atomicDevice, subscription);

            // load device strike price only for VVL Consumer, VVL Soho, VVL F&F
            if (undefined !== this._subscriptionId && ('consumer' === this.getSalesChannel() || 'soho' === this.getSalesChannel() || 'familyfriends' === this.getSalesChannel())) {
                const requestParamsDeviceStrikePrice = this.getInjector().getOfferCollection().getDevices().map((deviceOffer: DeviceOffer) => {
                    return {productId: deviceOffer.atomicDeviceId, subscriptionId: deviceOffer.offer.subscriptionId};
                });

                this.loadDeviceStrikePrice(requestParamsDeviceStrikePrice);
            }

        });

        this.getInjector().getEvent().listen('device-tile@insuranceChanged', (eventObject: JQueryEventObject, data: any) => {
            const atomicDevice: AtomicDevice = data.atomicDevice;
            const subscription: Subscription = data.subscription;
            const isSelected: boolean = data.insuranceBoxSelected;

            this.handleEventDeviceTileInsuranceChanged(atomicDevice, subscription, isSelected);

            // load device strike price only for VVL Consumer, VVL Soho, VVL F&F
            if (undefined !== this._subscriptionId && ('consumer' === this.getSalesChannel() || 'soho' === this.getSalesChannel() || 'familyfriends' === this.getSalesChannel())) {
                const requestParamsDeviceStrikePrice = this.getInjector().getOfferCollection().getDevices().map((deviceOffer: DeviceOffer) => {
                    return {productId: deviceOffer.atomicDeviceId, subscriptionId: deviceOffer.offer.subscriptionId};
                });

                this.loadDeviceStrikePrice(requestParamsDeviceStrikePrice);
            }

        });

        this.getInjector().getEvent().listen('device-tile@openDetailOverlay', (eventObject: JQueryEventObject, data: any) => {

            const deviceOffer: DeviceOffer = data.deviceOffer;

            this.handleEventDeviceTileOpenDetailOverlay(deviceOffer);

        });

        this.getInjector().getEvent().listen('tradein@changed', (eventObject: JQueryEventObject, data: any) => {

            this.handleEventAtomicDeviceIdChanged(data.atomicDeviceId, false);

        });

        /**
         * A listener to be able to trigger it in the subscription-selection-device-overview view.
         */
        this.getInjector().getEvent().listen('notification-discounts@toggle', (eventObject: JQueryEventObject, subscription: any) => {
                this.handleEventNotificationDiscountsToggle();
        });
    }

    /**
     * @TODO can this uses 1:1 in bnt's  device overview page
     */
    protected getActiveDeviceOffer (): DeviceOffer {

        let activeOffer: DeviceOffer = super.getActiveDeviceOffer();

        if (undefined !== activeOffer) {
                const activeAtomicDevice: AtomicDevice = activeOffer.atomicDevice.device.getAtomicDeviceById(this.getInjector().getFlowStateWithSalesChannel().getAtomicDeviceId());

                const offer = this._generalSalesObjectInterface.getSimHardwareOfferByAtomicDeviceIdAndSubscriptionId(
                    activeAtomicDevice.id,
                    activeOffer.subscription.id,
                    this._btx,
                    this.getSalesChannel()
                );
                // active atomic device is not the first one, so we have to correct t
                if (this.getInjector().getFlowStateWithSalesChannel().getAtomicDeviceId() !== activeOffer.atomicDeviceId) {
                    if (undefined !== offer) {
                        // We reset red plus cards when user enters tariff device selection page, so we pass red plus cards as an empty array
                        activeOffer = new DeviceOffer(
                            activeAtomicDevice,
                            activeOffer.subscription,
                            offer,
                            [],
                            this.getInjector().getFlowStateWithSalesChannel().optionalServiceIds.elements
                        );

                        const deviceOffers = this.getInjector().getOfferCollection().getDevices();

                        for (let i = 0, x = deviceOffers.length; i < x; i += 1) {

                            if (true === deviceOffers[i].isDeviceSelected) {
                                this.getInjector().getOfferCollection().replaceDevice(i, activeOffer);

                                break;
                            }

                        }

                    }

                }

            return new DeviceOffer(
                activeAtomicDevice,
                activeOffer.subscription,
                offer,
                [],
                this.getInjector().getFlowStateWithSalesChannel().optionalServiceIds.elements
            );
        }

        return activeOffer;
    }

    protected tracking () {
        const activeOffer = this.getActiveOffer();

        this.getInjector().getEvent().trigger('pageviewTracking@onload',
            {
                subscription: this._subscription,
                pageName: 'all smartphones and tariffs',
                pageType: (undefined === activeOffer) ? 'product listing' : 'product detail',
                deviceOffer: activeOffer,
                btx: this._btx,
                currentPage: this._injector.getRouting().getCurrentPage(),
                customer: this.getInjector().getFlowStateWithSalesChannel().getCustomer()
            }
        );
    }

    public async loadDeviceStrikePrice (requestParamsDeviceStrikePrice: any) {

        const deviceOffers: DeviceOffer[] = this.getInjector().getOfferCollection().getDevices();

        const strikePriceService = new StrikePriceService();
        // call strike price service
        const data = await strikePriceService.getStrikePrice(requestParamsDeviceStrikePrice);

            for (let i = 0, x = requestParamsDeviceStrikePrice.length; i < x; i += 1) {
                if (deviceOffers[i].atomicDeviceId === data[i].productId) {
                    deviceOffers[i].setOnetimeDeviceStrikePrice(data[i].price);
                }
            }

            this.getInjector().getOfferCollection().setDevices(deviceOffers);
            this._deviceList.update(this.getInjector().getOfferCollection().getDevices());
    }

    public bind (): void {

        // We reset all red plus cards when user enter tariff device selection page
        this.resetRedPlusCards();

        this._contractDataAccordion.bind();

        // Attention: While _prolongationInCurrentSubscription removes all unused subscription tile from old portfolia, this has to be invoked before super.bind!!!
        this._prolongationInCurrentSubscription.bind();

        if (true === this.getInjector().getFlowStateWithSalesChannel().getRecommendedInsurance()) {
            this.getInjector().getFlowStateWithSalesChannel().optionalServiceIds.addElement(158);
        }

        super.bind();

        $('#subscriptions').show();

        this._simonlyHandling.bind(this._customer.isSimOnlyVvlAllowed);

        const selectedDeviceOffer = this.getInjector().getOfferCollection().getActiveOffer();

        // load device strike price only for VVL Consumer, VVL Soho, VVL F&F
        if (undefined !== this._subscriptionId && ('consumer' === this.getSalesChannel() || 'soho' === this.getSalesChannel() || 'familyfriends' === this.getSalesChannel())) {
            const requestParamsDeviceStrikePrice = this.getInjector().getOfferCollection().getDevices().map((deviceOffer: DeviceOffer) => {
                return {productId: deviceOffer.atomicDeviceId, subscriptionId: deviceOffer.offer.subscriptionId};
            });

            this.loadDeviceStrikePrice(requestParamsDeviceStrikePrice);
        }

        /**
         * Check if a notification needs to be shown after page has loaded and show the right one
         */
        if (undefined !== selectedDeviceOffer) {
            this.notificationBoxHandler();
        }

        if (undefined !== selectedDeviceOffer && selectedDeviceOffer.isSimOnly()) {
            this._simonlyHandling.update(true);
        }

        this._pricebox.setAnnotationPriceOnce('hide');

        // adds a notification Container div to the poge
        this.addNotificationContainer();

        // call initially because the notification shall be shown even without a selected tariff
        this.handleGigakombiNotification(this._subscriptionId);

        if (this._customer.isGigaKombiCustomer && !!Constants.gkNotificationData[this._customer.hasGigakombiCase]) {
            this.handleGKNotification();
            this._notificationForGigaKombiCases.bind();
        }
    }
}
