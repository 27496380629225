/**
 * @module SalesFlow/model/repo
 */

import NsfError from 'core/error';

import {Repo} from 'model/repo/repo';
import AttributeGroup from 'model/type/attribute-group';
import AttributeDefinition from 'model/type/attribute-definition';

import AttributesParser from 'model/type/attributes-parser';

interface IdMapping {
    [index: number]: number;
}

export default class AttributeRepo extends Repo {

    private _groupIdMapping: IdMapping = {};
    private _definitionIdMapping: IdMapping = {};

    private _attributeGroups: AttributeGroup[] = [];
    private _attributeDefinition: AttributeDefinition[] = [];

    constructor (json: any) {
        super();
        this.rawData = json;
        this.parseData(json);
    }

    /**
     * @TODO create an interface for products json
     */
    protected parseData (json: any): NsfError {

        if (undefined === json.attributeDefinitions) {
            return new NsfError('attributeDefinitions is mandatory when create a AttributeRepo.');
        }

        if (undefined === json.attributeDefinitions.attributes) {
            return new NsfError('attributes is mandatory when create a AttributeRepo.');
        }

        if (undefined === json.attributeDefinitions.attributes.attribute) {
            return new NsfError('attributes.attribute must be an array when create a AttributeRepo.');
        }

        if (0 === json.attributeDefinitions.attributes.attribute.length) {
            return new NsfError('attributeDefinitions.attributes.attribute is empty when create a AttributeRepo.');
        }

        if (undefined === json.attributeDefinitions.groups) {
            return new NsfError('attributeDefinitions.groups is mandatory when create a AttributeRepo.');
        }
        if (undefined === json.attributeDefinitions.groups.group) {
            return new NsfError('attributeDefinitions.groups.group is mandatory when create a AttributeRepo.');
        }

        if (undefined === json.attributeDefinitions.groups.group.length) {
            return new NsfError('attributeDefinitions.groups.group must be an array when create a AttributeRepo.');
        }

        if (0 === json.attributeDefinitions.groups.group.length) {
            return new NsfError('attributeDefinitions.groups.group is empty when create a AttributeRepo.');
        }

        const attributeDefinitions: any = json.attributeDefinitions.attributes.attribute;

        this._attributeDefinition = attributeDefinitions.map((attributeDefinition: any) => {
            return new AttributeDefinition(attributeDefinition);
        });

        for (let i: number = 0, x = this._attributeDefinition.length; i < x; i += 1) {
            this._definitionIdMapping[this._attributeDefinition[i].id] = i;
        }

        const attributeGroups: any = json.attributeDefinitions.groups.group;

        this._attributeGroups = attributeGroups.map((attributeGroup: any) => {
            return new AttributeGroup(attributeGroup, this._attributeDefinition);
        });

        this._attributeGroups = this._attributeGroups.filter((attributeGroup) => {
            const id = attributeGroup.id.toString();

            if (undefined === AttributesParser.attributesGroups[id]) {
                return false;
            }

            if (false === AttributesParser.attributesGroups[id].showInFrontend) {
                return false;
            }

            return true;
        });

        this._attributeGroups.sort(function (a: AttributeGroup, b: AttributeGroup) {
            if (a.sortOrder > b.sortOrder) {
                return 1;
            }
            if (a.sortOrder < b.sortOrder) {
                return -1;
            }

            // a muss gleich b sein
            return 0;
        });

        for (let i: number = 0, x = this._attributeGroups.length; i < x; i += 1) {
            this._groupIdMapping[this._attributeGroups[i].id] = i;
        }

        return undefined;
    }

    public getAttributeGroups (): AttributeGroup[] {
        return this._attributeGroups;
    }

    public getAttributeGroup (id: number): AttributeGroup {

        const index = this._groupIdMapping[id];

        if (undefined === index) {
            return undefined;
        }

        return this._attributeGroups[index];
    }

    public getAttributeDefinition (id: number): AttributeDefinition {

        const index = this._definitionIdMapping[id];

        if (undefined === index) {
            return undefined;
        }

        return this._attributeDefinition[index];
    }

}
