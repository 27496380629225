/**
 * @module SalesFlow/core
 */

declare var $: JQueryStatic;

/**
 * Read options that are stored in data-attributes
 */
export default class Strings {

    private _element: JQuery;

    private _texts: any = {};

    constructor () {
        this._element = $('#nsf-strings');

        this._element.find('div[data-name]').each((index, item) => {

            const name = $(item).data('name');
            let text;
            if ('hotline.number' === name) {
                text = $(item).text();
            } else {
                text = $(item).html();
            }

            this._texts[name] = text;

        });

    }

    public getAll (): any {
        return this._texts;
    }

    public get (name: string): string {

        if (undefined === this._texts[name]) {

            return '';

        }

        return this._texts[name];

    }
}
