/**
 * @module SalesFlow/controller
 */

import {FlowCtas} from 'view/ctas/shared/flow-ctas';

declare var $: JQueryStatic;

import { Constants } from 'core/constants';

import Injector from 'core/injector';
import Pricebox from 'view/element/shared/pricebox';
import {SalesChannelName} from 'core/ids';

import Subscription from 'model/type/subscription';

import GeneralSalesObjectInterface from 'service/general-sales-object/general-sales-object-interface';

import Offer from 'model/type/offer';

import Customer from 'shopbackend/customer';
import SimOnlyOffer from 'view/view/shared/offer/sim-only-offer';
import DeviceOffer from 'view/view/shared/offer/device-offer';
import {ControllerEvolvedFlowBase} from 'controller-evolved/controller-evolved--flow-base';
import {ModelEvolvedRepoSupervisor} from 'model-evolved/repo/model-evolved--repo--supervisor';
import {ViewEvolvedCtasXSell} from 'view-evolved/ctas/vvl/view-evolved--ctas--x-sell';
import {ViewEvolvedElementVvlAdditionalCard} from 'view-evolved/element/vvl/view-evolved--element-vvl--additional-card';

export class ControllerEvolvedVvlXSell extends ControllerEvolvedFlowBase {

    private _salesChannel: SalesChannelName;
    private _atomicDeviceId: number;
    private _subscriptionId: number;

    private _subscription: Subscription;
    private _offer: Offer;

    private _additionalCard: JQuery;
    private _pricebox: Pricebox;

    protected _generalSalesObjectInterface: GeneralSalesObjectInterface;

    private _maxCards: number = 4;

    constructor (
        salesChannel: SalesChannelName,
        atomicDeviceId: number,
        subscriptionId: number,
        reposSupervisor: ModelEvolvedRepoSupervisor,
        injector: Injector
    ) {
        super(
            reposSupervisor,
            injector
        );

        if (true === this.getInjector().getOptions().get('debug')) {
            const cnsl = console;
            cnsl.log('VvlXSellController....');
        }

        this._salesChannel = salesChannel;
        this._atomicDeviceId = atomicDeviceId;
        this._subscriptionId = subscriptionId;

        this._additionalCard = $('.additional-card-tile[data-type]');
        const vacantRedPlusSlots = this.getInjector().getFlowStateWithSalesChannel().customer.vacantRedPlusSlots;
        if (vacantRedPlusSlots > 0 && vacantRedPlusSlots < 4) {
            this._maxCards = this.getInjector().getFlowStateWithSalesChannel().customer.vacantRedPlusSlots;
        }
        this._pricebox = new Pricebox(injector);
        this._generalSalesObjectInterface = this.getInjector().getGeneralSalesObjectInterface();
    }

    protected getGeneralSalesObject (): GeneralSalesObjectInterface {
        return this._generalSalesObjectInterface;
    }

    protected getSalesChannel (): SalesChannelName {
        return this._salesChannel;
    }

    protected createCtas (): FlowCtas {

        return new ViewEvolvedCtasXSell(
            this.getInjector()
        );

    }

    protected getSubscriptionId (): number {

        return this._subscriptionId;

    }

    protected getAtomicDevicId (): number {
        return this._atomicDeviceId;
    }

    protected render (): void {

        /**
         * If switching from or to red unlimited:
         * Show message that explains that price and condition for red cards will change
         */

        const customer = this.getInjector().getFlowStateWithSalesChannel().customer;
        const selectedTariff = this.getSubscriptionId();
        const subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(
            selectedTariff
        );

        // if the customer isGigakombi && has selected REDM || REDL which are currently unlimited or has selected an UNLIMITED tariff
        // show the correct bullet points and the unlimited teaser

        const isXLTariffs = subscription.isXLUnlimitedTariff();
        const isUnlimitedGigakombiSoho = this.isUnlimitedGigakombi(customer, selectedTariff) && customer.isSohoCustomer;
        const isUnlimitedGigakombiConsumer = this.isUnlimitedGigakombi(customer, selectedTariff)  && !customer.isSohoCustomer;

        if ( isXLTariffs || isUnlimitedGigakombiSoho || isUnlimitedGigakombiConsumer) {
            const youngTitle = this.getInjector().getStrings().get('vvl.x-sell-page.headline');

            $($('.belt h1').get(0)).text(youngTitle);
            $('#nsf-explain-other-then-unlimited').hide();
            $('.show-young-content, #nsf-explain-unlimited').show();

            // nsf-unlimited class is used to show and hide the bullet points in red+allnet accordion
            // dynamically with classes .nly-unlimited and .not-unlimited with css
            $('body').addClass('nsf-unlimited');
        } else {
            // there are not differnt image-text: One explains unlimited and one explains all other subscriptions
            // by css the unlimited one is hidden. Toogle when unlimited

                $('.show-young-content, #nsf-explain-unlimited').hide();
                $('#nsf-explain-other-then-unlimited').show();
        }

        /**
         * [CO-20615] Required with the REDPLUS 2020 Portfolio.
         * No notification, no overlay for the unlimited anymore here.
         */

        this.setRedplusCards(customer);
    }

    protected setRedplusCards (customer: Customer) {
        const selectedRedPlusAllnet = this.getInjector().getFlowStateWithSalesChannel().redPlusAllnet.elements.length;
        const selectedRedPlusBasic = this.getInjector().getFlowStateWithSalesChannel().redPlusBasic.elements.length;
        const selectedRedPlusKids = this.getInjector().getFlowStateWithSalesChannel().redPlusKids.elements.length;
        const selectedRedPlusData = this.getInjector().getFlowStateWithSalesChannel().redPlusData.elements.length;
        const totalRedPlusCards = this.getCurrentSelectedRedPlusCards();
        const that = this; // @TODO This is amazing (I am mean,that)!

        this.getGeneralSalesObject().redPlusOfferService.setMainTariffId(this._offer.subscriptionId.toString());

        this._additionalCard.each((index, element) => {

            const dataType = $(element).data('type');

            let length = 0;

            /**
             * Map family card tariffs to redplus values in the session:
             * RedPlus-Data -> FamilyCard large
             * RedPlus-Basic -> FamilyCard medium
             * RedPlus-Allnet -> FamilyCard small
             */
            switch (dataType) {
                case Constants.RedPlus_Kids:
                    length = selectedRedPlusKids;
                    break;
                case Constants.RedPlus_Data:
                    length = selectedRedPlusData;
                    break;
                case Constants.RedPlus_Basic:
                    length = selectedRedPlusBasic;
                    break;
                case Constants.FAMILYCARD_L_SUBSCRIPTION_TYPE:
                    length = selectedRedPlusData;
                    break;
                case Constants.FAMILYCARD_M_SUBSCRIPTION_TYPE:
                    length = selectedRedPlusBasic;
                    break;
                case Constants.FAMILYCARD_S_SUBSCRIPTION_TYPE:
                    length = selectedRedPlusAllnet;
                    break;
                default:
                    length = selectedRedPlusAllnet;
            }

            const redPlusOffer = that.getGeneralSalesObject().getRedPlusSimOnlyOfferByType(dataType, this._salesChannel);

            if (0 < length) {
                $(element).data('value', length);
                $(element).find('.btn-count[data-count=' + length + ']').addClass('selected');
                $(element).find('.add-cards-header').addClass('active');
            }

            $(element).find('.btn-count').each(function () {
                if (($(this).data('count') - $(element).data('value')) > totalRedPlusCards) {
                    $(this).addClass('disabled');
                }
            });

            $(element).find('.Price .priceVlux').html(
                this.getInjector().getTemplates().render(
                    'price-element',
                    (redPlusOffer.subcriptionPriceMonthly.value - (redPlusOffer.monthlySaving ? Math.abs(redPlusOffer.monthlySaving) : 0)),
                    'partials'
                )
            );
        });
    }

    protected handleEventRedPlusCardCountChanged (): void {
        let currentCount = 0;
        this._additionalCard.each(function () {
            currentCount = currentCount + parseInt($(this).data('value'), 10);
        });

        const totalRedPlusCards = this._maxCards - currentCount;

        const that = this;
        this.getInjector().getFlowStateWithSalesChannel().redPlusAllnet.resetAllElements();
        this.getInjector().getFlowStateWithSalesChannel().redPlusBasic.resetAllElements();
        this.getInjector().getFlowStateWithSalesChannel().redPlusKids.resetAllElements();
        this.getInjector().getFlowStateWithSalesChannel().redPlusData.resetAllElements();

        this._additionalCard.each(function () {

            const there = this;
            $(this).find('.btn-count').each(function () {
                $(this).removeClass('disabled');
                if (($(this).data('count') - $(there).data('value')) > totalRedPlusCards) {
                    $(this).addClass('disabled');
                }
            });

            const val = $(this).data('value');
            const dataType = $(this).data('type');

            const simOnlyOffer = that.getGeneralSalesObject().getRedPlusSimOnlyOfferByType(dataType, that.getSalesChannel());

            for (let i = 0; i < val; i++) {
                switch (dataType) {
                    case Constants.RedPlus_Allnet:
                        that._injector.getFlow().redPlusAllnet.addElement(simOnlyOffer.offerId);
                        break;
                    case Constants.RedPlus_Kids:
                        that._injector.getFlow().redPlusKids.addElement(simOnlyOffer.offerId);
                        break;
                    case Constants.RedPlus_Data:
                        that._injector.getFlow().redPlusData.addElement(simOnlyOffer.offerId);
                        break;
                    case Constants.RedPlus_Basic:
                        that._injector.getFlow().redPlusBasic.addElement(simOnlyOffer.offerId);
                        break;
                    case Constants.FAMILYCARD_L_SUBSCRIPTION_TYPE:
                        that._injector.getFlow().redPlusData.addElement(simOnlyOffer.offerId);
                        break;
                    case Constants.FAMILYCARD_M_SUBSCRIPTION_TYPE:
                        that._injector.getFlow().redPlusBasic.addElement(simOnlyOffer.offerId);
                        break;
                    case Constants.FAMILYCARD_S_SUBSCRIPTION_TYPE:
                        that._injector.getFlow().redPlusAllnet.addElement(simOnlyOffer.offerId);
                        break;
                    default:
                        that._injector.getFlow().redPlusAllnet.addElement(simOnlyOffer.offerId);
                }
            }

        });

        this._injector.getEvent().trigger('redPlusCards@changed');

        if (totalRedPlusCards < this._maxCards) {
            this._pricebox.setAnnotationPriceOnce('show');
        } else {
            this._pricebox.setAnnotationPriceOnce('hide');
        }

    }

    protected handleEventRedPlusCardsChanged (): void {
        this.fillOfferOfferCollection();

        this.getInjector().getEvent().trigger('offer@changed', {
            offer: this.getInjector().getOfferCollection().getActiveOffer()
        });

    }

    protected events (): void {

        this._injector.getEvent().listen('redPlusCardCount@changed', () => {
            this.handleEventRedPlusCardCountChanged();
        });

        this._injector.getEvent().listen('redPlusCards@changed', () => {
            this.handleEventRedPlusCardsChanged();
        });

    }

    /**
     * Check if customer is unlimited Gigakombi soho or consumer
     */
    private isUnlimitedGigakombi (customer: Customer, selectedTariff: number): boolean {
        const redTariffs = [
             Constants.RedL_Id,
             Constants.RedM_Id
        ];

        if ((customer.hasGigakombiIP || (customer.hasGigakombiIP && customer.hasGigakombiTV))
                && -1 !== redTariffs.indexOf(selectedTariff)) {
           return true;
        }

        return false;
    }

    /**
     * For x sell page there is always one subscription, on active offer and none (simonly) or one device
     */
    private fillOfferOfferCollection (): void {

        const subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(
            this._subscriptionId
        );

        let offer;
        let atomicDevice;

        // Subscription with device
        if (undefined !== this._atomicDeviceId) {
            /**
             * Hardware plus Tariff
             */

            atomicDevice = this.getReposSupervisor().getPurchasableDeviceRepo().getAtomicDevice(
                this._atomicDeviceId,
                this.getSalesChannel(),
                subscription
            );

            offer = this._generalSalesObjectInterface.getSimHardwareOfferByAtomicDeviceIdAndSubscriptionId(
                this._atomicDeviceId,
                subscription.id,
                this._btx,
                this.getSalesChannel()
            );

        } else  {
            /**
             * SIM Only
             */

            offer = this.getGeneralSalesObject().getSimOnlyOfferBySubscriptionId(
                this._subscriptionId,
                this._btx,
                this.getSalesChannel()
            );

        }

        // @TODO Great return ;) But what happens then
        if (undefined === offer) {
            return;
        }

        this._offer = offer;

        /**
         *  Did the user opts in a RedPlus Option
         */
        const offersRedPlus: any[] = [];
        const allRedPlusOptions  = [
            ...this.getInjector().getFlowStateWithSalesChannel().redPlusAllnet.elements,
            ...this.getInjector().getFlowStateWithSalesChannel().redPlusData.elements,
            ...this.getInjector().getFlowStateWithSalesChannel().redPlusKids.elements,
            ...this.getInjector().getFlowStateWithSalesChannel().redPlusBasic.elements
        ];

        if (0 < allRedPlusOptions.length) {

            for (const offerId of allRedPlusOptions) {

                const redPlusOffer = this.getGeneralSalesObject().getRedPlusOfferById(
                    offerId,
                    this.getSalesChannel());
                const redPlusSubscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(
                    redPlusOffer.subscriptionId
                );

                offersRedPlus.push(new SimOnlyOffer(redPlusSubscription, redPlusOffer));
            }
        }

        /**
         * Get selected optional services
         */
        const optionalServiceIds = this.getInjector().getFlowStateWithSalesChannel().optionalServiceIds.elements;

        if (undefined === this._atomicDeviceId) {
            const simOnlyOffer = new SimOnlyOffer(
                subscription,
                offer,
                offersRedPlus,
                optionalServiceIds
            );

            this.getInjector().getOfferCollection().setActiveOffer(
                simOnlyOffer
            );

        }
        else {
            const deviceOffer = new DeviceOffer(
                atomicDevice.getDevice().getAtomicDeviceById(offer.deviceId),
                subscription,
                offer,
                offersRedPlus,
                optionalServiceIds
            );

            this.getInjector().getOfferCollection().setActiveOffer(
                deviceOffer
            );

        }

    }

    protected tracking (): void {
        this.getInjector().getEvent().trigger('pageviewTracking@onload',
            {
                deviceOffer: this.getInjector().getOfferCollection().getActiveOffer(),
                pageName: 'extras',
                pageType: 'product listing',
                btx: this._btx,
                currentPage: this._injector.getRouting().getCurrentPage(),
                customer: this.getInjector().getFlowStateWithSalesChannel().getCustomer()
            }
        );
    }

    private getCurrentSelectedRedPlusCards (): number {
        const selectedRedPlusAllnet = this.getInjector().getFlowStateWithSalesChannel().redPlusAllnet.elements.length;
        const selectedRedPlusBasic = this.getInjector().getFlowStateWithSalesChannel().redPlusBasic.elements.length;
        const selectedRedPlusKids = this.getInjector().getFlowStateWithSalesChannel().redPlusKids.elements.length;
        const selectedRedPlusData = this.getInjector().getFlowStateWithSalesChannel().redPlusData.elements.length;

        return (this._maxCards - (selectedRedPlusAllnet + selectedRedPlusBasic + selectedRedPlusKids + selectedRedPlusData));
    }

    public bind (): void {
        this._subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(this.getSubscriptionId());
        const atomicDeviceId = this.getInjector().getFlowStateWithSalesChannel().getAtomicDeviceId();
        const customer = this.getInjector().getFlowStateWithSalesChannel().customer;

        // @TODO What is this and why here and not in a router? This should be moved into router
        if (undefined === this._subscription || (undefined === atomicDeviceId && !customer.isSimOnlyVvlAllowed)) {
            window.location.href = this.getInjector().getOptions().get('vvl_tariff_device_url');
        }

        this.fillOfferOfferCollection();

        this._stepper.bind();

        const redPlusAllnet = new ViewEvolvedElementVvlAdditionalCard(
            this._injector,
            Constants.RedPlus_Allnet
        );
        redPlusAllnet.bind();

        const redPlusBasic = new ViewEvolvedElementVvlAdditionalCard(
            this._injector,
            Constants.RedPlus_Basic
        );
        redPlusBasic.bind();

        /*
         * No red plus kids for young and SOHO customers.
         * [CO-20615] UPDATE: All young vvl tariffs will be combinable with the red plus kids. Removing young check.
        */
        if (Constants.SALESCHANNEL_SOHO === this.getInjector().getFlowStateWithSalesChannel().getSalesChannel()) {

            this.getInjector().getFlowStateWithSalesChannel().redPlusKids.resetAllElements();
            $('.additional-card-tile[data-type="kids"]').remove();
        }
        else {

            const redPlusKids = new ViewEvolvedElementVvlAdditionalCard(
                this._injector,
                Constants.RedPlus_Kids
            );
            redPlusKids.bind();

        }

        const redPlusData = new ViewEvolvedElementVvlAdditionalCard(
            this._injector,
            Constants.RedPlus_Data
        );
        redPlusData.bind();

        const familyCardLarge = new ViewEvolvedElementVvlAdditionalCard(
            this._injector,
            Constants.FAMILYCARD_L_SUBSCRIPTION_TYPE
        );
        familyCardLarge.bind();

        const familyCardMedium = new ViewEvolvedElementVvlAdditionalCard(
            this._injector,
            Constants.FAMILYCARD_M_SUBSCRIPTION_TYPE
        );
        familyCardMedium.bind();

        const familyCardSmall = new ViewEvolvedElementVvlAdditionalCard(
            this._injector,
            Constants.FAMILYCARD_S_SUBSCRIPTION_TYPE
        );
        familyCardSmall.bind();

        this._pricebox.bind(this.getInjector().getOfferCollection().getActiveOffer());

        // @TODO CTAS should always be here. Check and then remove the if
        if (undefined !== this._ctas) {
            this._ctas.bind(
                this.getInjector().getOfferCollection().getActiveOffer()
            );
        }

        this.render();
        this.events();

        const totalRedPlusCards = this.getCurrentSelectedRedPlusCards();
        if (totalRedPlusCards < this._maxCards) {
            this._pricebox.setAnnotationPriceOnce('show');
        }

        this.tracking();
        this.getInjector().getLoadingIndicator().hide();
    }

}
