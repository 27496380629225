/**
 * @module SalesFlow/evolved/view
 */

declare var $: JQueryStatic;
declare var vf: any;

import Injector from 'core/injector';
import ViewOverlay from 'view/view/shared/overlay';
import {Renderable} from 'view/renderable';

export class ViewEvolvedElementInsuranceDetailOverlay extends Renderable<any> {

    private _headline: string;
    private _elementHtml: string;

    constructor (injector: Injector) {

        super(injector);

        this._elementHtml = $('#nsf-handy-insurance-overlay-content').html();

        /**
         * Content need to be removed. If not there are multiple ids for the tabs, so the tabs are not working in the overlay
         */
        $('#nsf-handy-insurance-overlay-content').remove();

    }

    public render (): void {

    }

    public events (): void {

        $('.insuranceBox').on('click', '.nsf-insurance-detail-link', (evt: JQueryEventObject) => {

            evt.preventDefault();

            const viewOverlay = new ViewOverlay(this._headline, '', this._elementHtml);

            this.getInjector().getOverlay().open(viewOverlay);

            vf.tabs.init();

        });

    }

    public bind (): void {

        this.render();
        this.events();

    }

}
