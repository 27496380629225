/**
 * @module SalesFlow/view
 */

declare var $: JQueryStatic;
declare var moment: any;

import DeviceOffer from '../../../view/shared/offer/device-offer';

import FilterOption from '../../../view/shared/filter-option';
import AtomicDevice from 'model/type/atomic-device';

/**
 * Execute a condition given by a filter against a ViewOffer
 *
 * This is not an element, but makes sense only in this folder
 */
export default class FilterMatch {

    public static exec (filterOption: FilterOption, viewOffer: DeviceOffer): boolean {

        let match = false;

        let value: any = filterOption.value;

        if ('js:undefined' === value) {
            value = undefined;
        }

        if ('number' === filterOption.type) {
            value = parseFloat(value);
        }
        else if ('date' === filterOption.type) {

            let now = moment();

            const dateModifier = value.split(' ');

            if (2 === dateModifier.length) {
                dateModifier[0] = parseFloat(dateModifier);
                now = now.subtract(dateModifier[0], dateModifier[1]);
            }

            value = now.format();

        }

        let deviceValues: any[] = [];

        if ('price' === filterOption.attribute) {
            deviceValues.push(viewOffer.onetimeDevicePrice);
        }
        else if ('hasReducedPrice' === filterOption.attribute) {

            if (0 === viewOffer.onetimeDeviceStrikePrice) {
                deviceValues.push(false);
            }
            else {
                deviceValues.push(viewOffer.onetimeDevicePrice !== viewOffer.onetimeDeviceStrikePrice);
            }
        }
        else if ('topdevice' === filterOption.attribute) {

            const priceCap = $('.options div[data-name="price-cap-top-device"]').data('value');

            if (null === priceCap) {
                deviceValues.push(viewOffer.atomicDevice.topDevice);
            }
            else {
                if (priceCap > viewOffer.onetimeDevicePrice) {
                    deviceValues.push('top-device');
                }
                else {
                    deviceValues.push(viewOffer.atomicDevice.topDevice);
                }
            }
        }
        else {

            deviceValues = viewOffer.atomicDevice.getDevice().getAtomicDevices().map((atomicDevice: AtomicDevice) => {

                let deviceValue = atomicDevice.attr[filterOption.attribute];

                if ('number' === filterOption.type) {
                    deviceValue = parseFloat(deviceValue);
                }

                return deviceValue;
            });

        }

        for (const deviceValue of deviceValues) {

            if ('=' === filterOption.condition) {
                if (deviceValue === value) {
                    match = true;
                    break;
                }
            }
            else if ('!=' === filterOption.condition) {
                if (deviceValue !== value) {
                    match = true;
                    break;
                }
            }
            else if ('>=' === filterOption.condition) {

                if (deviceValue >= value) {
                    match = true;
                    break;
                }
            }
            else if ('>' === filterOption.condition) {

                if (deviceValue > value) {
                    match = true;
                    break;
                }
            }

        }

        return match;

    }

}
