/**
 * @module SalesFlow/model/type
 */

import {ContractPeriodType} from 'core/ids';
export default class AllowedTariffDefinition {

    private _backendId: string;
    private _subscriptionId: number;
    private _contractPeriod: ContractPeriodType;

    constructor (data: any) {
        this.parseData(data);
    }

    private parseData (data: any) {

        if (undefined !== data.backendId) {
            this._backendId = data.backendId;
        }

        if (undefined !== data.subscriptionId) {
            this._subscriptionId = data.subscriptionId;
        }

        if (undefined !== data.contractPeriod) {
            this._contractPeriod = data.contractPeriod;
        }
    }

    get backendId (): string {
        return this._backendId;
    }

    get subscriptionId (): number {
        return this._subscriptionId;
    }

    get contractPeriod (): ContractPeriodType {
        return this._contractPeriod;
    }

}
