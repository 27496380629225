(function() {
    'use strict';

    // @TOD BPe -> This script fires on domready, create custom event that is trigger on handlebars rendered

    vf['pass-row-module'] = {
        settings: {
            target: '.mod-pass-row-module',
        },

        init: function(context) {

            var s = this.settings;
            $(s.target, context).addBack(s.target).each(function() {
                var $this = $(this);

                var getSliderWidth = function(){
                    return $this.find('.pass-module-tiles-wrapper').width();
                };

                var getItemsCount = function(){
                    return $this.find('.pass-module-tiles .pass-module-tile').length;
                };

                var getItemWidth = function(){

                    if ('desktop' === vf.util.layout(true)) {

                        var count = (getItemsCount());

                        if ($this.find('.pass-module-tiles-wrapper').hasClass('scroll-pane')) {
                            /*
                            if ($this.parents('.mod-cm-option').hasClass('mod-cm-accordion')) {
                                var width = ((getSliderWidth() - 4) / 100 * 33.33333333333);
                            } else {
                                var width = (getSliderWidth() / 100 * 33.33333333333);
                            }
                            */
                            var width = (getSliderWidth() / 100 * 24.9);
                            $this.find('.pass-module-tiles').css( 'width', width * count + 'px' );
                            $this.find('.pass-module-tile').css( 'width', width + 'px' );
                        } else {
                            var width = (getSliderWidth() / 100 * 24.9);
                            $this.find('.pass-module-tiles').css( 'width', '100%' );
                            $this.find('.pass-module-tile').css( 'width', width + 'px' );
                        }
                    }

                    if ('tablet' === vf.util.layout(true)) {

                        var width = (getSliderWidth() / 100 * 41.66666666666);
                        var count = (getItemsCount());

                        $this.find('.pass-module-tiles').css( 'width', width * count + 'px' );
                        $this.find('.pass-module-tile').css( 'width', width + 'px' );

                    }

                    if ('mobile' === vf.util.layout(true)) {

                        var width = ((getSliderWidth() - 3) / 100 * 83.33333333333);
                        var count = (getItemsCount());

                        $this.find('.pass-module-tiles').css( 'width', width * count + 'px' );
                        $this.find('.pass-module-tile').css( 'width', width + 'px' );

                    }
                };

                $(window).on('vf::resize', getItemWidth);
                getItemWidth();

                /**
                 * Init jScrollPane only on touch devices
                 */
                function isTouchDevice() {
                    return (('ontouchstart' in window) || (0 < navigator.MaxTouchPoints) || (0 < navigator.msMaxTouchPoints));
                }

                if (!isTouchDevice()) {
                    var pane = $this.find('.scroll-pane');
                    pane.jScrollPane();

                    var api = pane.data('jsp');

                    if (undefined !== api && null !== api) {
                        if (-0 === api.getPercentScrolledX()) {
                            $this.find('.row-prev').hide();
                        }
                        if (1 === api.getPercentScrolledX()) {
                            $this.find('.row-next').hide();
                        }
                    }

                    pane.on(
                        'jsp-scroll-x',
                        function(event, srollPositionX, isAtLeft, isAtRight) {
                            if (isAtLeft) {
                                $this.find('.row-prev').hide();
                            } else {
                                $this.find('.row-prev').show();
                            }

                            if (isAtRight) {
                                $this.find('.row-next').hide();
                            } else {
                                $this.find('.row-next').show();
                            }
                        }
                    );

                    $this.on('click', '.row-next', function() {
                        api.scrollByX($this.find('.pass-module-tile')[0].getBoundingClientRect().width, true);
                    });

                    $this.on('click', '.row-prev', function() {
                        api.scrollByX(-$this.find('.pass-module-tile')[0].getBoundingClientRect().width, true);
                    });
                } else {
                    if ('desktop' === vf.util.layout(true)) {
                        if (0 === $this.find('.pass-module-tiles').position().left) {
                            $this.find('.row-prev').hide();
                        }

                        if (-1 * ($this.find('.pass-module-tiles').width() - $this.find('.scroll-pane').width()) === $this.find('.pass-module-tiles').position().left) {
                            $this.find('.row-next').hide();
                        }

                        $this.find('.scroll-pane').on('scroll', function() {
                            if (0 === $this.find('.pass-module-tiles').position().left) {
                                $this.find('.row-prev').hide();
                            } else {
                                $this.find('.row-prev').show();
                            }

                            if (-1 * ($this.find('.pass-module-tiles').width() - $this.find('.scroll-pane').width()) === $this.find('.pass-module-tiles').position().left) {
                                $this.find('.row-next').hide();
                            } else {
                                $this.find('.row-next').show();
                            }

                        });

                        $this.on('click', '.row-next', function() {
                            var scrollWidth = ($this.find('.pass-module-tiles').position().left * -1) + $this.find('.pass-module-tile')[0].getBoundingClientRect().width;
                            $this.find('.scroll-pane').animate({
                                scrollLeft: scrollWidth
                            }, 500);
                        });

                        $this.on('click', '.row-prev', function() {
                            var scrollWidth = ($this.find('.pass-module-tiles').position().left * -1) - $this.find('.pass-module-tile')[0].getBoundingClientRect().width;
                            $this.find('.scroll-pane').animate({
                                scrollLeft: scrollWidth
                            }, 500);
                        });
                    }
                }
            });
        },
    };
}(vf));
