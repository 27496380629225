/**
 * @module SalesFlow/view
 */

import Injector from 'core/injector';

import Subscription from 'model/type/subscription';
import Offer from 'view/view/shared/offer/offer';

declare var $: JQueryStatic;

export default class ViewStopper {

    private _injector: Injector;

    private _subscription: Subscription;
    private _hardwareOnly: Boolean;
    private _offer: Offer;

    private _imageURL: string;
    private _hasPromoModule: boolean;

    constructor (injector: Injector, subscription?: Subscription, offer?: Offer) {

        this._injector = injector;

        this._hardwareOnly = true;

        if (undefined !== subscription) {
            this._hardwareOnly = false;
            this._subscription = subscription;
        }

        if (undefined !== offer) {
            this._offer = offer;
        }

        this._imageURL = '';
        this._hasPromoModule = true;

        if (true === this._hardwareOnly) {

            if ('string' === typeof this._injector.getOptions().get('stopperImgUrlhwonly')) {
                this._imageURL = this._injector.getOptions().get('stopperImgUrlhwonly');
            }

        } else {

            if ('string' === typeof this._injector.getOptions().get('stopperImgUrl' + this._subscription.subscriptionGroupId)) {

                this._imageURL = this._injector.getOptions().get('stopperImgUrl' + this._subscription.subscriptionGroupId);

            } else if ('string' === typeof this._injector.getOptions().get('stopperImgUrl' + this._subscription.id)) {

                this._imageURL = this._injector.getOptions().get('stopperImgUrl' + this._subscription.id);
            }

            // let's catch only the gigakombi cases
            if (this._offer.isGigakombi) {
                // check if there is a gigakombi discount applied to show the green bubble
                if (undefined !== this._offer.monthlyDiscountGigakombiSum || 0 !== this._offer.monthlyDiscountGigakombiSum) {

                    const gigakombiMonthlyDiscount = this._offer.monthlyDiscountGigakombiSum;

                    switch (gigakombiMonthlyDiscount) {

                        case 5:
                            this._imageURL = '/simplicity/assets/img/gigakombi/GigaKombi-Bubble-5.png';
                            break;
                        case 10:
                            this._imageURL = '/simplicity/assets/img/gigakombi/GigaKombi-Bubble-10.png';
                            break;
                        case 15:
                            this._imageURL = '/simplicity/assets/img/gigakombi/GigaKombi-Bubble-15.png';
                            break;
                    }
                }
            }
        }

        if ('' === this._imageURL) {
            this._imageURL = this._injector.getOptions().get('stopperImgUrl');
        }

    }

    private checkPromoModule () {

        // nsf-stopper-promo-module

        /**
         * data-valid-for "all"
         * data-valid-for "hwonly"
         * data-valid-for "tariffgroup-red"
         * data-valid-for "tariffgroupid-265"   (Red-Tarife)
         * data-valid-for "tariffgroupid-425"   (Easy-Tarife)
         *
         */

        if (true === this._hardwareOnly) {

            if (0 < $('.nsf-stopper-promo-module[data-valid-for="hwonly"]').length) {
                this._hasPromoModule = true;
            }

        } else {

            if (0 < $('.nsf-stopper-promo-module[data-valid-for="tariffgroupid-' + this._subscription.subscriptionGroupId + '"]').length) {

                this._hasPromoModule = true;

            } else if (0 < $('.nsf-stopper-promo-module[data-valid-for="tariffid-' + this._subscription.id + '"]').length) {

                this._hasPromoModule = true;

            }

        }

        if (false === this._hasPromoModule) {
            if (0 < $('.nsf-stopper-promo-module[data-valid-for="all"]').length) {
                this._hasPromoModule = true;
            }
        }

    }

    get hardwareOnly (): Boolean {
        return this._hardwareOnly;
    }

    get subscriptionId (): number {
        let ret: number = 0;
        if (undefined !== this._subscription) {
            ret = this._subscription.id;
        }

        return ret;
    }

    get subscriptionGroupId (): number {
        let ret: number = 0;
        if (undefined !== this._subscription) {
            ret = this._subscription.subscriptionGroupId;
        }

        return ret;
    }

    get injector (): Injector {
        return this._injector;
    }

    get imageURL (): string {
        return this._imageURL;
    }

    get hasPromoModule (): boolean {
        return this._hasPromoModule;
    }

}
