/**
 * @module SalesFlow/model/type
 */
import OfferPrice from './offer-price';

import {VluxJsonOfferComponent} from '../vlux-interface';

export default class OfferComponent {

    private _type: string;
    private _internalLabel: string;
    private _refId: number;

    private _prices: OfferPrice[] = [];

    constructor (data: VluxJsonOfferComponent) {

        this._type = data.type;
        this._internalLabel = data.internalLabel;
        this._refId = parseFloat(data.refId);

        if (data.prices && data.prices.price) {
            for (const price of data.prices.price) {
                this._prices.push(new OfferPrice(price));
            }
        }

    }

    public getPrices (): OfferPrice[] {
        return this._prices;
    }

    get type (): string {
        return this._type;
    }

    get internalLabel (): string {
        return this._internalLabel;
    }

    get refId (): number {
        return this._refId;
    }

}
