import {Constants} from 'core/constants';

/**
 * @module SalesFlow/evolved/view
 */

declare var $: any;

import Customer from 'shopbackend/customer';
import Subscription from 'model/type/subscription';
import Injector from 'core/injector';
import {Renderable} from 'view/renderable';
import {SalesChannelName} from 'core/ids';

export class EvolvedProlongationInCurrentSubscription extends Renderable<any> {

    protected _element: JQuery;

    protected _customer: Customer;
    protected _currentSubscription: Subscription;
    protected _currentSubscriptionTile: JQuery;

    constructor (
        customer: Customer,
        currentSubscription: Subscription,
        injector: Injector
    ) {
        super(injector);

        this._element = $('#nsf-prolongation-in-current-tarif');

        this._customer = customer;
        this._currentSubscription = currentSubscription;
    }

    public render (): void {
        /**
         * Removed CTA "Mein Tarif behalten" CO-22690 & CO-22247
         */
        let isAllowedToProlong = true;

        if (undefined !== this._currentSubscription) {
            if ((false === this._customer.isYoungCustomer && 'young' === this._currentSubscription.subscriptionGroupName) || false === this._currentSubscription.isLatestPortfolio()) {
                isAllowedToProlong = false;
            }
        } else {
            isAllowedToProlong = false;
        }

        if (false === isAllowedToProlong) {
            this._element.find('.current-tariff-not-eligible').show();

            return;
        }

    }

    public events (): void {

        this._element.on('click', '.current-tariff-not-eligible a', (evt) => {
            this._element.find('.message-tooltip').show();
        });

        this._element.on('click', '.message-tooltip .close', (evt) => {
            this._element.find('.message-tooltip').hide();
        });
    }

    public bind (): void {

        this.render();
        this.events();
    }
}
