/**
 * @module SalesFlow/view
 */
import GeneralBasketServiceInterfaceBNT from 'shopbackend/general-basket-service/general-basket-service-interface-bnt';

import Offer from 'view/view/shared/offer/offer';
import {FlowCta} from 'view/ctas/shared/flow-cta';
import Injector from 'core/injector';
import {Renderable} from 'view/renderable';

import BasketHandler from 'shopbackend/basket-handler';
import {Constants} from 'core/constants';
import GeneralBasketServiceInterfaceVVL from 'shopbackend/general-basket-service/general-basket-service-interface-vvl';
import GeneralBasketServiceInterfaceInlife from 'shopbackend/general-basket-service/general-basket-service-interface-inlife';
import GeneralBasketServiceInterfaceRedPlus from 'shopbackend/general-basket-service/general-basket-service-interface-redplus';

declare var $: JQueryStatic;

export class FlowCtas extends Renderable<Offer> {

    /**
     * There are not more than two buttons
     */
    protected _left: FlowCta;
    protected _right: FlowCta;

    protected _offer: Offer;

    protected _element: JQuery;

    constructor (injector: Injector) {

        super(injector);

        this._left = this.getLeftButton();
        this._right = this.getRightButton();

    }

    /**
     * Default is taking care of buttons in pricebox and costoverview
     */
    protected getElement (): JQuery {

        return $('#nsf-pricebox-buttons-wrap,#nsf-cost-overview-buttons-wrap');

    }

    protected getLeftButton (): FlowCta {
        return undefined;
    }

    protected getRightButton (): FlowCta {
        return undefined;
    }

    public update (offer: Offer): void {

        // Just do nothing when no offer
        // pricebox and costoverview take care of hiding themselves including button-wraps
        if (undefined === offer) {
            return;
        }

    }

    protected render (offer: Offer): void {

        /*
         * Getting element in render and while FlowCtas.bind MUST be always after pricebox
         * Now we can be sure that button-wrapper are already in DOM
         */
        this._element = this.getElement();

        this.update(offer);
    }

    protected events (): void {

        /*
         * Save offer and update.
         */
        this.getInjector().getEvent().listen('offer@changed', (eventObject: JQueryEventObject, data: any) => {

            const offer: Offer = data.offer;
            this._offer = offer;
            this.update(offer);

        });

    }

    /**
     * @param {Offer} offer
     */
    public bind (offer: Offer): void {

        if (undefined !== offer) {
            this._offer = offer;
        }

        this.render(offer);

        this.events();
    }

    /**
     * Generate, and fill basket.
     * When XHR returns it will redirect into basket
     *
     * @protected
     * @memberof BntFlowCtas
     */
    protected createBasketAndProceed (): void {

        const btx = this.getInjector().getBtx();
        const salesChannel = this.getInjector().getFlow().getSalesChannel();

        const basketHandler = new BasketHandler(this.getInjector());

        const generalSalesObjectInterface = this.getInjector().getGeneralSalesObjectInterface();

        if (Constants.BTX_VVL === btx) {

            const generalBasketServiceInterface = <GeneralBasketServiceInterfaceVVL> this.getInjector().getGeneralBasketServiceInterface(btx);
            generalBasketServiceInterface.executeOffer(
                this._offer,
                salesChannel,
                this.getInjector().getFlow().customer,
                generalSalesObjectInterface).then((success) => {
                basketHandler.defaultSuccessHandling(success);
            }, (error) => {
                basketHandler.defaultErrorHandling(error);
            });

        } else if (Constants.BTX_INLIFE === btx) {

            const generalBasketServiceInterface = <GeneralBasketServiceInterfaceInlife> this.getInjector().getGeneralBasketServiceInterface(btx);
            generalBasketServiceInterface.executeOffer(
                this._offer,
                salesChannel,
                this.getInjector().getFlow().customer,
                generalSalesObjectInterface).then((success) => {
                basketHandler.defaultSuccessHandling(success);
            }, (error) => {
                basketHandler.defaultErrorHandling(error);
            });

        } else if (Constants.BTX_REDPLUS === btx) {

            const generalBasketServiceInterface = <GeneralBasketServiceInterfaceRedPlus> this.getInjector().getGeneralBasketServiceInterface(btx);
            generalBasketServiceInterface.executeOffer(
                this._offer,
                salesChannel,
                generalSalesObjectInterface).then((success) => {
                basketHandler.defaultSuccessHandling(success);
            }, (error) => {
                basketHandler.defaultErrorHandling(error);
            });

        } else {

            const generalBasketServiceInterface = <GeneralBasketServiceInterfaceBNT> this.getInjector().getGeneralBasketServiceInterface(btx);
            generalBasketServiceInterface.executeOffer(
                this._offer,
                salesChannel,
                generalSalesObjectInterface).then((success) => {
                basketHandler.defaultSuccessHandling(success);
            }, (error) => {
                basketHandler.defaultErrorHandling(error);
            });

        }

    }

}
