/**
 * @module SalesFlow/model/type
 */
import NsfError from 'core/error';

import AccessoryAttributes from 'model/type/attributes-accessory';

import AtomicAccessory from 'model/type/atomic-accessory';

interface AttributeQuery {
    [index: string]: any;

    color: string;
}

export default class Accessory {

    [key: string]: any;

    private _id: number;

    private _name: string;

    private _attributes: AccessoryAttributes;

    private _promotion: string;

    private _characteristic: string;

    private _badge_text: string;

    private _atomicAccessory: AtomicAccessory[] = [];

    constructor (data: any) {
        this.parseData(data);
    }

    private parseData (data: any) {

        if (undefined === data.id) {
            new NsfError('id is mandatory when create a Accessory.');
        }

        if (undefined === data.label) {
            new NsfError('label is mandatory when create a Accessory.');
        }

        this._id = parseInt(data.id, 10);

        this._name = data.label;

        let attributes = {};

        if (undefined !== data.attributes && undefined !== data.attributes.attribute) {
            attributes = data.attributes.attribute;
        }

        this._attributes = new AccessoryAttributes(attributes);

        if (undefined !== data.products && undefined !== data.products.product) {

            for (const atomicAccessoryData of data.products.product) {

                /**
                 * If no backendId this atomicAccessory is not orderable, so skip it anyway
                 * There are NO attributes defined, skip this atomicAccessory [VGOF-5525][INC-23524]
                 */
                if (undefined === atomicAccessoryData.backendId
                    || (undefined === atomicAccessoryData.attributes
                    || undefined === atomicAccessoryData.attributes.attribute
                    || 0 === atomicAccessoryData.attributes.attribute.length)) {
                    continue;
                }

                this._atomicAccessory.push(
                    new AtomicAccessory(atomicAccessoryData, this)
                );
            }
        }
    }

    public getAttributes (): AccessoryAttributes {
        return this._attributes;
    }

    public isVirtualAccessory (): boolean {
        return (0 !== this._atomicAccessory.length);
    }

    public getAtomicAccessories (): AtomicAccessory[] {
        return this._atomicAccessory;
    }

    public removeUnPurchasableAtomicAccessories (): void {

        this._atomicAccessory = this._atomicAccessory.filter((atomicAccessory) => {
            return true === atomicAccessory.purchasable;
        });

    }

    public getAtomicAccessoryByIndex (index: number): AtomicAccessory {
        if (undefined === this._atomicAccessory[index]) {
            return undefined;
        }

        return this._atomicAccessory[index];
    }

    public getAtomicAccessoryById (id: number): AtomicAccessory {

        const atomicAccessories = this._atomicAccessory.filter((atomicAccessory: AtomicAccessory) => {

            if (id === atomicAccessory.id) {
                return true;
            }

            return false;

        });

        if (0 === atomicAccessories.length) {
            return undefined;
        }

        return atomicAccessories[0];

    }

    public getAtomicAccessoriesByAttr (attrName: string, attrValue: any): AtomicAccessory[] {

        const atomicAccessories = this._atomicAccessory.filter((atomicAccessory: AtomicAccessory) => {

            if (attrValue === atomicAccessory.attr[attrName]) {
                return true;
            }

            return false;

        });

        return atomicAccessories;

    }

    public getAtomicAccessoriesByAttrs (attributes: AttributeQuery): AtomicAccessory[] {

        const atomicAccessories = this._atomicAccessory.filter((atomicAccessory: AtomicAccessory) => {

            let match: Boolean = true;
            for (const attributeName in attributes) {

                const attributeValue = attributes[attributeName];

                if (attributeValue !== atomicAccessory.attr[attributeName]) {
                    match = false;
                    break;
                }

            }

            return match;

        });

        return atomicAccessories;

    }

    private getSeoUrl (name: string): string {

        name = name.toLocaleLowerCase();

        const umlaute: any = {
            'ä': 'ae',
            'ö': 'oe',
            'ü': 'ue',
            'ß': 'ss',
            '\\+': ' plus'
        };

        for (const umlaut in umlaute) {
            const regEx = new RegExp(umlaut, 'g');
            name = name.replace(regEx, umlaute[umlaut]);
        }

        name = name.replace(/\W+/g, '-');
        name = name.replace(/[^a-z0-9-]/g, '');
        name = name.replace(/[^0-9a-z]$/g, '');

        return name;
    }

    /**
     * Assume that prefix has no traling slash
     * @TODO In produktion no '?'
     */
    public getDetailLink (prefix: string): string {
        return prefix + '?/' + this.getSeoUrl(this._name) + '.html';
    }

    get id (): number {
        return this._id;
    }

    get name (): string {
        return this._name;
    }

    get attr (): AccessoryAttributes {
        return this._attributes;
    }

    get attributes (): AccessoryAttributes {
        return this._attributes;
    }

    get promotion (): string {
        return this._promotion;
    }

    get characteristic (): string {
        return this._characteristic;
    }

    get badge_text (): string {
        return this._badge_text;
    }

}
