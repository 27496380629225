/**
 * @module SalesFlow/view
 */

declare var $: JQueryStatic;

import Injector from 'core/injector';

import {Renderable} from '../../../renderable';

import DeviceOffer from '../../../view/shared/offer/device-offer';

import FilterBase from './filter-base';

import FilterGeneric from './filter-generic';

import FilterVendor from './filter-vendor';

import ActiveFilterBox from './active-filters-box';

import FilterMatch from './filter-match';

import FilterOption from '../../../view/shared/filter-option';

import FoldUpAndDown from '../../../element/shared/fold-up-and-down';

export interface ActiveFilterOptionsByGroup {
    [index: string]: FilterOption[];
}

export default class Filters extends Renderable<DeviceOffer[]> {

    private _element: JQuery;

    private _filters: FilterBase[] = [];

    private _activeFilters: ActiveFilterBox;

    constructor (injector: Injector) {

        super(injector);

        this._element = $('#nsf-device-filters');

        if (undefined === this._element.get(0)) {
            throw new Error('#nsf-device-filters not found.');
        }

        this._element.find('.device-filter').each((index, element) => {
            const name = $(element).data('name');

            if ('vendor' === name) {
                this._filters.push(new FilterVendor($(element), injector));
            } else {
                this._filters.push(new FilterGeneric($(element), injector));
            }
        });

        this._activeFilters = new ActiveFilterBox(injector);

    }

    protected render (viewOffers: DeviceOffer[]): void {

        const filtersHtml: string[] = this._filters.map((filter) => {
            return filter.render(viewOffers);
        });

        const widths = ['100', '100', '50', '33', '25'];

        const width = widths[filtersHtml.length];

        const filters: any = filtersHtml.map((filterHtml) => {
            return {
                width: width,
                filter: filterHtml
            };
        });

        const activeFiltersBox = this._activeFilters.render(this._filters);

        this._element.html(
            this.getInjector().getTemplates().render('device_filters', {
                filters: filters,
                activeFiltersBox: activeFiltersBox
            })
        );

        if (600 > $(window).width()) {
            this.initMobileAccordion();
        }

    }

    public getActiveFilterOptionsByGroup (): ActiveFilterOptionsByGroup {

        const activeFilters: ActiveFilterOptionsByGroup = {};

        for (const filter of this._filters) {

            for (const option of filter.options) {

                if (false === option.getActive()) {
                    continue;
                }

                if (undefined === activeFilters[filter.name]) {
                    activeFilters[filter.name] = [];
                }

                activeFilters[filter.name].push(option);

            }

        }

        return activeFilters;
    }

    protected disableOptionsWithNoCorrespondinfOffers (matchingViewOffers: DeviceOffer[]): void {

        // TODO @implement
        // yet impelemented while @ first step there are only vendor and rating filters; in this combination there should be no disabled options

        for (const filter of this._filters) {

            let lastChangeInSameGroup = false;

            for (const option of filter.options) {

                const $option = $('.checkBox[data-type="' + option.type + '"][data-attribute="' + option.attribute + '"][data-value="' + option.value + '"][data-condition="' + option.condition + '"]');

                // check is the actual option in the lastChanged filterGroup
                if ($option.parents('.mod-dropdowncheckbox').hasClass('lastChanged')) {
                    lastChangeInSameGroup = true;
                    $option.parents('.mod-dropdowncheckbox').removeClass('lastChanged');
                    break;
                }

                let atLeastOneHit: boolean = false;

                for (const matchingViewOffer of matchingViewOffers) {

                    const match = FilterMatch.exec(option, matchingViewOffer);

                    if (true === match) {
                        atLeastOneHit = true;
                        break;
                    }
                }

                option.setUmmatched(atLeastOneHit);

            }

            if (true === lastChangeInSameGroup) {
                lastChangeInSameGroup = false;
            }

        }

        for (const filter of this._filters) {

            for (const option of filter.options) {

                const $option = $('.checkBox[data-type="' + option.type + '"][data-attribute="' + option.attribute + '"][data-value="' + option.value + '"][data-condition="' + option.condition + '"]');

                if (false === option.getUnmatched()) {
                    $option.addClass('disabled');
                }
                else {
                    $option.removeClass('disabled');
                }
            }
        }

    }

    protected events (): void {

        for (const filter of this._filters) {
            filter.events();
        }

        this._activeFilters.events();

        this.getInjector().getEvent().listen('device-list-for-filter@filtered', (eventObject: JQueryEventObject, data: any) => {

            const matchingViewOffers: DeviceOffer[] = data.matchingViewOffers;

            this.disableOptionsWithNoCorrespondinfOffers(matchingViewOffers);

        });

        this.getInjector().getEvent().listen('filter@changed', (eventObject: JQueryEventObject, data: any) => {

            this.getInjector().getEvent().trigger('filters@changed', this.getActiveFilterOptionsByGroup());

        });

        this.getInjector().getEvent().trigger('filters@changed', this.getActiveFilterOptionsByGroup());

        let isAccordionRendered = false;
        $(window).on('vf::resize orientationchange', (eventObject: JQueryEventObject) => {
            if (600 > $(eventObject.currentTarget).width() && !isAccordionRendered) {
                this.initMobileAccordion();
                isAccordionRendered = true;
            } else {
                isAccordionRendered = false;
            }
        });

    }

    public bind (viewOffers: DeviceOffer[]): void {

        this.render(viewOffers);
        this.events();
    }

    private initMobileAccordion (): void {
        const foldUpAndDown = new FoldUpAndDown($('#nsf-fold-up-and-down-filter'), this._injector);
        foldUpAndDown.bind();
    }

}
