/**
 * @module SalesFlow/evolved/router
 */

import ControllerEvolvedVvlWinback from 'controller-evolved/vvl/controller-evolved--vvl-winback-landing-page';
import Customer from 'shopbackend/customer';
import { SalesChannelName} from 'core/ids';

export class RouterEvolvedVvlWinback {

    public constructor (
        protected customer: Customer,
        protected salesChannel: SalesChannelName) {

        this.customer = customer;
        this.salesChannel = salesChannel;

    }

    /**
     * Create the controller of winback landing page and render the functionallity.
     */
    public createController () {

    const winbackLandingPage = new ControllerEvolvedVvlWinback (this.customer, this.salesChannel);
    winbackLandingPage.render();
}

}
