/**
 * @module SalesFlow/view
 */

import AtomicAccessory from 'model/type/atomic-accessory';

import Offer from 'model/type/offer';

/**
 * @TODO To me clear in class naming: This should be named and imported as FrontendAccessoryOffer
 */
export default class ViewAccessoryOffer {

    private _offer: Offer;

    public readonly type: string = 'accessory';

    private _atomicAccessory: AtomicAccessory;

    private _isAccessorySelected: boolean = false;

    constructor (offer: Offer, atomicAccessory: AtomicAccessory) {

        this._offer = offer;

        this._atomicAccessory = atomicAccessory;

    }

    get offer (): Offer {
        return this._offer;
    }

    public setIsAccessorySelected (isSelected: boolean): void {
        this._isAccessorySelected = isSelected;
    }

    get onetimeAccessoryPrice (): number {
        return this._offer.devicePrice[0].value;
    }

    get onetimeAccessoryStrikePrice (): number {
        return this._offer.devicePrice[0].strikePrice;
    }

    get atomicAccessory (): AtomicAccessory {
        return this._atomicAccessory;
    }

    get atomicAccessoryId (): number {
        return this._atomicAccessory.id;
    }

    get attrAccessoryId (): string {
        return 'data-device-id="' + this._atomicAccessory.accessory.id + '"';
    }

    get attrAtomicAccessoryId (): string {
        return 'data-atomic-Accessory-id="' + this._atomicAccessory.id + '"';
    }

    get dataAttr (): string {

        const attrs: string[] = [];

        attrs.push(this.attrAccessoryId);
        attrs.push(this.attrAtomicAccessoryId);

        return attrs.join(' ');

    }

    get AccessorySelected (): string {

        if (false === this._isAccessorySelected) {
            return '';
        }

        return 'selected';
    }

    get nsfImage90_120 (): string {
        return 'https://opweb4.vfd2-testnet.de' + this._atomicAccessory.attr.image228_309;
    }

    /**
     * @TODO while the "real" nsf images an not in vlux, yet: Use older images in worng format as dummy
     */
    get nfsLargeImages (): string[] {
        return [
            'https://opweb4.vfd2-testnet.de' + this._atomicAccessory.attr.image228_309,
            'https://opweb4.vfd2-testnet.de' + this._atomicAccessory.attr.image228_309
        ];
    }

    get name (): string {
        return this._atomicAccessory.name;
    }

    get AccessoryName (): string {
        return this._atomicAccessory.getAccessory().name;
    }

    /**
     * headline above "Dein gewähltes Paket": different in sim-only offer and divice-offer
     */
    get labelMainProposition (): string {

        return this._atomicAccessory.name;

    }

}
