
import { SalesChannelName, ITariffDiscountMapping, WinbackCampaignGroup } from 'core/ids';
import { Constants } from 'core/constants';
import Customer from 'shopbackend/customer';
import { generatorVoucherCode } from 'service/winback/winback-generate-voucher-code';
import { ViewEvolvedVvlWinback } from 'view-evolved/element/vvl/view-evolved--element--vvl--winback-landing--page';

export default class ControllerEvolvedVvlWinback {

    private winbackView: ViewEvolvedVvlWinback = new ViewEvolvedVvlWinback();

    protected voucherServiceData = {
        error: '',
        voucherCode: '',
        group: '',
        groupDiscounts: {}
    };

    public constructor (
        protected customer: Customer,
        protected salesChannel: SalesChannelName) {

        this.customer = customer;
        this.salesChannel = salesChannel;

    }

    /**
     * Get voucher discounts per group
     */
    public getVoucherDiscountPerGroup (CampaignGroup: WinbackCampaignGroup): ITariffDiscountMapping {

        switch (CampaignGroup) {
            case Constants.WinbackGroup.GROUP_A:
                return Constants.GroupADiscounts;
                break;

            case Constants.WinbackGroup.GROUP_C:
                return Constants.GroupCDiscounts;
                break;
        }
    }

    /**
     * Get the valid campaign groups for the landing page.
     */
    public getCampaignGroups (groupCampaign: WinbackCampaignGroup) {

        const VoucherCampaignGroups = [
            Constants.WinbackGroup.GROUP_A,
            Constants.WinbackGroup.GROUP_C];

        return (-1 < VoucherCampaignGroups.indexOf(groupCampaign));
    }

    /**
     * Checks if the sales channel is eligable to access the landing page.
     */
    public isEligibleForLandingPage (): boolean {

        return (-1 < Constants.EligibleSalesChannelForWinbackVoucher.indexOf(this.salesChannel));

    }

    /**
     * Resolve the generator voucher code service.
     */
    private resolveService (): JQueryPromise<any> {
        const deferredService = $.Deferred();

        $.when(generatorVoucherCode(this.customer.msisdn)).then(res => {
            const voucherResponse = res;
            if (this.customer.isWinbackCustomer && this.isEligibleForLandingPage()) {
                if (voucherResponse.result === 'voucher' && this.getCampaignGroups(voucherResponse.group)) {
                    this.voucherServiceData.voucherCode = voucherResponse.voucher;
                    /**
                     * Get the discounts per group only if there is a voucher and the group is part of Campaign
                     */
                    this.voucherServiceData.groupDiscounts = this.getVoucherDiscountPerGroup(voucherResponse.group);
                }
                else if (voucherResponse.result === 'error') {
                    this.voucherServiceData.error = voucherResponse.reason;
                }
                else if (!this.getCampaignGroups(voucherResponse.group)) {
                    this.voucherServiceData.error = Constants.WINBACK_VOUCHER_NOT_PART_OF_CAMPAIGN;
                }
            }
            else {
                this.voucherServiceData.error = Constants.WINBACK_VOUCHER_NOT_PART_OF_CAMPAIGN;
            }

            this.winbackView.bind(this.voucherServiceData);

            deferredService.resolve();
        });

        return deferredService.promise();
    }

    /**
     * Render the functionallity.
     * For customers that are part of the campaign we generate the vouchers and load the discounts,
     * for the others we show the eligability restriction notification.
     * The function is done seperate in case other functionallity is added to the render
     */
    public render () {

        this.resolveService();
    }

}
