/**
 * @module SalesFlow/tracking
 */

import {IProduct} from './interfaces/IProduct';
import {IProposition} from './interfaces/IProposition';

export default class Order {
    public aProducts: IProduct[];
    public aPropositions: IProposition[];

    constructor (
        products: IProduct[],
        propositions: IProposition[]
    ) {
        this.aProducts = products;
        this.aPropositions = propositions;
    }
}
