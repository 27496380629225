/**
 * @module SalesFlow/controller-evolved
 */
declare var $: JQueryStatic;
declare var vf: any;

import { Constants } from 'core/constants';
import {FlowCtas} from 'view/ctas/shared/flow-ctas';
import Injector from 'core/injector';
import {DeviceOverviewPageState} from 'controller/shared/device-overview-controller';
import Offer from 'view/view/shared/offer/offer';
import DeviceOffer from 'view/view/shared/offer/device-offer';
import Customer from 'shopbackend/customer';
import AtomicDevice from 'model/type/atomic-device';
import Subscription from 'model/type/subscription';
import ViewOverlayDeviceDetails from 'view/view/shared/overlay-device-details';
import {ControllerEvolvedSharedDeviceOverview} from 'controller-evolved/shared/controller-evolved--shared--device-overview';
import {SalesChannelName, SubscriptionGroupName, SubscriptionIdPerSalesChannel} from 'core/ids';
import {EvolvedSubscriptionSelectionDeviceOverviewRedPlus} from 'view-evolved/element/redplus/view-evolved--element-redplus--subscription-selection-device-overview';
import {ModelEvolvedRepoSupervisor} from 'model-evolved/repo/model-evolved--repo--supervisor';
import {ViewEvolvedCtasRedPlusDeviceOverview} from 'view-evolved/ctas/redplus/view-evolved--ctas--redplus-device-overview';
import Device from 'model/type/device';
import { ViewEvolvedElementRedPlusContractDataAccordion } from 'view-evolved/element/redplus/view-evolved--element-redplus--contract-data-accordion';
import { ViewEvolvedElementRedPlusDeviceList } from 'view-evolved/element/redplus/view-evolved--element-redplus--device-list';
import { ModelEvolvedRepoPurchasableDevice } from 'model-evolved/repo/model-evolved--repo--purchasable-device';

export class ControllerEvolvedRedPlusDeviceTariff extends ControllerEvolvedSharedDeviceOverview {

    protected _deviceList: ViewEvolvedElementRedPlusDeviceList;

    protected _contractDataAccordion: ViewEvolvedElementRedPlusContractDataAccordion;
    protected _deviceOffersInitial: DeviceOffer[] = [];

    private _focusAtomicDeviceId: number;

    protected _subscriptionSelection: EvolvedSubscriptionSelectionDeviceOverviewRedPlus;

    constructor (
        customer: Customer,
        salesChannel: SalesChannelName,
        atomicDeviceId: number,
        subscriptionId: number,
        focusSubscriptionIds: SubscriptionIdPerSalesChannel,
        reposSupervisor: ModelEvolvedRepoSupervisor,
        injector: Injector
    ) {

        super(
            salesChannel,
            atomicDeviceId,
            subscriptionId,
            focusSubscriptionIds,
            customer,
            reposSupervisor,
            injector
        );

        /**
         * Display Logged-In Customer data
         */
        this._customer = this.getInjector().getFlowStateWithSalesChannel().customer;
        this._contractDataAccordion = new ViewEvolvedElementRedPlusContractDataAccordion(
            this._customer,
            injector
        );

        this._deviceList = new ViewEvolvedElementRedPlusDeviceList(
            injector,
            this.getReposSupervisor().getAttributeRepo(),
            this._focusAtomicDeviceId
        );

    }

    protected createSubscriptionList (): EvolvedSubscriptionSelectionDeviceOverviewRedPlus {

        return new EvolvedSubscriptionSelectionDeviceOverviewRedPlus(
            this._subscriptionId,
            this.getInjector(),
            this._focusSubscriptionId
        );

    }

    /**
     * overwritten from base class as we have to read different device repos
     */
    protected getSubscriptionsOffers (): DeviceOffer[] {

        const offers: DeviceOffer[] = [];

        const subscriptionIds = this._subscriptionSelection.getSubscriptionIds();

        for (const subscriptionId of subscriptionIds) {

            // get current subscription
            const subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(subscriptionId);

            // get matching device repo
            let purchasableRepo: ModelEvolvedRepoPurchasableDevice;

            if (Constants.RedData_MainId === subscriptionId) {
                purchasableRepo = this.getReposSupervisor().getPurchasableTabletRepo();
            } else if (Constants.RedKids_MainId === subscriptionId)  {
                purchasableRepo = this.getReposSupervisor().getPurchasableDeviceKidsRepo();
            } else {
                purchasableRepo = this.getReposSupervisor().getPurchasableDeviceRepo();
            }

            // get atomic device
            const atomicDevice = purchasableRepo.getAtomicDevice(
                this._atomicDeviceId,
                this.getSalesChannel(),
                subscription
            );

            if (undefined === atomicDevice) {
                $('.tariff-module-tile[data-subscription-id="' + subscription.id + '"]').hide().addClass('hide');
                continue;
            } else {
                $('.tariff-module-tile[data-subscription-id="' + subscription.id + '"]').show().removeClass('hide');
            }

            // do we have an offer for tariff-device combination?
            const offer = this._generalSalesObjectInterface.getSimHardwareOfferByAtomicDeviceIdAndSubscriptionId(
                atomicDevice.id,
                subscription.id,
                this._btx,
                this.getSalesChannel()
            );

            /** not every device is offered for each tariff */
            if (undefined !== offer) {
                offers.push(
                    new DeviceOffer(
                        atomicDevice,
                        subscription,
                        offer
                    )
                );
            }

        }

        return offers;
    }

    protected getDeviceOffersWithActiveSubscription (): DeviceOffer[] {

        const subscriptionId = this.getSubscriptionId();
        let devices: Device[];

        this._subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(subscriptionId);

        if (Constants.RedData_MainId === subscriptionId) {
            devices = this.getReposSupervisor().getPurchasableTabletRepo().getDevices(this.getSalesChannel(), this._subscription);
        } else if (Constants.RedKids_MainId === subscriptionId)  {
            devices = this.getReposSupervisor().getPurchasableDeviceKidsRepo().getDevices(this.getSalesChannel(), this._subscription);
        } else {
            devices = this.getReposSupervisor().getPurchasableDeviceRepo().getDevices(this.getSalesChannel(), this._subscription);
        }

        const deviceOffers = devices.map(device => {

            /**
             * Subscription is locked, so I use the first atomic device of the virtual device
             */
            const offer = this._generalSalesObjectInterface.getSimHardwareOfferByAtomicDeviceIdAndSubscriptionId(
                device.getAtomicDeviceByIndex(0).id,
                this._subscription.id,
                this._btx,
                this.getSalesChannel()
            );

            return new DeviceOffer(
                device.getAtomicDeviceById(offer.deviceId),
                this._subscription, offer,
                this.getRedPlusFromFlow(),
                this.getInjector().getFlowStateWithSalesChannel().optionalServiceIds.elements
            );

        });

        return deviceOffers;
    }

    protected getSalesChannel (): SalesChannelName {
        return this._salesChannel;
    }

    protected getSubscriptionGroup (): SubscriptionGroupName {
        return this.getInjector().getFlowStateWithSalesChannel().getSubscriptionGroup();
    }

    protected createCtas (): FlowCtas {

        return new ViewEvolvedCtasRedPlusDeviceOverview(this.getInjector());

    }

    protected getOneDeviceOffer (atomicDevice: AtomicDevice, subscription: Subscription): DeviceOffer {

        const vluxOffer = this._generalSalesObjectInterface.getSimHardwareOfferByAtomicDeviceIdAndSubscriptionId(
            atomicDevice.id,
            subscription.id,
            this._btx,
            this.getSalesChannel()
        );

        /**
         * Get selected optional services
         */
        const optionalServiceIds = this.getInjector().getFlowStateWithSalesChannel().optionalServiceIds.elements;

        return new DeviceOffer(
            atomicDevice.getDevice().getAtomicDeviceById(vluxOffer.deviceId),
            subscription,
            vluxOffer, [],
            optionalServiceIds, []
        );

    }

    /**
     * A device is selected
     *
     * @param atomicDeviceId
     * @param avoidTracking
     */
    private handleEventAtomicDeviceIdChanged (atomicDeviceId: number, avoidTracking: boolean) {

        this._atomicDeviceId = atomicDeviceId;

        this._pageState = new DeviceOverviewPageState(this._subscriptionId, this._atomicDeviceId, this.getInjector().getFlowStateWithSalesChannel().getHardwareOnly());

        // A device is selected, but not yet a subscription -> Scroll to scubsriptions
        if (undefined !== this._atomicDeviceId && undefined === this._subscriptionId) {

            const offset = $('header').height() - 20;

            $('html, body').animate({
                scrollTop: $('#subscriptions').offset().top - offset
            }, 1000);

        }

        this._subscriptionSelection.setScrollToFocus(false);
        this._subscriptionSelection.update(this.getSubscriptions());

        // atomicDeviceId has changed and is now undefined or no subscription is selected
        // -> We can make no offer
        if (undefined === atomicDeviceId && undefined === this._subscriptionId) {
            this.getInjector().getEvent().trigger('offer@none');

            return;
        }

        const offer: Offer = this.getActiveOffer();

        if (undefined === offer) {
            return;
        }

        this.getInjector().getOfferCollection().setActiveOffer(offer);

        this.getInjector().getEvent().trigger('offer@changed', {
            offer: offer
        });

        if (true !== avoidTracking) {
            this.getInjector().getEvent().trigger('pageviewTracking@changed', {
                pageName: 'all smartphones and tariffs',
                pageType: (undefined === offer) ? 'product listing' : 'product detail',
                deviceOffer: offer,
                btx: this._btx,
                currentPage: this._injector.getRouting().getCurrentPage(),
                customer: this.getInjector().getFlowStateWithSalesChannel().getCustomer()
            });
        }

    }

    /**
     * Active atomicId of a deviceTile has changes. e.g. by color picker or bundle dropdown
     *
     * We need to update tiles offer and send it back to tile
     * If the tile is currently selected we need to update the current offer as well
     *
     * @param atomicDevice
     * @param subscription
     */
    private handleEventDeviceTileAtomicIdChanged (atomicDevice: AtomicDevice, subscription: Subscription): void {

        const deviceOffer = this.getOneDeviceOffer(atomicDevice, subscription);

        const selectedDeviceId = $('.device-module-tile.selected').data('device-id');

        const deviceOffers = this.getInjector().getOfferCollection().getDevices();

        // replace matching this._deviceOffer
        // @TODO instead of looping here fetch one device offer from offerCollection
        for (let i = 0, x = deviceOffers.length; i < x; i += 1) {

            if (deviceOffers[i].atomicDevice.device.id === deviceOffer.atomicDevice.device.id) {

                deviceOffers[i] = deviceOffer;

                // is the offer that we are replacing to active one?
                if (selectedDeviceId === deviceOffer.atomicDevice.device.id) {

                    this._atomicDeviceId = deviceOffer.atomicDeviceId;

                    this.getInjector().getOfferCollection().setActiveOffer(deviceOffer);
                    this.getInjector().getOfferCollection().setDevices(deviceOffers);

                    // we changed the atomic device of the active offer.
                    // most likely the onetime price per subscription has changed as well
                    this.getInjector().getOfferCollection().setSubscriptions(
                        this.getSubscriptions()
                    );

                    this._subscriptionSelection.update(
                        this.getInjector().getOfferCollection().getSubscriptions()
                    );

                    this.getInjector().getFlowStateWithSalesChannel().setAtomicDeviceId(deviceOffer.atomicDevice.id, true);
                    this.getInjector().getEvent().trigger('offer@changed', {
                        offer: deviceOffer
                    });

                }

                // no need to loop up to the end while we had  a match
                break;

            }

        }

        this.getInjector().getEvent().trigger('device-tile@offerChanged:' + deviceOffer.atomicDevice.device.id, {
            deviceOffer: deviceOffer
        });

    }

    /**
     * Insurance has been (un)checked
     *
     * While all insurance checkboxes are checked together, we need to update all deviceOffers and send them to all tiles
     * If a device is already selected, we update the current offer as well
     *
     * @param atomicDevice
     * @param subscription
     */
    private handleEventDeviceTileInsuranceChanged (atomicDevice: AtomicDevice, subscription: Subscription, isSelected: boolean): void {

        const deviceOffers = this.getInjector().getOfferCollection().getDevices().map(deviceOffer => {

            // We reset red plus cards when user enters tariff device selection page, so we pass red plus cards as an empty array
            return new DeviceOffer(
                deviceOffer.atomicDevice,
                deviceOffer.subscription,
                deviceOffer.offer,
                [],
                this.getInjector().getFlowStateWithSalesChannel().optionalServiceIds.elements
            );

        });

        this.getInjector().getOfferCollection().setDevices(deviceOffers);

        const selectedDeviceId = $('.device-module-tile.selected').data('device-id');
        const isSubscriptionSelected = this.getInjector().getFlowStateWithSalesChannel().getSubscriptionId();

        for (const deviceOffer of this.getInjector().getOfferCollection().getDevices()) {

            this.getInjector().getEvent().trigger('device-tile@offerChanged:' + deviceOffer.atomicDevice.device.id, {
                deviceOffer: deviceOffer
            });

            // is the offer that we are replacing to active one? and is there a subscription tile already selected?
            if (selectedDeviceId === deviceOffer.atomicDevice.device.id && undefined !== isSubscriptionSelected) {
                this.getInjector().getEvent().trigger('offer@changed', {
                    offer: deviceOffer
                });
            }

        }

        // automatic select device, if insuranceBox is selected
        if (true === isSelected) {

            const deviceOffer = this.getOneDeviceOffer(atomicDevice, subscription);

            this.getInjector().getFlowStateWithSalesChannel().setAtomicDeviceId(deviceOffer.atomicDevice.id, false);

            // Only trigger this event if a subscription tile is selected
            if (undefined !== isSubscriptionSelected) {
                this.getInjector().getEvent().trigger('offer@changed', {
                    offer: deviceOffer
                });
            }

            this.getInjector().getEvent().trigger('atomicDeviceId@changedByInsurance', {
                atomicDeviceId: deviceOffer.atomicDeviceId
            });
        }
    }

    /**
     *
     * @param deviceOffer
     */
    protected handleEventDeviceTileOpenDetailOverlay (deviceOffer: DeviceOffer): void  {

        const viewOverlay = new ViewOverlayDeviceDetails(this._injector, deviceOffer, this.getReposSupervisor().getAttributeRepo());
        this.getInjector().getOverlay().open(viewOverlay, 'overlay_device_details');
        vf.tabs.init();

        vf['responsive-table'].init();

    }

    public events () {

        this.getInjector().getEvent().listen('atomicDeviceId@changed', (eventObject: JQueryEventObject, data: any): void => {

            const atomicDeviceId: number = data.atomicDeviceId;

            if (this._atomicDeviceId === atomicDeviceId) {
                return;
            }

            const avoidTracking = (true === data.avoidTracking) ? true : false;

            this.handleEventAtomicDeviceIdChanged(atomicDeviceId, avoidTracking);

        });

        this.getInjector().getEvent().listen('device-tile@atomicIdChanged', (eventObject: JQueryEventObject, data: any) => {

            const atomicDevice: AtomicDevice = data.atomicDevice;
            const subscription: Subscription = data.subscription;

            this.handleEventDeviceTileAtomicIdChanged(atomicDevice, subscription);

         });

        this.getInjector().getEvent().listen('device-tile@insuranceChanged', (eventObject: JQueryEventObject, data: any) => {
            const atomicDevice: AtomicDevice = data.atomicDevice;
            const subscription: Subscription = data.subscription;
            const isSelected: boolean = data.insuranceBoxSelected;

            this.handleEventDeviceTileInsuranceChanged(atomicDevice, subscription, isSelected);

        });

        this.getInjector().getEvent().listen('device-tile@openDetailOverlay', (eventObject: JQueryEventObject, data: any) => {

            const deviceOffer: DeviceOffer = data.deviceOffer;

            this.handleEventDeviceTileOpenDetailOverlay(deviceOffer);

        });

    }

    /**
     * @TODO can this uses 1:1 in bnt's  device overview page
     */
    protected getActiveDeviceOffer (): DeviceOffer {

        let activeOffer: DeviceOffer = super.getActiveDeviceOffer();

        if (undefined !== activeOffer) {

            // active atomic device is not the first one, so we have to correct t
            if (this.getInjector().getFlowStateWithSalesChannel().getAtomicDeviceId() !== activeOffer.atomicDeviceId) {

                const activeAtomicDevice: AtomicDevice = activeOffer.atomicDevice.device.getAtomicDeviceById(this.getInjector().getFlowStateWithSalesChannel().getAtomicDeviceId());

                const offer = this._generalSalesObjectInterface.getSimHardwareOfferByAtomicDeviceIdAndSubscriptionId(
                    activeAtomicDevice.id,
                    activeOffer.subscription.id,
                    this._btx,
                    this.getSalesChannel()
                );

                if (undefined !== offer) {
                    // We reset red plus cards when user enters tariff device selection page, so we pass red plus cards as an empty array
                    activeOffer = new DeviceOffer(
                        activeAtomicDevice,
                        activeOffer.subscription,
                        offer,
                        [],
                        this.getInjector().getFlowStateWithSalesChannel().optionalServiceIds.elements
                    );

                    const deviceOffers = this.getInjector().getOfferCollection().getDevices();

                    for (let i = 0, x = deviceOffers.length; i < x; i += 1) {

                        if (true === deviceOffers[i].isDeviceSelected) {
                            this.getInjector().getOfferCollection().replaceDevice(i, activeOffer);

                            break;
                        }

                    }

                }

            }

        }

        return activeOffer;

    }

    protected tracking () {
        const activeOffer = this.getActiveOffer();

        this.getInjector().getEvent().trigger('pageviewTracking@onload',
            {
                subscription: this._subscription,
                pageName: 'all smartphones and tariffs',
                pageType: (undefined === activeOffer) ? 'product listing' : 'product detail',
                deviceOffer: activeOffer,
                btx: this._btx,
                currentPage: this._injector.getRouting().getCurrentPage(),
                customer: this.getInjector().getFlowStateWithSalesChannel().getCustomer()
            }
        );
    }

    public bind (): void {

        this._contractDataAccordion.bind();

        super.bind();

        $('#subscriptions').show();

        this._pricebox.setAnnotationPriceOnce('hide');

    }
}
