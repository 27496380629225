/**
 * @module SalesFlow/view
 */
export default class FilterOption {

    private _type: string;
    private _title: string;
    private _attribute: string;
    private _value: string;
    private _condition: string;

    private _active: boolean = false;
    private _unmatched: boolean = false;

    constructor (data: JQuery) {

        this._type = data.data('type');
        this._title = data.data('title');
        this._attribute = data.data('attribute');
        this._value = data.data('value');
        this._condition = data.data('condition');

    }

    public getActive (): boolean {
        return this._active;
    }

    public setActive (active: boolean) {
        this._active = active;
    }

    public getUnmatched (): boolean {
        return this._unmatched;
    }

    public setUmmatched (unmatched: boolean) {
        this._unmatched = unmatched;
    }

    get type (): string {
        return this._type;
    }

    get title (): string {
        return this._title;
    }

    get attribute (): string {
        return this._attribute;
    }

    get value (): string {
        return this._value;
    }

    get condition (): string {
        return this._condition;
    }

    get selected (): string {
        if (true === this._active) {
            return 'selected';
        }

        return '';
    }

}
