/**
 * @module SalesFlow/evolved/view
 */

declare var $: JQueryStatic;

import Injector from 'core/injector';
import {Renderable} from 'view/renderable';

/**
 * @TODO This should be complete refactored and moved to DeviceOverviewVvlCtas
 *
 * @export
 * @class ViewEvolvedElementVvlSimonlyHandling
 * @extends {Renderable<boolean>}
 */
export class ViewEvolvedElementVvlSimonlyHandling extends Renderable<boolean> {

    private _isSimonlyAllowed: boolean;
    private _isSimonlyOffer: boolean;
    private _showAutomatic: boolean;
    private _warningOpen: boolean;
    private _notificationTimeout: any;

    constructor (injector: Injector) {
        super(injector);

        this._showAutomatic = true;
        this._warningOpen = false;
    }

    protected render (): string {

        if (!this._isSimonlyAllowed && this._isSimonlyOffer) {

            $('.nfs-device-overview-confirm').attr('disabled', 'disabled');

            this._notificationTimeout = setTimeout(() => {
                if (this._showAutomatic) {
                    this._showAutomatic = false;
                    this._warningOpen = true;
                }
            }, 5000);

        } else {
            this._warningOpen = false;
            this._showAutomatic = false;
            clearTimeout(this._notificationTimeout);
        }

        return '';
    }

    protected events (): void {
        this.getInjector().getEvent().listen('confirmButton@set', (eventObject: JQueryEventObject, data: any) => {
            this.render();
        });

        $(document).on('click', '#nsf-pricebox-ctas .btn-wrap, .pricebox-button-box .btn-wrap', (eventObject: JQueryEventObject) => {
            if (eventObject.currentTarget !== eventObject.target) {
                return;
            }
            if ($(eventObject.currentTarget).find('.nfs-device-overview-confirm').attr('disabled')) {
                this._warningOpen = true;
            }
        });

        $(document).on('click', '.simonly-warning .close', (eventObject: JQueryEventObject) => {
            this._warningOpen = false;
        });
    }

    public bind (isSimonlyAllowed: boolean): void {
        this._isSimonlyAllowed = isSimonlyAllowed;
        this.events();
    }

    public update (isSimonlyOffer: boolean) {
        if (isSimonlyOffer) {
            this._isSimonlyOffer = true;
        } else {
            this._isSimonlyOffer = false;
        }
        this.render();
    }

}
