/**
 * @module SalesFlow/model/type
 */

/*
$('li.jstree-closed i').trigger('click');
window.setTimeout(function(){

    $('#tree').find('li a').each(function(){console.log($(this).data('title').replace('ID: ', ''), $(this).text());})

},2000);

Liste aller Attribute von
http://handyfinder-admin.vfcom.de/vlux2016/index.cfm?show=master_data&action=show&data_type=attribute
Gescrapt mit dem Script oben

1 Merkmale
    143 Top-Features
    9 Substufe
    168 Liefertermin
    127 Lieferzeit
    126 Lieferumfang
    118 Hersteller
    109 Primärfarbe
    21 Handytyp
    145 Themenwelt
    32 ECO-Rating (Wert zwischen 1-5)
    167 Enthaltenes Highspeed-Datenvolumen
2 Alle Top-Eigenschaften
    16 Standby-Zeit bis zu
    17 Gesprächszeit bis zu
    18 Nutzungszeit bis zu
    19 Musikwiedergabe bis zu
    28 Prozessor
    12 Betriebssystem
    22 Downloadgeschwindigkeit bis zu
    23 Uploadgeschwindigkeit bis zu
    135 Fingerabdrucksensor
    157 Wasserabweisend
    33 SAR-Wert
    20 Abmessungen
    15 Gewicht
    13 Farbe
    217 Anzahl SIM-Slots
    222 Dual-SIM
    226 Videowiedergabe bis zu
6 Display(s)
    57 Display-Diagonale in Zoll (Inch)
    58 Display-Diagonale
    59 Auflösung
    60 Farbtiefe
    101 Display-Digonale entfaltet
    225 Display-Digonale entfaltet in Zoll (Inch)
    224 Auflösung entfaltet
5 Digitalkamera
    47 Megapixel
    48 Fotoauflösung
    53 Videoauflösung
    49 Frontkamera für Videotelefonie/Videochat
    50 FaceTime
    51 Megapixel Frontkamera
    52 Fotoauflösung Frontkamera
    54 Videoauflösung Frontkamera
    55 Videoaufnahme in
    46 Integrierte Digitalkamera
15 Speicher-Eigenschaften
    27 Arbeitsspeicher
    14 Interner Speicher bis zu
    24 Interner Speicher für eigene Dateien bis zu
    25 Speicher erweiterbar
    26 Unterstützt Speicherkarten bis
8 Konnektivität
    71 LTE/4G
    146 5G
    271 5G+
    146 Chrystal Clear
    72 VoLTE
    134 WiFi Calling
    73 UMTS Broadband
    74 UMTS
    75 EDGE
    76 EDGE/GPRS
    77 GPRS
    78 Quadband
    79 Triband
    80 Dualband
    81 WLAN
    82 Bluetooth
    83 Bluetooth-Version
    84 Infrarotschnittstelle
    85 DLNA
    86 GPS-Empfänger
    87 NFC
    88 NFC-Wallet-zertifiziert
    89 HDMI
    144 micro HDMI
    90 USB-Anschluss
	165 USB-Anschluss (micro-USB)
	166 USB-Anschluss (Typ C)
    91 VGA-Ausgang
    92 Kopfhöreranschluss
    93 USB 2.0
    94 USB 3.0
    95 Mic-In
    96 Ethernet (RJ45)
    97 Cardreader
    98 Ext. Antennenanschluss
    99 SIM-Kartentyp
    128 DECT-Telefonanlage
    129 UPnP
    100 Netzsperre
    195 Qi-Standard
18 Kompatible Betriebssysteme
    147 Windows 10
    148 Windows 8
    149 Windows 7
    150 macOS (El Capitan)
11 Unterhaltung
    110 MP3-Player
    111 Radio
    112 MusicDownload
    113 MobileTV
    115 Stereolautsprecher
    116 MMS
3 Bedienung
    36 Touchscreen
    37 Touchscreen QWERTZ-Tastatur
    38 Tastatur
    39 QWERTZ-Tastatur
    40 Sprachbefehle
    41 Sprachwahl
    42 Freisprechfunktion
    158 Seniorengerecht
9 Organizer
    103 Kalender
    104 Diktierfunktion
    105 E-Mail Client
    106 E-MailConnect
12 Images
    159 NSF-Image 288x320
    160 NSF-Image 288x200
    162 NSF-Image 170x230
    161 NSF-Image 90x120
    122 Image 170x230
    123 Image 228x309
    125 Image 170x119
    130 Image 228x160
4 Besonderheiten
    44 Handy-Einstufung
    43 Produkttyp
    45 SIM-Lock
    35 Technical-Segment
14 Descriptions
    132 description_devicecard_back
    137 description_accessory
    131 description_devicecard_front
    169 Produktinformationsblatt
16 Internal Flags
	164 OLS Launch Date (yyyymmdd)
	170 Themenwelt
    138 order type
    139 contract type
    140 contractProlongation restrictions
    142 im Bundle mit
17 Promotion Flags
    141 5€ discount for selected devices
20 Technische IDs
    155 EAN
    163 Artikelnummer
    156 MPN
21 Context Attributes
    119 btx
    136 salesChannel
13 KDG JSON
    124 kdg_json_tv
    133 Product Code
*/

/**
 * @TODO Make attribute repo a parameter of device repo and read these infos async from json
 * @TODO This does not belong to type
 */
export default class AttributesParser {

    public static attributesGroups: any = {
        1: {
            name: 'Merkmale',
            showInFrontend: false
        },
        2: {
            name: 'Alle Top-Eigenschaften',
            showInFrontend: true
        },
        3: {
            name: 'Bedienung',
            showInFrontend: true
        },
        4: {
            name: 'Besonderheiten',
            showInFrontend: true
        },
        5: {
            name: 'Digitalkamera',
            showInFrontend: true
        },
        6: {
            name: 'Display(s)',
            showInFrontend: true
        },
        8: {
            name: 'Konnektivit&#228;t',
            showInFrontend: true
        },
        9: {
            name: 'Organizer',
            showInFrontend: true
        },
        11: {
            name: 'Unterhaltung',
            showInFrontend: true
        },
        12: {
            name: 'Images',
            showInFrontend: false
        },
        13: {
            name: 'KDG JSON',
            showInFrontend: false
        },
        14: {
            name: 'Descriptions',
            showInFrontend: false
        },
        15: {
            name: 'Speicher-Eigenschaften',
            showInFrontend: true
        },
        16: {
            name: 'Internal Flags',
            showInFrontend: false
        },
        17: {
            name: 'Promotion Flags',
            showInFrontend: false
        },
        18: {
            name: 'Kompatible Betriebssysteme',
            showInFrontend: true
        },
        20: {
            name: 'Technische IDs',
            showInFrontend: false
        },
        21: {
            name: 'Context Attributes',
            showInFrontend: false
        },
        23: {
            name: 'Pass-Apps',
            showInFrontend: false
        },
        25: {
            name: 'SIM-Karte(n)',
            showInFrontend: true
        }
    };

    public static attributesMapping: any = {
        143: {
            germanLabel: 'Top-Features',
            name: 'topFeatures',
            type: 'array',
            used: true
        },
        9: {
            germanLabel: 'Substufe',
            name: 'subLevel',
            type: 'number',
            used: true
        },
        168: {
            germanLabel: 'Liefertermin',
            name: 'deliverDate',
            type: 'string',
            used: true
        },
        127: {
            germanLabel: 'Lieferzeit',
            name: 'deliveryTime',
            type: 'string',
            used: true
        },
        126: {
            germanLabel: 'Lieferumfang',
            name: 'packageParts',
            type: 'array',
            used: true
        },
        118: {
            germanLabel: 'Hersteller',
            name: 'vendor',
            type: 'string',
            used: true
        },
        109: {
            germanLabel: 'Primärfarbe',
            name: 'primaryColor',
            type: 'string',
            used: false
        },
        21: {
            germanLabel: 'Handytyp',
            name: 'type',
            type: 'string',
            used: true
        },
        145: {
            germanLabel: 'Themenwelt',
            name: 'themeWorld',
            type: 'string',
            used: false
        },
        32: {
            germanLabel: 'ECO-Rating (Wert zwischen 1-5)',
            name: 'eco',
            type: 'string',
            used: false
        },
        167: {
            germanLabel: 'Enthaltenes Highspeed-Datenvolumen',
            name: 'dataVolumeIncluded',
            type: 'number',
            used: false
        },
        16: {
            germanLabel: 'Standby-Zeit bis zu',
            name: 'standbyTime',
            type: 'number',
            used: true
        },
        17: {
            germanLabel: 'Gesprächszeit bis zu',
            name: 'talkingTime',
            type: 'number',
            used: true
        },
        18: {
            germanLabel: 'Nutzungszeit bis zu',
            name: 'usageTime',
            type: 'number',
            used: true
        },
        19: {
            germanLabel: 'Musikwiedergabe bis zu',
            name: 'musicPlayback',
            type: 'number',
            used: true
        },
        28: {
            germanLabel: 'Prozessor',
            name: 'processor',
            type: 'string',
            used: true
        },
        12: {
            germanLabel: 'Betriebssystem',
            name: 'os',
            type: 'string',
            used: true
        },
        22: {
            germanLabel: 'Downloadgeschwindigkeit bis zu',
            name: 'downloadSpeed',
            type: 'number',
            used: true
        },
        23: {
            germanLabel: 'Uploadgeschwindigkeit bis zu',
            name: 'uploadSpeed',
            type: 'number',
            used: true
        },
        135: {
            germanLabel: 'Fingerabdrucksensor',
            name: 'fingerprintSensor',
            type: 'boolean',
            default: false,
            used: true
        },
        157: {
            germanLabel: 'Wasserabweisend',
            name: 'waterRepellent',
            type: 'boolean',
            default: false,
            used: true
        },
        33: {
            germanLabel: 'SAR-Wert',
            name: 'sarValue',
            type: 'number',
            used: true
        },
        20: {
            germanLabel: 'Abmessungen',
            name: 'measurements',
            type: 'string',
            used: true
        },
        15: {
            germanLabel: 'Gewicht',
            name: 'weight',
            type: 'number',
            used: true
        },
        226: {
            germanLabel: 'Videowiedergabe bis zu',
            name: 'videoPlayback',
            type: 'number',
            used: true
        },
        13: {
            germanLabel: 'Farbe',
            name: 'color',
            type: 'string',
            used: true
        },
        57: {
            germanLabel: 'Display-Diagonale in Zoll (Inch)',
            name: 'displayInch',
            type: 'number',
            used: true
        },
        58: {
            germanLabel: 'Display-Diagonale',
            name: 'displayCm',
            type: 'number',
            used: true
        },
        59: {
            germanLabel: 'Auflösung',
            name: 'resolution',
            type: 'string',
            used: true
        },
        60: {
            germanLabel: 'Farbtiefe',
            name: 'colorDepth',
            type: 'number',
            used: true
        },
        101: {
            germanLabel: 'Display-Digonale entfaltet',
            name: 'displayUnfolded',
            type: 'number',
            default: false,
            used: true
        },
        225: {
            germanLabel: 'Display-Digonale entfaltet in Zoll (Inch)',
            name: 'displayUnfoldedInch',
            type: 'number',
            used: true
        },
        224: {
            germanLabel: 'Auflösung entfaltet',
            name: 'resolutionUnfolded',
            type: 'string',
            used: true
        },
        47: {
            germanLabel: 'Megapixel',
            name: 'megapixel',
            type: 'number',
            used: true
        },
        48: {
            germanLabel: 'Fotoauflösung',
            name: 'photoResolution',
            type: 'string',
            used: true
        },
        49: {
            germanLabel: 'Frontkamera für Videotelefonie/Videochat',
            name: 'frontcamera',
            type: 'boolean',
            default: false,
            used: true
        },
        50: {
            germanLabel: 'FaceTime',
            name: 'faceTime',
            type: 'boolean',
            default: false,
            used: true
        },
        51: {
            germanLabel: 'Megapixel Frontkamera',
            name: 'megapixelFrontcamera',
            type: 'number',
            used: true
        },
        52: {
            germanLabel: 'Fotoauflösung Frontkamera',
            name: 'resolutionFrontcamera',
            type: 'string',
            used: true
        },
        53: {
            germanLabel: 'Videoauflösung',
            name: 'videoResolution',
            type: 'string',
            used: true
        },
        54: {
            germanLabel: 'Videoauflösung Frontkamera',
            name: 'videoResolutionFrontcamera',
            type: 'string',
            used: true
        },
        55: {
            germanLabel: 'Videoaufnahme in',
            name: 'hdMode',
            type: 'string',
            used: true
        },
        46: {
            germanLabel: 'Integrierte Digitalkamera',
            name: 'integratedDigitalCamera',
            type: 'boolean',
            default: false,
            used: true
        },
        27: {
            germanLabel: 'Arbeitsspeicher',
            name: 'ram',
            type: 'number',
            used: true
        },
        14: {
            germanLabel: 'Interner Speicher bis zu',
            name: 'internalMemory',
            type: 'number',
            used: true
        },
        24: {
            germanLabel: 'Interner Speicher für eigene Dateien bis zu',
            name: 'internalMemoryOwnFiles',
            type: 'number',
            used: true
        },
        25: {
            germanLabel: 'Speicher erweiterbar',
            name: 'memoryExpandable',
            type: 'boolean',
            default: false,
            used: true
        },
        26: {
            germanLabel: 'Unterstützt Speicherkarten bis',
            name: 'memoryCardsUpTo',
            type: 'number',
            used: true
        },
        71: {
            germanLabel: 'LTE/4G',
            name: 'lte',
            type: 'boolean',
            default: false,
            used: true
        },
        146: {
            germanLabel: '5G',
            name: 'fiveG',
            type: 'boolean',
            default: false,
            used: true
        },
        271: {
            germanLabel: '5G+',
            name: 'fiveGPlus',
            type: 'boolean',
            default: false,
            used: true
        },
        72: {
            germanLabel: 'VoLTE',
            name: 'voLte',
            type: 'boolean',
            default: false,
            used: true
        },
        134: {
            germanLabel: 'WiFi Calling',
            name: 'wifiCalling',
            type: 'boolean',
            default: false,
            used: true
        },
        73: {
            germanLabel: 'UMTS Broadband',
            name: 'umtsBroadband',
            type: 'boolean',
            default: false,
            used: true
        },
        74: {
            germanLabel: 'UMTS',
            name: 'umts',
            type: 'boolean',
            default: false,
            used: true
        },
        75: {
            germanLabel: 'EDGE',
            name: 'edge',
            type: 'boolean',
            default: false,
            used: true
        },
        76: {
            germanLabel: 'EDGE/GPRS',
            name: 'edgeGprs',
            type: 'boolean',
            default: false,
            used: true
        },
        77: {
            germanLabel: 'GPRS',
            name: 'gprs',
            type: 'boolean',
            default: false,
            used: true
        },
        78: {
            germanLabel: 'Quadband',
            name: 'quadband',
            type: 'boolean',
            default: false,
            used: true
        },
        79: {
            germanLabel: 'Triband',
            name: 'triband',
            type: 'boolean',
            default: false,
            used: true
        },
        80: {
            germanLabel: 'Dualband',
            name: 'dualband',
            type: 'boolean',
            default: false,
            used: true
        },
        81: {
            germanLabel: 'W-LAN',
            name: 'wlan',
            type: 'boolean',
            default: false,
            used: true
        },
        82: {
            germanLabel: 'Bluetooth',
            name: 'bluetooth',
            type: 'boolean',
            default: false,
            used: true
        },
        83: {
            germanLabel: 'Bluetooth-Version',
            name: 'bluetoothVersion',
            type: 'number',
            used: true
        },
        84: {
            germanLabel: 'Infrarotschnittstelle',
            name: 'infrared',
            type: 'boolean',
            default: false,
            used: true
        },
        85: {
            germanLabel: 'DLNA',
            name: 'DLNA',
            type: 'boolean',
            default: false,
            used: true
        },
        86: {
            germanLabel: 'GPS-Empfänger',
            name: 'gps',
            type: 'boolean',
            default: false,
            used: true
        },
        87: {
            germanLabel: 'NFC',
            name: 'nfc',
            type: 'boolean',
            default: false,
            used: true
        },
        88: {
            germanLabel: 'NFC-Wallet-zertifiziert',
            name: 'nfcWallet',
            type: 'boolean',
            default: false,
            used: true
        },
        89: {
            germanLabel: 'HDMI',
            name: 'hdmi',
            type: 'boolean',
            default: false,
            used: true
        },
        144: {
            germanLabel: 'micro HDMI',
            name: 'microHdmi',
            type: 'boolean',
            default: false,
            used: true
        },
        90: {
            germanLabel: 'USB-Anschluss',
            name: 'usb',
            type: 'boolean',
            default: false,
            used: true
        },
        165: {
            germanLabel: 'USB-Anschluss (micro-USB)',
            name: 'microUsb',
            type: 'boolean',
            default: false,
            used: true
        },
        166: {
            germanLabel: 'USB-Anschluss (Typ C)',
            name: 'usbTypC',
            type: 'boolean',
            default: false,
            used: true
        },
        91: {
            germanLabel: 'VGA-Ausgang',
            name: 'vga',
            type: 'boolean',
            default: false,
            used: false
        },
        92: {
            germanLabel: 'Kopfhöreranschluss',
            name: 'headphones',
            type: 'boolean',
            default: false,
            used: true
        },
        93: {
            germanLabel: 'USB 2.0',
            name: 'usb2',
            type: 'boolean',
            default: false,
            used: true
        },
        94: {
            germanLabel: 'Abmessungen entfaltet',
            name: 'measurementsFold',
            type: 'string',
            used: true
        },
        95: {
            germanLabel: 'Mic-In',
            name: 'micIn',
            type: 'boolean',
            default: false,
            used: true
        },
        96: {
            germanLabel: 'Ethernet (RJ45)',
            name: 'ethernet',
            type: 'boolean',
            default: false,
            used: true
        },
        97: {
            germanLabel: 'Cardreader',
            name: 'cardreader',
            type: 'boolean',
            default: false,
            used: true
        },
        98: {
            germanLabel: 'Ext. Antennenanschluss',
            name: 'externalAntenna',
            type: 'boolean',
            default: false,
            used: true
        },
        99: {
            germanLabel: 'SIM-Kartentyp',
            name: 'simType',
            type: 'array',
            used: true
        },
        128: {
            germanLabel: 'DECT-Telefonanlage',
            name: 'dect',
            type: 'boolean',
            default: false,
            used: true
        },
        129: {
            germanLabel: 'UPnP',
            name: 'upnp',
            type: 'boolean',
            default: false,
            used: true
        },
        100: {
            germanLabel: 'Netzsperre',
            name: 'netLock',
            type: 'boolean',
            default: false,
            used: true
        },
        195: {
            germanLabel: 'Qi-Standard',
            name: 'qiStandard',
            type: 'boolean',
            default: false,
            used: true
        },
        147: {
            germanLabel: 'Windows 10',
            name: 'windows10',
            type: 'boolean',
            default: false,
            used: true
        },
        148: {
            germanLabel: 'Windows 8',
            name: 'windows8',
            type: 'boolean',
            default: false,
            used: true
        },
        149: {
            germanLabel: 'Windows 7',
            name: 'windows7',
            type: 'boolean',
            default: false,
            used: true
        },
        150: {
            germanLabel: 'macOS (El Capitan)',
            name: 'macOS',
            type: 'boolean',
            default: false,
            used: true
        },
        110: {
            germanLabel: 'MP3-Player',
            name: 'mp3Player',
            type: 'boolean',
            default: false,
            used: true
        },
        111: {
            germanLabel: 'Radio',
            name: 'radio',
            type: 'boolean',
            default: false,
            used: true
        },
        112: {
            germanLabel: 'MusicDownload',
            name: 'musicDownload',
            type: 'boolean',
            default: false,
            used: true
        },
        113: {
            germanLabel: 'MobileTV',
            name: 'mobileTv',
            type: 'boolean',
            default: false,
            used: true
        },
        115: {
            germanLabel: 'stereo',
            name: 'stereoSpeaker',
            type: 'boolean',
            default: false,
            used: true
        },
        116: {
            germanLabel: 'MMS',
            name: 'mms',
            type: 'boolean',
            default: false,
            used: true
        },
        36: {
            germanLabel: 'Touchscreen',
            name: 'touchscreen',
            type: 'boolean',
            default: false,
            used: true
        },
        37: {
            germanLabel: 'Touchscreen QWERTZ-Tastatur',
            name: 'touchscreenQwertz',
            type: 'boolean',
            default: false,
            used: true
        },
        38: {
            germanLabel: 'Tastatur',
            name: 'kayboard',
            type: 'boolean',
            default: false,
            used: true
        },
        39: {
            germanLabel: 'QWERTZ-Tastatur',
            name: 'qwertzKeyboard',
            type: 'boolean',
            default: false,
            used: true
        },
        40: {
            germanLabel: 'Sprachbefehle',
            name: 'voiceCommands',
            type: 'boolean',
            default: false,
            used: true
        },
        41: {
            germanLabel: 'Sprachwahl',
            name: 'voiceDail',
            type: 'boolean',
            default: false,
            used: true
        },
        42: {
            germanLabel: 'Freisprechfunktion',
            name: 'handsfree',
            type: 'boolean',
            default: false,
            used: true
        },
        158: {
            germanLabel: 'Seniorengerecht',
            name: 'seniorFocused',
            type: 'boolean',
            default: false,
            used: true
        },
        103: {
            germanLabel: 'Kalender',
            name: 'calendar',
            type: 'boolean',
            default: false,
            used: true
        },
        104: {
            germanLabel: 'Diktierfunktion',
            name: 'dictation',
            type: 'boolean',
            default: false,
            used: true
        },
        105: {
            germanLabel: 'E-Mail Client',
            name: 'mailClient',
            type: 'boolean',
            default: false,
            used: true
        },
        106: {
            germanLabel: 'E-MailConnect',
            name: 'mailConnect',
            type: 'string',
            used: true
        },
        159: {
            germanLabel: 'NSF-Image 288x320',
            name: 'nsfImage288_320',
            type: 'string',
            used: true
        },
        160: {
            germanLabel: 'NSF-Image 288x200',
            name: 'nsfImage288_200',
            type: 'string',
            used: true
        },
        162: {
            germanLabel: 'NSF-Image 170x230',
            name: 'nsfImage170_230',
            type: 'string',
            used: true
        },
        161: {
            germanLabel: 'NSF-Image 90x120',
            name: 'nsfImage90_120',
            type: 'string',
            used: true
        },
        122: {
            germanLabel: 'Image 170x230',
            name: 'image170_230',
            type: 'string',
            used: true
        },
        123: {
            germanLabel: 'Image 228x309',
            name: 'image228_309',
            type: 'string',
            used: true
        },
        125: {
            germanLabel: 'Image 170x119',
            name: 'image170_119',
            type: 'string',
            used: true
        },
        130: {
            germanLabel: 'Image 228x160',
            name: 'image228_160',
            type: 'string',
            used: true
        },
        44: {
            germanLabel: 'Handy-Einstufung',
            name: 'cellphoneRating',
            type: 'boolean',
            used: false
        },
        43: {
            germanLabel: 'Produkttyp',
            name: 'productType',
            type: 'string',
            used: true
        },
        45: {
            germanLabel: 'SIM-Lock',
            name: 'simLock',
            type: 'boolean',
            default: false,
            used: true
        },
        35: {
            germanLabel: 'Technical-Segment',
            name: 'technicalSegment',
            type: 'boolean',
            default: false,
            used: true
        },
        137: {
            germanLabel: 'description_accessory',
            name: 'descriptionAccessory',
            type: 'string',
            used: true
        },
        131: {
            germanLabel: 'description_devicecard_front',
            name: 'descriptionDevicecardFront',
            type: 'string',
            used: true
        },
        132: {
            germanLabel: 'description_devicecard_back',
            name: 'descriptionDevicecardBack',
            type: 'string',
            used: true
        },
        169: {
            germanLabel: 'Produktinformationsblatt',
            name: 'productInfoSheet',
            type: 'string',
            used: true
        },
        164: {
            germanLabel: 'OLS Launch Date (yyyymmdd)',
            name: 'olsLaunchDate',
            type: 'string',
            used: true
        },
        170: {
            germanLabel: 'Themenwelt',
            name: 'productCategory',
            type: 'string',
            used: true
        },
        138: {
            germanLabel: 'order type',
            name: 'orderType',
            type: 'string',
            used: true
        },
        139: {
            germanLabel: 'contract type',
            name: 'contractType',
            type: 'string',
            used: true
        },
        140: {
            germanLabel: 'contractProlongation restrictions',
            name: 'contractProlongationRestrictions',
            type: 'string',
            used: true
        },
        142: {
            germanLabel: 'im Bundle mit',
            name: 'bundledWith',
            type: 'string',
            used: true
        },
        141: {
            germanLabel: '5€ discount for selected devices',
            name: 'dicount5Euro',
            type: 'boolean',
            default: false,
            used: true
        },
        155: {
            germanLabel: 'EAN',
            name: 'ean',
            type: 'string',
            used: true
        },
        163: {
            germanLabel: 'Artikelnummer',
            name: 'artikelnummer',
            type: 'string',
            used: true
        },
        156: {
            germanLabel: 'MPN',
            name: 'mpn',
            type: 'string',
            used: true
        },
        119: {
            germanLabel: 'btx',
            name: 'btx',
            type: 'string',
            used: true
        },
        136: {
            germanLabel: 'salesChannel',
            name: 'salesChannel',
            type: 'string',
            used: true
        },
        124: {
            germanLabel: 'kdg_json_tv',
            name: 'kdgJsonTv',
            type: 'boolean',
            used: true
        },
        133: {
            germanLabel: 'Product Code',
            name: 'productCode',
            type: 'string',
            used: true
        },
        217: {
            germanLabel: 'Anzahl SIM-Slots',
            name: 'simSlotCount',
            type: 'string',
            used: true
        },
        171: {
            germanLabel: 'Internet-Nutzung bis zu',
            name: 'dataUsage',
            type: 'number',
            used: true
        },
        207: {
            germanLabel: 'Gesichtserkennung',
            name: 'faceId',
            type: 'boolean',
            used: true
        },
        172: {
            germanLabel: 'USB-Anschluss (Lightning)',
            name: 'lightning',
            type: 'boolean',
            used: true
        },
        222: {
            germanLabel: 'Mehrfach-Objektive',
            name: 'multipleLenses',
            type: 'string',
            used: true
        },
        257: {
            germanLabel: 'Nachhaltigkeit',
            name: 'ecoRating',
            type: 'string',
            used: true
        }

    };

    public static parse (attributes: any): any {

        const parsedAttributes: any = {};
        const arrayAttributes: any = {};

        for (const attribute of attributes) {
            const attributeId: any = attribute.id;

            const attributeMapping: any = this.attributesMapping[attributeId];

            if (undefined === attributeMapping) {
                continue;
            }

            if ('string' === attributeMapping.type) {
                parsedAttributes[attributeMapping.name] = attribute.value;
            }

            else if ('boolean' === attributeMapping.type) {
                parsedAttributes[attributeMapping.name] = ('1' === attribute.value) ? true : false;
            }

            else if ('number' === attributeMapping.type) {
                parsedAttributes[attributeMapping.name] = 1 * attribute.value;
            }
            else if ('array' === attributeMapping.type) {

                if (undefined === parsedAttributes[attributeMapping.name]) {
                    parsedAttributes[attributeMapping.name] = [];
                    arrayAttributes[attributeMapping.name] = true;
                }

                parsedAttributes[attributeMapping.name].push(attribute);
            }

        }

        for (const arrayAttributeName in arrayAttributes) {

            const arrayAttribute = parsedAttributes[arrayAttributeName];

            arrayAttribute.sort(function (a: any, b: any) {

                if (a.sortOrder > b.sortOrder) {

                    return 1;

                }

                if (a.sortOrder < b.sortOrder) {

                    return -1;

                }

                // a muss gleich b sein
                return 0;
            });

            parsedAttributes[arrayAttributeName] = arrayAttribute.map((part: any): string => {

                return part.value;

            });
        }

        return parsedAttributes;

    }
}
