/**
 * @module SalesFlow/controller
 */

import {Controller} from 'controller/controller';

import Stepper from 'view/element/shared/stepper';
import StepperBnt from 'view/element/bnt/stepper';
import StepperGigakombi from 'view/element/gigakombi/stepper';
import {ViewEvolvedElementVvlStepper} from 'view-evolved/element/vvl/view-evolved--element-vvl--stepper';

import {FlowCtas} from 'view/ctas/shared/flow-ctas';
import Injector from 'core/injector';
import { Constants } from 'core/constants';

export abstract class FlowController extends Controller {

    protected _stepper: Stepper;
    protected _ctas: FlowCtas;

    constructor (injector: Injector) {

        super(injector);

        /**
         * Use same stepper for BNT and Gigakombi as they have slimier text
         */
        if (Constants.BTX_BNT === this._btx ) {
            this._stepper = new StepperBnt(injector);
        } else if ( Constants.BTX_GIGAKOMBI === this._btx) {
            this._stepper = new StepperGigakombi(injector);
        } else {
            this._stepper = new ViewEvolvedElementVvlStepper(injector);
        }

        this._ctas = this.createCtas();

    }

    protected abstract tracking (): void;

    protected abstract createCtas (): FlowCtas;

}
