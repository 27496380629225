/**
 * @module SalesFlow/model/type
 */
export default class SubscriptionAttributes {

    [key: string]: any;

    private _subLevel: number;

    private _orderType: string;

    private _contractType: string;

    private _contractProlongationRestrictions: string;

    private _dataVolume: number;

    private _pib: string;

    /**
     * @TODO If only these four attributes are used this bugaboo is not needed, so check which attributes are used in conjunction with subscriptions
     * @TODO create an attributes interface including an index signature
     */
    constructor (attributes: any, parentAttributes?: SubscriptionAttributes) {

        if (undefined !== parentAttributes) {

            for (const key in parentAttributes) {
                if (false === parentAttributes.hasOwnProperty(key)) {
                    continue;
                }

                const publicKey = key.replace('_', '');

                this[key] = parentAttributes[publicKey];

            }

        }

        for (const attribute of attributes) {

            const attributeId = attribute.id;

            if ('9' === attributeId) {
                this._subLevel = parseFloat(attribute.value);
            }
            if ('138' === attributeId) {
                this._orderType = attribute.value;
            }
            if ('139' === attributeId) {
                this._contractType = attribute.value;
            }
            if ('140' === attributeId) {
                this._contractProlongationRestrictions = attribute.value;
            }
            if ('167' === attributeId) {
                this._dataVolume = parseFloat(attribute.value.replace(',', '.'));
            }
            if ('169' === attributeId) {
                this._pib = attribute.value;
            }

        }
    }

    get subLevel (): number {
        return this._subLevel;
    }

    get orderType (): string {
        return this._orderType;
    }

    get contractType (): string {
        return this._contractType;
    }

    get contractProlongationRestrictions (): string {
        return this._contractProlongationRestrictions;
    }

    get dataVolume (): number {
        return this._dataVolume;
    }

    get pib (): string {
        return this._pib;
    }

}
