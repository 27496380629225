/**
 * @module SalesFlow/evolved/view
 */

declare var $: JQueryStatic;

import {Renderable} from '../../../view/renderable';

import Injector from 'core/injector';

import Offer from 'model/type/offer';

export default class ViewEvolvedElementSharedVFPassArea extends Renderable<any> {

    constructor (injector: Injector) {
        super(injector);
        this.initPassId();
    }

    public render (): void {
    }

    public events (): void {
    }

    public renderPassArea (tariffTile: JQuery, offer: Offer): void {
        /**
         * If there is no pass box in the tariff tile there is nothing to do
         */
        if (0 === tariffTile.find('.pass-box[data-pass-id]').length) {
            return;
        }

        let selectedPassId: number = this.getStoredPassId();
        if ( !selectedPassId ) {
            // preselect default passId if defined and still nothing is selected
            selectedPassId = this.getDefaultPassId();
            if (selectedPassId) {
                this.setStoredPassId(selectedPassId);
            }
        }

        if (selectedPassId) {
            this.getInjector().getFlow().optionalServiceIds.removeElement(selectedPassId);
            this.getInjector().getFlow().optionalServiceIds.addElement(selectedPassId);
        }

        const optionalServices = offer.getOptionalServices();
        let showPassNotAvailableNotification = false;
        const $choiceBox = tariffTile.find('.pass-area .choice-box');
        let choiceBoxText = $choiceBox.data('pass-select-text');
        const $notificationBox = tariffTile.find('.pass-area .notification');
        let notificationText = '';

        tariffTile.find('.pass-box[data-pass-id]').each((index: number, element: Element) => {
            const $passBox = $(element);
            const passId = parseInt($passBox.data('pass-id'), 10);
            let passIsAvailable = false;

            for (let i = 0; i < optionalServices.length; i++) {
                const services = optionalServices[i].services;
                for (let j = 0; j < services.length; j++) {
                    if (passId === services[j].id) {
                        passIsAvailable = true;
                    }
                }
            }

            if (passIsAvailable) {
                showPassNotAvailableNotification = false;
                notificationText = '';

                $passBox.removeClass('disabled');

                if (passId === selectedPassId) {
                    $passBox.addClass('selected');
                }
            } else {
                if (passId === selectedPassId) {
                    showPassNotAvailableNotification = true;
                    notificationText = $passBox.data('pass-notification');
                }
            }

            if (passId === selectedPassId) {
                choiceBoxText = $passBox.data('pass-change-text');
                $choiceBox.attr('class', 'choice-box');
                $choiceBox.addClass($passBox.data('pass-icon-class'));
            }
        });

        if (!selectedPassId) {
            showPassNotAvailableNotification = false;
        }

        $choiceBox.find('.choice').text(choiceBoxText);
        $notificationBox.text(notificationText);

        if (showPassNotAvailableNotification) {
            $notificationBox.show();
        } else {
            $notificationBox.hide();
        }
    }

    public bind (): void {
    }

    /**
     * check for passId via param only on init
     */
    private initPassId (): void {
        let passId: number = this._injector.getGetParameter().getVfPassIdParam();
        if (undefined === passId) {
            // check if pass is already selected
            passId = this.getStoredPassId();
            if (!passId) {
                // store default pass if defined and nothing is selected
                passId = this.getDefaultPassId();
                if (passId) {
                    this.setStoredPassId(passId);
                }
            }
        } else {
            this.setStoredPassId(passId);
        }
    }

    /**
     * this method will return the default passId from the options
     */
    public getDefaultPassId (): number {
        const tariffGroup: string = this.getInjector().getFlow().getSubscriptionGroup();
        const optionName: string = 'preselected_' + tariffGroup + '_passid';
        const passId: number = parseInt(this._injector.getOptions().get(optionName), 10);

        return isNaN(passId) ? undefined : passId ;
    }

    public getStoredPassId (): number {
        return parseInt(this._injector.getStorage().getItem('passId'), 10);
    }

    public setStoredPassId (passId: number): void {
        if (undefined !== passId) {
            this._injector.getStorage().setItem('passId', passId.toString());
        }
    }

}
