/**
 * @module SalesFlow/view
 */

declare var vf: any;

import Injector from 'core/injector';

import {Renderable} from '../../renderable';

export default class FoldUpAndDown extends Renderable<any> {

    private _open: boolean;

    private _element: JQuery;

    constructor (element: JQuery, injector: Injector) {

        super(injector);

        this._element = element;

        this._open = this._element.find('.tg-head').hasClass('tg-active');

    }

    public getId (): string {
        return this._element.attr('id');
    }

    public render (): void {

    }

    public events (): void {

        this._element.on('click', '.tg-head', () => {
            if (true === this._open) {
                this._element.find('.tg-head').removeClass('tg-active');
                this._element.find('.tg-body').removeClass('tg-display');
                this._open = false;

                this._injector.getEvent().trigger('foldUpAndDown@up', {
                    element: this._element
                });

            }
            else {
                this._element.find('.tg-head').addClass('tg-active');
                this._element.find('.tg-body').addClass('tg-display');
                this._open = true;

                this._injector.getEvent().trigger('foldUpAndDown@down', {
                    element: this._element
                });

                vf['tariff-detail-akko'].setDetailHeight(this._element);

            }
        });

    }

    /**
     * Worlds best method: Allow to remove accordion and just keep content.
     * Needed e.g. while subscriptionlist is not shown in accordion in devicesFirstFlow but OpenText sends the very same response not knowing xyFistFlow
     */
    public removeAccordionStyling (): void {

        this._element.removeClass('accordion accordion-filterbox');
        this._element.removeClass('accordion-filterbox');
        this._element.find('.tg-head').hide();

        this._element.find('.tg-accord').removeClass('tg-accord');
        this._element.find('.tg-item').removeClass('tg-item');
        this._element.find('.tg-body').removeClass('tg-body');
        this._element.find('.tg-board').removeClass('tg-board');

    }

    public bind (): void {

        this.render();
        this.events();
    }

}
