/**
 * @module SalesFlow/controller
 */

import Injector from 'core/injector';
import {BusinessTransactionContext, SubscriptionIdPerSalesChannel, TariffGroupName} from 'core/ids';

import {ControllerEvolvedBaseClass} from 'controller-evolved/base-class/controller-evolved--base-class';

import Offer from 'view/view/shared/offer/offer';
import SimOnlyOffer from 'view/view/shared/offer/sim-only-offer';

import {ModelEvolvedRepoSupervisor} from 'model-evolved/repo/model-evolved--repo--supervisor';
import {Constants} from 'core/constants';

/**
 * @TODO When Get parameter, tha focus that
 * @TODO Resolve by Router
 */
export abstract class ControllerEvolvedBaseClassSubscriptionOverview extends ControllerEvolvedBaseClass {

    constructor (
        focusSubscriptionIdArray: SubscriptionIdPerSalesChannel,
        reposSupervisor: ModelEvolvedRepoSupervisor,
        injector: Injector
    ) {

        super(focusSubscriptionIdArray, reposSupervisor, injector);

    }

    protected tracking (eventType: string, overwriteRule?: boolean ) {

        this.getInjector().getEvent().trigger(eventType, {
            overwriteRule: overwriteRule,
            pageName: this.getInjector().getOptions().get('page_name'),
            pageType: 'product listing',
            deviceOffer: {}
        });

    }

    protected render (data?: any): void {
        super.render();
    }

    protected switchTeaser (tariffGroupName: string): void {

        if (Constants.BTX_GIGAKOMBI === this._btx) {
            $('.mod-simple-promo-teaser[data-pass-id="0"][data-subscription-group="consumer"][data-gigakombi="1"]').show();
        } else {
            let isVfPassTeaser = false;
            $('.mod-simple-promo-teaser[data-pass-id]').each((index: number, element: Element) => {
                if (false === $(element).is(':hidden')) {
                    if (0 < $(element).data('pass-id')) {
                        isVfPassTeaser = true;
                    }
                }
            });
            // switch SPT, if no passId was passed
            if (false  === isVfPassTeaser) {
                $('.mod-simple-promo-teaser[data-pass-id]').hide();
                if ('easy' !== tariffGroupName) {
                    $('.mod-simple-promo-teaser[data-pass-id="0"][data-subscription-group="' + tariffGroupName + '"]').show();
                }
            }
            $('.mod-simple-promo-teaser[data-pass-id="0"][data-subscription-group="consumer"][data-gigakombi="1"]').hide();
        }
    }

    protected switchHeadlines (tariffGroupName: string): void {
        const idHeadline = this.getInjector().getOptions().get('groupchange_headline_page_id');
        const idSubheadline = this.getInjector().getOptions().get('groupchange_subheadline_page_id');
        if (idHeadline) {
            $('#text-only-' + idHeadline + ' h1').html(
                this.getInjector().getStrings().get('groupchanged.headline.' + tariffGroupName)
            );
        }
        if (idSubheadline) {
            $('#text-only-' + idSubheadline + ' h2').html(
                this.getInjector().getStrings().get('groupchanged.subheadline.' + tariffGroupName)
            );
        }
    }

    /**
     * This function gets the simonly-offer to the active subscriptionId that is stored in flow
     */
    protected getActiveSimOnlyOffer (): SimOnlyOffer {

        const subscriptionId = this.getInjector().getFlowState().getSubscriptionId();
        const salesChannel = this.getInjector().getFlowState().getSalesChannel();
        const subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(subscriptionId);

        return new SimOnlyOffer(
            subscription,
            this.getInjector().getGeneralSalesObjectInterface().getSimOnlyOfferBySubscriptionId(subscriptionId, this._btx, salesChannel)
        );

    }

    /**
     * This function gets the offer to the active subscriptionId that is stored in flow
     */
    protected getActiveOffer (): Offer {

        const subscriptionId = this.getInjector().getFlowState().getSubscriptionId();

        if (undefined !== subscriptionId) {

            return this.getActiveSimOnlyOffer();

        }

    }

    /**
     * This function gets all subscriptionIds for the selected tariff-group from HTML markup.
     * Then the subcritions and after that the simonly offers matching to the subscriptionIds are loaded.
     */
    protected getSimOnlyOffersMatchingToSlider (): SimOnlyOffer[] {

        const salesChannel = this.getInjector().getFlowState().getSalesChannel();
        const tariffGroup: TariffGroupName = this.getInjector().getFlowState().getTariffGroup();

        const generalSalesObjectInterface = this.getInjector().getGeneralSalesObjectInterface();

        const simOnlyOffers: SimOnlyOffer[] = [];

        for (const subscriptionId of this._subscriptionSelection.getEvolvedSubscriptionIds(tariffGroup)) {

            const subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(subscriptionId);
            let offer = generalSalesObjectInterface.getSimOnlyOfferBySubscriptionId(subscriptionId, this._btx, salesChannel);

            if (undefined === offer) {
                /**
                 * sometimes, vlux forgets to configure simOnly Offer. So this is a kind of workaround to fix this
                 * problem
                 */
                offer = this.getInjector().getGeneralSalesObjectInterface().getFirstAvailableOfferBySubscriptionId(subscriptionId, this._btx, salesChannel);
            }

            simOnlyOffers.push(new SimOnlyOffer(subscription, offer));

        }

        return simOnlyOffers;
    }

    protected getOffersMatchingToSlider (): Offer[] {

        return this.getSimOnlyOffersMatchingToSlider();
    }

    protected fillOfferOfferCollection (): void {

        this.getInjector().getOfferCollection().setActiveOffer(
            this.getActiveOffer()
        );

        this.getInjector().getOfferCollection().setSubscriptions(
            this.getOffersMatchingToSlider()
        );

        this.getInjector().getOfferCollection().log();

    }

    public bind (): void {

        if (this._showSubscriptionGroupSwitcher) {
            this._subscriptionGroupSwitcher.bind();
        }

        this.resetDedicatedFlowData();

        this.fillOfferOfferCollection();

        this._subscriptionSelection.bind(
            this.getInjector().getOfferCollection().getSubscriptions()
        );

        this.getInjector().getLoadingIndicator().hide();

        this.render();
        this.events();
        this.tracking('pageviewTracking@onload');

    }

}
