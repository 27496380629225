/**
 * @module SalesFlow/model/repo
 */

import NsfError from 'core/error';

/**
 * This class is only needed for typing the getXYRepo promises
 */
export abstract class Repo {

    private _rawData: any;

    protected abstract parseData (json: any): NsfError;

    public get rawData (): any {
        return this._rawData;
    }

    public set rawData (json: any) {

        if ('' === json) {
            return;
        }

        this._rawData = json;

    }

}
