/**
 * @module SalesFlow/model/repo
 */

import NsfError from 'core/error';

import {Repo} from './repo';
import Accessory from 'model/type/accessory';
import AtomicAccessory from '../type/atomic-accessory';

import AccessoryRepo from 'model/repo/accessory-repo';
import GeneralSalesObjectInterface from 'service/general-sales-object/general-sales-object-interface';
import { SalesChannelName } from 'core/ids';
import { Constants } from 'core/constants';

/**
 * Repo for accessory but with an additional check if a accessory is purchasable
 */
export default class PurchasableAccessoryRepo extends Repo {

    private _accessoryRepo: AccessoryRepo;
    private _generalOfferServiceInterface: GeneralSalesObjectInterface;
    private _salesChannel: SalesChannelName;

    constructor (accessoryRepo: AccessoryRepo, generalOfferServiceInterface: GeneralSalesObjectInterface, salesChannel: SalesChannelName) {

        super();

        this._accessoryRepo = accessoryRepo;

        this._generalOfferServiceInterface = generalOfferServiceInterface;
        this._salesChannel = salesChannel;

    }

    protected parseData (json: any): NsfError {
        return undefined;
    }

    public getAccessories (): Accessory[] {

        let accessories: Accessory[] = this._accessoryRepo.getAccessories();

        for (const accessory of accessories) {

            for (const atomicAccessory of accessory.getAtomicAccessories()) {

                atomicAccessory.setPurchasable(undefined !== this._generalOfferServiceInterface.getHardwareOnlyOfferByAtomicId(
                    atomicAccessory.id,
                    this._salesChannel));
            }

            accessory.removeUnPurchasableAtomicAccessories();

        }

        accessories = accessories.filter((accessory: Accessory) => {
            return 0 !== accessory.getAtomicAccessories().length;
        });

        return accessories;
    }

    public getAccessory (id: number): Accessory {

        const accessories = this.getAccessories();

        for (const accessory of accessories) {
            if (id === accessory.id) {
                return accessory;
            }
        }

        return undefined;

    }

    public getAtomicAccessory (id: number): AtomicAccessory {

        const accessories = this.getAccessories();

        for (const accessory of accessories) {
            for (const atomicDevice of accessory.getAtomicAccessories()) {
                if (id === atomicDevice.id) {
                    return atomicDevice;
                }
            }
        }

        return undefined;

    }

}
