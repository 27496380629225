/**
 * @module SalesFlow/controller
 */

import Injector from 'core/injector';
import {SalesChannelName, SubscriptionIdPerSalesChannel, SubscriptionGroupName, OrderType} from 'core/ids';

import {ControllerEvolvedFlowBaseWithoutSalesChannel} from 'controller-evolved/controller-evolved--flow-base-without-sales-channel';

import {ViewEvolvedCtasSharedFlowCtas} from 'view-evolved/ctas/shared/view-evolved--ctas--shared--flow-ctas';

import {AbstractSubscriptionGroupSwitcher} from 'view/element/shared/subscription-group-switcher';
// import SubscriptionGroupSwitcher from 'view/element/bnt/subscription-group-switcher';

import {TariffGroupInterface, ViewEvolvedElementSharedTariffgroupSwitcher} from 'view-evolved/element/shared/view-evolved--element-shared--tariffgroup-switcher';

import {ModelEvolvedRepoSupervisor} from 'model-evolved/repo/model-evolved--repo--supervisor';

import {EvolvedBaseClassSubscriptionSelection} from 'view-evolved/element/base-class/view-evolved--element-base-class--subscription-selection';
import ViewEvolvedElementGigakombiTariffgroupSwitcher from 'view-evolved/element/gigakombi/view-evolved--element-gigakombi--tariffgroup-switcher';
import {Constants} from 'core/constants';
import { ModelEvolvedRepoPurchasableDevice } from 'model-evolved/repo/model-evolved--repo--purchasable-device';
import { ViewEvolvedElementBntTariffGroupSwitcher } from 'view-evolved/element/bnt/view-evolved--element-bnt--tariffgroup-switcher';
import GeneralSalesObjectInterface from 'service/general-sales-object/general-sales-object-interface';

/**
 * @TODO When Get parameter, tha focus that
 * @TODO Resolve by Router
 */
export abstract class ControllerEvolvedBaseClass extends ControllerEvolvedFlowBaseWithoutSalesChannel {

    protected _subscriptionGroupSwitcher: AbstractSubscriptionGroupSwitcher;

    protected _showSubscriptionGroupSwitcher: boolean = true;

    protected _subscriptionSelection: EvolvedBaseClassSubscriptionSelection;

    protected _focusSubscriptionIdArray: SubscriptionIdPerSalesChannel;

    protected abstract createSubscriptionList (): EvolvedBaseClassSubscriptionSelection;
    protected abstract fillOfferOfferCollection (): void ;
    protected abstract switchTeaser (tariffGroupName: string): void;
    protected abstract switchHeadlines (tariffGroupName: string): void;

    protected _generalSalesObjectInterface: GeneralSalesObjectInterface;

    /**
     * For all Voice-Tariff-Pages it's the pruchasableDeviceRepo.
     */
    private _generalDeviceRepo: ModelEvolvedRepoPurchasableDevice;

    constructor (
        focusSubscriptionIdArray: SubscriptionIdPerSalesChannel,
        reposSupervisor: ModelEvolvedRepoSupervisor,
        injector: Injector
    ) {

        super(reposSupervisor, injector);

        this._generalSalesObjectInterface = injector.getGeneralSalesObjectInterface();

        /**
         * Array of Subscription Ids per tariffGroup
         */
        this._focusSubscriptionIdArray = focusSubscriptionIdArray;

        const orderType: OrderType = this.getInjector().getFlowState().getOrderType();

        /**
         * No SalesChannel- (aka SubscriptionGroup-) in case of family&friends
         */
        if (Constants.SALESCHANNEL_FAMILY_FRIENDS ===  injector.getFlowState().getSalesChannel()) {
            this._showSubscriptionGroupSwitcher = false;
        }

        /**
         * The subscription group switcher has to be removed from the Young Landing Page
         */
        if ('young' === this.getInjector().getFlowState().getSalesChannel() && 'tarife yolo' === this.getInjector().getOptions().get('page_name')) {
            this._showSubscriptionGroupSwitcher = false;
        }

        if ( Constants.BTX_GIGAKOMBI === this._btx) {
            this._subscriptionGroupSwitcher = this.createGigakombiTariffGroupSwitcher();
        }
        else if (this._showSubscriptionGroupSwitcher) {
            this._subscriptionGroupSwitcher = this.createTariffGroupSwitcher();
        }

        this._subscriptionSelection = this.createSubscriptionList();

        this.setGeneralDeviceRepo();
    }

    protected createCtas (): ViewEvolvedCtasSharedFlowCtas {
        return undefined;

    }

    /**
     * Get the BNT subscription group switcher
     *
     * @returns SubscriptionGroupSwitcher
     */
    protected createTariffGroupSwitcher (): AbstractSubscriptionGroupSwitcher {
        return new ViewEvolvedElementBntTariffGroupSwitcher(
            this.getInjector()
        );
    }

    protected render (data?: any): void {

        this._subscriptionSelection.update(
            this.getInjector().getOfferCollection().getSubscriptions()
        );

    }

    /**
     * @TODO This should be reviewed
     */
    protected resetDedicatedFlowData () {

        let resetData = true;

        // if coming from subscribtion, don't reset
        if ('subscription_overview' === this._stepper.previousStep) {
            resetData = false;
        }

        // reloaded page
        if ('device_overview' !== this._stepper.previousStep) {
            resetData = false;
        }

        /**
         * Remove dedicated Flowdata (Accessories, Optional Services, HandyInsurance, noRedplus)
         */
        if (true === resetData) {
            this.getInjector().getFlowState().resetDedicatedFlowData(true);
        }

    }

     /**
      *
      * The Tariffgroup (SalesChannel or SubscriptionGroup) is switched by user
      *
      * @param tariffGroupParam
      *
      */
    protected handleEventTariffGroupNameChanged (tariffGroupParam: TariffGroupInterface): void  {

        const subscriptionId = this.getInjector().getFlowState().getSubscriptionId();

        this._subscriptionSelection.setActiveSubscriptionId(subscriptionId);

        this.fillOfferOfferCollection();

        if (undefined !== this.getInjector().getOfferCollection().getActiveOffer()) {

            this.getInjector().getFlowState().setSubscriptionId(
                this.getInjector().getOfferCollection().getActiveOffer().subscriptionId
            );

        }

        this.tracking('pageviewTracking@changed', true);

        this.getInjector().getEvent().trigger('TariffGroup@changed', tariffGroupParam);

    // @TODO why handleAtomicDeviceIdChanged was in orginal method
        // this.handleAtomicDeviceIdChanged(atomicDeviceId, avoidTracking);

    }

    protected handleEventTariffGroupChanged (tariffGroupParam: TariffGroupInterface) {
        // debugger;
        this.getInjector().getEvent().trigger('TariffGroup@loaded', tariffGroupParam);

        const tariffGroup = this.getInjector().getFlowState().getTariffGroup();

        this.switchTeaser(tariffGroup);
        this.switchHeadlines(tariffGroup);

        if (true === $('#nsf').hasClass('vo-do-not-track')) {
            return;
        }

        $.when(this._subscriptionSelection.animateTileSwitch('fadeOut', 400, [])).done(() => {

            $.when(this._subscriptionSelection.animateTileSwitch('fadeIn', 300, [])).done( () => {

                this.getInjector().getEvent().trigger('offer@changed', {
                    offer: this.getInjector().getOfferCollection().getActiveOffer()
                });

                // @TODO this should only an update an not an render... Especially while in vvl no device specific stuff is in subscription list is maybe can be removed
                this._subscriptionSelection.render(
                    this.getInjector().getOfferCollection().getSubscriptions()
                );

                this._subscriptionSelection._slider.update(); // Bugfix for slider width after tariff group change
                this._subscriptionSelection._slider.updateHeight();
                this._subscriptionSelection._slider.scrollToFocus();

                this.getInjector().getEvent().trigger('TariffGroup@finished');

            });

        });

    }

    /**
     * Get the subscription group switcher for Gigakombi
     *
     * @returns GigakombiSubscriptionGroupSwitcher
     */
    private createGigakombiTariffGroupSwitcher (): ViewEvolvedElementSharedTariffgroupSwitcher {
        return new ViewEvolvedElementGigakombiTariffgroupSwitcher(
            this.getInjector()
        );
    }

    /**
     * sets the Purchasable Device Repo depending on ordertype
     */
    private setGeneralDeviceRepo () {

        const orderType = this.getInjector().getFlowState().getOrderType();

        if (Constants.OrderType_Voice === orderType) {
            this._generalDeviceRepo = this.getReposSupervisor().getPurchasableDeviceRepo();
        } else {
            this._generalDeviceRepo =  this.getReposSupervisor().getPurchasableTabletRepo();
        }

    }

    protected getGeneralDeviceRepo (): ModelEvolvedRepoPurchasableDevice {
        return this._generalDeviceRepo;
    }

    public hasTradeInSelected (): boolean {
        const optionalServiceIds = this.getInjector().getFlowState().optionalServiceIds.elements;

        return 0 <= optionalServiceIds.indexOf(Constants.TradeInDiscount_Id );
    }
}
