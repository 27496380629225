import Injector from 'core/injector';

export class SkeletonService {

    protected _injector: Injector;

    constructor (injector: Injector) {

        this._injector = injector;

    }

    /**
     * remove Skeleton Loading ClassNames
     */
    public removeSkeletonLoadingClassNames () {

        const skeletonStaticElementClass = 'skeleton-static-element';
        const skeletonStaticElements = document.getElementsByClassName(skeletonStaticElementClass);

        document.body.classList.add('after-skeleton-loading');

        while (skeletonStaticElements[0]) {
            skeletonStaticElements[0].classList.remove(skeletonStaticElementClass);
        }
    }
}
