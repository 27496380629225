/**
 * @module SalesFlow/model/type
 */

import OfferService from './offer-service';

import {VluxJsonOfferServiceGroup} from '../vlux-interface';

/**
 * @internal
 */
export default class OfferServiceGroup {

    private _id: string;
    private _label: string;
    private _maxOccurs: number;
    private _minOccurs: number;

    /**
     * discount/service
     */
    private _type: string;

    /**
     * optional/mandatory
     */
    private _selectable: string;

    private _services: OfferService[] = [];

    constructor (data: VluxJsonOfferServiceGroup) {

        this._id = data.id;
        this._label = data.internalLabel;

        this._maxOccurs = parseInt(data.maxOccurs, 10);
        this._minOccurs = parseInt(data.minOccurs, 10);

        this._services = data.service.map((service: any) => {
            /** special treatment for gigakombi
             *
             */
            return new OfferService(service);
        });

    }

    public setType (pType: string) {

        /**
         *
         * wann ist ein Service included?
         * -> minOccurs == _maxOccurs
         * -> kein Price_Element
         * -> hat PriceElement und Price = 0
         *
         * wann ist ein Service optional
         * -> hat PriceElement und Price > 0
         * -> dann muss auch minOccurs und maxOccurs beachtet werden, wenn z.B. aus einer Servicegruppe nur ein Service gewählt werden darf
         *    (gibt es aber derzeit nicht)
         *
         * wann ist ein Service ein Discount?
         * -> erkennt man eigentlich nur daran, das er im discount repo enthalten ist
         */

        if ('discountMobile' === pType || 'discount' === pType) {
            this._type = 'discount';
        } else {
            this._type = 'service';
        }

        if (this._minOccurs === this._maxOccurs) {
            this._selectable = 'included';
        } else {
            this._selectable = 'optional';
        }

    }

    /**
     * Add services that were not in this group in vlux
     * E.g. gigakombi vorteil
     *
     * @param services
     */
    public addService (services: OfferService[]): void {

        if (0 !== services.length) {
            for (const service of services) {
                this._services.push(service);
            }
        }

    }

    // Coming from VLUX red XL tariffs don't have a discount service group, so we have to add the discount from scratch to the offer
    public addServicesFromGigakombiIds (gigakombiIds: number[]) {

        this._label = 'Rabatte';
        this._selectable = 'included';

        const offerServices: OfferService[] = [];

        for (const service of this._services) {

            if (-1 !== gigakombiIds.indexOf(service.id)) {
                offerServices.push(service);
            }
        }

        this._services = offerServices;

    }

    get id (): string {
        return this._id;
    }

    get label (): string {
        return this._label;
    }

    get type (): string {
        return this._type;
    }

    get selectable (): string {
        return this._selectable;
    }

    get services (): OfferService[] {
        return this._services;
    }

    get minOccurs (): number {
        return this._minOccurs;
    }

    get maxOccurs (): number {
        return this._maxOccurs;
    }

    public overwriteServices (services: OfferService[]): void {
        this._services = services;
    }

}
