/**
 * @module SalesFlow/view
 */

import {Renderable} from 'view/renderable';
import {TariffGroupName} from 'core/ids';

export abstract class ViewEvolvedElementSharedSubscriptionList<T> extends Renderable<T> {

    protected _scrollToFocus: boolean;

    public abstract getEvolvedSubscriptionIds (tariffGroup: TariffGroupName): number[];

    public abstract getSubscriptionIds (): number[];

    public setActiveSubscriptionId (subscriptionId: number): void {

    }

    public setScrollToFocus (scrollToFocus: boolean) {
        this._scrollToFocus = scrollToFocus;
    }

}
