/**
 * @module SalesFlow/view
 */

import {ViewEvolvedCtasSharedFlowCtas} from 'view-evolved/ctas/shared/view-evolved--ctas--shared--flow-ctas';
import {ViewEvolvedCtasSharedFlowCta} from 'view-evolved/ctas/shared/view-evolved--ctas--shared--flow-cta';

import ViewOverlay from 'view/view/shared/overlay';

export class ViewEvolvedCtasGigakombiFlowCtas extends ViewEvolvedCtasSharedFlowCtas {

    /**
     * In Bnt the left button is always cross link into prolongation
     */
    protected getLeftButton (): ViewEvolvedCtasSharedFlowCta {

        return undefined;

    }

    /**
     * On device overview this is the right button. Overwrite when diff
     */
    protected getRightButton (): ViewEvolvedCtasSharedFlowCta {

        return new ViewEvolvedCtasSharedFlowCta(
            ['nfs-pricebox-confirm'],
            this.getInjector().getStrings().get('pricebox.confirm.text1') + '<span class="part-one">' + this.getInjector().getStrings().get('pricebox.confirm.text2') + '</span>'

        );

    }

    protected getUnityCaseOverlay (detailUrl: string): JQueryPromise<String> {

        const deferred = $.Deferred<String>();

        $.get(detailUrl)

            .done(function (data) {

                deferred.resolve(data);

            })
            .fail(function () {

                deferred.reject();

            });

        return deferred.promise();

    }

    /**
     * show Unitymedia Case confirmation-overlay before basket
     */
    protected showUnityCaseOverlay (): void {

        this.getUnityCaseOverlay('/privat/unitymedia/748181.html')
        .done((data: any) => {

            const viewOverlay = new ViewOverlay('', '', data);

            this.getInjector().getOverlay().open(viewOverlay);

            const that = this;

            document.getElementById('proceedToBasket').addEventListener('click', function () {
                that.createBasketAndProceed();
            });

        });
    }

}
