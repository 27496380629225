/**
 * @module SalesFlow/view
 */

import AlertButton from './alert-button';

export default class ViewAlert {

    protected _alertType: string;

    protected _alertColor: string;

    protected _accessText: string;

    protected _headline: string;

    protected _alertMessage: string;

    protected _alertButton: AlertButton[] = [];

    protected _HTMLId: string;

    protected _additionalClass: string;

    constructor (alertMessage: string, headline: string, alertButton?: AlertButton[], HTMLId?: string, additionalClass?: string) {

        this._alertMessage = alertMessage;

        this._headline = headline;

        this._HTMLId = HTMLId;

        this._additionalClass = additionalClass;

        if (undefined !== alertButton) {
            this._alertButton = alertButton;
        }

    }

    get alertType (): string {
        return this._alertType;
    }

    get alertColor (): string {
        return this._alertColor;
    }

    get accessText (): string {
        return this._accessText;
    }

    get headline (): string {
        return this._headline;
    }

    get alertMessage (): string {
        return this._alertMessage;
    }

    get alertButton (): AlertButton[] {
        return this._alertButton;
    }

    get HTMLId (): string {
        return this._HTMLId;
    }

    get additionalClass (): string {
        return this._additionalClass;
    }
}
