/**
 * @module SalesFlow/view
 */

declare var $: JQueryStatic;
declare var vf: any;

import Injector from 'core/injector';

import {Renderable} from '../../renderable';

import ViewTechnicalDetails from '../../view/shared/technical-details';
import DeviceOffer from '../../view/shared/offer/device-offer';

import AttributeRepo from 'model/repo/attribute-repo';

export default class TechnicalDetails extends Renderable<DeviceOffer> {

    private _element: JQuery;

    private _attributeRepo: AttributeRepo;

    constructor (injector: Injector) {

        super(injector);

        this._element = $('#nsf-technical-details-list');

    }

    /**
     * @TODO That's ugly, but it's async and can not be set constructor
     */
    public setAttributeRepo (attributeRepo: AttributeRepo): void {

        this._attributeRepo = attributeRepo;

    }

    public getViewTechnicalDetails (deviceOffer: DeviceOffer): ViewTechnicalDetails {

        const attributeGroups = this._attributeRepo.getAttributeGroups();

        const technicalDetails = new ViewTechnicalDetails(attributeGroups, deviceOffer.atomicDevice);

        return technicalDetails;

    }

    public render (deviceOffer: DeviceOffer): void {

        const frontendTechnicalDetails = this.getViewTechnicalDetails(deviceOffer);

        this._element.html(this.getInjector().getTemplates().render('technical_details', frontendTechnicalDetails));

        if (undefined !== vf['responsive-table']) {
            vf['responsive-table'].init();
        }

    }

    public events (): void {

        this.getInjector().getEvent().listen('offer@changed', (eventObject: JQueryEventObject, data: any) => {

            const deviceOffer: DeviceOffer = data.offer;

            this.render(deviceOffer);

        });

    }

    public bind (deviceOffer: DeviceOffer): void {
        this.render(deviceOffer);
        this.events();
    }

}
