/**
 * Story: CO-2762
 * A notification is shown to an inlife user every time he reaches the recommendation page.
 * The notification is based on the contract period type. N = Inlife, R = Inlife 24
 * INL: Informs the user that the contract time stays the same.
 * INL24: Informs the user that the contract time will be prolonged by 24 months.
 */

import {
    NotificationBox,
    NotificationType,
    NotificationDataForHBS
} from '../notification-box';

import Injector from 'core/injector';

type ContractPeriodType = 'N' | 'R';

export class NotificationContractPeriodType extends NotificationBox {

    protected _notificationType: NotificationType;
    protected _dataForNotificationHBS: NotificationDataForHBS;
    protected _notificationContent: string;
    protected _CSSSelectorAsAnchorInDOM: string;

    protected _additionalCSSClassForStyling: string;
    protected _notificationHeadline: string;
    protected _contractPeriodType: ContractPeriodType;
    protected _selector: string;
    protected _isSohoCustomer: boolean;

    constructor (
        injector: Injector,
        contractPeriodType: ContractPeriodType,
        isSohoCustomer: boolean,
        selector: string
        ) {
        super(
            injector
        );

        this._contractPeriodType = contractPeriodType;
        this._selector = selector;
        this._isSohoCustomer = isSohoCustomer;

        this.setNotificationOptions();
        this.setNotificationText();
        this.setNotificationDataForHBS();
    }

    protected setNotificationOptions () {
        this._CSSSelectorAsAnchorInDOM = this._selector;
        this._notificationType = 'warning';
        this._additionalCSSClassForStyling = 'notification-contract-period-type';
    }

    protected setNotificationText () {

        if ('N' === this._contractPeriodType) {
            this._notificationHeadline = this.getInjector().getStrings().get('inlife24-notification.headline');
            this._notificationContent = this.getInjector().getStrings().get('inlife24-notification.content');
            if (this._isSohoCustomer) {
                this._notificationContent = this.getInjector().getStrings().get('inlife24-notification.soho.content');
            }
            this._toggleViewIdentifier = 'notification-warning-inlife24';
        }
        else if ('R' === this._contractPeriodType) {
            this._notificationHeadline = this.getInjector().getStrings().get('inlife-notification.headline');
            this._notificationContent = this.getInjector().getStrings().get('inlife-notification.content');
            if (this._isSohoCustomer) {
                this._notificationContent = this.getInjector().getStrings().get('inlife-notification.soho.content');
            }
            this._toggleViewIdentifier = 'notification-warning-inlife';
        }
    }

    protected events (): void {

        $('.scroll-to-faqs-link').on('click', (evt) => {

            evt.preventDefault();

            const navHeight: number = $('.main-nav').height();
            const firstFAQSelector: JQuery = $('.mod-footnotes');
            const firstFAQCoordinates = firstFAQSelector.first().offset();

            if (undefined !== firstFAQCoordinates) {
                $('html, body').animate({

                    scrollTop: firstFAQCoordinates.top - navHeight - 10

                }, 800);
            }
        });
    }
}
