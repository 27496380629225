/**
 * @module SalesFlow/view
 */

declare var $: JQueryStatic;

import Event from 'core/event';

export default class LoadingIndicator {

    private _event: Event;

    private _element: JQuery;

    constructor (event: Event) {

        this._event = event;

        this._element = $('#nsf-loading-indicator');
    }

    public show (): void {

        this._element.show();

    }

    public hide (): void {

        this._element.fadeOut(500, () => {
            $('#nsf-loading-indicator').hide();
            this._event.trigger('loadingIndicator@hide');
        });

    }

    public remove (): void {

        this._element.fadeOut(500, () => {
            $('#nsf-loading-indicator').remove();
        });

    }

}
