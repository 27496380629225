/**
 * @module SalesFlow/evolved/view
 */

import Injector from 'core/injector';
import SimOnlyOffer from 'view/view/shared/offer/sim-only-offer';
import {Renderable} from 'view/renderable';
import SubscriptionDetailOverlay from 'view/element/bnt/subscription-detail-overlay';

declare var $: JQueryStatic;
declare var vf: any;

export class ViewEvolvedElementBaseClassSubscriptionDetail extends Renderable<SimOnlyOffer[]> {

    private _element: JQuery;

    private _accordionToggle: JQuery;

    private _subscriptionDetailOverlay: SubscriptionDetailOverlay;

    constructor (element: JQuery, injector: Injector) {

        super(injector);

        this._element = element;

        this._accordionToggle = this._element.find('.vvl-subscription-tile-accordion .foot');

        this._subscriptionDetailOverlay = new SubscriptionDetailOverlay(injector);

    }

    public render (offers: SimOnlyOffer[]): void {
        /**
         * intentionally left blank
         */
    }

    public events (offers: SimOnlyOffer[]): void {

        this._element.on('click', '.vll-accordion-toggle, .redplus-accordion-toggle', (evt: JQueryEventObject) => {
            const currentElement: JQuery<Element> = $(evt.currentTarget);

            currentElement
                .find('.more')
                .toggleClass('is-hidden');

            currentElement
                .find('.less')
                .toggleClass('is-hidden');

            currentElement
                .parent()
                .find('.vll-list-container')
                .toggleClass('vll-accordion-is-open');
        });

        this._element.on('click', '.open-overlay', (evt: JQueryEventObject) => {
            // check if already open. Because in some cases its opened twice
            const overlay = document.getElementById('nsf-vf-pass-overlay');
            let openOverlay = true;
            // exists the overlay, should
            if ( overlay ) {
                const style = overlay.getAttribute('style');
                // has a style attribute
                if ( style.length > 0 ) {
                    // is display block?
                    if ( 0 <= style.indexOf('display: block;') ) {
                        openOverlay = false;
                    }
                }
            }
            if ( openOverlay ) {

                vf.overlay.openOverlayDirect('nsf-vf-pass-overlay');
            }
        });

    }

    public bind (offers: SimOnlyOffer[]): void {
        this.events(offers);
        this._subscriptionDetailOverlay.bind();
    }

}
