/**
 * @module SalesFlow/view
 */

declare var $: JQueryStatic;

import Injector from 'core/injector';

import {Renderable} from 'view/renderable';

import SimoOnlyOffer from 'view/view/shared/offer/sim-only-offer';

import Offer from 'model/type/offer';

import {ModelEvolvedRepoSupervisor} from 'model-evolved/repo/model-evolved--repo--supervisor';

import ViewOverlay from '../../../view/view/shared/overlay';
import { Constants } from 'core/constants';

export class ViewEvolvedElementBntCmRedPlusOhneHardware extends Renderable<SimoOnlyOffer> {

    private _element: JQuery;
    private _selected: boolean;
    private _type: string;
    private _offer: Offer;

    constructor (injector: Injector, offer: Offer, type: string, offerRepo: ModelEvolvedRepoSupervisor) {
        super(injector);

        this._element = $('.cm-redplus[data-redplus="' + type + '"]');
        this._offer = offer;
        this._type = type;
        this._selected = false;

    }

    public render (offer: SimoOnlyOffer): void {

        /**
         * Remove the element if it is not bookable with the selected tariff
         */
        if (this._element.data('not-bookable-with')) {

            const nonBookableTariffs = ('' + this._element.data('not-bookable-with')).split(',').map(Number);

            if (0 <= nonBookableTariffs.indexOf(this.getInjector().getFlow().getSubscriptionId())) {

                this._element.remove();

                return;

            }

        }

        if (Constants.RedPlus_Kids === this._type) {

            /**
             * Check if Red+ Data is already selected
             */
            if (0 < this.getInjector().getFlow().redPlusKids.elements.length) {

                this._selected = true;
                this._element.find('.checkBox').addClass('selected');

            }

        }

        if (2 === $('.cm-redplus .checkBox.selected').length) {
            $('.cm-redplus .checkBox:not(.selected)').addClass('disabled');
        }

        if (this._offer.subcriptionPriceMonthly) {

            this._element.find('.price').html(this._offer.subcriptionPriceMonthly.value.toFixed(2).replace('.', ',') + ' &euro;');

        }

        /**
         * Show element - important to do it before the device row module is rendered
         */
        this._element.show();

    }

    public events (): void {

        this._element.on('click', '.checkBox', (evt) => {
            $('.cm-redplus .checkBox.disabled').removeClass('disabled');
            /**
             * not more than two options are allowed
             * or in case user wants to deselect
             */
            if (2 > $('.cm-redplus .checkBox.selected').length || this._selected) {

                this._selected = !this._selected;
                this.getInjector().getFlow().redPlusKids.resetAllElements();

                if (this._selected) {

                    this.getInjector().getFlow().redPlusKids.addElement(this._offer.offerId);

                }

                $(evt.currentTarget).toggleClass('selected');

                /**
                 * Trigger that the optional service has changed. Will be handled in connect-more-controller.ts
                 */
                this._injector.getEvent().trigger('redPlusOffer@changed');
                this._injector.getEvent().trigger('redPlusTracking@changed', {
                    redPlusOption: this._offer,
                    redPlusSelected: this._selected,
                    redPlusName: this._type
                });
            }
            if (2 === $('.cm-redplus .checkBox.selected').length) {
                $('.cm-redplus .checkBox:not(.selected)').addClass('disabled');
            }
        });

        $('.cm-redplus').on('click', '.redplus', (evt: JQueryEventObject) => {
            evt.preventDefault();
            const viewOverlay = new ViewOverlay('', '', $('#nsf-red-plus-overlay-content').html());
            this.getInjector().getOverlay().open(viewOverlay);
            // hide checkBoxes
            $('#nsf-overlay .overlay-content').find('.addcard-box').hide();
        });

    }

}
