/**
 * @module SalesFlow/model/repo
 */

import {Repo} from './repo';

import SimCard from '../type/simcard';
import NsfError from 'core/error';

export default class SimCardRepo extends Repo {

    private _simCards: SimCard[];

    constructor (json: any) {
        super();
        this.rawData = json;
        this.parseData(json);
    }

    /**
     * @TODO create an interface for products json
     */
    protected parseData (json: any): NsfError {
        if (1 !== json.productGroups.productGroup.length) {
            return new NsfError('one productGroup is expected');
        }

        const productGroup = json.productGroups.productGroup[0];

        const simCards: any = productGroup.products.product;

        this._simCards = simCards.map((simCard: any) => {
            return new SimCard(simCard);
        });

        return undefined;
    }

    public getSimCards (): SimCard[] {
        return this._simCards;
    }

    public getSimCard (id: number): SimCard {
        const simCards: SimCard[] = this._simCards.filter((simCard: SimCard) => {
            return (id === simCard.id);
        });
        if (0 === simCards.length) {
            return undefined;
        }

        return simCards[0];
    }

}
