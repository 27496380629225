/**
 * @module SalesFlow/evolved/router
 */

import {RouterEvolvedVvlXSell} from 'router-evolved/vvl/router-evolved--vvl--x-sell';
import {ControllerEvolvedFlowBase} from 'controller-evolved/controller-evolved--flow-base';
import {ControllerEvolvedVvlXSell} from 'controller-evolved/vvl/controller-evolved--vvl--x-sell';
import {Constants} from 'core/constants';
import GeneralSalesObjectInterface from 'service/general-sales-object/general-sales-object-interface';
import {SalesChannelName, BusinessTransactionContext} from 'core/ids';

export class RouterEvolvedSohoVvlXSell extends RouterEvolvedVvlXSell {

    /**
     * For soho sales channel is always soho
     */
    protected setSalesChannel (): void {

        this._salesChannel = Constants.SALESCHANNEL_SOHO;

    }

    public loadReposSupervisor (): JQueryPromise<any> {

        const deferred = $.Deferred<any>();

        this.getReposSupervisor().initialLoadingDone().done( () => {

            const salesChannel: SalesChannelName = this.getSalesChannel();
            const btx: BusinessTransactionContext = this.getInjector().getBtx();
            const generalSalesObjectInterface: GeneralSalesObjectInterface = this.getInjector().getGeneralSalesObjectInterface();

            this.getReposSupervisor().loadPurchasableDeviceRepo(btx, salesChannel, generalSalesObjectInterface);
            this.getReposSupervisor().loadDependencyRepo();

            this.getReposSupervisor().getChainedPromise().done(() => {

                /**
                 * Send raw VLUX Data to General Offer Service node module so it doesn't has to be loaded twice
                 */
                this.prepareGeneralSalesObject();

                deferred.resolve();

            });

        });

        return deferred.promise();

    }

    public createController (): JQueryPromise<ControllerEvolvedFlowBase> {
        const deferred = $.Deferred<any>();

        this.loadReposSupervisor().then(() => {

            this.validateIncoming();

            deferred.resolve(
                new ControllerEvolvedVvlXSell(
                    this.getSalesChannel(),
                    this._atomicDeviceId,
                    this.getSubscriptionId(),
                    this.getReposSupervisor(),
                    this.getInjector()
                )
            );
        }, function () {
            deferred.reject();
        });

        return deferred.promise();
    }

}
