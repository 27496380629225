/**
 * @module SalesFlow/evolved/controller
 */

declare var _ddq: any;

import { Constants } from 'core/constants';
import {BusinessTransactionContext, SubscriptionIdPerSalesChannel, TariffGroupName} from 'core/ids';

import Injector from 'core/injector';

import DeviceList from 'view/element/bnt/device-list';
import Pricebox from 'view/element/shared/pricebox';

import Filters from 'view/element/shared/filter/filters';

import Subscription from 'model/type/subscription';
import AtomicDevice from 'model/type/atomic-device';

import Offer from 'view/view/shared/offer/offer';
import HardwareOffer from 'view/view/shared/offer/hardwareonly-offer';
import DeviceOffer from 'view/view/shared/offer/device-offer';
import SimOnlyOffer from 'view/view/shared/offer/sim-only-offer';

import {DeviceOverviewPageState} from 'controller/shared/device-overview-controller';
import {ControllerEvolvedBaseClass} from 'controller-evolved/base-class/controller-evolved--base-class';
import {ModelEvolvedRepoSupervisor} from 'model-evolved/repo/model-evolved--repo--supervisor';
import Customer from 'shopbackend/customer';

import {TariffGroupInterface, ViewEvolvedElementSharedTariffgroupSwitcher} from 'view-evolved/element/shared/view-evolved--element-shared--tariffgroup-switcher';
import GeneralSalesObjectInterface from 'service/general-sales-object/general-sales-object-interface';
import { ModelEvolvedRepoPurchasableDevice } from 'model-evolved/repo/model-evolved--repo--purchasable-device';
import GigakombiDeviceDetailService from 'service/gigakombi/gigakombi-device-detail-service';

declare var $: JQueryStatic;

/**
 * @internal
 * I come from previous step:
 *      => Tariff is locked
 */
export abstract class ControllerEvolvedBaseClassDeviceOverview extends ControllerEvolvedBaseClass {

    protected _pageState: DeviceOverviewPageState;

    protected _pricebox: Pricebox;

    protected _customer: Customer;

    protected _filters: Filters;

    /** @TODO Should not be any ;) */
    protected _deviceList: any;

    protected _subscription: Subscription;

    protected _gigakombiDeviceDetailService: GigakombiDeviceDetailService;

    protected _originalAtomicDeviceId: number = undefined;

    constructor (
        focusSubscriptionIdArray: SubscriptionIdPerSalesChannel,
        customer: Customer,
        reposSupervisor: ModelEvolvedRepoSupervisor,
        injector: Injector
    ) {

        super(
            focusSubscriptionIdArray,
            reposSupervisor,
            injector
        );

        this._customer = customer;

        const atomicDeviceId = this.getInjector().getFlowState().getAtomicDeviceId();

        if (true === this.getInjector().getOptions().get('debug')) {
            const cnsl = console;
            cnsl.log('RealProductOverviewPage....');
        }

        const subscriptionId = this.getInjector().getFlowState().getSubscriptionId();

        const isHardwareOnly = this.getInjector().getFlowState().getHardwareOnly();

        this._pageState = new DeviceOverviewPageState(subscriptionId, atomicDeviceId, isHardwareOnly);

        this._pricebox = new Pricebox(injector);

        this._filters = new Filters(injector);

        this._deviceList = new DeviceList(injector);

        this._gigakombiDeviceDetailService = new GigakombiDeviceDetailService(injector);

    }

    protected getCustomer (): Customer {
        return this._customer;
    }

    protected hasCustomer (): boolean {
        return (undefined !== this._customer);
    }

    /**
     * Setting tracking on page load. If a tariff and a device has been selected,
     * we send 'product detail' as pageType, else we send 'product listing' as pageType.
     */
    protected tracking (eventType: string, overwriteRule?: boolean) {

        if (false === this._pageState.canBePurchased()) {
            this.getInjector().getEvent().trigger(eventType,
                {
                    overwriteRule: overwriteRule,
                    subscription: this._subscription,
                    pageName: this.getPageName(),
                    pageType: 'product listing',
                    deviceOffer: this.getActiveOffer()
                }
            );
        } else {
            this.getInjector().getEvent().trigger(eventType,
                {
                    overwriteRule: overwriteRule,
                    subscription: this._subscription,
                    pageName: this.getPageName(),
                    pageType: 'product detail',
                    deviceOffer: this.getActiveOffer()
                }
            );
        }

    }

    protected render (): void {

        this._subscriptionSelection.update(
            this.getInjector().getOfferCollection().getSubscriptions()
        );

    }

    /**
     * Tracking different PageNames
     */
    public getPageName (): string {
        return 'handys';
    }

    public setSubscriptionName (subscriptionName: string): void {
        $('#nsf-tariff-name').html(subscriptionName);
    }

    protected getFallbackSubscription (): Subscription {
        const salesChannel = this.getInjector().getFlowState().getSalesChannel();
        const subscriptionId = this.getInjector().getOptions().get(`default_${ salesChannel }_subscription_id`);

        this.getInjector().getFlowState().setSubscriptionId(subscriptionId);

        return this.getReposSupervisor().getSubscriptionRepo().getSubscription(subscriptionId);

    }

    /**
     * Did the user opts in a RedPlus Option
     * @TODO THis should not be any[]
     */
    protected getRedPlusFromFlow (): any[] {

        const offersRedPlus: any[] = [];

        const subscriptionId = this.getInjector().getFlowState().getSubscriptionId();
        const salesChannel = this.getInjector().getFlowState().getSalesChannel();
         const allRedPlusOptions = [
            ...this.getInjector().getFlowState().redPlusAllnet.elements,
            ...this.getInjector().getFlowState().redPlusData.elements,
            ...this.getInjector().getFlowState().redPlusKids.elements,
            ...this.getInjector().getFlowState().redPlusBasic.elements
        ];

        for (const offerId of allRedPlusOptions) {

            const redPlusOffer = this._generalSalesObjectInterface.getRedPlusOfferById(
                offerId,
                salesChannel);
            const redPlusSubscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(redPlusOffer.subscriptionId);

            if ('HmK' === redPlusOffer.offerType) {
                const redPlusAtomicDevice = this.getGeneralDeviceRepo().getAtomicDevice(redPlusOffer.deviceId, salesChannel, this._subscription);
                offersRedPlus.push(new DeviceOffer(redPlusAtomicDevice, redPlusSubscription, redPlusOffer));
            } else {
                offersRedPlus.push(new SimOnlyOffer(redPlusSubscription, redPlusOffer));
            }

        }

        return offersRedPlus;

    }

    /**
     * This function gets the simonly-offer to the active subscriptionId that is stored in flow
     */
    protected getActiveSimOnlyOffer (): SimOnlyOffer {

        const subscriptionId = this.getInjector().getFlowState().getSubscriptionId();
        let subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(subscriptionId);
        const salesChannel = this.getInjector().getFlowState().getSalesChannel();

        let simOnlyOffer;
        let optionalServiceIds = this.getInjector().getFlowState().optionalServiceIds.elements;

        try {

            if (Constants.BTX_GIGAKOMBI === this._btx) {
                optionalServiceIds = this._gigakombiDeviceDetailService.removeVFPassfromGKUnlimitedOffer(optionalServiceIds, subscription);
            }

            simOnlyOffer = new SimOnlyOffer(
                subscription,
                this._generalSalesObjectInterface.getSimOnlyOfferBySubscriptionId(subscriptionId, this._btx, salesChannel),
                this.getRedPlusFromFlow(),
                optionalServiceIds
            );
        } catch (e) {
            subscription = this.getFallbackSubscription();

            if (Constants.BTX_GIGAKOMBI === this._btx) {
                optionalServiceIds = this._gigakombiDeviceDetailService.removeVFPassfromGKUnlimitedOffer(optionalServiceIds, subscription);
            }

            simOnlyOffer = new SimOnlyOffer(
                subscription,
                this._generalSalesObjectInterface.getSimOnlyOfferBySubscriptionId(subscriptionId, this._btx, salesChannel),
                this.getRedPlusFromFlow(),
                optionalServiceIds
            );

        }

        return simOnlyOffer;

    }

    /**
     * This function gets the device-offer to the active subscriptionId and atomicDeviceId
     * that is stored in flow
     */
    protected getActiveDeviceOffer (): DeviceOffer {

        const atomicDeviceId = this.getInjector().getFlowState().getAtomicDeviceId();
        const salesChannel = this.getInjector().getFlowState().getSalesChannel();

        const atomicDevice = this.getGeneralDeviceRepo().getAtomicDevice(
            atomicDeviceId,
            salesChannel,
            this._subscription
        );

        // gets the selected device with configuration, not only the atomic
        const vluxOffer = this._generalSalesObjectInterface.getSimHardwareOfferByAtomicDeviceIdAndSubscriptionId (
            atomicDevice.id,
            this._subscription.id,
            this._btx,
            salesChannel
        );

        // device caroussel price handling
        if ($('.device-module-tile.selected').length > 0) {

            // If device selection changes, we need to show the original price in the device caroussel
            if (undefined !== this._originalAtomicDeviceId) {

                const offer = this._generalSalesObjectInterface.getSimHardwareOfferByAtomicDeviceIdAndSubscriptionId (
                    this._originalAtomicDeviceId,
                    this._subscription.id,
                    this._btx,
                    salesChannel
                );

                $('.device-module-tile[data-atomic-device-id="' + this._originalAtomicDeviceId + '"').find('.price')[0].innerText = (offer.devicePriceOnetime.value.toFixed(2) + ' €').replace('.', ',');
                this._originalAtomicDeviceId = undefined;
            }

            /**
             * checks, if the device in the session is diffent than the atomic device
             * sets the correct price from the preselected device configuration in the device caroussel
             *
             */
            if (atomicDeviceId !== $('.device-module-tile.selected').data('atomic-device-id')) {
                this._originalAtomicDeviceId = $('.device-module-tile.selected').data('atomic-device-id');
                $('.device-module-tile.selected').find('.price')[0].innerText = (vluxOffer.devicePriceOnetime.value.toFixed(2) + ' €').replace('.', ',');
            }

        }

        let optionalServiceIds = this.getInjector().getFlowState().optionalServiceIds.elements;

        if (Constants.BTX_GIGAKOMBI === this._btx) {
            optionalServiceIds = this._gigakombiDeviceDetailService.removeVFPassfromGKUnlimitedOffer(optionalServiceIds, this._subscription);
        }

        return new DeviceOffer(atomicDevice.getDevice().getAtomicDeviceById(vluxOffer.deviceId), this._subscription, vluxOffer, [], optionalServiceIds, []);

    }

    protected getActiveHardwareOnlyOffer (): DeviceOffer {

        let activeOffer: DeviceOffer = undefined;
        const atomicDeviceId = this.getInjector().getFlowState().getAtomicDeviceId();

        for (const deviceOffer of this.getInjector().getOfferCollection().getDevices()) {

            for (const atomicDevice of deviceOffer.atomicDevice.device.getAtomicDevices()) {

                if (atomicDeviceId === atomicDevice.id) {

                    activeOffer = deviceOffer;
                    if (activeOffer.isDevice()) {
                        activeOffer.setIsDeviceSelected(true);
                    }
                    break;
                }
                else {
                    deviceOffer.setIsDeviceSelected(false);
                }
            }

        }

        return activeOffer;

    }

    /**
     * Get all HardwareOffers, if hardwareOnly is stored in the flow
     */
    protected getHardwareOnlyOffers (): HardwareOffer[] {

        const salesChannel = this.getInjector().getFlowState().getSalesChannel();
        const devices = this.getGeneralDeviceRepo().getDevices(salesChannel);

        return devices.map(device => {

            const offer = this._generalSalesObjectInterface.getHardwareOnlyOfferByAtomicId(
                device.getAtomicDeviceByIndex(0).id,
                salesChannel
            );

            return new HardwareOffer(device.getAtomicDeviceById(offer.deviceId), offer);

        });

    }

    /**
     * This function gets the offer to the active subscriptionId that is stored in flow
     */
    protected getActiveOffer (): Offer {

        if (false === this._pageState.canBePurchased()) {

            return undefined;

        }

        const subscriptionId = this.getInjector().getFlowState().getSubscriptionId();
        const atomicDeviceId = this.getInjector().getFlowState().getAtomicDeviceId();

        // SimOnly
        if (undefined === atomicDeviceId && undefined !== subscriptionId) {
            return this.getActiveSimOnlyOffer();
        }

        // HardwareOnly
        if (undefined !== atomicDeviceId && undefined === subscriptionId) {
            return this.getActiveHardwareOnlyOffer();
        }

        // if an atomicDeviceId is set in flow: Try to find that, select it and overwrite default
        return this.getActiveDeviceOffer();

    }

    /**
     * This function gets all subscriptionIds for the selected tariff-group from HTML markup.
     * Then the subcriptions will be loaded and after that the simonly offers matching to the
     * subscriptionIds found in the beginning
     */
    protected getSimOnlyOffersMatchingToSlider (): SimOnlyOffer[] {

        const simOnlyOffers: SimOnlyOffer[] = [];

        const salesChannel = this.getInjector().getFlowState().getSalesChannel();
        const tariffGroup: TariffGroupName = this.getInjector().getFlowState().getTariffGroup();

        const subscriptionIds = this._subscriptionSelection.getEvolvedSubscriptionIds(tariffGroup);

        for (const subscriptionId of subscriptionIds) {

            const subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(subscriptionId);

            let offer = this._generalSalesObjectInterface.getSimOnlyOfferBySubscriptionId(subscriptionId, this._btx, salesChannel);

            if (undefined === offer) {
                /**
                 * sometimes, vlux forgets to configure simOnly Offer. So this is a kind of workaround to fix this
                 * problem
                 */
                offer = this._generalSalesObjectInterface.getFirstAvailableOfferBySubscriptionId(subscriptionId, this._btx, salesChannel);
            }

            simOnlyOffers.push(new SimOnlyOffer(subscription, offer));

        }

        return simOnlyOffers;
    }

    /**
     * This function gets all subscriptionIds for the selected tariff-group from HTML markup.
     * Then the subcriptions will be loaded and after that the device offers matching to the
     * subscriptionIds found in the beginning
     */
    protected getDeviceOffersMatchingToSlider (): DeviceOffer[] {

        const offers: DeviceOffer[] = [];

        const salesChannel = this.getInjector().getFlowState().getSalesChannel();
        const tariffGroup: TariffGroupName = this.getInjector().getFlowState().getTariffGroup();

        const subscriptionIds = this._subscriptionSelection.getEvolvedSubscriptionIds(tariffGroup);

        for (const subscriptionId of subscriptionIds) {

            const subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(subscriptionId);
            const atomicDeviceId = this.getInjector().getFlowState().getAtomicDeviceId();

            const atomicDevice = this.getGeneralDeviceRepo().getAtomicDevice(
                atomicDeviceId,
                salesChannel,
                subscription
            );

            if (undefined === atomicDevice) {
                $('.tariff-module-tile[data-subscription-id="' + subscription.id + '"][data-tariffgroup-id="' + tariffGroup + '"]').hide().addClass('hide');
                continue;
            } else {
                $('.tariff-module-tile[data-subscription-id="' + subscription.id + '"][data-tariffgroup-id="' + tariffGroup + '"]').show().removeClass('hide');
            }

            const offer = this._generalSalesObjectInterface.getSimHardwareOfferByAtomicDeviceIdAndSubscriptionId (
                atomicDevice.id,
                subscription.id,
                this._btx,
                salesChannel
            );

            /** not every device is offered for each tariff */
            if (undefined !== offer) {
                // @TODO Fetch redplus from activeOffer, too
                offers.push(
                    new DeviceOffer(
                        atomicDevice,
                        subscription,
                        offer
                    )
                );
            }

        }

        return offers;
    }

    protected getOffersMatchingToSlider (): Offer[] {

        const atomicDeviceId = this.getInjector().getFlowState().getAtomicDeviceId();

        /**
         * if no atomic is selected, the simonly offers will be loaded
         */
        if (undefined === atomicDeviceId) {
            return this.getSimOnlyOffersMatchingToSlider();
        }

        /**
         * if an atomic is selected, the device offers will be loaded
         */
        return this.getDeviceOffersMatchingToSlider();
    }

    /**
     * This function returns all deviceOffers when no subscription is selected.
     * In this case, the deviceOffer with the lowest price is returned
     */
    protected getDeviceOffersWithoutActiveSubscription (): DeviceOffer[] {

        const salesChannel = this.getInjector().getFlowState().getSalesChannel();

        /** as we don't have a subscription at this point, I presume
         *  we're looking for hardware only purchasable devices
         */
        const devices = this.getGeneralDeviceRepo().getDevices(salesChannel);

        const subscriptionGroupId = this.getReposSupervisor().getSubscriptionRepo().getSubscriptionGroupIds(
            this.getInjector().getFlowState().getSubscriptionGroup()
        );

        /**
         * get all subscriptions except YoungXS (removed with Portfolio 2022), RedL and RedXL
         */
        const notAllowedSubs = [Constants.RedL_Id, Constants.RedXL_Id];
        let subscriptions = this.getReposSupervisor().getSubscriptionRepo().getSubscriptions(subscriptionGroupId);
        subscriptions = subscriptions.filter (subscription => {
            if (notAllowedSubs.includes(subscription.id)) {
                return false;
            } else {
                return true;
            }
        });

        return devices.map(device => {

            const atomicDevice: AtomicDevice = device.getAtomicDeviceByIndex(0);

            /**
             * No tariff is locked, so I look for the tariff where the device
             * has the lowest onetime price.
             */
            const offer = this._generalSalesObjectInterface.getLowestDevicePriceOfferForAtomicId (
                atomicDevice.id,
                subscriptions,
                this._btx,
                salesChannel);

            if (undefined === offer) {
                return;
            }

            const subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(offer.subscriptionId);

            let optionalServiceIds = this.getInjector().getFlowState().optionalServiceIds.elements;

            if (Constants.BTX_GIGAKOMBI === this._btx) {
                optionalServiceIds = this._gigakombiDeviceDetailService.removeVFPassfromGKUnlimitedOffer(optionalServiceIds, subscription);
            }

            return new DeviceOffer(atomicDevice, subscription, offer, this.getRedPlusFromFlow(), optionalServiceIds);

        });

    }

    /**
     * This function returns all matching deviceOffers to the currently
     * selected subscription
     */

    protected getDeviceOffersWithActiveSubscription (): DeviceOffer[] {

        const subscriptionId = this.getInjector().getFlowState().getSubscriptionId();
        const salesChannel = this.getInjector().getFlowState().getSalesChannel();

        this._subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(subscriptionId);

        let devices = this.getGeneralDeviceRepo().getDevices(salesChannel, this._subscription);
        if (0 === devices.length) {
            this._subscription = this.getFallbackSubscription();
            devices = this.getGeneralDeviceRepo().getDevices(salesChannel, this._subscription);
        }

        const deviceOffers = devices.map(device => {

            /**
             * Subscription is locked, so I use the first atomic device of the virtual device
             */
            const offer = this._generalSalesObjectInterface.getSimHardwareOfferByAtomicDeviceIdAndSubscriptionId (
                device.getAtomicDeviceByIndex(0).id,
                this._subscription.id,
                this._btx,
                salesChannel
            );

            let optionalServiceIds = this.getInjector().getFlowState().optionalServiceIds.elements;
            if (Constants.BTX_GIGAKOMBI === this._btx) {
                optionalServiceIds = this._gigakombiDeviceDetailService.removeVFPassfromGKUnlimitedOffer(optionalServiceIds, this._subscription);
            }

            return new DeviceOffer(
                device.getAtomicDeviceById(offer.deviceId),
                this._subscription, offer,
                this.getRedPlusFromFlow(),
                optionalServiceIds
            );

        });

        return deviceOffers;
    }

    protected getDeviceOffers (): DeviceOffer[] {

        const subscriptionId = this.getInjector().getFlowState().getSubscriptionId();

        if (undefined === subscriptionId) {
            if (true === this.getInjector().getFlowState().getHardwareOnly()) {
                return this.getHardwareOnlyOffers();
            } else {
                return this.getDeviceOffersWithoutActiveSubscription();
            }
        }

        return this.getDeviceOffersWithActiveSubscription();
    }

    protected fillOfferOfferCollection (): void {

        this.getInjector().getOfferCollection().setDevices(
            this.getDeviceOffers()
        );

        this.getInjector().getOfferCollection().setActiveOffer(
            this.getActiveOffer()
        );

        this.getInjector().getOfferCollection().setSubscriptions(
            this.getOffersMatchingToSlider()
        );

        this.getInjector().getOfferCollection().log();

    }

    protected switchTeaser (tariffGroupName: string): void {
    }

    protected switchHeadlines (tariffGroupName: string): void {
    }

    protected events (): void {

        /**
         * When switching between subscriptionGroups no subscription should be selected,
         * even if a subsciption was selected in previous group
         * In this case we need to reset subscription and offer
         */
        this.getInjector().getEvent().listen('subscription@no-active', (eventObject: JQueryEventObject, groupName: string) => {
        });

        this.getInjector().getEvent().listen('TariffGroup@changed', (eventObject: JQueryEventObject, tariffGroupParam: TariffGroupInterface) => {

            const subscriptionId = this.getInjector().getFlowState().getSubscriptionId();

            if (undefined === subscriptionId) {

                this._pricebox.hide();
                this.getInjector().getEvent().trigger('offer@none');

            }
        });

        this.getInjector().getEvent().listen('vfPass@changed-trackSelection', (eventObject: JQueryEventObject, data: any) => {

            const service = this.getReposSupervisor().getServiceRepo().getOneById(data.newPassId);
            const selectedOption = [{
                sku: service.backendId,
                name: service.label,
                type: 'service',
                priceOnce: '0.00',
                priceMonthly: '0',
                duration: 24
            }];

            const removedOption = [];
            if (data.oldPassId) {
                const service = this.getReposSupervisor().getServiceRepo().getOneById(data.oldPassId);
                removedOption.push({
                    sku: service.backendId,
                    name: service.label,
                    type: 'service',
                    priceOnce: '0.00',
                    priceMonthly: '0',
                    duration: 24
                });
            }

            _ddq.push(['switch enjoy more', {
                selectedOption: selectedOption,
                removedOption: removedOption
            }]);

        });

    }

    public bind (): void {

        this._stepper.bind();

        if (this._subscriptionGroupSwitcher) {
            this._subscriptionGroupSwitcher.bind();
        }

        // co-27531 hardcoding: check, if tradeIn is selected
        // and if so, add do optional services again, but only if not part of optional elements ()
        if (this.hasTradeInSelected()) {
            const elementsOptionalServices = this.getInjector().getFlowState().optionalServiceIds.elements;
            if (-1 === elementsOptionalServices.indexOf(Constants.TradeInDiscount_Id)) {
                this.getInjector().getFlowState().optionalServiceIds.addElement(Constants.TradeInDiscount_Id );
            }
        }

        /**
         * hide Headline above Tariffs
         */
        if ('subscription_overview' === this._stepper.previousStep || this.getInjector().getFlowState().isSubscriptionFirstFlow()) {
            $('#subscriptions').hide();
        }

        this.fillOfferOfferCollection();

        const offer = this.getActiveOffer();

        if (undefined !== offer) {
            this.setSubscriptionName(offer.subscriptionName);
        }

        this._deviceList.bind(
            this.getInjector().getOfferCollection().getDevices()
        );

        this._subscriptionSelection.bind(
            this.getInjector().getOfferCollection().getSubscriptions()
        );

        this._subscriptionSelection.setActiveSubscriptionId(
            this.getInjector().getFlowState().getSubscriptionId()
        );

        this._filters.bind(
            this.getInjector().getOfferCollection().getDevices()
        );

        this._pricebox.bind(
            offer
        );

        if (undefined !== this._ctas) {
            this._ctas.bind(offer);
        }

        this.getInjector().getLoadingIndicator().hide();

        this.events();
        this.render();

        let device;
        if (undefined !== offer && (offer.isDevice() || offer.isHardwareOnly())) {
            device = offer.atomicDevice.getDevice();
            const url = device.getDetailLink(this.getInjector().getOptions().get('device_detail_prefix'));
            this.getInjector().getEvent().trigger('stepper@device_detail_url', url);
        }

        this.tracking('pageviewTracking@onload');

        // co-27531 hardcoding: check, if tradeIn is selected preselect checkbox
        if (this.hasTradeInSelected() && device && device.id) {
            $(`[data-device-id=${device.id}]`).find('.tradeinBox > .checkBox').addClass('selected');
        }
    }
}
